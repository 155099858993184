<script setup>
import { ref, nextTick } from "vue";
import { usePaginatedList } from "../composables";
import { getDataRefs } from "../util/vue";
import scrollToTopOf from "../util/scrollToTopOf";
import FhSection from "../includes/FhSection.vue";
import FhPaginationNav from "./FhPaginationNav.vue";
import FhMediaItem from "./FhMediaListing/FhMediaItem.vue";
import FhButton from "./FhButton.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { type, title, items, linkText, linkAltText, linkTarget, linkUrl } = getDataRefs(props.data);

const PER_PAGE = 8;
const { page, totalPages, sublist: currentPage } = usePaginatedList(items, PER_PAGE);

const rootElem = ref(null);
</script>

<template>
  <div ref="rootElem">
    <FhSection
      aria-label="Media Listing"
      :base="style.base"
      :class="[
        props.style.base?.background !== 'limestone' && props.style.base?.background !== null && props.style.base?.background !== '' ? 'text-white' : ''
      ]"
    >
      <div v-if="type === 'more'" class="mb-f3">
        <span>{{ title }}</span>
      </div>
      <div
        :class="[
          'flex',
          type === 'listing'
            ? ' mb-f6 flex-wrap gap-[3rem] border-b border-solid border-neutral-30 pb-f15 lg:gap-[4rem]'
            : 'flex-col gap-10 md:flex-row md:gap-4'
        ]"
      >
        <FhMediaItem v-for="item in currentPage" :key="item.id" :item="item" :type="type"></FhMediaItem>
      </div>
      <div v-if="type === 'listing' && totalPages > 1" class="mb-f12.5 flex items-center justify-center">
        <FhPaginationNav v-model:page="page" :total-items="items.length" :per-page="PER_PAGE" @page-change="nextTick(() => scrollToTopOf(rootElem))" />
      </div>
      <div v-else-if="linkText" class="mt-f10 flex flex-col md:flex-row md:justify-center">
        <FhButton :aria-label="linkAltText" :href="linkUrl" :target="linkTarget" size="small">{{ linkText }}</FhButton>
      </div>
    </FhSection>
  </div>
</template>

<style lang="pcss" scoped>
::v-deep .text-white * {
  color: white !important;
}
</style>

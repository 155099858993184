<script setup>
import { getDataRefs } from "../util/vue";
import FhHeading from "@/components/FhHeading.vue";
import FhSection from "../includes/FhSection.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { copy } = getDataRefs(props.data);
</script>

<template>
  <FhSection :base="style.base">
    <!-- PRODUCT SUPPORT -->
    <div class="mb-f10 flex flex-col text-neutral-70 md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10 md:pb-f10">
      <FhHeading tag="h2">Product Support</FhHeading>
      <div class="flex flex-col md:flex-row">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="text-f-sm-base" v-html="copy"></div>
      </div>
    </div>
  </FhSection>
</template>

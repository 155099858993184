<script setup>
import { computed } from "vue";
import FhFormField from "./FhFormField.vue";

const props = defineProps({
  modelValue: {
    type: undefined,
    required: true
  },
  /**
   * The select options. One of:
   *   - An array of primitive values: each entry will be used for both the `text` and `value`.
   *   - An array of arrays (e.g., `[["Option 1", 1], ...]`): the two sub-array entries will be used as the `text` and `value`, respectively.
   *   - An array of objects (e.g., `[{text: "Option 1", value: 1}, ...]`). If `value` is missing, then `text` will be used for both the `text` and `value`.
   */
  options: {
    type: Array,
    default: () => []
  },
  /**
   * The placeholder is added as the first option with a `value` of `null`. To ensure it is selected by default, `modelValue` should be `null`.
   */
  placeholder: {
    type: String,
    default: undefined
  },
  label: {
    type: String,
    default: undefined
  },
  note: {
    type: String,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: undefined
  },
  /**
   * The Vuelidate validation object for this field. Use this to automatically set the error state and errorMessage.
   * The `errorMessage` prop will override any error messages from this prop if both are set.
   */
  vuelidate: {
    type: Object,
    default: () => undefined
  },
  /**
   * Fallthrough attributes are applied to the inner form control. Use this object if you need to bind anything custom to the form field wrapper node intead.
   */
  wrapperAttrs: {
    type: Object,
    default: () => {}
  }
});

const _options = computed(() => {
  return props.options.map((opt) => {
    if (Array.isArray(opt)) {
      return { text: opt[0], value: opt.length === 1 ? opt[0] : opt[1] };
    } else if (typeof opt === "object") {
      return { ...opt, value: Object.prototype.hasOwnProperty.call(opt, "value") ? opt.value : opt.text };
    } else {
      return { text: opt, value: opt };
    }
  });
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});
</script>

<template>
  <FhFormField v-bind="{ label, note, disabled, required, error, errorMessage, vuelidate, wrapperAttrs }">
    <template #default="controlBindings">
      <select v-bind="controlBindings" v-model="value" class="fh-input group" :class="{ 'text-neutral-50': value === null }">
        <option v-if="placeholder" :value="null">{{ placeholder }}</option>
        <slot>
          <option v-for="option in _options" :key="option.value" :value="option.value" :disabled="option.disabled">{{ option.text }}</option>
        </slot>
      </select>
    </template>
    <template v-if="$slots.note" #note><slot name="note"></slot></template>
    <template v-if="$slots.errorMessage" #errorMessage><slot name="errorMessage"></slot></template>
  </FhFormField>
</template>

<style scoped>
select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237a7a7a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.5rem;
  padding-right: 3.5rem;
}
</style>

<script setup>
import { computed } from "vue";
import FhIcon from "./FhIcon.vue";
import { CART } from "../constants/routes";

const props = defineProps({
  totalItemsCount: {
    type: Number,
    default: 0,
    validator: (val) => val >= 0
  },
  iconWrapperClass: {
    type: String,
    default: null
  }
});

const isEmpty = computed(() => props.totalItemsCount === 0);
const title = computed(() => (isEmpty.value ? "Cart" : `Cart: ${props.totalItemsCount} ${props.totalItemsCount === 1 ? "item" : "items"}`));
</script>

<template>
  <a class="inline-block" :href="CART" :aria-label="title" :title="title">
    <span :class="props.iconWrapperClass">
      <FhIcon v-if="isEmpty" name="ShoppingCart" class="text-2xl" />
      <span
        v-else
        class="flex h-[1.5rem] min-w-[1.5rem] items-center justify-center bg-neutral-70 px-1 font-bold text-white"
        :class="{
          'text-base': props.totalItemsCount < 10,
          'text-sm': props.totalItemsCount >= 10 && props.totalItemsCount < 100,
          'text-xs': props.totalItemsCount >= 100
        }"
      >
        {{ props.totalItemsCount }}
      </span>
    </span>
  </a>
</template>

<style scoped></style>

<script setup>
import FhImage from "./FhImage.vue";

const props = defineProps({
  category: {
    type: Object,
    required: true
  }
});
</script>

<template>
  <a :href="props.category.url" class="group block">
    <FhImage :src="props.category.image" :alt="`${props.category.name} category`" square border />
    <div class="px-2.5 py-f3">
      <span class="group-hover-underline-with-transition text-f-sm-2xl">{{ props.category.name }}</span>
    </div>
  </a>
</template>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.563 6.4211L12.5003 0.84375L2.4375 6.4211V17.5758L12.5003 23.1532L22.563 17.5758V6.4211ZM10.2473 4.40186L12.5003 3.15315L19.4398 6.99946L17.1932 8.24709L10.2473 4.40186ZM8.16337 5.5569L5.56068 6.99946L12.5003 10.8532L15.1112 9.40325L8.16337 5.5569ZM4.52083 8.73252V16.4211L11.4586 20.2664V12.5852L4.52083 8.73252ZM13.5419 20.2664L20.4797 16.4211V8.73252L13.5419 12.5852V20.2664Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup>
import FhPageHeader from "./FhPageHeader.vue";
import FhCategoryGrid from "./FhCategoryGrid.vue";
import FhButton from "./FhButton.vue";

const props = defineProps({
  categories: {
    type: Array,
    required: true
  },
  header: {
    type: Object,
    required: true
  },
  shopAllButton: {
    type: Object,
    required: true
  }
});
</script>

<template>
  <FhPageHeader :header="props.header.header" :subheader="props.header.subheader" :description="props.header.description" />

  <FhCategoryGrid :categories="props.categories" />
  <div class="flex justify-center py-f5">
    <FhButton :href="props.shopAllButton.url">{{ props.shopAllButton.title }}</FhButton>
  </div>
</template>

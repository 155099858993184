<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.6343 4H2V2H6.27479L7.13907 6.33323H22.2079L20.5992 14.7986C20.4748 15.4269 20.134 15.9921 19.6355 16.3948C19.1397 16.7953 18.5195 17.0094 17.8823 16.9997H9.50493C8.86776 17.0094 8.24754 16.7953 7.75176 16.3948C7.25356 15.9923 6.91286 15.4275 6.78832 14.7995L6.78812 14.7985L4.6343 4ZM7.53798 8.33323L8.75006 14.4102C8.78363 14.5798 8.87545 14.7315 9.00854 14.839C9.14156 14.9464 9.30744 15.0031 9.47714 14.9999L9.48675 14.9997H17.9005L17.9101 14.9999C18.0798 15.0031 18.2457 14.9464 18.3787 14.839C18.5111 14.7321 18.6026 14.5816 18.6366 14.4131L18.6372 14.4102L19.7921 8.33323H7.53798ZM7 20.5C7 19.6716 7.67157 19 8.5 19C9.32843 19 10 19.6716 10 20.5C10 21.3284 9.32843 22 8.5 22C7.67157 22 7 21.3284 7 20.5ZM18.5 19C17.6716 19 17 19.6716 17 20.5C17 21.3284 17.6716 22 18.5 22C19.3284 22 20 21.3284 20 20.5C20 19.6716 19.3284 19 18.5 19Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  /**
   * The Promo Banner message
   */
  largeMessageText: {
    type: String,
    default: null
  },
  smallMessageText: {
    type: String,
    default: null
  },
  /**
   * The color of notification
   * @values Slate, Limestone, Umber, Sage
   */
  backgroundColor: {
    type: String,
    default: "limestone",
    validator: (val) => ["slate", "limestone", "umber", "sage"].includes(val)
  },
  linkUrl: {
    type: String,
    default: null
  },
  linkTarget: {
    type: Boolean,
    default: false
  }
});

const bgColorClass = computed(() => {
  switch (props.backgroundColor) {
    case "limestone":
      return "bg-limestone";
    case "slate":
      return "bg-slate";
    case "umber":
      return "bg-umber";
    case "sage":
      return "bg-sage";
    default:
      return "bg-limestone";
  }
});

const emit = defineEmits(["linkClick"]);
</script>

<template>
  <div class="z-[100]">
    <a data-ga="promo-banner" :href="props.linkUrl" :target="props.linkTarget ? '_blank' : ''" @click="emit('linkClick')">
      <div class="w-full py-f1.5 px-f3" :class="[bgColorClass, props.backgroundColor !== 'limestone' ? 'text-white' : '']">
        <div class="mx-auto max-w-max">
          <span v-if="props.largeMessageText" class="mr-f2 inline-block font-bold">{{ props.largeMessageText }}</span>
          <span>{{ props.smallMessageText }}</span>
        </div>
      </div>
    </a>
  </div>
</template>

import { ref } from "vue";
import { defineStore } from "pinia";

/**
 * The only purpose of this store is to keep track of which shipping addresses have
 * been manually confirmed to accept freight by the end-user, saving them from
 * needing to go through this process more than once per checkout sessions.
 */
export const useShippingAddressStore = defineStore("shippingAddress", () => {
  const addressIdsConfirmedForFreight = ref([]);

  return {
    addressIdsConfirmedForFreight
  };
});

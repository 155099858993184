import { createApp } from "vue";

/**
 * Wraps a Vue component in Web Component of that same name that bootstraps itself as a standalone Vue app when added to the DOM.
 * This is used to render Vue components within Kentico page builder, where the markup is injected after page load.
 */
export default class CreateWebComponent {
  constructor(name, component, initVueApp) {
    name = name
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/[\s_]+/g, "-")
      .toLowerCase();

    this.registerComponent(name, component, initVueApp);
  }
  registerComponent(name, component, initVueApp) {
    customElements.define(
      name,
      class extends HTMLElement {
        constructor() {
          super();
        }
        async connectedCallback() {
          const vueComponentTagName = `${name}-vue`;
          const vueComponentTag = document.createElement(vueComponentTagName);
          Array.from(this.attributes).forEach(({ name, value }) => {
            vueComponentTag.setAttribute(name, value);
          });
          this.insertAdjacentElement("afterbegin", vueComponentTag);

          const app = createApp();
          if (typeof initVueApp === "function") {
            initVueApp.call(null, app);
          }
          app.component(vueComponentTagName, component);
          app.mount(this);
        }
      }
    );
  }
}

<script setup>
import { getDataRefs } from "../util/vue";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const { item } = getDataRefs(props.data);
</script>

<template>
  <!-- item ITEM -->
  <div :class="['-mx-f5 flex flex-col md:flex-row lg:mx-auto', item.layout === 'imageRight' ? 'md:flex-row-reverse' : '']">
    <div class="fhTestimonial__image relative box-border min-w-[50%] md:flex-[1_1_0]">
      <img :src="item.image" class="absolute inset-0 h-full w-full object-cover object-center" :alt="item.imageAltText" />
    </div>
    <div class="relative box-border flex min-w-[50%] flex-col items-start justify-center bg-limestone py-f5 px-f5 md:flex-[1_1_0] lg:px-f12.5">
      <FhWysiwyg>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="quote mb-f3 text-f-base-4xl" v-html="item.quote"></div>
      </FhWysiwyg>
      <div class="mb-1 text-f-sm-xl">{{ item.firstName }} {{ item.lastName }}</div>
      <div class="text-f-xs-sm text-neutral-opacity-60">{{ item.role }}</div>
      <div v-if="item.logo" class="absolute bottom-f5 right-f4 flex h-14 w-16 items-center justify-center lg:h-28 lg:w-32">
        <img :src="item.logo" :alt="item.logoAltText" class="max-h-auto max-w-full" />
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.quote {
  position: relative;
  &::before {
    @apply absolute top-0 left-[-0.5rem] md:left-[-0.75rem] lg:left-[-1rem];
    content: open-quote;
  }
  &::after {
    @apply inline;
    content: close-quote;
  }
}

.fhTestimonial__image::after {
  content: '';
  display: block;
  padding-top: calc((3 / 4) * 100%);
}
</style>

import { ref } from "vue";
import { defineStore } from "pinia";

export const useFeaturesStore = defineStore("features", () => {
  const features = ref({});

  const init = (featuresObj) => {
    features.value = featuresObj;
  };

  const getFeatureValue = (featureName) => {
    if (!Object.hasOwn(features.value, featureName)) return false;

    return features.value[featureName];
  };

  return {
    features,
    getFeatureValue,
    init
  };
});

<script setup>
import { computed } from "vue";
import formatPrice from "@/util/formatPrice";
import { useViewportSizes } from "@/composables";
import FhButton from "@/components/FhButton.vue";
import FhModal from "@/components/FhModal.vue";
import FhCartSummaryLineItem from "@/components/FhCartSummaryLineItem.vue";

const props = defineProps({
  totalItemsPrice: {
    type: Number,
    default: null
  },
  totalShippingPrice: {
    type: Number,
    default: null
  },
  /**
   * If `true`, the `totalShippingPrice` prop will be ignored and the shipping price will be displayed as "to be quoted"
   */
  shippingPriceToBeQuoted: {
    type: Boolean,
    default: false
  },
  totalTaxPrice: {
    type: Number,
    default: null
  },
  totalPrice: {
    type: Number,
    default: null
  },
  shippingTooltip: {
    type: String,
    default: null
  },
  taxTooltip: {
    type: String,
    default: null
  },
  /**
   * This component displays in a collapsed state on mobile and tablet screens, with the full details available in modal.
   * Setting this prop to `true` shows the full details inline, same as the desktop view.
   */
  showInlineOnMobile: {
    type: Boolean,
    default: false
  }
});

function isPriceSet(price) {
  return !!price || price === 0;
}

function getDisplayPrice(price) {
  return isPriceSet(price) ? formatPrice(price) : "--";
}

const summaryLineItems = computed(() => [
  {
    label: "Items in Cart",
    value: getDisplayPrice(props.totalItemsPrice)
  },
  {
    label: "Shipping & Handling",
    value: props.shippingPriceToBeQuoted ? "To be quoted" : getDisplayPrice(props.totalShippingPrice),
    text: props.shippingTooltip
  },
  {
    label: "Tax",
    value: getDisplayPrice(props.totalTaxPrice),
    text: props.taxTooltip
  }
]);

const showBeforeShippingAndTaxNote = computed(() => !isPriceSet(props.totalShippingPrice) && !isPriceSet(props.totalTaxPrice));

const { isLg } = useViewportSizes();
</script>

<template>
  <div class="flex flex-col gap-5 lg:gap-f3">
    <h2 v-if="isLg || props.showInlineOnMobile" class="text-f-xl-4xl">Summary</h2>
    <div v-if="isLg || props.showInlineOnMobile" class="flex flex-col gap-5 lg:gap-f2">
      <FhCartSummaryLineItem v-for="lineItem in summaryLineItems" :key="lineItem.label" v-bind="lineItem" />
    </div>
    <div v-if="$slots.extras && (isLg || props.showInlineOnMobile)" class="border-t border-neutral-30 pt-f3">
      <slot name="extras"></slot>
    </div>
    <div v-if="isLg || props.showInlineOnMobile" class="h-px w-full bg-neutral-30"></div>
    <div class="flex items-start justify-between">
      <div v-if="isLg || props.showInlineOnMobile" class="text-f-sm-lg">Total</div>
      <FhModal v-else :data="{ heading: 'Summary' }">
        <template #activator="{ open }">
          <FhButton variant="link" @click="open">Summary</FhButton>
        </template>
        <template #default>
          <div class="flex flex-col gap-5">
            <FhCartSummaryLineItem v-for="lineItem in summaryLineItems" :key="lineItem.label" v-bind="lineItem" />
            <div v-if="$slots.extras" class="border-t border-neutral-30 pt-5">
              <slot name="extras"></slot>
            </div>
            <div class="flex items-start justify-between border-t border-t-neutral-30 pt-5">
              <div class="text-f-sm-lg">Total</div>
              <div class="text-right">
                <div class="text-f-lg-3xl">{{ getDisplayPrice(props.totalPrice) }}</div>
                <div v-if="showBeforeShippingAndTaxNote" class="text-neutral-50">Before shipping &amp; tax</div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="$slots.actions" #footer>
          <div class="flex w-full flex-col gap-f2">
            <slot name="actions"></slot>
          </div>
        </template>
      </FhModal>
      <div class="text-right">
        <div class="text-f-lg-3xl">{{ getDisplayPrice(props.totalPrice) }}</div>
        <div v-if="showBeforeShippingAndTaxNote" class="text-neutral-50">Before shipping &amp; tax</div>
      </div>
    </div>
    <div v-if="$slots.actions" class="flex flex-col gap-f2" :class="{ 'actions--collapsed': !isLg && !props.showInlineOnMobile }">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<style scoped>
/* Hides all but the primary action */
.actions--collapsed :not(:first-child) {
  @apply hidden;
}
</style>

<script setup>
import { getDataRefs } from "../util/vue";
import FhSection from "../includes/FhSection.vue";
import FhCtaListingItem from "./FhCtaListing/FhCtaListingItem.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { ctas, gaTagging } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    aria-label="CTA Listing"
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div :class="ctas.length > 1 ? 'grid gap-y-16 md:grid-cols-2 md:grid-rows-1 md:gap-y-0 md:gap-x-4 md:pt-4 lg:gap-x-20 lg:pt-14' : 'mx-auto max-w-2xl'">
      <FhCtaListingItem
        v-for="cta in ctas"
        :key="cta.id"
        :cta="cta"
        :ga-tagging="gaTagging"
        class="first:md:mb-f15 last:md:mt-f15 only:md:my-f10"
      ></FhCtaListingItem>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.text-white * {
  color: white;
}
</style>

<script setup>
import { getDataRefs } from "../util/vue";
import { computed, ref, watch, onMounted } from "vue";
import FhButton from "./FhButton.vue";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { title, description, ctaTarget, ctaText, ctaAriaLabel, ctaURL, subContent, leftImages, leftImages_AriaLabel, rightImages, rightImages_AriaLabel } =
  getDataRefs(props.data);

const trackContainer = ref(null);

const trackLeft = ref(null);
const leftImagesLoadState = ref(leftImages.value.map(() => false));

const trackRight = ref(null);
const rightImagesLoadState = ref(rightImages.value.map(() => false));

const allImagesLoaded = computed(() => leftImagesLoadState.value.every((i) => i) && rightImagesLoadState.value.every((i) => i));

const mounted = ref(false);
const _backdoor = ref(0); // reactive property incremented by resize event listener

const animationReady = computed(() => {
  _backdoor.value;
  return mounted.value && trackContainer.value && trackLeft.value && trackRight.value && allImagesLoaded.value;
});

const animating = ref(false);
watch(animationReady, () => {
  if (animationReady.value) {
    setTimeout(() => (animating.value = true), 500);
  }
});

const animationDuration = computed(() => {
  if (!animationReady.value) return "0s";
  // Calculate an animation speed that moves the tracks
  // at a constant velocity regardless of the height of
  // the tracks (which change based on screen size).

  // Reference reactive property so that a change in
  // its value triggers a recalculation of this
  // computed property
  _backdoor.value;

  const velocity = 20; // pixels/second
  const distance = Math.max(trackLeft.value.offsetHeight, trackRight.value.offsetHeight); // pixels

  return `${distance / velocity}s`; // seconds
});

const trackOffset = computed(() => {
  if (!animationReady.value) return "0%";
  // Reference reactive property so that a change in
  // its value triggers a recalculation of this
  // computed property
  _backdoor.value;

  const distance = Math.max(trackLeft.value.offsetHeight, trackRight.value.offsetHeight);
  const containerHeight = trackContainer.value.offsetHeight;
  return {
    left: `translateY(${Math.round(((distance - containerHeight) / distance) * 100)}%)`,
    right: `translateY(${Math.round(((distance - containerHeight) / distance) * -100)}%)`
  };
});

const recalculateAnimation = () => _backdoor.value++;

watch(allImagesLoaded, recalculateAnimation);
onMounted(() => {
  window.addEventListener("resize", recalculateAnimation);
  mounted.value = true;
});
</script>

<template>
  <section class="fh-hero widget--bg-slate flex flex-col-reverse md:min-h-[75vh] md:flex-row">
    <div class="box-border flex flex-[0_0_50%] flex-col justify-center py-8 text-white md:py-24 md:pl-8 md:pr-4">
      <h1 class="mb-f3 font-serif text-f-5xl-9xl">{{ title }}</h1>
      <FhWysiwyg>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="description" class="mb-f6 md:max-w-[350px] lg:max-w-[450px]" v-html="description"></div>
      </FhWysiwyg>
      <FhButton color="primary" inverse :href="ctaURL" :target="ctaTarget" :aria-label="ctaAriaLabel" class="text-center md:self-start">
        {{ ctaText }}
      </FhButton>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <small class="mt-f4" v-html="subContent"></small>
    </div>
    <div class="box-border flex h-[300px] md:h-auto md:flex-[0_0_50%]">
      <div
        ref="trackContainer"
        class="relative left-[50%] min-w-[100vw] translate-x-[-50%] overflow-hidden md:left-0 md:min-w-0 md:flex-[1_1_100%] md:translate-x-[0]"
      >
        <div class="absolute top-0 bottom-0 left-0 flex w-[calc(50%-0.75rem)] flex-col justify-end" :aria-label="leftImages_AriaLabel">
          <div
            ref="trackLeft"
            :class="[
              'fh-hero__images fh-hero__images--left easing-ease py--6 flex flex-col gap-[1.5rem] transition-opacity duration-200',
              allImagesLoaded ? 'opacity-1' : 'opacity-0',
              { animating }
            ]"
          >
            <img
              v-for="(image, i) in leftImages"
              :key="image"
              class="w-full flex-[0_0_auto] self-start"
              :src="image"
              aria-hidden="true"
              @load="leftImagesLoadState[i] = true"
            />
          </div>
        </div>
        <div class="absolute top-0 bottom-0 right-0 flex w-[calc(50%-0.75rem)] flex-col justify-start" :aria-label="rightImages_AriaLabel">
          <div
            ref="trackRight"
            :class="[
              'fh-hero__images fh-hero__images--right easing-ease py--6 flex flex-col gap-[1.5rem] transition-opacity duration-200',
              allImagesLoaded ? 'opacity-1' : 'opacity-0',
              { animating }
            ]"
          >
            <img
              v-for="(image, i) in rightImages"
              :key="image"
              class="w-full flex-[0_0_auto] self-start"
              :src="image"
              aria-hidden="true"
              @load="rightImagesLoadState[i] = true"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="pcss">
.fh-hero {
  z-index: 0;
  position: relative;

  & small {
    &:deep(a) {
      text-decoration: underline;
    }
  }
}

.fh-hero__images.animating {
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.1, 0, 0.9, 1);
  animation-direction: alternate;
  animation-duration: v-bind("animationDuration");
  transform-origin: bottom;
}

.fh-hero__images--left.animating {
  animation-name: images-slide-left;
  @media (prefers-reduced-motion) {
    animation-play-state: paused;
  }
}

.fh-hero__images--right.animating {
  animation-name: images-slide-right;
  @media (prefers-reduced-motion) {
    animation-play-state: paused;
  }
}

@keyframes images-slide-left {
  to {
    transform: v-bind("trackOffset.left");
  }
}

@keyframes images-slide-right {
  to {
    transform: v-bind("trackOffset.right");
  }
}
</style>

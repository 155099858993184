<script setup>
import { computed } from "vue";
import FhOptionGroup from "./FhOptionGroup.vue";

const props = defineProps({
  modelValue: {
    type: Array,
    required: true
  },
  options: {
    type: Array,
    required: true
  },
  facetCounts: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(["update:modelValue"]);

const selectedOptions = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const facetCountValues = computed(() => props.facetCounts.values);

const filterOptions = computed(() => {
  return props.options
    .filter((opt) => {
      // Remove filter options with a facet count of 0 and hideNoResults=true
      const hasFacetCount = facetCountValues.value.find((facetCount) => facetCount.key === opt.value);
      return hasFacetCount || !opt.hideNoResults;
    })
    .map(buildFilterOptions);
});

const getFacetCounts = (facet) => {
  const counts = facetCountValues.value.find((facetValues) => facetValues.key === facet);
  return counts?.value;
};

const buildFilterOptions = (filterOption) => {
  return {
    value: filterOption.value,
    label: filterOption.label,
    selected: selectedOptions.value.includes(filterOption.value),
    count: getFacetCounts(filterOption.value) || 0,
    disabled: !getFacetCounts(filterOption.value),
    options: filterOption?.options ? filterOption?.options.map(buildFilterOptions) : []
  };
};
</script>

<template>
  <FhOptionGroup v-model="selectedOptions" type="checkbox" :options="filterOptions" size="lg"></FhOptionGroup>
</template>

<script setup>
import { computed } from "vue";
import FhFormField from "./FhFormField.vue";

const props = defineProps({
  modelValue: {
    type: undefined,
    required: true
  },
  placeholder: {
    type: String,
    default: undefined
  },
  label: {
    type: String,
    default: undefined
  },
  note: {
    type: String,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: undefined
  },
  /**
   * The Vuelidate validation object for this field. Use this to automatically set the error state and errorMessage.
   * The `errorMessage` prop will override any error messages from this prop if both are set.
   */
  vuelidate: {
    type: Object,
    default: () => undefined
  },
  /**
   * Fallthrough attributes are applied to the inner form control. Use this object if you need to bind anything custom to the form field wrapper node intead.
   */
  wrapperAttrs: {
    type: Object,
    default: () => {}
  }
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});
</script>

<template>
  <FhFormField v-bind="{ label, note, disabled, required, error, errorMessage, vuelidate, wrapperAttrs }">
    <template #default="controlBindings">
      <textarea v-bind="controlBindings" v-model="value" :placeholder="props.placeholder" class="fh-input h-40"></textarea>
    </template>
    <template v-if="$slots.note" #note><slot name="note"></slot></template>
    <template v-if="$slots.errorMessage" #errorMessage><slot name="errorMessage"></slot></template>
  </FhFormField>
</template>

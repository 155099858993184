<script setup>
import { getDataRefs } from "../util/vue";
import FhSection from "../includes/FhSection.vue";
import UpRightArrow from "./icons/IconUpRightArrow.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  },
  isFullWidth: {
    type: Boolean,
    default: false
  }
});

const { pageTitle, links } = getDataRefs(props.data);
</script>

<template>
  <FhSection class="" aria-label="Page Navigation" :base="style.base">
    <div
      v-if="links != null"
      :class="isFullWidth ? '' : 'relative'"
      class="mb-f5 after:absolute after:left-0 after:h-px after:w-full after:bg-neutral-30 after:content-['']"
    >
      <div class="p-f5 pl-0 text-f-base-2xl text-neutral-80">{{ pageTitle }}</div>
      <nav class="w-full overflow-visible">
        <div class="fh-nav-wrapper flex w-full overflow-x-scroll">
          <h1 v-for="(link, index) in links" :key="index" class="mr-f5 [&:not(:first-child)]:ml-f5">
            <a
              :href="link.url"
              :target="link.target"
              :aria-label="link.ariaLabel"
              class="relative inline-flex w-max items-center whitespace-nowrap py-f5 text-f-base-2xl text-neutral-50 transition duration-200 hover:text-neutral-70"
              :class="{ 'is-active text-neutral-70': link.isActive, group: link.target == '_blank' }"
            >
              {{ link.name }}
              <UpRightArrow
                v-if="link.target === '_blank'"
                class="invisible absolute left-[calc(100%-5px)] ml-2 h-3.5 w-3.5 opacity-0 transition-all duration-200 group-hover:visible group-hover:opacity-100 md:h-4 md:w-4 lg:h-5 lg:w-5"
              />
            </a>
          </h1>
        </div>
      </nav>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.is-active:after {
  @apply content-[""] absolute top-[calc(100%-1px)] left-0 right-0 w-full h-px bg-neutral-80 z-10;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.fh-nav-wrapper::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.fh-nav-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

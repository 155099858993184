<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9826 12C19.6634 15.7688 16.0223 18.125 12 18.125C7.97768 18.125 4.3366 15.7688 2.01741 12C4.3366 8.23115 7.97768 5.875 12 5.875C16.0223 5.875 19.6634 8.23115 21.9826 12Z"
      stroke="currentColor"
      stroke-width="1.75"
    />
    <circle cx="12" cy="12" r="3" fill="currentColor" />
  </svg>
</template>

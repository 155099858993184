import { ref } from "vue";
import { defineStore } from "pinia";

export const useNotificationStore = defineStore("notification", () => {
  const notifications = ref([]);
  let id = 1;

  function notify(type, message, notificationSettings) {
    notifications.value.push({
      ...(notificationSettings || {}),
      id: id++,
      type: type,
      message: message
    });
  }

  function notifySuccess(message, notificationSettings) {
    notify("success", message, notificationSettings);
  }

  function notifyError(message, notificationSettings) {
    notify("error", message, notificationSettings);
  }

  function dismiss(id) {
    notifications.value = notifications.value.filter((notification) => notification.id !== id);
  }

  return {
    notifications,
    notify,
    notifySuccess,
    notifyError,
    dismiss
  };
});

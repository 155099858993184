<script setup>
import TransitionExpand from "../transitions/TransitionExpand.vue";
import TransitionFade from "../transitions/TransitionFade.vue";
import { ref, computed } from "vue";
import { cloneDeep } from "lodash";
import { getDataRefs } from "../util/vue";
import { useViewportSizes } from "../composables";
import UpRightArrow from "./icons/IconUpRightArrow.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { items } = getDataRefs(props.data);

const idBase = ref(Math.floor(Math.random() * parseInt(Date.now())));
const triggerId = ref(`trigger-${idBase.value}`);
const regionId = ref(`region-${idBase.value}`);
const triggerAriaAttributes = computed(() => {
  if (isSm) {
    return {
      id: triggerId.value,
      role: "button",
      "aria-controls": regionId.value,
      "aria-expanded": menuOpen.value ? "true" : "false"
    };
  }
  return {};
});
const regionAriaAttributes = computed(() => {
  if (isSm) {
    return {
      id: regionId.value,
      role: "region",
      "aria-labelledby": triggerId.value
    };
  }
  return {};
});

const menuOpen = ref(false);
const activePage = computed(() => {
  return items.value.find((item) => item.isActive);
});

const { isSm } = useViewportSizes();

const menuItems = computed(() => {
  if (isSm.value) {
    return cloneDeep(items.value).filter((item) => !item.isActive);
  }
  return items.value;
});

const menuStyles = computed(() => {
  if (isSm.value) {
    return "absolute top-[100%] w-full pb-2 bg-slate text-white-opacity-70 z-[2]";
  }
  return "flex flex-col items-start text-neutral-50";
});
</script>

<template>
  <div :class="{ 'relative left-1/2 z-50 w-[var(--100vw)] -translate-x-1/2 border-t border-t-neutral-60 text-white': isSm }">
    <button
      v-if="isSm"
      v-bind="triggerAriaAttributes"
      :id="triggerId"
      :class="['z-[2] flex w-full items-center justify-between bg-slate px-f5 pt-5 text-left transition-all', menuOpen ? 'mb-2.5 pb-2.5' : 'mb-0 pb-5']"
      type="button"
      @click.stop.prevent="menuOpen = !menuOpen"
    >
      <span class="grow">
        {{ activePage.name }}
      </span>
      <span class="relative flex flex-[0_0_auto]">
        <span :class="['fh-section-nav__toggle relative', { 'is-open': menuOpen }]"></span>
      </span>
    </button>
    <TransitionExpand v-bind="regionAriaAttributes">
      <ul v-if="menuOpen || !isSm" :class="menuStyles">
        <li v-for="(item, index) in menuItems" :key="index" :class="{ 'text-neutral-70': item.isActive }">
          <a
            :href="item.url"
            class="flex items-center px-f5 py-2.5 transition-all duration-200 ease-linear md:px-3 md:hover:text-neutral-70"
            :class="{ group: item.target == '_blank' }"
            :aria-label="item.ariaLabel"
            :target="item.target"
          >
            {{ item.name }}
            <UpRightArrow
              v-if="item.target == '_blank'"
              :class="{ 'visible opacity-100': isSm, 'invisible ml-2 opacity-0': !isSm }"
              class="transition-all duration-200 group-hover:visible group-hover:opacity-100"
            />
          </a>
          <ul v-if="!isSm && item.childItems && item.isActive" class="ml-3 border-l border-solid border-[black] border-opacity-10 md:ml-0">
            <li
              v-for="(childItem, i) in item.childItems"
              :key="i"
              class="mb-4 pl-8"
              :class="{
                'border-current -ml-px border-l border-solid text-neutral-70': childItem.isActive,
                'text-neutral-50': !childItem.isActive,
                'text-white': isSm
              }"
            >
              <a
                class="transition-all duration-200 ease-linear md:hover:text-neutral-70"
                :href="childItem.url"
                :aria-label="childItem.ariaLabel"
                :target="childItem.target"
              >
                {{ childItem.name }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </TransitionExpand>
    <TransitionFade>
      <div v-if="menuOpen && isSm" class="absolute top-0 left-0 right-0 z-[-1] h-screen w-[var(--100vw)] bg-neutral-80 opacity-[0.35] md:hidden"></div>
    </TransitionFade>
  </div>
</template>

<style lang="pcss" scoped>
.fh-section-nav__toggle {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 1rem;
  height: 0.5rem;
  align-items: start;
  justify-items: center;

  &::before,
  &::after {
    content: "";
    display: block;
    grid-area: 1 / 1 / 2 / 2;
    border-radius: 2px;
    height: 2px;
    width: 8px;
    background: #fff;
    transition: 0.2s ease;
  }
  &::before {
    transform: translateX(1px) rotate(-45deg);
    transform-origin: top right;
  }
  &::after {
    transform: rotate(45deg);
    transform-origin: top left;
  }
}
.fh-section-nav__toggle.is-open {
  &::before,
  &::after {
    transform-origin: 50% 50%;
    width: 12px;
  }
  &::before {
    transform: rotate(-45deg);
  }
}
</style>

<script setup>
import { ref } from "vue";
const props = defineProps({
  src: {
    type: String,
    required: true
  },
  poster: {
    type: String,
    default: null
  },
  autoplay: {
    type: Boolean,
    default: false
  },
  loop: {
    type: Boolean,
    default: false
  },
  playsinline: {
    type: Boolean,
    default: true
  },
  controls: {
    type: Boolean,
    defaul: false
  }
});
const emit = defineEmits(["vidloaded"]);
const videoPlayer = ref(null);
const isPlaying = ref(true);
const videoToggle = () => {
  const video = videoPlayer.value;
  if (isPlaying.value) {
    video.pause();
  } else {
    video.play();
  }
  isPlaying.value = !isPlaying.value;
};
const videoFrameLoaded = () => {
  emit("vidloaded", videoPlayer.value);
};
</script>

<template>
  <div class="relative">
    <video
      ref="videoPlayer"
      class="fh_video"
      :src="props.src"
      :poster="props.poster"
      :autoplay="props.autoplay"
      :loop="props.loop"
      :controls="props.controls"
      :playsinline="props.playsinline"
      muted
      @loadeddata="videoFrameLoaded"
    />
    <slot v-if="$slots.overlay" name="overlay" :video-toggle="videoToggle" :is-playing="isPlaying"></slot>
  </div>
</template>

<style lang="pcss" scoped></style>

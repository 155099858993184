<script setup>
import { computed, reactive } from "vue";
import FhInput from "@/components/FhInput.vue";
import FhSelect from "@/components/FhSelect.vue";
import FhModal from "@/components/FhModal.vue";
import FhButton from "@/components/FhButton.vue";
import { PRICING_LEVELS, PRICING_LEVELS_MARK_UP, CAD_PRICING } from "@/constants";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import FhTooltip from "./FhTooltip.vue";
const props = defineProps({
  isCreating: {
    type: Boolean,
    default: false
  },
  editMode: {
    type: Boolean,
    default: false
  },
  accountDetails: {
    type: Object,
    default: () => {}
  },
  error: {
    type: Object,
    default: null
  },
  markup: {
    type: Number,
    default: 0
  },
  showPriceMarkup: {
    type: Boolean, // Just using this as a feature flag
    default: false
  },
  showCADPricing: {
    type: Boolean, // Just using this as a feature flag
    default: false
  }
});

const emits = defineEmits(["create", "update"]);

const initialState = {
  name: null,
  email: null,
  pricingVisibility: null
};

const errorObject = computed(() => {
  return props.error?.response;
});

const formData = reactive({ ...initialState, ...props.accountDetails });

const editMode = computed(() => props.editMode);

const emailValidation = helpers.regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/);
const markup = computed(() => (props.markup != null ? props.markup : 0));

const vuelidate = useVuelidate(
  {
    name: { mustHave: helpers.withMessage("Please enter a name for the account.", required) },
    email: {
      mustHave: helpers.withMessage("Please enter an email for the account.", required),
      email: helpers.withMessage(
        "The email must be valid and can contain only alphanumeric characters and some special characters (_, -, ., @).",
        emailValidation
      )
    },
    pricingVisibility: { mustHave: helpers.withMessage("Please select a pricing level for the account.", required) }
  },
  formData
);

const isCreatingAccount = computed(() => props.isCreating);

const createAccount = () => {
  if (errorObject.value) errorObject.value = null;
  if (editMode.value) {
    emits("update", formData);
  } else {
    emits("create", formData);
  }
};

const reset = () => {
  vuelidate.value.$reset();
  formData.name = null;
  formData.email = null;
  formData.pricingVisibility = null;
};

//TODO: Feature Price Markup - remove refactor when ready to release or feature is complete
//TODO: Feature CAD MAP/MSRP - remove refactor when ready to release or feature is complete
//Look into turning this into a compostable since its used in a few spots
const pricing_visibilities = computed(() => {
  if (props.showPriceMarkup && props.showCADPricing) {
    return [...PRICING_LEVELS_MARK_UP(markup.value), ...CAD_PRICING];
  } else if (props.showPriceMarkup && !props.showCADPricing) {
    return PRICING_LEVELS_MARK_UP(markup.value);
  } else if (!props.showPriceMarkup && props.showCADPricing) {
    return [...PRICING_LEVELS, ...CAD_PRICING];
  } else {
    return PRICING_LEVELS;
  }
});
</script>

<template>
  <FhModal ref="modal" :data="{ heading: editMode != true ? 'Create a Guest Account' : 'Edit a Guest Account' }" @close="reset">
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <form id="guestAccountForm" @submit.prevent="createAccount">
        <fieldset class="mb-f8">
          <legend class="mb-f4 text-f-xl-2xl">Account Details</legend>
          <div class="flex flex-col gap-f3">
            <FhInput v-model="formData.name" label="Name" required :vuelidate="vuelidate.name" />
            <FhInput v-model="formData.email" label="Email" required :vuelidate="vuelidate.email" :disabled="editMode" />
            <label class="-mb-5 flex gap-f1">
              Pricing Visibility
              <FhTooltip v-if="showPriceMarkup" class="mr-2 ml-2"
                >Select from one of the pre-configured pricing options below or go to My Account to set or update your unique price markup.</FhTooltip
              >
              <span class="text-body-xs leading-6 text-neutral-50"> (Required)</span>
            </label>
            <FhSelect
              v-model="formData.pricingVisibility"
              :options="pricing_visibilities"
              placeholder="Select a pricing visibility"
              required
              :vuelidate="vuelidate.pricingVisibility"
            />
          </div>
          <div v-if="errorObject" class="mt-3 flex items-center gap-2 py-2 text-brand-red">
            <div>
              <fh-icon name="AlertCircle" />
            </div>
            <div v-html="errorObject.data"></div>
          </div>
        </fieldset>
      </form>
    </template>
    <template #footer="{ close }">
      <FhButton type="submit" form="guestAccountForm" color="primary" :disabled="vuelidate.$invalid || isCreatingAccount" :is-loading="isCreatingAccount">{{
        editMode ? "Update" : "Save"
      }}</FhButton>
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
</template>

<style scoped>
.leading-6 {
  line-height: 1.5rem !important;
}
</style>

export const PRICING_LEVELS = [
  {
    value: "none",
    text: "No Price"
  },
  {
    value: "M",
    text: "MAP"
  },
  {
    value: "R",
    text: "MSRP"
  }
];

// TODO New Pricing Visiblites for price markup feature
export const PRICING_LEVELS_MARK_UP = (markup) => [
  {
    value: "none",
    text: "No Price"
  },
  {
    value: "M",
    text: "MAP"
  },
  {
    value: "R",
    text: "MSRP"
  },
  {
    value: "markup",
    text: `Price Markup: Your Price + ${markup}%`
  },
  {
    value: "account",
    text: "Your Price"
  }
];

// TODO New Pricing Visiblites for price markup feature
export const CAD_PRICING = [
  {
    value: "CADM",
    text: "CAD MAP"
  },
  {
    value: "CADR",
    text: "CAD MSRP"
  }
];

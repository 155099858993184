<script setup>
import { getDataRefs } from "@/util/vue";
import FhSection from "@/includes/FhSection.vue";
import FhVideoModal from "@/components/FhVideo/FhVideoModal.vue";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { posterImage, caption, gaTagging } = getDataRefs(props.data);

let modalData = {
  embedLink: props.data.embedLink,
  caption: props.data.caption
};
</script>

<template>
  <FhSection
    aria-label="Video"
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
    :data-ga="gaTagging"
  >
    <div class="mx-auto max-w-[1530px]">
      <figure class="relative aspect-video">
        <FhVideoModal :modal-data="modalData">
          <template #default="{ toggleModal }">
            <FhButton
              variant="round"
              class="absolute top-1/2 left-1/2 z-10 flex !h-f15 !w-f15 -translate-y-1/2 -translate-x-1/2 items-center justify-center border-none !bg-neutral-opacity-35"
              aria-label="Play video"
              @click="toggleModal"
            >
              <FhIcon name="Play" class="h-f5 w-f5 translate-x-1" />
              <span class="sr-only">Play Video</span>
            </FhButton>
          </template>
        </FhVideoModal>
        <img class="h-full w-full object-cover object-center" :src="posterImage" :alt="caption" />
        <figcaption class="pt-f4 text-f-sm-xl md:px-4">{{ caption }}</figcaption>
      </figure>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped></style>

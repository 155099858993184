<script setup>
import { ref, computed } from "vue";
import FhModal from "../FhModal.vue";
import FhButton from "../FhButton.vue";
import FhImageCarousel from "../FhImageCarousel.vue";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  modalData: {
    type: Object,
    required: true
  }
});

const modal = ref(null);
const isOpen = ref(false);
const toggleModal = () => {
  isOpen.value = !isOpen.value;
};

const carouselBreakpoints = computed(() => {
  return {
    0: {
      slidesPerView: 1.5,
      spaceBetween: 10
    },
    576: {
      slidesPerView: 2.5,
      spaceBetween: 20
    }
  };
});
</script>

<template>
  <slot :toggle-modal="toggleModal">
    <!-- Button To Open/Close Injected Here -->
  </slot>
  <FhModal ref="modal" v-model:is-open="isOpen" wide>
    <template #body>
      <div class="flex flex-col items-start justify-start">
        <div class="mb-f3 font-serif text-f-2xl-4xl">{{ modalData.title }}</div>
        <div class="mb-f5 text-neutral-50">{{ modalData.date }}</div>
        <FhWysiwyg>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p class="mb-f8" v-html="modalData.copy"></p>
        </FhWysiwyg>
        <FhButton
          variant="text"
          size="small"
          class="underline underline-offset-4"
          :href="modalData.followUsLink.url"
          :target="modalData.followUsLink.target"
          :aria-label="modalData.followUsLink.ariaLabel"
          >{{ modalData.followUsLink.label }}</FhButton
        >
      </div>
      <FhImageCarousel class="-mr-f5" :data="{ slides: modalData.slides }" :breakpoints="carouselBreakpoints"></FhImageCarousel>
    </template>
  </FhModal>
</template>

<style lang="pcss" scoped></style>

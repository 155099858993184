<script setup>
import { computed } from "vue";

const props = defineProps({
  shippedTotal: {
    type: Number,
    default: 0
  },
  nextLevel: {
    type: String,
    default: null
  },
  amountToNextLevel: {
    type: Number,
    default: 0
  },
  nextDiscount: {
    type: String,
    default: null
  },
  pricingLevel: {
    type: String,
    default: null
  },
  pricingLevelDiscount: {
    type: String,
    default: null
  }
});

const levelTotal = computed(() => {
  return props.nextLevel == "Enterprise" ? 20000 : 10000;
});

const pricingProgressWidth = computed(() => {
  const totalFromLevel = levelTotal.value - props.amountToNextLevel;
  return `${Math.floor((totalFromLevel / levelTotal.value) * 100)}%`;
});
</script>

<template>
  <div class="-z-[1] flex flex-col">
    <template v-if="props.pricingLevel == 'Enterprise'">
      <span class="mb-f4 text-f-sm-2xl font-bold">Congratulations, welcome to the top tier.</span>
    </template>
    <template v-else>
      <span class="mb-f2 text-f-sm-2xl font-bold">Spend ${{ props.amountToNextLevel.toLocaleString() }} to reach {{ props.nextLevel }} </span>
      <div class="relative mb-f1.5 h-3.5 w-full bg-neutral-40">
        <span class="progress-bar absolute left-0 top-0 bottom-0 h-3.5 bg-black"></span>
      </div>
      <div class="flex">
        <span class="mr-auto text-f-xs-sm text-neutral-50"> {{ props.pricingLevel }} Status: {{ props.pricingLevelDiscount }} </span>
        <span class="ml-auto text-f-xs-sm text-neutral-50"> {{ props.nextLevel }} Status: {{ props.nextDiscount }} </span>
      </div>
    </template>
  </div>
</template>

<style lang="pcss">
.progress-bar {
  width: 0%;
  animation: grow 1s 0.5s ease 1 forwards;
}

@keyframes grow {
  to {
    width: v-bind("pricingProgressWidth");
  }
}
</style>

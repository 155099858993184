<script setup>
import { SUPPORT_HOURS_OF_OPERATION, SUPPORT_PHONE_NUMBER } from "../constants/strings";
import Logo from "../assets/four-hands-logo.svg";
import FhIcon from "@/components/FhIcon.vue";

defineProps({
  social: {
    type: Object,
    default: null
  },
  legal: {
    type: Object,
    default: null
  },
  artStudioUrl: {
    type: String,
    default: null
  },
  simpleMode: {
    type: Boolean,
    default: false
  }
});

const currentYear = new Date().getFullYear();
</script>

<template>
  <!-- Footer: Bottom Section -->
  <div class="py-f5" :class="{ 'lg:border-t lg:border-neutral-30': !simpleMode }">
    <div class="pg-container flex flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center lg:gap-8">
      <div class="flex flex-col gap-1 lg:flex-row lg:items-center">
        <img :src="Logo" alt="Four Hands Logo" class="mr-f3 w-f5" />
        <div class="text-body-sm mt-1 text-neutral-opacity-60">&copy; {{ currentYear }} Four Hands · All Rights Reserved</div>
      </div>
      <div class="md:flex-grow">
        <ul v-if="!simpleMode" class="text-body-xs flex space-x-f2">
          <li v-for="(link, linkIndex) in social" :key="linkIndex" class="text-xl">
            <a :href="link.ctaLink" target="_blank"><FhIcon :name="link.title"></FhIcon></a>
          </li>
        </ul>
        <span v-if="simpleMode" class="text-body-sm">{{ SUPPORT_PHONE_NUMBER }} &#8226; {{ SUPPORT_HOURS_OF_OPERATION }}</span>
      </div>
      <div>
        <ul class="text-body-xs flex flex-col gap-2 text-neutral-40 lg:flex-row lg:gap-5">
          <li v-for="(link, Index) in legal" :key="Index" class="text-body-sm text-neutral-opacity-60">
            <a :href="link.ctaLink">{{ link.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

import { ref, computed } from "vue";

export const useUrlState = () => {
  const filterSearchURL = computed(() => new URL(window.location));
  const urlSearchParams = ref(new URLSearchParams(filterSearchURL.value.searchParams));

  const searchParams = computed(() => {
    const searchParamsObj = [];
    const queryParams = urlSearchParams.value.entries();
    for (const [key, value] of queryParams) {
      if (!["page", "sort"].includes(key)) {
        searchParamsObj.push({
          key: key,
          values: value.split(",")
        });
      }
    }
    return searchParamsObj;
  });

  const currentSortOrder = computed(() => {
    return urlSearchParams.value.has("sort") ? urlSearchParams.value.get("sort") : null;
  });

  const currentPage = computed(() => {
    return urlSearchParams.value.has("page") ? parseInt(urlSearchParams.value.get("page")) - 1 : 0;
  });

  const setURLParam = (key, value) => {
    filterSearchURL.value.searchParams.set(key, value);
  };

  const updateURL = () => {
    window.history.replaceState({}, "", filterSearchURL.value);
  };

  const clearAllParams = () => {
    const searchParams = filterSearchURL.value.searchParams;
    const keysToDelete = [];
    searchParams.forEach((value, key) => {
      if (key != "query") keysToDelete.push(key);
    });
    keysToDelete.forEach((searchParam) => {
      searchParams.delete(searchParam);
    });
    updateURL();
  };

  const deleteSearchParam = (searchKey) => {
    filterSearchURL.value.searchParams.delete(searchKey);
  };

  return {
    setURLParam,
    updateURL,
    clearAllParams,
    deleteSearchParam,
    searchParams,
    currentPage,
    currentSortOrder
  };
};

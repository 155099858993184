<script setup>
import { ref, reactive } from "vue";
import FhModal from "../FhModal.vue";

const props = defineProps({
  material: {
    type: Object,
    required: true
  }
});

const isOpen = ref(false);
const toggleModal = () => {
  isOpen.value = !isOpen.value;
};
const modalData = reactive({
  version: "product",
  heading: props.material.careTitle,
  copy: props.material.careCopy,
  ctaText: props.material.ctaText,
  ctaURL: props.material.ctaURL,
  ctaTarget: props.material.ctaTarget,
  ctaAriaLabel: "Download " + props.material.materialName + " Care Guide",
  ctaDownload: true
});
</script>

<template>
  <button
    type="button"
    class="flex cursor-pointer items-start gap-[1.5rem] border-[1px] border-solid border-neutral-30 p-f5 text-left transition-all hover:bg-neutral-10"
    @click="toggleModal"
  >
    <img :src="material.imageSrc" class="max-w-[70px]" />
    <div class="flex flex-col gap-[0.25rem]">
      <p class="text-f-base-lg">{{ material.title }}</p>
      <fh-wyswiyg>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="text-f-xs-sm text-neutral-50" v-html="material.copy"></p>
      </fh-wyswiyg>
    </div>
  </button>
  <FhModal v-model:is-open="isOpen" :data="modalData"></FhModal>
</template>

<style lang="pcss" scoped></style>

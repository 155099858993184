<script setup>
import { computed, ref, onMounted } from "vue";

const props = defineProps({
  step: {
    type: Number,
    required: true
  },
  total: {
    type: Number,
    required: true
  }
});

const barWidth = computed(() => {
  return `${(props.step / props.total) * 100}%`;
});

const mounted = ref(false);
onMounted(() => {
  setTimeout(() => (mounted.value = true), 400);
});
</script>

<template>
  <div class="z-10 mb-f5 flex flex-col gap-f2 bg-white pb-f3">
    <h6 id="tpaProgressBarLabel">Trade Application</h6>
    <div
      role="progressbar"
      aria-labelledby="tpaProgressBarLabel"
      aria-valuetext="current page"
      aria-valuemin="1"
      :aria-valuemax="step"
      :aria-valuenow="total"
      :class="{ mounted }"
    ></div>
  </div>
</template>

<style lang="pcss" scoped>
[role="progressbar"] {
  @apply bg-neutral-opacity-10 flex h-px;

  &.mounted {
    &::before {
      width: v-bind("barWidth");
    }
  }

  &::before {
    @apply bg-neutral-70;
    content: '';
    width: 0;
    transition: width 0.35s ease;
  }
}
</style>

import { ref } from "vue";
import { defineStore } from "pinia";
import { send_user_dimension } from "@/util/googleAnalytics";

export const useUserStore = defineStore("user", () => {
  const user = ref(null);

  const init = (userObj) => {
    user.value = userObj;
    send_user_dimension(user.value);
  };

  return {
    user,
    init
  };
});

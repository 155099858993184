<script setup>
import { ref } from "vue";
import { useShippingAddressesQuery, useDeleteShippingAddressMutation } from "../api/shippingAddressApi";
import { useNotificationStore } from "../stores/NotificationStore.js";
import FhShippingAddressListPresenter from "./FhShippingAddressListPresenter.vue";

const props = defineProps({
  mode: {
    type: String,
    required: true,
    validator: (val) => ["manage", "select"].includes(val)
  },
  /**
   * Relevant only if `mode` is `select`. The id of the selected address.
   */
  selectedId: {
    type: Number,
    default: null
  },
  /**
   * Relevant only if `mode` is `select`. Indicates that only addresses that have been verified for freight should be allowed to be selected.
   */
  requireFreight: {
    type: Boolean,
    default: false
  },
  /**
   * Controls the vertical alignment of the header content and "Add Address" button, which appear in a single row in the tablet and desktop layouts.
   */
  headingRowAlignment: {
    type: String,
    default: "start",
    validator: (val) => ["start", "center", "end"].includes(val)
  },
  isResidentialShippingEligible: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["create", "update:selectedId"]);

const notificationStore = useNotificationStore();

const { isLoading, isError, data: shippingAddresses } = useShippingAddressesQuery({ includeSingleUse: props.mode === "select" });

const deleteShippingAddressMutation = useDeleteShippingAddressMutation();

const isDeletingAddressIds = ref([]);

function handleCreate(newAddress) {
  emit("create", newAddress);
}

function handleDelete(id) {
  isDeletingAddressIds.value.push(id);
  deleteShippingAddressMutation.mutate(id, {
    onSuccess: () => notificationStore.notifySuccess("Shipping address removed."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not remove that shipping address."),
    onSettled: () => isDeletingAddressIds.value.splice(isDeletingAddressIds.value.indexOf(id), 1)
  });
}
</script>

<template>
  <FhShippingAddressListPresenter
    v-bind="{ ...props, shippingAddresses, isLoading, isError, isDeletingAddressIds }"
    @create="handleCreate"
    @delete="handleDelete"
    @update:selected-id="(val) => emit('update:selectedId', val)"
  >
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope"></slot>
    </template>
  </FhShippingAddressListPresenter>
</template>

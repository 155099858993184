<script setup>
import { ref, computed, watch } from "vue";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhImage from "@/components/FhImage.vue";
import FhVideo from "@/components/FhVideo.vue";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode, Thumbs, Keyboard } from "swiper/modules";
import { MEDIA_TYPES } from "@/constants/media";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

const props = defineProps({
  isActive: {
    type: Boolean,
    required: true
  },
  media: {
    type: Array,
    required: true
  },
  productName: {
    type: String,
    required: true
  },
  skuName: {
    type: String,
    required: true
  },
  initialMediaIndex: {
    type: Number,
    required: true
  },
  isAuthenticated: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["close"]);

const realIndex = ref(0);

function setIndex(swiper) {
  realIndex.value = swiper.realIndex;
}

const activeHiResImageUrl = computed(() => {
  if (props.media[realIndex.value].type === MEDIA_TYPES.VIDEO) {
    return props.media[realIndex.value].videoUrl;
  }

  return props.media[realIndex.value].highResImageUrl;
});

const mainSwiper = ref(null);
const thumbsSwiper = ref(null);

const setMainSwiper = (swiper) => (mainSwiper.value = swiper);
const setThumbsSwiper = (swiper) => (thumbsSwiper.value = swiper);

const nextButton = ref(null);

const slidePrev = () => mainSwiper.value?.slidePrev();
const slideNext = () => mainSwiper.value?.slideNext();

const destroySwiper = (swiperRef) => {
  if (swiperRef.value === null) return;
  swiperRef.value.destroy(true, false);
  swiperRef.value = null;
};

const vidLoaded = (video) => {
  // This is a hack please fix me correctly
  const vidContainer = document.querySelector(".vid-container");
  if (video?.offsetWidth > 0) vidContainer.style.maxWidth = `${video.offsetWidth}px`;
  else video.style.width = "100%";
};

watch(
  () => props.isActive,
  (val) => {
    if (val) return;
    destroySwiper(mainSwiper);
    destroySwiper(thumbsSwiper);
  }
);
</script>

<template>
  <TransitionRoot :show="props.isActive" as="template">
    <Dialog as="div" :initial-focus="nextButton" class="relative z-20" @close="emit('close')">
      <div class="fixed inset-0 overflow-y-auto">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="relative flex h-full w-full scale-95 flex-col bg-white opacity-0 transition-all">
            <FhButton variant="round" class="absolute top-f5 right-f5 z-10 !bg-white-opacity-70" title="Close gallery" @click="emit('close')">
              <FhIcon name="XIcon" />
            </FhButton>
            <div class="flex-1">
              <Swiper
                :modules="[Thumbs, Keyboard]"
                :thumbs="{ swiper: thumbsSwiper }"
                :keyboard="{ enabled: true }"
                :initial-slide="props.initialMediaIndex"
                loop
                class="group h-full"
                @swiper="setMainSwiper"
                @active-index-change="setIndex"
              >
                <SwiperSlide v-for="mediaItem in props.media" :key="mediaItem.largeUrl">
                  <div
                    class="h-full"
                    :class="{
                      'flex justify-center': mediaItem.type === MEDIA_TYPES.VIDEO
                    }"
                  >
                    <img
                      v-if="mediaItem.type === MEDIA_TYPES.IMAGE"
                      :src="mediaItem.largeUrl"
                      class="mx-auto h-0 min-h-full object-contain"
                      :alt="mediaItem.altText"
                    />
                    <FhVideo
                      v-if="mediaItem.type === MEDIA_TYPES.VIDEO"
                      :src="mediaItem.videoUrl"
                      :autoplay="true"
                      :loop="true"
                      class="vid-container flex-1"
                      @vidloaded="vidLoaded"
                    >
                      <template #overlay="{ videoToggle, isPlaying }">
                        <div class="absolute top-0 left-0 h-full w-full"></div>
                        <button class="absolute bottom-2 right-2 flex h-16 w-16" @click.stop="videoToggle">
                          <FhIcon v-if="!isPlaying" name="VideoPlay" class="h-full w-full" />
                          <FhIcon v-else name="VideoPause" class="h-full w-full" />
                        </button>
                      </template>
                    </FhVideo>
                  </div>
                </SwiperSlide>
                <template #container-start>
                  <FhButton
                    variant="round"
                    class="absolute top-1/2 left-f5 z-10 -translate-y-1/2 !bg-white-opacity-70 opacity-0 transition-opacity focus-visible:opacity-100 group-hover:opacity-100"
                    title="Previous"
                    @click="slidePrev"
                  >
                    <FhIcon name="LeftChevron" />
                  </FhButton>
                </template>
                <template #container-end>
                  <FhButton
                    ref="nextButton"
                    variant="round"
                    class="absolute top-1/2 right-f5 z-10 -translate-y-1/2 !bg-white-opacity-70 opacity-0 transition-opacity focus-visible:opacity-100 group-hover:opacity-100"
                    title="Next"
                    @click="slideNext"
                  >
                    <FhIcon name="RightChevron" />
                  </FhButton>
                </template>
              </Swiper>
            </div>
            <div class="flex-0 flex h-min w-full items-center gap-f5 border-t border-neutral-30 px-f5 py-4">
              <div class="flex-0 whitespace-nowrap">
                <div class="text-f-lg-2xl">{{ props.productName }}</div>
                <div class="text-neutral-50">{{ props.skuName }}</div>
              </div>
              <div class="h-[5.5rem] min-w-0 flex-1">
                <Swiper
                  :modules="[FreeMode, Thumbs]"
                  slides-per-view="auto"
                  :space-between="8"
                  watch-slides-progress
                  free-mode
                  center-insufficient-slides
                  class="thumbs-swiper w-full"
                  @swiper="setThumbsSwiper"
                >
                  <SwiperSlide v-for="mediaItem in props.media" :key="mediaItem.thumbUrl">
                    <FhImage :src="mediaItem.thumbUrl" :alt="mediaItem.altText" square border class="thumb-img" />
                    <div v-if="mediaItem.type === MEDIA_TYPES.VIDEO" class="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black/20">
                      <FhIcon name="VideoPlay" class="h-[46px] w-[46px]" />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div v-if="props.isAuthenticated" class="flex-0">
                <FhButton
                  :href="activeHiResImageUrl"
                  download
                  variant="text"
                  aria-label="Download a high-resolution version of this image"
                  title="Download a high-resolution version of this image"
                  class="whitespace-nowrap"
                >
                  <FhIcon name="Download" /> Hi-Res
                </FhButton>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style lang="pcss" scoped>
.thumbs-swiper .swiper-slide {
  width: 5.5rem;
  height: 5.5rem;
}
.thumbs-swiper .swiper-slide-thumb-active .thumb-img {
  @apply border-neutral-70;
}
:deep(.fh_video) {
  @apply h-0 min-h-full object-contain;
  aspect-ratio: 1/1;
}
</style>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.37459 15.5859L20.0964 5.29297L21.5695 6.70718L9.37459 18.4143L3.42969 12.7072L4.90283 11.293L9.37459 15.5859Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup>
import { computed, watch, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, requiredIf, maxLength } from "@vuelidate/validators";
import FhHeading from "@/components/FhHeading.vue";
import FhOptionCard from "@/components/FhOptionCard.vue";
import FhButton from "@/components/FhButton.vue";
import FhInput from "@/components/FhInput.vue";
import { useViewportSizes } from "@/composables";
import TransitionExpand from "@/transitions/TransitionExpand.vue";

const emit = defineEmits(["update:modelValue", "navigate"]);
const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  model: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
});

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const modelProperties = computed(() => props.model);

const primaryProjectTypeOtherRequired = computed(() => {
  return value.value.primaryProjectType === "commercial-specialty";
});

watch(primaryProjectTypeOtherRequired, (val) => {
  if (val) return;
  value.value.primaryProjectTypeOther = "";
});

const vuelidate = useVuelidate(
  {
    primaryProjectType: {
      required: helpers.withMessage(
        "An option must be selected",
        requiredIf(() => value.value.primaryProjectType == null)
      )
    },
    primaryProjectTypeOther: {
      requiredIf: helpers.withMessage("Please describe what types of projects you work on.", requiredIf(primaryProjectTypeOtherRequired)),
      maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(255))
    }
  },
  value
);

onMounted(() => {
  if (modelProperties.value.projectType.projectTypeOther.length > 0) {
    modelProperties.value.projectType.projectTypeOther = [];
    modelProperties.value.projectType.projectTypeOtherOther = "";
  }
});

const { isSm } = useViewportSizes();
</script>

<template>
  <div class="flex flex-col">
    <FhHeading heading="What commercial design projects does your business do most?" subheading="Select only one." />
    <div class="grid grid-cols-2 gap-f3 lg:grid-cols-3">
      <FhOptionCard
        v-for="option in options"
        :key="option.value"
        v-model="value.primaryProjectType"
        :value="option.value"
        :label="option.label"
        type="radio"
        layout="column"
        class="cb-grid__item"
      />
    </div>
    <TransitionExpand>
      <FhInput
        v-if="primaryProjectTypeOtherRequired"
        v-model="value.primaryProjectTypeOther"
        label="If other, please describe"
        :wrapper-attrs="{ class: 'mt-4 lg:col-span-3' }"
        required
        :vuelidate="vuelidate.primaryProjectTypeOther"
      />
    </TransitionExpand>

    <div class="mt-f8 flex items-center gap-f3 md:self-end">
      <FhButton :variant="isSm ? false : 'text'" :class="{ 'flex-1': isSm }" @click="emit('navigate', -1)">Back</FhButton>
      <FhButton :disabled="vuelidate.$invalid" color="primary" :class="{ 'flex-1': isSm }" @click="emit('navigate', 3)">Continue</FhButton>
    </div>
  </div>
</template>

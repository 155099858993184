<script setup>
import { computed } from "vue";
import FhButton from "./FhButton.vue";
import FhIcon from "./FhIcon.vue";

const props = defineProps({
  page: {
    type: Number,
    required: true
  },
  totalItems: {
    type: Number,
    required: true,
    validator: (value) => value > 0
  },
  perPage: {
    type: Number,
    default: 10,
    validator: (value) => value > 0
  }
});

const emit = defineEmits(["update:page", "pageChange"]);

const currentPage = computed({
  get: () => props.page,
  set: (val) => {
    if (val !== props.page) {
      emit("update:page", val);
      emit("pageChange", val);
    }
  }
});

const totalPages = computed(() => Math.max(Math.ceil(props.totalItems / props.perPage), 1));
const isOnFirstPage = computed(() => currentPage.value === 1);
const isOnLastPage = computed(() => currentPage.value === totalPages.value);

const paginationVisibility = (i) => {
  let cp = currentPage.value;
  let tp = totalPages.value;

  if (tp <= 9) return true;
  if (i === 0) return true;
  if (i === tp - 1) return true;
  if (cp < 5 && i < 5) return true;
  if (Math.abs(cp - i) <= 1) return true;
  if (cp > tp - 5 && i > tp - 5) return true;

  return false;
};
</script>

<template>
  <nav role="navigation" class="flex items-center justify-center gap-4">
    <FhButton size="small" variant="round" :disabled="isOnFirstPage" title="Go to first page" @click="currentPage--">
      <FhIcon name="LeftChevron" />
    </FhButton>
    <div class="flex">
      <template v-for="(pageNum, index) in totalPages" :key="index">
        <FhButton
          v-if="paginationVisibility(index)"
          class="h-6 w-6"
          :class="[currentPage === pageNum ? 'font-bold text-neutral-70' : 'text-neutral-50']"
          variant="text"
          size="small"
          :title="`Go to page ${pageNum}`"
          :aria-current="currentPage === pageNum"
          @click="currentPage = pageNum"
        >
          {{ pageNum }}
        </FhButton>
        <span v-else class="pagination__ellipsis">...</span>
      </template>
    </div>
    <FhButton size="small" variant="round" :disabled="isOnLastPage" title="Go to last page" @click="currentPage++">
      <FhIcon name="RightChevron" />
    </FhButton>
  </nav>
</template>

<style lang="pcss" scoped>
.pagination__ellipsis {
  @apply h-6 w-6;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-left: -1px;
  & + & {
    display: none;
  }
}
</style>

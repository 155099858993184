<script setup>
import { ref, reactive, computed } from "vue";
import FhProductOptionSelector from "./FhProductOptionSelector.vue";
import { intersection } from "lodash";

const props = defineProps({
  options: {
    type: Object,
    required: true
  },
  activeSku: {
    type: String,
    required: true
  }
});

const emit = defineEmits(["update:activeSku"]);

const _options = props.options.map((option) => {
  let selectedValueId = ref(option.values.find((value) => value.skus.includes(props.activeSku))?.id);
  if (selectedValueId.value === undefined) {
    throw new Error(`No option value exists for SKU ${props.activeSku}`);
  }
  let selectedValue = computed(() => option.values.find((val) => val.id === selectedValueId.value));
  let selectedValueSkus = computed(() => selectedValue.value.skus);

  return {
    name: option.name,
    type: option.type,
    values: option.values.map((value) => {
      return reactive({
        ...value,
        disabled: false
      });
    }),
    selectedValueId: selectedValueId,
    selectedValueSkus: selectedValueSkus
  };
});

const selectedSkusIntersection = computed(() => intersection(..._options.map((option) => option.selectedValueSkus.value)));

function handleChange(optionIndex) {
  // Selected combo is invalid
  if (selectedSkusIntersection.value.length === 0) {
    adjustSelectedValuesForAvailability(optionIndex);
  }
  setOptionValuesEnabledState();
  emit("update:activeSku", selectedSkusIntersection.value[0]);
}

function LazyProduct(sets) {
  for (var dm = [], f = 1, l, i = sets.length; i--; f *= l) {
    dm[i] = [f, (l = sets[i].length)];
  }
  this.length = f;
  this.item = function (n) {
    for (var c = [], i = sets.length; i--; ) c[i] = sets[i][((n / dm[i][0]) << 0) % dm[i][1]];
    return c;
  };
}

function adjustSelectedValuesForAvailability(fixedOptionIndex) {
  let fixedOption = _options[fixedOptionIndex];

  let sets = _options.map((option, i) => {
    let values = option.values;
    let selectedValueIndex = values.findIndex((val) => val.id === option.selectedValueId.value);
    let valuesSorted = [values[selectedValueIndex], ...values.slice(0, selectedValueIndex), ...values.slice(selectedValueIndex + 1)];

    return valuesSorted.map((value) => {
      return {
        optionIndex: i,
        valueId: value.id,
        valueSkus: value.skus
      };
    });
  });

  sets = sets.slice(0, fixedOptionIndex).concat(sets.slice(fixedOptionIndex + 1));

  let combos = new LazyProduct(sets);
  for (var i = 0; i < combos.length; i++) {
    let validCombo = intersection(...combos.item(i).map((x) => x.valueSkus), fixedOption.selectedValueSkus.value).length > 0;
    if (validCombo) {
      combos.item(i).forEach((item) => (_options[item.optionIndex].selectedValueId.value = item.valueId));
      break;
    }
  }
}

function setOptionValuesEnabledState() {
  _options.forEach((option, i) => {
    let otherOptions = _options.slice(0, i).concat(_options.slice(i + 1));
    let otherOptionsSelectedValueSkus = otherOptions.map((option) => option.selectedValueSkus.value);
    option.values.forEach((value) => {
      let disabled = intersection(...otherOptionsSelectedValueSkus, value.skus).length === 0;
      value.disabled = disabled;
    });
  });
}

setOptionValuesEnabledState();
</script>

<template>
  <div>
    <FhProductOptionSelector
      v-for="({ name, type, values }, i) in _options"
      :key="i"
      v-model:selected-value-id="_options[i].selectedValueId.value"
      v-bind="{ name, type, values }"
      class="relative -mb-px"
      @change="handleChange(i)"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import FhSection from "../includes/FhSection.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { date, location, title } = getDataRefs(props.data);

const convertedDate = computed(() => {
  //convert html entities to text w/o using v-html
  if (!date.value) return false;
  const ta = document.createElement("textarea");
  ta.innerHTML = date.value;
  return ta.textContent;
});
const convertedLocation = computed(() => {
  //convert html entities to text w/o using v-html
  //convert html entities to text w/o using v-html
  if (!location.value) return false;
  const ta = document.createElement("textarea");
  ta.innerHTML = location.value;
  return ta.textContent;
});
const convertedTitle = computed(() => {
  //convert html entities to text w/o using v-html
  const ta = document.createElement("textarea");
  ta.innerHTML = title.value;
  return ta.textContent;
});
</script>

<template>
  <FhSection :base="style.base">
    <div class="flex flex-col items-center gap-f3 text-center">
      <p class="text-f-sm-base text-neutral-60 md:-mb-4">
        <span v-if="date">{{ convertedDate }}</span>
        <span v-if="date && location"> &mdash; </span>
        <span v-if="location">{{ convertedLocation }}</span>
      </p>
      <h1 class="mb-f10 w-full whitespace-pre-line font-serif text-f-4xl-9xl font-light leading-[1.125]">{{ convertedTitle }}</h1>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped></style>

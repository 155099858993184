import axiosClient from "./axiosClient";
import { useQuery, useQueryClient, useMutation } from "@tanstack/vue-query";

const QUERY_KEY_BASE = "shipping_addresses";

export function useShippingAddressesQuery(params, options) {
  return useQuery({
    ...(options || {}),
    queryKey: [QUERY_KEY_BASE, { includeSingleUse: !!params?.includeSingleUse }],
    queryFn: () => axiosClient.get("/shipping_addresses", { params }),
    refetchOnWindowFocus: false
  });
}

export function useCreateShippingAddressMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (shippingAddress) => axiosClient.post("/shipping_addresses", shippingAddress),
    onSuccess: (newAddress, { isSingleUse }) => {
      // Add the new address to the query cache(s)
      const queryKeyFilter = [QUERY_KEY_BASE];
      if (isSingleUse) {
        queryKeyFilter.push({ includeSingleUse: true });
      }
      queryClient.setQueriesData({ queryKey: queryKeyFilter }, (existingAddresses) => {
        return [...(existingAddresses || []), newAddress];
      });
    }
  });
}

export function useDeleteShippingAddressMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (id) => axiosClient.delete(`/shipping_addresses/${encodeURIComponent(id)}`),
    onSuccess: (_data, deletedId) => {
      // Remove the deleted address from the query cache(s)
      queryClient.setQueriesData([QUERY_KEY_BASE], (existingAddresses) => {
        let deletedAddressIndex = existingAddresses.findIndex((address) => address.id === deletedId);
        if (deletedAddressIndex < 0) {
          return existingAddresses;
        } else {
          return [...existingAddresses.slice(0, deletedAddressIndex), ...existingAddresses.slice(deletedAddressIndex + 1)];
        }
      });
    }
  });
}

export function useVerifyShippingAddressMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (id) => axiosClient.put(`/shipping_addresses/${encodeURIComponent(id)}/verify`),
    onSuccess: (updatedAddress) => {
      // Update the address in the query cache(s)
      queryClient.setQueriesData([QUERY_KEY_BASE], (existingAddresses) => {
        return existingAddresses.map((address) => (address.id === updatedAddress.id ? updatedAddress : address));
      });
    }
  });
}

<script setup>
const props = defineProps({
  src: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    required: true
  },
  /**
   * Forces the image into a square (1:1) aspect ratio, cropping if necessary (by default; use `contain` to change the behavior)
   */
  square: {
    type: Boolean,
    default: false
  },
  /**
   * If `square` is `true`, the default behavior is to crop non-square images. Setting this prop to `true` will preserve the image aspect ratio instead.
   */
  contain: {
    type: Boolean,
    default: false
  },
  /**
   * Adds a border to the image
   */
  border: {
    type: Boolean,
    default: false
  }
});
</script>

<template>
  <img
    :src="src"
    :alt="alt"
    :class="{
      'aspect-square': props.square,
      'object-cover': props.square && !props.contain,
      'object-contain': props.square && props.contain,
      'border border-solid border-neutral-30 bg-neutral-10': props.border
    }"
    loading="lazy"
    class="block w-full"
  />
</template>

<script setup>
import { computed, ref } from "vue";
import FhProductCard from "@/components/FhProductCard.vue";

const props = defineProps({
  products: {
    type: Array,
    required: true
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  }
});
const emits = defineEmits(["addtoshoppinglist"]);
const productsContainer = ref(null);
const products = computed(() => {
  return props.products.map((product, index) => {
    return {
      ...product,
      index
    };
  });
});
</script>

<template>
  <div id="productsContainer" ref="productsContainer" class="my-f5 grid grid-cols-2 gap-x-f2.5 gap-y-f10 lg:grid-cols-3">
    <FhProductCard
      v-for="product in products"
      :key="product.id"
      :product="product"
      :is-c-a-d-price="props.isCADPrice"
      :is-customer-guest="props.isCustomerGuest"
      :is-admin="props.isAdmin"
      @addtoshoppinglist="(selectedProduct) => emits('addtoshoppinglist', selectedProduct)"
    />
  </div>
</template>

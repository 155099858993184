<script setup>
import FhSection from "../includes/FhSection.vue";
import FhDepartment from "./FhDepartments/FhDepartment.vue";
import FhButton from "./FhButton.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { linkUrl, linkTarget, linkAltText, linkText, departments } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    aria-label="Departments"
    :base="style.base"
    class="mx-auto max-w-[1530px]"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-x-f10 md:gap-y-f8 md:pt-f5">
      <FhDepartment v-for="department in departments" :key="department.id" :department="department"></FhDepartment>
    </div>
    <div v-if="linkText" class="mt-f8 flex flex-col justify-center md:flex-row md:items-center">
      <fh-button :wide="true" :aria-label="linkAltText" :target="linkTarget" :href="linkUrl">{{ linkText }}</fh-button>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.text-white * {
  color: white;
}
</style>

import { ref } from "vue";

export default function (data) {
  const refs = {};

  try {
    Object.entries(data).forEach(([key, value]) => (refs[key] = ref(value)));
    return refs;
  } catch (error) {
    return {};
  }
}

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.6881 14.2084C20.0713 13.3179 20.9997 11.7642 20.9997 10.0021C20.9997 7.23579 18.745 5 15.9976 5C13.2502 5 10.9766 7.23579 10.9766 10.0021C10.9766 12.7684 13.2313 15.0042 15.9787 15.0042C16.3766 15.0042 16.7555 14.9474 17.1345 14.8716L18.745 18.6611C18.8776 18.9832 19.1997 19.1726 19.5218 19.1726C19.6355 19.1726 19.7492 19.1537 19.8439 19.0969C20.2797 18.9074 20.4692 18.4147 20.2987 17.9979L18.6881 14.2084ZM12.6629 10.0021C12.6629 8.16421 14.1597 6.68632 15.9787 6.68632C17.8166 6.68632 19.2945 8.18316 19.2945 10.0021C19.2945 11.8211 17.7976 13.3179 15.9787 13.3179C14.1597 13.3179 12.6629 11.84 12.6629 10.0021Z"
      fill="#212121"
    />
    <path d="M10 6.68642L3 6.68641V5.01904L10 5.01905V6.68642Z" fill="#212121" />
    <path d="M9 10.8358H3V9.16846H9V10.8358Z" fill="#212121" />
    <path d="M10.3516 15.0043H3V13.3369H10.3516V15.0043Z" fill="#212121" />
    <path d="M3 17.4863H16.5V19.1537H3V17.4863Z" fill="#212121" />
  </svg>
</template>

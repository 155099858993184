<script setup>
import { computed, onMounted } from "vue";
import FhButton from "@/components/FhButton.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhOptionCard from "@/components/FhOptionCard.vue";

const emit = defineEmits(["update:modelValue", "navigate"]);
const props = defineProps({
  modelValue: {
    type: String,
    default: null,
    validator: (val) => typeof val === "string" || val === null
  },
  model: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
});

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const modelProperties = computed(() => props.model);

const nextPage = () => {
  let pageValue = 4;

  if (value.value === "commercial-design") {
    pageValue = 2;
  } else if (value.value === "residential-design") {
    modelProperties.value.primaryProject.primaryProjectType = "residential-design";
    pageValue = 3;
  } else if (value.value === "e-commerce") {
    modelProperties.value.businessDetails.social.website.selected = true;
  }

  emit("navigate", pageValue);
};

onMounted(() => {
  if (modelProperties.value.primaryProject.primaryProjectType !== null) {
    modelProperties.value.primaryProject.primaryProjectType = null;
    modelProperties.value.primaryProject.primaryProjectTypeOther = "";
  }

  if (modelProperties.value.projectType.projectTypeOther.length > 0) {
    modelProperties.value.projectType.projectTypeOther = [];
    modelProperties.value.projectType.projectTypeOtherOther = "";
  }
});

const allowNext = computed(() => !!value.value);
</script>

<template>
  <div class="flex flex-col">
    <FhHeading
      heading="Select your primary business"
      subheading="Please select the response that most closely aligns with your main business model. You'll have the option of adding on other services you provide in the next question."
    />
    <div class="grid grid-cols-2 gap-f3 lg:grid-cols-3">
      <FhOptionCard
        v-for="option in options"
        :key="option.value"
        v-model="value"
        :value="option.value"
        :label="option.label"
        type="radio"
        layout="column"
        class="cb-grid__item"
      />
    </div>
    <div class="mt-f8 flex flex-col justify-center gap-6 md:self-end">
      <FhButton :disabled="!allowNext" color="primary" @click="nextPage">Continue</FhButton>
    </div>
  </div>
</template>

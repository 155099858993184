<script setup>
import { ref } from "vue";
import { getDataRefs } from "@/util/vue";
import FhButton from "@/components/FhButton.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhSection from "@/includes/FhSection.vue";
import FhPriceMarkupModal from "@/components/FhPriceMarkupModal.vue";

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const { heading, subHeading, markup } = getDataRefs(props.data);

const markupValue = ref(markup.value == null ? 0 : markup);

const handleMarkupUpdate = (updatedMarkupValue) => {
  markupValue.value = updatedMarkupValue;
};
</script>

<template>
  <FhSection>
    <div class="mb-f10 flex flex-col text-neutral-70 md:flex-row md:flex-wrap md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10 md:pb-f10">
      <FhHeading tag="h2" class="md:order-1 md:mb-0 md:flex-1 md:justify-center">{{ heading }}</FhHeading>
      <div class="my-4 flex flex-col md:order-3 md:mb-2 md:w-full md:flex-row md:gap-24">
        <span class="mb-3 text-f-sm-base font-bold md:mb-0 md:basis-4/12">
          {{ subHeading }}
        </span>
        <span>
          <p class="text-neutral-50">Your Markup</p>
          <p class="text-f-sm-base font-bold md:mb-0 md:basis-4/12">{{ markupValue }}%</p>
        </span>
      </div>
      <FhPriceMarkupModal :markup-value="markupValue" @update="handleMarkupUpdate">
        <template #activator="{ open }">
          <FhButton class="w-full md:order-2 md:w-auto md:self-end" @click="open">Update Markup</FhButton>
        </template>
      </FhPriceMarkupModal>
    </div>
  </FhSection>
</template>

<script setup>
import { ref, onMounted } from "vue";
import placeholderSeal from "@/assets/digicert-seal-placeholder.png";

const isProd = ref(window.location.hostname.endsWith("fourhands.com"));

onMounted(() => {
  if (isProd.value) {
    initDigiCertSeal();
  }
});

function initDigiCertSeal() {
  window.__dcid = window.__dcid || [];
  window.__dcid.push(["DigiCertClickID_DG37kk7S", "3", "m", "black", "DG37kk7S"]);
  (function () {
    var cid = document.createElement("script");
    cid.async = true;
    cid.src = "//seal.digicert.com/seals/cascade/seal.min.js";
    var s = document.getElementsByTagName("script");
    var ls = s[s.length - 1];
    ls.parentNode.insertBefore(cid, ls.nextSibling);
  })();
}
</script>

<template>
  <div id="DigiCertClickID_DG37kk7S" data-language="en">
    <img v-if="!isProd" :src="placeholderSeal" alt="DigiCert Seal" />
  </div>
</template>

<script setup>
import FhIcon from "./FhIcon.vue";

const props = defineProps({
  icon: {
    type: String,
    default: null
  }
});
</script>

<template>
  <div class="bg-limestone p-f5">
    <div class="flex gap-f2">
      <FhIcon v-if="props.icon" :name="props.icon" class="text-body-xl flex-none" />
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

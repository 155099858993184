<script setup>
import { computed, ref, reactive } from "vue";
import FhIcon from "@/components/FhIcon.vue";
import FhInput from "@/components/FhInput.vue";
import IconFhLogo from "@/components/icons/IconFhLogo.vue";
import FhAccordion from "@/components/FhAccordion.vue";
import UpRightArrow from "./icons/IconUpRightArrow.vue";
const props = defineProps({
  isAuthenticated: {
    type: Boolean,
    default: false
  },
  navItems: {
    type: Object,
    required: true
  },
  isAccountMenu: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(["close", "search"]);

const mainMenuItems = computed(() => ({
  mainMenu: props.navItems.mainMenu,
  moreMenu: props.navItems.moreMenu,
  accountMenu: props.navItems.accountMenu?.childItems
}));
const menuChildItems = ref(null);
const menuStack = reactive([]);
const sectionHeadline = ref(null);
const searchText = ref("");
const showAllLink = ref(false);
const isNotCategoryLinks = ref(false);

const menuItemClick = (event, menuItemClicked) => {
  event.preventDefault();
  const menuItemKey = menuItemClicked;
  let nextMenuItem = null;
  if (menuChildItems.value) {
    nextMenuItem = menuChildItems?.value?.childItems[menuItemKey];
  } else {
    nextMenuItem = mainMenuItems?.value?.mainMenu[menuItemKey];
  }

  if (nextMenuItem?.childItems) {
    menuStack.push(nextMenuItem);
  }

  // Filter nextMemuItem.childItems to get to the lowest level
  const hasChildren = nextMenuItem?.childItems?.filter((childItem) => {
    if (childItem?.childItems) return true;
  });

  if (menuStack[0].name.toLowerCase() === "discover" || menuStack[0].name.toLowerCase() === "products") {
    isNotCategoryLinks.value = true;
  } else {
    isNotCategoryLinks.value = false;
  }

  if (hasChildren?.length === 0 && !isNotCategoryLinks.value) {
    showAllLink.value = true;
  } else {
    showAllLink.value = false;
  }
  showMenuContent(nextMenuItem);
};

const goBack = (event) => {
  event.preventDefault();
  menuStack.pop();
  const sectionToGoTo = menuStack[menuStack.length - 1];
  if (sectionToGoTo) {
    showMenuContent(sectionToGoTo);
  } else {
    menuChildItems.value = null;
    sectionHeadline.value = null;
  }

  // reset showAllLink flag when reversing back through the menuStack
  showAllLink.value = false;
};

const onSearch = () => {
  emits("search", searchText.value);
};

const close = (event) => {
  event.preventDefault();
  emits("close");
};

const showMenuContent = (content) => {
  if (content?.childItems) {
    menuChildItems.value = content;
    sectionHeadline.value = menuChildItems?.value?.name;
  } else {
    switch (content.target) {
      case "_self":
        window.location.href = content.url;
        break;
      case "_blank":
        window.open(content.url);
        break;
      default:
        window.location.href = content.url;
    }
  }
};

const navPath = (childItems) => {
  if (childItems?.childItems) return "";

  return menuStack.map((menuItem) => menuItem.name).join("/");
};
</script>

<template>
  <section class="fixed top-0 left-0 z-[101] h-screen w-screen bg-white overflow-y-auto">
    <div class="h-full bg-limestone-opacity-50">
      <header class="grid h-[var(--site-header-height)] grid-cols-[50px_1fr_50px] items-center border-b-[1px] border-neutral-30 bg-white">
        <button v-if="menuStack.length >= 1" aria-label="Go Back" @click="goBack"><FhIcon class="main-nav-item__icon text-2xl" name="LeftChevron" /></button>
        <div class="col-start-2">
          <!-- <h2 v-if="sectionHeadline" class="text-center text-2xl">{{ sectionHeadline }}</h2> -->
          <IconFhLogo class="m-auto w-[150px]" />
        </div>
        <button aria-label="Close Navigation" @click="close"><FhIcon name="XIcon" class="main-nav-item__icon text-2xl" /></button>
      </header>
      <div v-if="!isAccountMenu">
        <div v-if="menuChildItems == null && props.isAuthenticated" class="search-section relative">
          <FhIcon name="Search" class="search-icon pointer-events-none absolute top-0 left-5 z-10 h-full text-xl" />
          <FhInput
            v-model="searchText"
            placeholder="Search"
            class="border-t-0 h-12 px-12 py-0 leading-tight outline-none focus:outline-none focus:border-neutral-30"
            @keyup.enter="onSearch"
          />
        </div>
        <nav data-ga="mobile_nav">
          <div>
            <!-- First layer -->
            <ul v-if="menuChildItems == null">
              <li v-for="(mainMenuItem, index) in mainMenuItems.mainMenu" :key="index">
                <a
                  v-if="mainMenuItem"
                  class="flex items-center text-xl px-5 leading-tight bg-white py-6 border-b-[1px] border-neutral-30"
                  :href="mainMenuItem?.url || '#'"
                  :target="mainMenuItem?.target || '_self'"
                  @click="menuItemClick($event, index)"
                >
                  <span>{{ mainMenuItem.name }}</span
                  ><FhIcon class="main-nav-item__icon" name="RightChevron" />
                </a>
              </li>
              <template v-if="!props.isAuthenticated">
                <li>
                  <a
                    class="flex items-center text-xl px-5 leading-tight bg-white py-6 border-b-[1px] border-neutral-30"
                    href="/our-locations"
                    data-ga="mobile_nav_click"
                  >
                    <span>Locations</span>
                  </a>
                </li>
                <li>
                  <a
                    class="flex items-center text-xl px-5 leading-tight bg-white py-6 border-b-[1px] border-neutral-30"
                    href="/art-studio"
                    data-ga="mobile_nav_click"
                  >
                    <span>Art Studio</span>
                  </a>
                </li>
                <li>
                  <a
                    class="flex items-center text-xl px-5 leading-tight bg-white py-6 border-b-[1px] border-neutral-30"
                    href="/to-the-trade"
                    data-ga="mobile_nav_click"
                  >
                    <span>To the Trade</span>
                  </a>
                </li>
              </template>
            </ul>
            <ul v-if="menuChildItems == null" class="pt-2.5 pb-[30px]">
              <li v-for="item in mainMenuItems.moreMenu.childItems" :key="item.name" class="px-5 pt-2.5 mt-2.5 pb-0 bt-0">
                <template v-if="item.childItems && item.childItems.length">
                  <ul class="ml-2 flex flex-col gap-4">
                    <li v-for="childItem in item.childItems" :key="childItem.name">
                      <a
                        :href="childItem?.url || '#'"
                        :target="item?.target || '_self'"
                        class="text-base text-neutral-70 leading-tight"
                        data-ga="mobile_nav_click"
                        >{{ childItem.name }}</a
                      >
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <a :href="item?.url || '#'" :target="item?.target || '_self'" class="text-base text-neutral-70 leading-tight" data-ga="mobile_nav_click">{{
                    item.name
                  }}</a>
                </template>
              </li>
            </ul>
          </div>
          <!-- Category level -->
          <div v-if="menuChildItems != null">
            <ul class="bg-white">
              <li v-for="(childItem, childIndex) in menuChildItems?.childItems" :key="childIndex">
                <template v-if="menuStack.length === 2 && childItem.childItems">
                  <FhAccordion
                    :is-nav-item="true"
                    :view-all-link="{ url: `${childItem?.url || ''}`, title: `View All ${childItem?.name || ''}` }"
                    :data="{
                      version: 'single',
                      items: [
                        {
                          title: childItem?.name,
                          copy: childItem?.childItems?.map((item) => ({
                            content: item.name,
                            url: item.url
                          }))
                        }
                      ]
                    }"
                  />
                </template>
                <template v-else>
                  <a
                    class="flex items-center text-xl px-5 leading-tight bg-white py-6 border-b-[1px] border-neutral-30"
                    :data-ga="`${childItem?.childItems ? '' : 'mobile_nav_click'}`"
                    :data-ga-nav-path="navPath(childItem)"
                    :href="childItem?.url || '#'"
                    :target="childItem?.target || '_self'"
                    @click="menuItemClick($event, childIndex)"
                  >
                    <span :class="[childItem?.name === 'Sale' ? 'text-sale-red' : '']">{{ childItem?.name }}</span>
                    <FhIcon :class="[menuStack.length === 2 ? 'hidden' : '']" class="main-nav-item__icon" name="RightChevron" />
                  </a>
                </template>
              </li>
              <li v-if="menuStack.length >= 2" class="bg-white py-6 border-b-[1px] border-neutral-30">
                <a class="flex items-center text-xl px-5 leading-tight" :href="menuStack[menuStack.length - 1].url || '#'">
                  <span>All {{ menuStack[menuStack.length - 1].name }}</span></a
                >
              </li>
              <!-- Show primaryCta if set -->
              <li v-if="menuStack.length > 0 && menuStack[menuStack.length - 1].primaryCTA" class="pt-3 px-3 pb-[60px]">
                <a
                  :href="menuStack[menuStack.length - 1].primaryCTA.ctaLink"
                  :target="menuStack[menuStack.length - 1].primaryCTA.ctaTarget || `_self`"
                  :data-ga-nav-path="navPath(null)"
                  data-ga="nav_marketing_banner_primary"
                  class="text-base text-neutral-70 leading-tight"
                >
                  <img :src="menuStack[menuStack.length - 1].primaryCTA.imageUrl" class="aspect-square" />
                  <p v-if="menuStack[menuStack.length - 1].primaryCTA.ctaText" class="text-neutral-800 text-base underline leading-tight text-center mt-[25px]">
                    {{ menuStack[menuStack.length - 1].primaryCTA.ctaText }}
                  </p>
                </a>
              </li>
            </ul>
            <ul v-if="menuChildItems.extraLinks" class="pt-2.5 pb-[30px]">
              <li v-for="extraLink in menuChildItems?.extraLinks" :key="extraLink.name" class="px-5 pt-2.5 mt-2.5 pb-0 bt-0">
                <a
                  :href="extraLink?.url || '#'"
                  :target="extraLink?.target || '_self'"
                  class="text-base text-neutral-70 leading-tight"
                  data-ga="mobile_nav_click"
                  >{{ extraLink?.name }}</a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <!-- Account Menu -->
      <div v-if="isAccountMenu">
        <nav data-ga="mobile_nav">
          <ul>
            <li v-for="accountMenuItem in mainMenuItems?.accountMenu" :key="accountMenuItem.name">
              <a
                class="flex items-center text-xl px-5 leading-tight bg-white py-6 border-b-[1px] border-neutral-30"
                :class="accountMenuItem?.name == 'Sign Out' ? 'isSignOut mx-5' : ''"
                :href="accountMenuItem?.url || '#'"
                data-ga="mobile_nav_click"
              >
                <span>{{ accountMenuItem?.name }}</span>
                <UpRightArrow v-if="accountMenuItem?.name === 'Claims Portal'" class="ml-1 w-4" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
</template>

<style lang="pcss" scoped>
:deep(.site-logo) {
  width: 376px;
}
:deep(.search-icon) {
  height: 100%;
}
:deep(.search-section > div) {
  gap: 0;
}
:deep(.isSignOut) {
  @apply border border-neutral-40 justify-center font-bold py-2 mt-10 text-base;
}
</style>

<script setup>
import { reactive, computed, ref } from "vue";
import FhInput from "@/components/FhInput.vue";
import FhSelect from "@/components/FhSelect.vue";
import FhCheckbox from "@/components/FhCheckbox.vue";
import FhButton from "@/components/FhButton.vue";
import FhModal from "@/components/FhModal.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhDigiCertSeal from "@/components/FhDigiCertSeal.vue";
import COUNTRIES from "@/constants/countries";
import { useCreateCreditCardMutation } from "@/api/creditCardApi";
import { useNotificationStore } from "@/stores/NotificationStore";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { CREDIT_CARDS } from "@/constants";
import { ERROR_RESPONSE_MESSAGES } from "@/constants/payment";
import { formatCardNumber, parseCardType, validateCardNumber, formatCardExpiry, validateCardExpiry, parseCardExpiry, validateCardCVC } from "creditcardutils";
import { omit } from "lodash";

const props = defineProps({
  allowSingleUse: {
    type: Boolean,
    default: false
  },
  allowSameAsShipping: {
    type: Boolean,
    default: false
  },
  shippingAddress: {
    type: Object,
    default: () => {}
  }
});

const emit = defineEmits(["create"]);

const initialState = {
  nickName: null,
  name: null,
  cardNumber: null,
  expiration: null,
  cvv: null,
  billingCountry: "US",
  billingAddress1: null,
  billingAddress2: null,
  billingCity: null,
  billingState: null,
  billingZip: null,
  saveForLater: false
};

const modal = ref(null);

const sameAsShipping = ref(false);
const creditCardError = ref(null);

const notificationStore = useNotificationStore();
const { mutate: createCreditCardMutation, isLoading } = useCreateCreditCardMutation();
const formData = reactive({ ...initialState });

const ALLOWED_BILLING_COUNTRIES = ["US", "CA", "BR", "CO", "EC", "MX", "PA", "PE"];
const countryOptions = Object.keys(COUNTRIES)
  .filter((key) => ALLOWED_BILLING_COUNTRIES.includes(key))
  .map((key) => ({ value: key, text: COUNTRIES[key].name }));

const hasStateOptions = computed(() => {
  return formData.billingCountry === "US" || formData.billingCountry === "CA";
});

const country = computed(() => COUNTRIES[formData.billingCountry]);
const stateLabel = computed(() => country.value.stateName ?? "State/Province/Region");
const stateOptions = computed(() =>
  country.value.states ? Object.keys(country.value.states).map((key) => ({ value: key, text: country.value.states[key] })) : []
);
const postalCodeLabel = computed(() => country.value.postalCodeName ?? "Postal Code");
const postalCodeRegex = computed(() => country.value?.postalCodeRegex);
const cardBrand = computed(() => {
  const cardType = parseCardType(formData.cardNumber);
  return {
    validationType: cardType,
    type: CREDIT_CARDS[cardType]
  };
});

const ACCEPTED_CARD_BRANDS = Object.keys(CREDIT_CARDS).map((key) => key);
const accepted_card_brands_label = ACCEPTED_CARD_BRANDS.map((key) => CREDIT_CARDS[key].name).join(", ");

const vuelidate = useVuelidate(
  {
    name: { mustHave: helpers.withMessage("Please enter the name on card.", required) },
    cardNumber: {
      mustHave: helpers.withMessage("Please enter the card number.", required),
      validCreditCardNumber: helpers.withMessage(
        () => "Please enter a valid credit card number",
        (value) => !helpers.req(value) || validateCardNumber(value)
      ),
      acceptedCreditCardType: helpers.withMessage(
        () => `${cardBrand.value.validationType} is not accepted`,
        (value) => !helpers.req(value) || ACCEPTED_CARD_BRANDS.includes(cardBrand.value.validationType)
      )
    },
    expiration: {
      mustHave: helpers.withMessage("Please enter the expiration date.", required),
      validExpirationDate: helpers.withMessage(
        () => "Please enter a valid expiration date MM/YYYY",
        (value) => {
          const { month, year } = parseCardExpiry(value);
          const paddedMonth = month.toString().padStart(2, 0);
          return !helpers.req(value) || validateCardExpiry(paddedMonth, year);
        }
      )
    },
    cvv: {
      mustHave: helpers.withMessage("Please enter the security code.", required),
      validCVV: helpers.withMessage(
        () => "Please enter a valid security code",
        (value) => !helpers.req(value) || validateCardCVC(value, cardBrand.value?.validationType)
      )
    },
    billingAddress1: { required: helpers.withMessage("Please enter an address.", required) },
    billingCity: { required: helpers.withMessage("Please enter a city.", required) },
    billingState: { required: helpers.withMessage(() => `Please select a ${stateLabel.value.toLowerCase()}.`, required) },
    billingZip: {
      required: helpers.withMessage(() => `Please enter a ${postalCodeLabel.value.toLowerCase()}.`, required),
      validPostalCode: helpers.withMessage(
        () => `Please enter a valid ${postalCodeLabel.value.toLowerCase()}.`,
        (value) => !helpers.req(value) || !postalCodeRegex.value || postalCodeRegex.value.test(value)
      )
    }
  },
  formData
);

const setSameAsShipping = (value) => {
  sameAsShipping.value = value;
  formData.billingAddress1 = props.shippingAddress.line1;
  formData.billingAddress2 = props.shippingAddress.line2;
  formData.billingCity = props.shippingAddress.city;
  formData.billingState = props.shippingAddress.state;
  formData.billingZip = props.shippingAddress.postalCode;
};

async function save() {
  creditCardError.value = false;
  if (await vuelidate.value.$validate()) {
    const { month, year } = parseCardExpiry(formData.expiration);
    let data = {
      ...omit(formData, "saveForLater"),
      isSingleUse: props.allowSingleUse && !formData.saveForLater,
      cardType: cardBrand.value?.type?.mapName,
      expiration: `${month.toString().padStart(2, 0)}/${year}`
    };
    createCreditCardMutation(data, { onSuccess: handleSaveSuccess, onError: handleSaveError });
  }
}

function handleSaveSuccess(newCreditCard) {
  modal.value.close();
  notificationStore.notifySuccess("Card added.");
  emit("create", newCreditCard);
}

function handleSaveError(error) {
  const { data, status } = error.response;
  if (status === 400 && Object.keys(ERROR_RESPONSE_MESSAGES).includes(data?.error_code?.toString())) {
    creditCardError.value = ERROR_RESPONSE_MESSAGES[data?.error_code].error_msg;
  } else {
    notificationStore.notifyError("Sorry, something went wrong and we could not add this card. Please try again.");
  }
}

const reset = () => {
  Object.assign(formData, initialState);
  vuelidate.value.$reset();
  sameAsShipping.value = false;
};

const onCardNumberKeyUp = (event, type) => {
  const inputValue = event.target.value;
  switch (type) {
    case "card_number":
      formData.cardNumber = formatCardNumber(inputValue);
      break;
    case "card_expire":
      formData.expiration = formatCardExpiry(inputValue);
      break;
  }
};
</script>

<template>
  <FhModal ref="modal" :data="{ heading: 'Add Card', subheading: `We accept ${accepted_card_brands_label}` }" @close="reset">
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <form id="creditCardForm" @submit.prevent="save">
        <fieldset class="mb-f8">
          <legend class="mb-f4 text-f-xl-2xl">Card Details</legend>
          <div class="flex flex-col gap-f3">
            <FhInput v-model="formData.nickName" label="Card Nickname" />
            <div class="flex flex-col gap-f3 border border-neutral-30 bg-neutral-20 p-f3">
              <div class="flex gap-3">
                <FhDigiCertSeal class="w-28 flex-none" />
                <div class="text-body-xs">
                  <p class="font-bold">Safe Shopping Guarantee</p>
                  <p>All information is encrypted and transmitted without risk using a Secure Socket Layer (SSL) protocol.</p>
                </div>
              </div>
              <FhInput v-model="formData.name" label="Name on Card" required :vuelidate="vuelidate.name" />
              <FhInput
                v-model="formData.cardNumber"
                class="card-number"
                label="Card Number"
                required
                :vuelidate="vuelidate.cardNumber"
                data-lpignore="true"
                :style="{
                  'background-image': `url(${cardBrand?.type?.icon})`
                }"
                @keyup="onCardNumberKeyUp($event, 'card_number')"
              >
              </FhInput>
              <div class="flex flex-col gap-f3 md:flex-row">
                <FhInput
                  v-model="formData.expiration"
                  label="Expiration"
                  required
                  placeholder="MM / YYYY"
                  :vuelidate="vuelidate.expiration"
                  :wrapper-attrs="{ class: 'flex-1' }"
                  class="w-1/2 md:w-full"
                  @keyup="onCardNumberKeyUp($event, 'card_expire')"
                />
                <FhInput
                  v-model="formData.cvv"
                  label="Security Code"
                  required
                  :vuelidate="vuelidate.cvv"
                  :wrapper-attrs="{ class: 'flex-1' }"
                  class="w-1/2 md:w-full"
                />
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="mb-f4 text-f-lg-2xl">Billing Address</legend>
          <div class="flex flex-col gap-f3">
            <FhCheckbox
              v-if="props.allowSameAsShipping"
              v-model="sameAsShipping"
              label="Same as shipping address"
              size="lg"
              class="mb-f1"
              @update:model-value="setSameAsShipping"
            />
            <FhSelect v-model="formData.billingCountry" label="Country" :options="countryOptions" :disabled="sameAsShipping" />
            <FhInput v-model="formData.billingAddress1" label="Address Line 1" required :vuelidate="vuelidate.billingAddress1" :disabled="sameAsShipping" />
            <FhInput v-model="formData.billingAddress2" label="Address Line 2" :disabled="sameAsShipping" />
            <FhInput v-model="formData.billingCity" label="City" required :vuelidate="vuelidate.billingCity" :disabled="sameAsShipping" />
            <component
              :is="hasStateOptions ? FhSelect : FhInput"
              v-model="formData.billingState"
              :options="stateOptions"
              :label="stateLabel"
              required
              :vuelidate="vuelidate.billingState"
              :disabled="sameAsShipping"
            />
            <FhInput v-model="formData.billingZip" :label="postalCodeLabel" required :vuelidate="vuelidate.billingZip" :disabled="sameAsShipping" />
          </div>
        </fieldset>
        <FhCheckbox v-if="props.allowSingleUse" v-model="formData.saveForLater" label="Save this card to my account for future use" size="lg" class="my-f4" />
      </form>
      <div v-if="creditCardError" class="mt-3 flex items-center gap-2 py-2 text-brand-red">
        <div>
          <fh-icon name="AlertCircle" />
        </div>
        <div v-html="creditCardError"></div>
      </div>
    </template>
    <template #footer="{ close }">
      <FhButton type="submit" form="creditCardForm" color="primary" :disabled="vuelidate.$invalid || isLoading" :is-loading="isLoading">Save</FhButton>
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
</template>

<style scoped lang="pcss">
:deep(.card-number){
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 42px 42px;
}
</style>

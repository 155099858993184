<script setup>
import FhSection from "../includes/FhSection.vue";
import FhShowroomCard from "./FhShowroomCards/FhShowroomCard.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { heading, cards } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="mb-16 flex flex-col text-neutral-70 md:pb-16">
      <h2 class="mb-f8 font-serif text-f-2xl-5xl">{{ heading }}</h2>
      <div class="grid grid-cols-[1fr] gap-y-[1rem] md:grid-cols-[repeat(2,1fr)] md:gap-y-[2.5rem] md:gap-x-[1.5rem]">
        <FhShowroomCard v-for="(card, i) in cards" :key="`card-${i}`" :card="card"></FhShowroomCard>
      </div>
    </div>
  </FhSection>
</template>
<style lang="pcss" scoped>
.text-white * {
  color: white;
}
</style>

<script setup>
import FhSection from "../includes/FhSection.vue";
import FhButton from "./FhButton.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { linkUrl, linkTarget, linkAltText, linkText, items } = getDataRefs(props.data);
</script>

<template>
  <FhSection :base="style.base">
    <div
      class="relative left-[50%] flex w-[100vw] translate-x-[-50%] flex-wrap gap-x-[1rem] md:static md:-mx-2 md:mb-3 md:w-[calc(100%+1rem)] md:min-w-[calc(100%+1rem)] md:translate-x-0 md:gap-x-0"
    >
      <div v-for="item in items" :key="item.id" class="flex flex-[0_0_calc(50%-0.5rem)] flex-col gap-[0.5rem] pb-f8 md:flex-[0_0_33.33%] md:px-2">
        <img :src="item.image" :alt="item.imageAltText" class="m-0 aspect-square object-cover object-center" />
        <p>{{ item.imageTitle }}</p>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <fh-button :wide="true" :aria-label="linkAltText" :target="linkTarget" :href="linkUrl">{{ linkText }}</fh-button>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped></style>

<script setup>
const emit = defineEmits(["expanded", "collapsed"]);
const props = defineProps({
  animation: {
    type: String,
    required: false,
    default: () => "height"
  },
  control: {
    type: String,
    required: false,
    default: () => "width"
  }
});

const onEnter = (element) => {
  const control = getComputedStyle(element)[props.control];
  element.style[props.control] = control;
  element.style.position = "absolute";
  element.style.visibility = "hidden";
  element.style[props.animation] = "auto";
  const animation = getComputedStyle(element)[props.animation];
  element.style[props.control] = null;
  element.style.position = null;
  element.style.visibility = null;
  element.style[props.animation] = 0;
  getComputedStyle(element)[props.animation];
  requestAnimationFrame(() => {
    element.style[props.animation] = animation;
  });
};

const onAfterEnter = (element) => {
  element.style[props.animation] = "auto";
  emit("expanded");
};

const onLeave = (element) => {
  const animation = getComputedStyle(element)[props.animation];
  element.style[props.animation] = animation;
  getComputedStyle(element)[props.animation];
  requestAnimationFrame(() => {
    element.style[props.animation] = 0;
  });
};

const onAfterLeave = () => {
  emit("collapsed");
};
</script>

<template>
  <div :class="['transition-expand', animation]">
    <Transition name="expand" @enter="onEnter" @after-enter="onAfterEnter" @leave="onLeave" @after-leave="onAfterLeave">
      <slot></slot>
    </Transition>
  </div>
</template>

<style lang="pcss" scoped>

.transition-expand {
  display: contents;
  &:deep(.expand-enter-active),
  &:deep(.expand-leave-active) {
    overflow: hidden;
    transition: v-bind("props.animation") 350ms ease, opacity 350ms ease;
    will-change: v-bind("props.animation") opacity;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &.height {
    &:deep(.expand-enter-from),
    &:deep(.expand-leave-to) {
      height: 0;
      opacity: 0;
    }

    &:deep(.expand-enter-to),
    &:deep(.expand-leave-from) {
      height: auto;
      opacity: 1;
    }
  }

  &.width {
    &:deep(.expand-enter-from),
    &:deep(.expand-leave-to) {
      width: 0;
      opacity: 0;
    }

    &:deep(.expand-enter-to),
    &:deep(.expand-leave-from) {
      width: auto;
      opacity: 1;
    }
  }
}
</style>

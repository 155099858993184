<script setup>
import FhSection from "../includes/FhSection.vue";
import FhButton from "./FhButton.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { linkUrl, linkTarget, linkAltText, linkText, items } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="flex flex-wrap gap-[1.25rem] md:gap-[2rem]">
      <div
        v-for="item in items"
        :key="item.id"
        class="award gird-rows-[1fr] grid flex-[0_0_100%] grid-cols-[1fr] bg-neutral-10 p-f10 pb-f8 md:flex-[0_0_calc(50%-1rem)]"
      >
        <div class="col-span-full row-span-full flex flex-col gap-[0.5rem]">
          <img
            :src="item.logo"
            :alt="item.logoAltText"
            class="m-0 mb-f4 max-w-[120px] self-start md:h-[80px] md:w-[120px] md:object-contain md:object-left-top"
          />
          <h5 class="mb-f3 w-full font-serif text-f-xl-6xl font-light">
            {{ item.title }}
          </h5>
          <p class="text-f-base-xl text-neutral-50">{{ item.copy }}</p>
          <a
            v-if="item.linkText"
            class="mt-f3 cursor-pointer text-left text-f-base-xl underline"
            :variant="'text'"
            :aria-label="item.linkAltText"
            :target="item.linkTarget"
            :href="item.linkUrl"
            >{{ item.linkText }}</a
          >
        </div>
      </div>
    </div>
    <div v-if="linkText" class="mt-f8 flex flex-col justify-center md:items-center">
      <fh-button :wide="true" :aria-label="linkAltText" :target="linkTarget" :href="linkUrl">{{ linkText }}</fh-button>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.award {
  &::before {
    @apply row-span-full col-span-full md:pt-[100%] z-[-1];
    content: "";
  }
}

.text-white .award {
  color: black;
}
</style>

/**
 * Returns string representing a date X days in the future, in YYYY-MM-DD format
 */
function getFutureDateString(days) {
  return new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000 + days * 24 * 60 * 60000).toISOString().substring(0, 10);
}

export const getHoldDateLimits = () => {
  return {
    minDate: getFutureDateString(1), // tomorrow
    maxDate: getFutureDateString(90)
  };
};

<script setup>
import { computed } from "vue";
import FhButton from "./FhButton.vue";
import FhIcon from "./FhIcon.vue";

const props = defineProps({
  /**
   * The notification message
   */
  message: {
    type: String,
    required: true
  },
  /**
   * The type of notification
   * @values default, success, error
   */
  type: {
    type: String,
    default: "default",
    validator: (val) => ["default", "success", "error"].includes(val)
  },
  linkText: {
    type: String,
    default: null
  },
  linkUrl: {
    type: String,
    default: null
  },
  linkTarget: {
    type: String,
    default: null
  }
});

const emit = defineEmits(["dismiss", "linkClick"]);

const iconName = computed(() => {
  if (props.type === "success") return "Check";
  if (props.type === "error") return "AlertCircle";
  return null;
});
</script>

<template>
  <div
    class="flex min-h-[var(--site-header-height)] w-full items-center gap-f5 py-f4 px-f3 md:min-h-[var(--site-header-height-md)]"
    :class="[props.type === 'error' ? 'bg-slate text-white' : 'bg-limestone']"
  >
    <div class="flex flex-1 items-center gap-f2 text-f-sm-lg md:justify-center">
      <FhIcon v-if="iconName" :name="iconName" class="flex-none self-start text-[125%]" />
      <span>
        {{ props.message }}
        <FhButton v-if="props.linkText" variant="link" :href="props.linkUrl" :target="props.linkTarget" @click="emit('linkClick')">{{
          props.linkText
        }}</FhButton>
      </span>
    </div>
    <FhButton variant="link" @click="emit('dismiss')">Dismiss</FhButton>
  </div>
</template>

<script setup>
import { ref, nextTick, watch, onMounted } from "vue";
import EmailSent from "@/components/FHLogin/FhLogin-Page-EmailSent.vue";
import FhFullscreenFocusedLayout from "@/components/FhFullscreenFocusedLayout.vue";
import FhLink from "@/components/FhLink.vue";
import ForgotPassword from "@/components/FHLogin/FhLogin-Page-ForgotPassword.vue";
import LogInPage from "@/components/FHLogin/FhLogin-Page-LogIn.vue";
import UpdatePassword from "@/components/FHLogin/FhLogin-Page-UpdatePassword.vue";
import { LOGIN, TRADEPROGRAM, CONTACT_US } from "@/constants/routes";

const PAGE_LOGIN = "login";
const PAGE_FORGOT = "forgot";
const PAGE_SENT = "sent";
const PAGE_RESET = "reset";

const props = defineProps({
  image: {
    type: String,
    required: true
  },
  page: {
    type: String,
    default: "login",
    validator: (val) => ["login", "forgot", "reset"].includes(val)
  },
  successRedirect: {
    type: String,
    default: "/"
  },
  userId: {
    type: [Number, String],
    default: null
  },
  token: {
    type: String,
    default: null
  },
  isInvalid: {
    type: Boolean,
    default: false
  }
});

const layout = ref(null);
const form = ref(null);
const currentPage = ref(props.page);

const focusFirstEmptyInput = () => {
  nextTick(() => form.value.querySelector("input:placeholder-shown")?.focus());
};
onMounted(focusFirstEmptyInput);
watch(currentPage, focusFirstEmptyInput);

const userName = ref(null);

const navigateTo = (destination, _userName) => {
  currentPage.value = destination;
  userName.value = _userName ?? null;

  if (destination === PAGE_LOGIN) history.replaceState(null, null, LOGIN);
  if (destination === PAGE_FORGOT) history.replaceState(null, null, `${LOGIN}?page=${PAGE_FORGOT}`);

  nextTick(() => layout.value.scrollContainer.scrollTo({ top: 0, behavior: "smooth" }));
};
</script>

<template>
  <FhFullscreenFocusedLayout ref="layout" :background-image="props.image">
    <template #default>
      <div ref="form">
        <LogInPage v-if="currentPage === PAGE_LOGIN" :success-redirect="props.successRedirect" :user-name="userName" @navigate="navigateTo" />
        <ForgotPassword v-else-if="currentPage === PAGE_FORGOT" @navigate="navigateTo" />
        <EmailSent v-else-if="currentPage === PAGE_SENT" @navigate="navigateTo" />
        <UpdatePassword
          v-else-if="currentPage === PAGE_RESET"
          :user-id="props.userId"
          :token="props.token"
          :is-invalid="props.isInvalid"
          @navigate="navigateTo"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex flex-col gap-f6 md:flex-row">
        <div class="flex flex-col gap-f0.5">
          <span class="text-neutral-50">Not a Member?</span>
          <FhLink :href="TRADEPROGRAM">Apply Now</FhLink>
        </div>
        <div class="flex flex-col gap-f0.5">
          <span class="text-neutral-50">Need Help?</span>
          <FhLink :href="CONTACT_US">Contact Us</FhLink>
        </div>
      </div>
    </template>
  </FhFullscreenFocusedLayout>
</template>

<script setup>
import { getDataRefs } from "../util/vue";
import FhSection from "../includes/FhSection.vue";
import FhPerson from "./FhPeople/FhPerson.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { people } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    aria-label="People & Leadership"
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="grid grid-cols-1 gap-5 pt-f8 md:grid-cols-2 lg:grid-cols-3">
      <fh-person v-for="person in people" :key="person.id" :person="person"></fh-person>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.text-white * {
  color: white;
}
</style>

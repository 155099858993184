<script setup>
import { computed } from "vue";

const props = defineProps({
  /**
   * Model of the component; Either use this property (along with a listener for 'update:model-value' event) OR use v-model directive
   */
  modelValue: {
    type: undefined,
    required: true
  },
  /**
   * The value of the option with which model value is changed
   */
  value: {
    type: undefined,
    required: true
  },
  /**
   * The checkbox label (or use the default slot instead of this prop)
   */
  label: {
    type: String,
    default: undefined
  },
  /**
   * Puts the radio button in a disabled, non-interactive state
   */
  disabled: {
    type: Boolean,
    default: false
  },
  /**
   * Controls the checkbox and label size; corresponds to standard fluid body text sizes
   * @values base, lg, xl
   */
  size: {
    type: String,
    default: "base",
    validator: (val) => ["base", "lg", "xl"].includes(val)
  }
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const labelSizeClass = computed(() => {
  if (props.size === "lg") return "text-f-base-lg";
  if (props.size === "xl") return "text-f-lg-xl";
  return "text-f-sm-base";
});
</script>

<template>
  <label
    class="c-radio relative inline-flex cursor-pointer select-none items-start"
    :class="[labelSizeClass, { 'cursor-default opacity-35': props.disabled }]"
    :style="{ '--label-lh': `var(--label-lh-${props.size})` }"
  >
    <input v-model="value" :value="props.value" type="radio" class="relative mr-f2 shrink-0" :disabled="props.disabled" />
    <!-- @slot The default slot can be used for a label; overwrites the `label` prop -->
    <slot>{{ props.label }}</slot>
  </label>
</template>

<style scoped>
.c-radio {
  /* Variables for the line heights used in the computations below, pulled from the Tailwind config; one for each possible size param */
  --label-lh-base: theme("fontSize.f-sm-base.1");
  --label-lh-lg: theme("fontSize.f-base-lg.1");
  --label-lh-xl: theme("fontSize.f-lg-xl.1");

  /* The radio button size in em units so it scales with the font size */
  --radio-size: 1.1em;
  --radio-fill-size: calc(var(--radio-size) * 3 / 5);

  /**
   * Vertical offset for the radio button to vertically centered it the first line of text in the label;
   * Depends on the dynamic `--label-lh` var, which is set above as an inline style based on the size prop.
   */
  --radio-offset: calc(var(--label-lh) / 2 - var(--radio-size) / 2);

  & input[type="radio"] {
    width: var(--radio-size);
    height: var(--radio-size);
    top: var(--radio-offset);
    @apply grid appearance-none place-content-center rounded-full border border-solid border-neutral-50 bg-white;

    &:checked {
      @apply border-neutral-70;
      &::before {
        @apply absolute top-1/2 left-1/2 rounded-full bg-neutral-70;
        content: "";
        width: var(--radio-fill-size);
        height: var(--radio-fill-size);
        transform: translateX(calc(var(--radio-fill-size) / -2)) translateY(calc(var(--radio-fill-size) / -2));
      }
    }
  }
}

/**
 * TODO: Add :focus styles to the custom radio button
 */
</style>

<script setup>
import FhSection from "../includes/FhSection.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { title, description } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    v-if="data"
    aria-label="items"
    :base="style.base"
    class=""
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="grid md:justify-items-center text-center justify-center mb-[40px] md:mb-[60px]">
      <div class="md:max-w-[600px] md:basis-1/2 mb-[32px]">
        <h2 class="font-sans text-3xl">{{ title }}</h2>
      </div>
      <p class="text-base font-normal md:max-w-[600px] md:basis-1/2">{{ description }}</p>
    </div>
    <div
      :class="[
        'grid grid-cols-2 gap-f4 md:gap-x-f3 gap-y-f6 md:gap-y-f4',
        data.layout === 'normal' ? 'lg:grid-cols-4' : '',
        data.layout === 'small' ? 'lg:grid-cols-6' : ''
      ]"
    >
      <div class="flex flex-col" :class="[data.layout === 'small' ? 'lg:col-start-2' : '']">
        <div class="">
          <img :src="data.image1" class="h-auto w-full object-cover" :alt="data.image1AltText" />
        </div>
        <div class="pt-[24px] h-full">
          <p class="text-base font-normal">{{ data.optionalSubcopy1 }}</p>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="">
          <img :src="data.image2" class="h-auto w-full object-cover" :alt="data.ima2AltText" />
        </div>
        <div class="pt-[24px] h-full">
          <p class="text-base font-normal">{{ data.optionalSubcopy2 }}</p>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="">
          <img :src="data.image3" class="h-auto w-full object-cover" :alt="data.ima3AltText" />
        </div>
        <div class="pt-[24px] h-full">
          <p class="text-base font-normal">{{ data.optionalSubcopy3 }}</p>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="">
          <img :src="data.image4" class="h-auto w-full object-cover" :alt="data.ima4AltText" />
        </div>
        <div class="pt-[24px] h-full">
          <p class="text-base font-normal">{{ data.optionalSubcopy4 }}</p>
        </div>
      </div>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.text-white * {
  color: white !important;
}
</style>

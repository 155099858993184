<script setup>
import { reactive, ref } from "vue";
import FhInput from "@/components/FhInput.vue";
import FhCheckbox from "@/components/FhCheckbox.vue";
import FhModal from "@/components/FhModal.vue";
import FhButton from "@/components/FhButton.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, maxLength, sameAs, email } from "@vuelidate/validators";
import { useNotificationStore } from "@/stores/NotificationStore.js";
import { useSubmitContactSubscriptionMutation } from "@/api/contactSubscriptionApi.js";
const props = defineProps({
  contactDetails: {
    type: Object,
    default: () => {}
  }
});

const initialState = {
  firstName: null,
  lastName: null,
  email: null,
  terms: false
};

const modal = ref(null);

const toggleModal = () => {
  modal.value.close();
};

const formData = reactive({ ...initialState, ...props.contactDetails });

const vuelidate = useVuelidate(
  {
    firstName: {
      required: helpers.withMessage("Please enter a first name.", required),
      maxLength: helpers.withMessage("Please keep your entry to no more than 15 characters.", maxLength(15))
    },
    lastName: {
      required: helpers.withMessage("Please enter a last name", required),
      maxLength: helpers.withMessage("Please keep your entry to no more than 15 characters.", maxLength(15))
    },
    email: {
      required: helpers.withMessage("Please enter an email address.", required),
      email: helpers.withMessage("Please enter a valid email address.", email)
    },
    terms: { sameAs: sameAs(true) }
  },
  formData
);

const notificationStore = useNotificationStore();

const { mutate: submitContactSubscriptionMutation, isLoading } = useSubmitContactSubscriptionMutation();

async function createContact() {
  if (await vuelidate.value.$validate()) {
    let data = { ...formData };
    submitContactSubscriptionMutation(data, {
      onSuccess: handleSaveSuccess,
      onError: handleSaveError,
      onSettled: () => {
        toggleModal();
      }
    });
  }
}

function handleSaveSuccess() {
  vuelidate.value.$reset();
  notificationStore.notifySuccess(`Thank you for signing up!`, {
    linkText: "Follow us on Instagram",
    linkUrl: "https://www.instagram.com/fourhandsfurniture",
    linkTarget: "_blank"
  });
}

function handleSaveError() {
  notificationStore.notifyError("Sorry, something went wrong. Please try again.");
}

const reset = () => {
  vuelidate.value.$reset();
  formData.firstName = null;
  formData.lastName = null;
  formData.email = null;
  formData.terms = false;
};
</script>

<template>
  <FhModal ref="modal" :data="{ heading: 'Email Signup', subheading: `Don’t miss out on new arrivals, Market news and exclusive savings.` }" @close="reset">
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <form id="contactCreateForm">
        <fieldset class="mb-f8">
          <div class="flex flex-col gap-f3">
            <div class="flex flex-col gap-f3 md:flex-row">
              <FhInput v-model="formData.firstName" label="First Name" required :vuelidate="vuelidate.firstName" />
              <FhInput v-model="formData.lastName" label="Last Name" required :vuelidate="vuelidate.lastName" />
            </div>
            <FhInput v-model="formData.email" label="Email Address" required :vuelidate="vuelidate.email" />
            <div>
              <FhCheckbox v-model="formData.terms" required :vuelidate="vuelidate.terms" class="lg:col-span-2">
                <div>Yes, please send me the latest collections, news and promotions from Four Hands.</div>
              </FhCheckbox>
            </div>
          </div>
        </fieldset>
      </form>
    </template>
    <template #footer="{ close }">
      <FhButton
        type="submit"
        form="createContactForm"
        color="primary"
        :disabled="vuelidate.$invalid || isLoading"
        :is-loading="isLoading"
        @click.stop.prevent="createContact"
        >{{ "Subscribe" }}</FhButton
      >
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
</template>

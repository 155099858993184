<script setup>
import { computed } from "vue";
import { CART } from "@/constants/routes";
import FhAlert from "./FhAlert.vue";
import FhLink from "./FhLink.vue";
import FhCartItem from "./FhCartItem.vue";

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  checkoutMessaging: {
    type: String,
    default: null
  }
});
const unavailableItems = computed(() => props.items.filter((item) => item.sku.isAvailable === false));
</script>

<template>
  <!-- CUSTOM MESSAGE -->
  <FhAlert v-if="props.checkoutMessaging" icon="AlertCircle" class="mb-8 md:mb-f6">
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="props.checkoutMessaging"></div>
  </FhAlert>
  <FhAlert v-if="unavailableItems.length > 0" icon="AlertCircle" class="mb-8 md:mb-f6">
    <div class="mb-2">The following items are no longer available:</div>
    <ul class="mb-2 ml-[1em] list-outside list-disc">
      <li v-for="item in unavailableItems" :key="item.sku.skuNumber">
        {{ item.sku.productName }} &bull; {{ item.sku.skuName }} &bull; {{ item.sku.skuNumber }}
      </li>
    </ul>
    <div>Please remove them from your <FhLink :href="CART">shopping cart</FhLink> before placing your order.</div>
    <div>The information you've entered in checkout has been saved.</div>
  </FhAlert>
  <div class="mb-f2.5 text-f-base-xl">Please note: Once you submit your order, we will begin processing in-stock product for shipment immediately.</div>
  <div>
    <FhCartItem v-for="item in props.items" :key="item.sku.skuNumber" v-bind="item" view-only class="relative -mb-px" />
  </div>
</template>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.75295 1.25H22.7529V18.25H5.75295V1.25ZM4.50295 0H5.75295H22.7529H24.0029V1.25V18.25V19.5H22.7529H5.75295H4.50295V18.25V1.25V0ZM0.274414 5.10059V23.1006V23.7256H0.899414H18.8994V22.4756H1.52441V5.10059H0.274414Z"
      fill="currentColor"
    />
  </svg>
</template>

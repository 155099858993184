<script setup>
import { computed } from "vue";
import FhPaymentCardList from "@/components/Ecomm/FhPaymentCardList/FhPaymentCardList.vue";
import FhButton from "@/components/FhButton.vue";
import FhCreditCardForm from "@/components/Ecomm/FhCreditCardForm/FhCreditCardForm.vue";
import { useCreditCardQuery } from "../../../api/creditCardApi";
import FhPaymentCardSelectList from "@/components/Ecomm/FhPaymentCardSelectList/FhPaymentCardSelectList.vue";
import FhLoader from "@/components/FhLoader.vue";

const props = defineProps({
  mode: {
    type: String,
    default: "list",
    validator: (val) => ["list", "select"].includes(val)
  },
  allowSameAsShipping: {
    type: Boolean,
    default: false
  },
  shippingAddress: {
    type: Object,
    default: () => {}
  },
  /**
   * Applicable only when `mode` is `select`
   */
  selectedId: {
    type: String,
    default: null
  },
  includeSingleUse: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:selectedId", "update:selectedCard"]);

const isListMode = computed(() => props.mode === "list");
const isSelectMode = computed(() => props.mode === "select");

const { isLoading, isError, data: cards } = useCreditCardQuery({ includeSingleUse: props.includeSingleUse || isSelectMode.value });

const _selectedId = computed({
  get: () => props.selectedId,
  set: (val) => {
    emit("update:selectedId", val);
    const selectedcard = cards.value.find((card) => card.id === val);
    emit("update:selectedCard", selectedcard);
  }
});

function handleCardCreate(newCard) {
  if (isSelectMode.value) {
    _selectedId.value = newCard.id;
  }
}
</script>

<template>
  <div class="payment-method grid gap-y-f4">
    <div class="header md:content-end">
      <slot name="header"></slot>
    </div>
    <div class="button md:justify-self-end">
      <FhCreditCardForm
        :allow-single-use="isSelectMode"
        :allow-same-as-shipping="allowSameAsShipping"
        :shipping-address="shippingAddress"
        @create="handleCardCreate"
      >
        <template #activator="{ open }">
          <FhButton class="w-full md:w-auto" @click="open">Add Card</FhButton>
        </template>
      </FhCreditCardForm>
    </div>
    <div class="content">
      <FhLoader v-if="isLoading" class="h-10 w-10" />
      <FhPaymentCardList v-if="!isLoading && isListMode" v-bind="{ cards, isError }" />
      <FhPaymentCardSelectList v-if="!isLoading && isSelectMode" v-model:selected-id="_selectedId" v-bind="{ cards, isError }" />
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.payment-method {
  grid-template-areas:
    "header header"
    "content content"
    "button button";
  &:deep(.header){
    grid-area: header
  }
  &:deep(.button){
    grid-area: button
  }
  &:deep(.content){
    grid-area: content
  }
}
@screen md{
  .payment-method{
    grid-template-areas:
    "header button"
    "content content";
  }
}
</style>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import TransitionExpand from "../../transitions/TransitionExpand.vue";
import IconChevronDown from "../../assets/icon-chevron-down.svg";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    default: ""
  }
});

const isActive = ref(false);

const isFaq = computed(() => window.location.pathname.includes("faq"));
if (isFaq.value) {
  document.body.classList.add("smooth-scroll");
}

const anchorTitle = computed(() => {
  let aText = props.title?.toLowerCase().replace(/[^\w\s]/gi, "");
  aText = aText.replace(/\s+/g, " ");
  return aText?.split(" ").join("-").toLowerCase() ?? "";
});

const windowHash = ref(window.location.hash);

watch(windowHash, (newHash) => {
  if (newHash.replace("#", "") === anchorTitle.value) {
    scrollToFaq();
  }
});

const scrollToFaq = () => {
  const element = document.querySelector(windowHash.value);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
    const openAnimation = setTimeout(() => {
      isActive.value = true;
      clearTimeout(openAnimation);
    }, 300);
  }
};

onMounted(() => {
  if (window.location.hash.length > 0 && window.location.hash.replace("#", "") === anchorTitle.value) {
    scrollToFaq();
  }
  window.addEventListener("hashchange", () => {
    windowHash.value = window.location.hash;
  });
});

onUnmounted(() => {
  window.removeEventListener("hashchange", () => {
    windowHash.value = window.location.hash;
  });
});
</script>

<template>
  <!-- ACCORDION ITEM -->
  <div class="border-b border-neutral-20">
    <!-- ACCORDION title -->
    <button
      :id="anchorTitle"
      class="flex w-full cursor-pointer list-none items-start py-f4 text-neutral-70 focus:outline-none"
      :aria-expanded="isActive"
      :aria-controls="`content-${title}`"
      @click.prevent="isActive = !isActive"
    >
      <!-- title TITLE -->
      <span class="pointer-events-none w-full grow basis-auto text-left text-f-base-2xl">
        {{ title }}
      </span>
      <span class="pointer-events-none ml-8 shrink pt-1.5 pr-6">
        <img
          :src="IconChevronDown"
          alt="Chevron Down"
          class="ChevronDown h-2 w-3.5 transition-transform duration-200 ease-linear lg:h-3 lg:w-5"
          :class="{ 'rotate-180': isActive }"
        />
      </span>
    </button>
    <TransitionExpand>
      <!-- ACCORDION CONTENT -->
      <div v-if="isActive" :id="`content-${title}`" :aria-hidden="!isActive">
        <div :class="['mb-f5 ml-1 flex flex-col pl-f5 text-f-sm-xl md:ml-1.5 md:pr-f25 lg:ml-0']">
          <FhWysiwyg>
            <template v-for="(item, i) in items" :key="`${i}-${item.title}`">
              <strong class="mb-4 block text-f-sm-base text-inherit">{{ item.title }}</strong>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p class="mb-8 text-f-sm-base text-inherit last:mb-0" v-html="item.copy"></p>
            </template>
          </FhWysiwyg>
        </div>
      </div>
    </TransitionExpand>
  </div>
</template>
<style lang="pcss">
html:has(body.smooth-scroll) {
  scroll-behavior: smooth;
}
</style>

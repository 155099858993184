<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 2C9.62352 2 7.29167 4.23858 7.29167 7C7.29167 9.76142 9.62352 12 12.5 12C15.3765 12 17.7083 9.76142 17.7083 7C17.7083 4.23858 15.3765 2 12.5 2ZM9.375 7C9.375 5.34315 10.7741 4 12.5 4C14.2259 4 15.625 5.34315 15.625 7C15.625 8.65685 14.2259 10 12.5 10C10.7741 10 9.375 8.65685 9.375 7Z"
      fill="currentColor"
    />
    <path
      d="M8.33333 14C6.952 14 5.62724 14.5268 4.65049 15.4645C3.67373 16.4021 3.125 17.6739 3.125 19V22H5.20833V19C5.20833 18.2043 5.53757 17.4413 6.12362 16.8787C6.70968 16.3161 7.50453 16 8.33333 16H16.6667C17.4955 16 18.2903 16.3161 18.8764 16.8787C19.4624 17.4413 19.7917 18.2044 19.7917 19V22H21.875V19C21.875 17.6739 21.3263 16.4021 20.3495 15.4645C19.3728 14.5268 18.048 14 16.6667 14H8.33333Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup>
import { reactive, ref, watch } from "vue";
import { useNotificationStore } from "../stores/NotificationStore.js";
import { useUpdateCheckoutMutation, UPDATE_CHECKOUT_PATHS } from "../api/checkoutApi";
import { useVuelidate } from "@vuelidate/core";
import { helpers, maxLength } from "@vuelidate/validators";
import FhButton from "./FhButton.vue";
import FhInput from "./FhInput.vue";
import FhModal from "./FhModal.vue";

const props = defineProps({
  poNumber: {
    type: String,
    default: null
  }
});

const data = reactive({
  poNumber: props.poNumber
});

const specialCharsValidation = helpers.regex(/^[^\t]*$/);

const vuelidate = useVuelidate(
  {
    poNumber: {
      maxLength: helpers.withMessage("Please enter no more than 25 characters.", maxLength(25)),
      validCharacters: helpers.withMessage("Custom PO numbers cannot contain tabs.", specialCharsValidation)
    }
  },
  data
);

const modalIsOpen = ref(false);

watch(
  () => props.poNumber,
  (val) => (data.poNumber = val)
);

const notificationStore = useNotificationStore();

const { mutate: updateCheckout, isLoading: isSaving } = useUpdateCheckoutMutation();

function save() {
  updateCheckout(
    {
      path: UPDATE_CHECKOUT_PATHS.PO,
      data: {
        poNumber: data.poNumber.trim()
      }
    },
    {
      onSuccess: () => (modalIsOpen.value = false),
      onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not save your custom PO number. Please try again.")
    }
  );
}

function cancel() {
  data.poNumber = props.poNumber;
  modalIsOpen.value = false;
}
</script>

<template>
  <div>
    <div class="flex justify-between" :class="[props.poNumber ? 'items-start' : 'items-center']">
      <div class="text-f-sm-lg">PO</div>
      <div v-if="props.poNumber" class="text-right">
        <div>{{ props.poNumber }}</div>
        <FhButton variant="link" size="small" @click="modalIsOpen = true">Edit</FhButton>
      </div>
      <FhButton v-else variant="link" size="small" @click="modalIsOpen = true">Add Custom PO</FhButton>
    </div>
    <FhModal v-model:is-open="modalIsOpen" :data="{ heading: `${props.poNumber ? 'Edit' : 'Add'} Custom PO` }">
      <template #default><FhInput v-model="data.poNumber" label="PO Number" :vuelidate="vuelidate.poNumber" /></template>
      <template #footer>
        <FhButton color="primary" :disabled="vuelidate.$invalid || isSaving" @click="save">Save</FhButton>
        <FhButton variant="text" @click="cancel">Cancel</FhButton>
      </template>
    </FhModal>
  </div>
</template>

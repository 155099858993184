export const envrionmentTypes = {
  default: "development",
  envrionments: {
    local: ["localhost"],
    test: ["fh-test-fourhandscom.azurewebsites.net"],
    development: ["fh-dev-fourhandscom.azurewebsites.net"],
    staging: ["fh-staging-fourhandscom.azurewebsites.net"],
    production: ["fh-prod-fourhandscom.azurewebsites.net", "fourhands.com"]
  },
  getEnvrionment: function (hostName) {
    if (!hostName) return this.default;
    const envrionment = Object.entries(this.envrionments).find(([_, value]) => {
      if (value.includes(hostName)) return true;
    });
    return envrionment?.[0] ?? this.default;
  }
};

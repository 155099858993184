<script setup>
import { computed } from "vue";
import FhGuestAccountCard from "@/components/FhGuestAccountCard.vue";

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  hasError: {
    type: Boolean,
    default: false
  },
  isEmpty: {
    type: Boolean,
    default: false
  },
  markup: {
    type: Number,
    default: 0
  }
});

const emits = defineEmits(["delete", "update"]);

const guestAccounts = computed(() => props.modelValue);
const isguestAccountsDataEmpty = computed(() => guestAccounts.value.length === 0);
const hasError = computed(() => props.hasError);
</script>

<template>
  <div v-if="!isEmpty && !hasError" class="grid gap-f3 md:grid-cols-2">
    <FhGuestAccountCard
      v-for="guestAccount in guestAccounts"
      :id="guestAccount.id"
      :key="guestAccount.id"
      :name="guestAccount.name"
      :email="guestAccount.email"
      :markup="props.markup"
      :pricing-visibility="guestAccount.pricingVisibility"
      @delete="(guestAccountId) => emits('delete', guestAccountId)"
      @update="(guestAccountId) => emits('update', guestAccountId)"
    ></FhGuestAccountCard>
  </div>
  <div v-if="hasError">
    <p class="text-body-lg mb-f2 text-neutral-50">Sorry, we are not able to load your guest accounts at this time.</p>
  </div>
  <div v-else-if="!isEmpty && isguestAccountsDataEmpty">
    <p class="text-body-lg mb-f2 text-neutral-50">You have no Guest Accounts with that name or email.</p>
  </div>
  <div v-else-if="isEmpty && isguestAccountsDataEmpty">
    <p class="text-body-lg mb-f2 text-neutral-50">You have no saved Guest Accounts.</p>
  </div>
</template>

<script setup>
import FhShoppingListCard from "@/components/FhShoppingListCard.vue";
import FhLoader from "@/components/FhLoader.vue";
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  hasError: {
    type: Boolean,
    default: false
  },
  isEmpty: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isAdminShoppingList: {
    type: Boolean,
    default: false
  },
  isGuestAccount: {
    type: Boolean,
    default: false
  },
  isSelectedGuestAccount: {
    type: Boolean,
    default: false
  },
  isSearchQuery: {
    type: Boolean,
    default: false
  }
});
const emits = defineEmits(["delete", "update"]);
const shoppingLists = computed(() => props.modelValue);
const isEmptyAccount = computed(() => shoppingLists.value.length == 0);
</script>

<template>
  <div v-if="props.isLoading" class="w-full"><FhLoader class="h-10 w-10" /></div>
  <div class="top-block mt-8 mb-8 grid gap-f5 md:grid-cols-3">
    <FhShoppingListCard
      v-for="item in shoppingLists"
      :key="item.key || item.id"
      :is-admin="props.isAdmin"
      v-bind="item"
      class="scroll-mt-24 flex flex-col"
      @delete="(shoppingListId) => emits('delete', shoppingListId)"
      @update="(shoppingListId) => emits('update', shoppingListId)"
    ></FhShoppingListCard>
  </div>
  <div v-if="props.hasError && !props.isLoading">
    <p class="text-body-lg mb-f2 text-neutral-50">Sorry, we are not able to load your Shopping Lists at this time.</p>
  </div>
  <div
    v-if="
      (isEmpty && isEmptyAccount && props.isAdmin && !props.isLoading) ||
      (isEmpty && isEmptyAccount && !props.isAdmin && !props.isLoading) ||
      (isEmpty && isEmptyAccount && !props.isAdmin && !props.isLoading)
    "
  >
    <p class="text-body-lg mb-f2 text-neutral-50">You have no Shopping Lists.</p>
  </div>
  <div
    v-if="
      (isEmpty && isEmptyAccount && props.isAdmin && props.isAdminShoppingList && !isSelectedGuestAccount && isSearchQuery && !props.isLoading) ||
      (!isEmpty && isEmptyAccount && props.isAdmin && props.isAdminShoppingList && !isSelectedGuestAccount && isSearchQuery && !props.isLoading)
    "
  >
    <p class="text-body-lg mb-f2 text-neutral-50">You have no Shopping Lists.</p>
  </div>
  <div
    v-if="
      (isEmpty &&
        isEmptyAccount &&
        !isGuestAccount &&
        props.isAdmin &&
        !props.isAdminShoppingList &&
        !isSelectedGuestAccount &&
        isSearchQuery &&
        !props.isLoading) ||
      (!isEmpty &&
        isEmptyAccount &&
        !isGuestAccount &&
        props.isAdmin &&
        !props.isAdminShoppingList &&
        !isSelectedGuestAccount &&
        isSearchQuery &&
        !props.isLoading)
    "
  >
    <p class="text-body-lg mb-f2 text-neutral-50">You have no Guest Accounts or your Guest Accounts do not have any Shopping Lists.</p>
  </div>
  <div
    v-if="
      (isEmpty &&
        isEmptyAccount &&
        isGuestAccount &&
        props.isAdmin &&
        !props.isAdminShoppingList &&
        !isSelectedGuestAccount &&
        isSearchQuery &&
        !props.isLoading) ||
      (!isEmpty &&
        isEmptyAccount &&
        isGuestAccount &&
        props.isAdmin &&
        !props.isAdminShoppingList &&
        !isSelectedGuestAccount &&
        isSearchQuery &&
        !props.isLoading)
    "
  >
    <p class="text-body-lg mb-f2 text-neutral-50">Your Guest Accounts do not have any Shopping Lists.</p>
  </div>
  <div v-if="!isEmpty && isEmptyAccount && props.isGuestAccount && isSearchQuery && !props.isAdminShoppingList && isSelectedGuestAccount && !props.isLoading">
    <p class="text-body-lg mb-f2 text-neutral-50">The selected Guest Account does not have any Shopping Lists.</p>
  </div>
  <div
    v-if="
      (!isEmpty && isEmptyAccount && !isSearchQuery) ||
      (!isEmpty && isEmptyAccount && isGuestAccount && props.isAdmin && !props.isAdminShoppingList && !isSelectedGuestAccount && !isSearchQuery)
    "
  >
    <p class="text-body-lg mb-f2 text-neutral-50">There are no Shopping Lists with that name.</p>
  </div>
</template>
<style scoped>
@media (min-width: 768px) and (max-width: 1024px) {
  .top-block {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  /**
   * Model of the component; Either use this property (along with a listener for 'update:model-value' event) OR use v-model directive
   */
  modelValue: {
    type: [Boolean, Array],
    required: true
  },
  /**
   * Applicable when `modelValue` is an Array; which value should be added/removed when checkbox is selected/unselected
   */
  value: {
    type: undefined,
    default: null
  },
  /**
   * The checkbox label (or use the default slot instead of this prop)
   */
  label: {
    type: String,
    default: undefined
  },
  /**
   * The checkbox indeterminate (or use the default slot instead of this prop)
   */
  indeterminate: {
    type: Boolean,
    default: false
  },
  /**
   * An optional note to include below the checkbox label
   */
  note: {
    type: String,
    default: undefined
  },
  /**
   * Puts the checkbox in a disabled, non-interactive state
   */
  disabled: {
    type: Boolean,
    default: false
  },
  /**
   * Controls the checkbox and label size; corresponds to standard fluid body text sizes
   * @values base, lg, xl
   */
  size: {
    type: String,
    default: "base",
    validator: (val) => ["base", "lg", "xl"].includes(val)
  }
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const labelSizeClass = computed(() => {
  if (props.size === "lg") return "text-f-base-lg";
  if (props.size === "xl") return "text-f-lg-xl";
  return "text-f-sm-base";
});

const inputRef = ref(null);

defineExpose({
  inputRef
});
</script>

<template>
  <label
    class="c-checkbox relative inline-flex cursor-pointer select-none items-start"
    :class="[labelSizeClass, { 'cursor-default opacity-35': props.disabled }]"
    :style="{ '--label-lh': `var(--label-lh-${props.size})` }"
  >
    <!-- The real checkbox; visually hidden, but still accessible to assistive technologies -->
    <input
      ref="inputRef"
      v-model="value"
      :value="props.value"
      type="checkbox"
      class="input absolute opacity-0"
      :disabled="props.disabled"
      :data-ga="`${$attrs['data-ga'] ?? 'checkbox'}`"
      :data-ga-value="`${$attrs['data-ga-value'] ?? props.value}`"
    />
    <!-- The "replacement" visual checkbox with custom styling -->
    <div class="checkbox relative mr-f2 shrink-0 border border-neutral-50" aria-hidden="true" focusable="false">
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="fill-transparent">
        <path v-if="!props.indeterminate" fill-rule="evenodd" clip-rule="evenodd" d="m6 10.39 6.862-6.863.943.943L6 12.275 2.195 8.47l.943-.943L6 10.39Z" />
        <rect v-if="props.indeterminate" x="1" y="7" width="14" height="2" fill="currentColor" />
      </svg>
    </div>
    <!-- @slot The default slot can be used for a label; overwrites the `label` and `note` props, if set -->
    <slot>
      <div v-if="props.note">
        <div class="mb-f1">{{ props.label }}</div>
        <div class="text-body-sm max-w-prose text-neutral-50">{{ props.note }}</div>
      </div>
      <template v-else>
        {{ props.label }}
      </template>
    </slot>
  </label>
</template>

<style scoped lang="pcss">
.c-checkbox {
  /* Variables for the line heights used in the computations below, pulled from the Tailwind config; one for each possible size param */
  --label-lh-base: theme("fontSize.f-sm-base.1");
  --label-lh-lg: theme("fontSize.f-base-lg.1");
  --label-lh-xl: theme("fontSize.f-lg-xl.1");

  /* The checkbox size in em units so it scales with the font size */
  --checkbox-size: 1em;

  /**
   * Vertical offset for the checkbox to vertically centered it the first line of text in the label;
   * Depends on the dynamic `--label-lh` var, which is set above as an inline style based on the size prop.
   */
  --checkbox-offset: calc(var(--label-lh) / 2 - var(--checkbox-size) / 2);

  /**
   * Position both the real checkbox and the "replacement" checkbox in the same spot, on top of eachother. The real checkbox is made invisible with opacity:0.
   * The approach, and the reasons for it, are outlined here: https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons
   */
  & .input,
  & .checkbox {
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    top: var(--checkbox-offset);
  }

  /* Checkbox styling for the checked state */
  & .input:checked + .checkbox {
    @apply border-neutral-70 bg-neutral-70;

    & svg {
      @apply fill-white;
    }
  }
}

/**
 * TODO: Add :focus styles to the custom checkbox
 */
</style>

<script setup>
import { ref, watch } from "vue";
import { useViewportSizes } from "@/composables";
import FhImage from "@/components/FhImage.vue";
import FhVideo from "@/components/FhVideo.vue";
import FhIcon from "@/components/FhIcon.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode, Thumbs } from "swiper/modules";
import { MEDIA_TYPES } from "@/constants/media";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

const props = defineProps({
  media: {
    type: Array,
    required: true
  }
});

const { isSm } = useViewportSizes();

const imagesSwiper = ref(null);
const thumbsSwiper = ref(null);

const setImagesSwiper = (swiper) => (imagesSwiper.value = swiper);
const setThumbsSwiper = (swiper) => (thumbsSwiper.value = swiper);

const destroySwiper = (swiperRef) => {
  if (swiperRef.value === null) return;
  swiperRef.value.destroy(true, false);
  swiperRef.value = null;
};

watch(isSm, (val) => {
  if (val) return;
  destroySwiper(imagesSwiper);
  destroySwiper(thumbsSwiper);
});
</script>

<template>
  <div v-if="isSm">
    <Swiper
      :modules="[Thumbs]"
      :thumbs="{ swiper: thumbsSwiper }"
      class="relative left-1/2 right-1/2 mb-f3 !ml-[-50vw] !mr-[-50vw] w-screen w-screen border-y border-solid border-neutral-30"
      @swiper="setImagesSwiper"
    >
      <SwiperSlide v-for="mediaItem in props.media" :key="mediaItem.largeUrl">
        <FhImage v-if="mediaItem.type === MEDIA_TYPES.IMAGE" :src="mediaItem.largeUrl" :alt="mediaItem.altText" square contain />
        <FhVideo v-if="mediaItem.type === MEDIA_TYPES.VIDEO" :src="mediaItem.videoUrl" :autoplay="true" :loop="true">
          <template #overlay="{ videoToggle, isPlaying }">
            <div class="absolute top-0 left-0 h-full w-full"></div>
            <button class="absolute bottom-2 right-2 flex h-16 w-16" @click.stop="videoToggle">
              <FhIcon v-if="!isPlaying" name="VideoPlay" class="h-full w-full" />
              <FhIcon v-else name="VideoPause" class="h-full w-full" />
            </button>
          </template>
        </FhVideo>
      </SwiperSlide>
    </Swiper>
    <Swiper
      :modules="[FreeMode, Thumbs]"
      :slides-per-view="4"
      :space-between="8"
      watch-slides-progress
      free-mode
      class="thumbs-swiper"
      @swiper="setThumbsSwiper"
    >
      <SwiperSlide v-for="mediaItem in props.media" :key="mediaItem.thumbUrl">
        <FhImage :src="mediaItem.thumbUrl" :alt="mediaItem.altText" square border class="thumb-img" />
        <div v-if="mediaItem.type === MEDIA_TYPES.VIDEO" class="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black/20">
          <FhIcon name="VideoPlay" class="h-[46px] w-[46px]" />
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<style scoped>
.thumbs-swiper .swiper-slide-thumb-active .thumb-img {
  @apply border-neutral-70;
}
:deep(.fh_video) {
  aspect-ratio: 1/1;
  object-fit: cover;
}
</style>

<script setup>
import FhMaterial from "./FhMaterials/FhMaterial.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const { materials } = getDataRefs(props.data);
</script>

<template>
  <div class="grid grid-cols-[1fr] gap-[2rem] md:grid-cols-[repeat(2,1fr)]">
    <FhMaterial v-for="(material, i) in materials" :key="`material-${i}`" :material="material"></FhMaterial>
  </div>
</template>

<style lang="pcss" scoped></style>

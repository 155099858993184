import { ref, computed, watch, unref } from "vue";

/**
 * Reactive paginated list of items (for client-side pagination).
 * @param {Array} list The full list of items to be paginated. Can be an Array or Vue ref to an Array.
 * @param {Number} perPage The number of items to show per page.
 * @returns An object with the following reactive properties:
    page: The current page number. This is a ref that can be assigned for changing the current page and can be bound to the v-model:page of the FhPaginationNav component.
    totalPages: The total number of pages.
    perPage: 
    sublist: The current page of items.
 */
export default function (list, perPage) {
  if (!Array.isArray(unref(list))) {
    throw new Error("list param expected to be an Array");
  }

  const page = ref(1);

  const totalPages = computed(() => Math.ceil(unref(list).length / perPage));

  // Ensures page ref is not left with an invalid value if the total number of pages changes (e.g., if the last item on the last page is removed)
  watch(totalPages, (val) => (page.value = Math.min(page.value, val)));

  const sublist = computed(() => {
    const start = (page.value - 1) * perPage;
    const end = start + perPage;
    return unref(list).slice(start, end);
  });

  return {
    page,
    totalPages,
    sublist
  };
}

<script setup>
import { reactive, ref } from "vue";
import FhSection from "../includes/FhSection.vue";
import FhInput from "./FhInput.vue";
import FhButton from "./FhButton.vue";
import FhMailingListModal from "./FhMailingList/FhMailingListModal.vue";
import { useSubmitMailingListMutation } from "@/api/mailingListApi.js";
import { useNotificationStore } from "@/stores/NotificationStore.js";
import { useVuelidate } from "@vuelidate/core";
import { helpers, email, required } from "@vuelidate/validators";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

const modalData = reactive({
  emailAddress: "",
  homeNewsletter: true,
  outletNewsletter: true
});

const openModal = ref(false);
const isLoading = ref(false);

const toggleModal = () => {
  openModal.value = !openModal.value;
};

const vuelidate = useVuelidate(
  {
    emailAddress: {
      required: helpers.withMessage("Please enter an email address.", required),
      email: helpers.withMessage("Please enter a valid email address.", email)
    }
  },
  modalData
);

const notificationStore = useNotificationStore();

const submitMailingListMutation = useSubmitMailingListMutation();

async function submit() {
  isLoading.value = true;
  if (await vuelidate.value.$validate()) {
    let data = { ...modalData };
    submitMailingListMutation.mutate(data, {
      onSuccess: handleSaveSuccess,
      onError: handleSaveError,
      onSettled: () => {
        toggleModal();
        isLoading.value = false;
      }
    });
  }
}

function handleSaveSuccess() {
  modalData.emailAddress = "";
  vuelidate.value.$reset();
  notificationStore.notifySuccess("You have been subscribed to our mailing list!");
}

function handleSaveError() {
  notificationStore.notifyError("Sorry, something went wrong. Please try again.");
}
</script>

<template>
  <FhSection aria-label="Mailing List Signup" :base="props.style.base" class="border-b border-solid border-neutral-30">
    <div class="flex flex-col items-center justify-center">
      <div class="mb-f8 text-center">
        <h2 class="mb-f4 font-serif text-f-4xl-8xl">Join Our Austin Mailing List</h2>
        <p class="max-w-[700px] text-f-sm-xl">Be the first to hear about our latest arrivals, special events and deals at our Austin showroom and outlet.</p>
      </div>
      <form id="mailingList" @submit.prevent>
        <div class="grid w-full grid-rows-2 items-start md:w-auto md:max-w-[615px] md:grid-cols-5 md:grid-rows-1">
          <FhMailingListModal v-model="modalData" :is-open="openModal" :is-loading="isLoading" @submit="submit" @cancel="toggleModal">
            <template #default="{}">
              <FhInput
                v-model="modalData.emailAddress"
                :wrapper-attrs="{ class: 'col-span-5 mb-3 w-full md:col-span-4 md:mb-0 md:pr-4' }"
                placeholder="Enter your email"
                required
                :vuelidate="vuelidate.emailAddress"
                @keyup.self.enter="!vuelidate.$invalid ? toggleModal() : false"
              />
              <FhButton
                v-bind="{ color: 'primary', size: 'large' }"
                :disabled="vuelidate.$invalid"
                class="col-span-5 mt-3 flex max-h-[61px] w-full items-center text-center md:col-span-1"
                @click="toggleModal"
              >
                Sign&nbsp;Up
              </FhButton>
            </template>
          </FhMailingListModal>
        </div>
      </form>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped></style>

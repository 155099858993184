<script setup>
import { ref, computed } from "vue";
import { useSignUpMutation } from "@/api/signUpApi";
import { useLoginMutation } from "@/api/authApi";
import { useNotificationStore } from "@/stores/NotificationStore";
import FhCreateAccountPresenter from "@/components/FhCreateAccountPresenter.vue";
import { SUPPORT_PHONE_NUMBER } from "@/constants/strings";

const props = defineProps({
  userId: {
    type: [String, Number],
    required: true
  },
  token: {
    type: String,
    required: true
  },
  companyName: {
    type: String,
    required: true
  },
  username: {
    type: String,
    required: true
  },
  email: {
    type: String,
    required: true
  },
  isInvalid: {
    type: Boolean,
    default: false
  },
  guestAccount: {
    type: Boolean,
    default: false
  }
});

const { mutate: signUpMutate, isLoading: isSignUpMutationRunning } = useSignUpMutation();
const { mutate: loginMutate, isLoading: isLoginMutationRunning } = useLoginMutation();
const notificationStore = useNotificationStore();

const isLoading = computed(() => isSignUpMutationRunning.value || isLoginMutationRunning.value);

const isSignUpComplete = ref(false);
const isLoginComplete = ref(false);

function handleSubmit({ password }) {
  signUpMutate(
    {
      userId: props.userId,
      token: props.token,
      password
    },
    {
      onSuccess: () => {
        isSignUpComplete.value = true;
        login(password);
      },
      onError: () => {
        notificationStore.notifyError(
          `Sorry, something went wrong and we could not create your account. Please try again or contact support at ${SUPPORT_PHONE_NUMBER} if the problem persists.`
        );
      }
    }
  );
}

function login(password) {
  loginMutate(
    {
      username: props.guestAccount ? props.email : props.username,
      password: password
    },
    {
      onSuccess: () => {
        isLoginComplete.value = true;
      }
    }
  );
}
</script>

<template>
  <FhCreateAccountPresenter
    :company-name="props.companyName"
    :username="props.username"
    :email="props.email"
    :is-loading="isLoading"
    :is-sign-up-complete="isSignUpComplete"
    :is-login-complete="isLoginComplete"
    :is-invalid="props.isInvalid"
    :guest-account="props.guestAccount"
    @submit="handleSubmit"
  />
</template>

<script setup>
import { useViewportSizes } from "../composables";
import { getDataRefs } from "../util/vue";
import FhTabs from "./FhTabs.vue";
import FhTestimonial from "./FhTestimonial.vue";
import { ref } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const { testimonials } = getDataRefs(props.data);
let { isSm } = useViewportSizes();
const selectedIndex = ref(0);
const updateTab = (index) => (selectedIndex.value = index);
</script>

<template>
  <FhTabs :align="[isSm ? 'left' : 'center', 'left']" :selected-index="selectedIndex" @change="updateTab">
    <template v-for="testimonial in testimonials" :key="testimonial.id" #[`trigger-${testimonial.id}`]>
      {{ testimonial.tabTitle }}
    </template>
    <template v-for="testimonial in testimonials" :key="testimonial.id" #[`content-${testimonial.id}`]>
      <FhTestimonial :data="{ item: testimonial }"></FhTestimonial>
    </template>
  </FhTabs>
</template>

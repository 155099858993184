<script setup>
import { computed, ref } from "vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  base: {
    type: Object,
    required: false,
    default: () => {}
  }
});

const {
  header = ref(null),
  subheader = ref(null),
  headerAlignment = ref(null),
  headerSize = ref(null),
  background = ref(null),
  padding = ref(null),
  margin = ref(null),
  width = ref(null),
  fontOptions = ref(null)
} = getDataRefs(props.base || {});

const headerAlignmentClasses = computed(() =>
  headerAlignment.value
    ? headerAlignment.value === "center"
      ? `text-${headerAlignment.value} mx-auto`
      : headerAlignment.value === "left"
      ? `text-${headerAlignment.value} mr-auto`
      : headerAlignment.value === "right"
      ? `text-${headerAlignment.value} ml-auto`
      : ""
    : ""
);

const headerFontSizeClass = computed(() =>
  headerSize.value ? (headerSize.value === "large" ? "text-f-3xl-8xl" : headerSize.value === "medium" ? "text-f-2xl-5xl" : "text-f-xl-3xl") : "text-f-3xl-8xl"
);

const backgroundClass = computed(() => (background.value ? `widget--bg-${background.value}` : ""));
const widthClass = computed(() => (width.value ? `widget--width-${width.value}` : ""));
const fontClass = computed(() => (fontOptions.value ? fontOptions.value : ""));

const paddingClass = computed(() => {
  if (!padding.value || !Array.isArray(padding.value)) return "";

  let classString = "";

  const appendClass = (str) => (classString += `widget--padding-${str} `);

  if (padding.value[0] && padding.value[0].trim().length) appendClass(padding.value[0]);
  if (padding.value[1] && padding.value[1].trim().length) appendClass(padding.value[1]);
  return classString;
});

const marginClass = computed(() => {
  if (!margin.value || !Array.isArray(margin.value)) return "";

  let classString = "";

  const appendClass = (str) => (classString += `widget--margin-${str} `);

  if (margin.value[0] && margin.value[0].trim().length) appendClass(margin.value[0]);
  if (margin.value[1] && margin.value[1].trim().length) appendClass(margin.value[1]);
  return classString;
});
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <section :class="['widget', backgroundClass, paddingClass, marginClass, widthClass, fontClass]">
    <header v-if="header || subheader" class="">
      <h2
        v-if="header"
        :class="[
          'w-full max-w-[675px] whitespace-pre-line font-serif font-light leading-[1.25]',
          headerAlignmentClasses,
          subheader ? 'mb-f3' : 'mb-f5',
          headerFontSizeClass
        ]"
        role="heading"
        aria-level="2"
        v-html="header"
      ></h2>
      <h3
        v-if="subheader"
        :class="['mb-f5 w-full max-w-[450px] whitespace-pre-line text-f-base-lg font-light leading-[1.25]', headerAlignmentClasses]"
        role="heading"
        aria-level="3"
        v-html="subheader"
      ></h3>
    </header>
    <div class="widget__inner">
      <slot></slot>
    </div>
  </section>
</template>

<style scoped></style>

<script setup>
import { getDataRefs } from "@/util/vue";
import FhHeading from "@/components/FhHeading.vue";
import FhLink from "@/components/FhLink.vue";
import FhSection from "@/includes/FhSection.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { heading, repsTitle, reps } = getDataRefs(props.data);
</script>

<template>
  <FhSection :base="style.base">
    <!-- PRODUCT SUPPORT -->
    <div class="mb-f10 flex flex-col text-neutral-70 md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10 md:pb-f10">
      <FhHeading tag="h2">{{ heading }}</FhHeading>
      <div class="mb-4 flex flex-col md:flex-row">
        <template v-if="repsTitle != null">
          <span class="mb-2 font-bold md:mb-0 md:basis-4/12 lg:basis-3/12">
            {{ repsTitle }}
          </span>
        </template>
        <div class="grid shrink basis-8/12 gap-f6 lg:grid-cols-2">
          <div v-for="(rep, i) in reps" :key="i" class="flex flex-col gap-f1">
            <div class="text-body-sm text-neutral-60">{{ rep.category }}</div>
            <div class="font-bold">{{ rep.name }}</div>
            <FhLink v-if="rep.phone" :href="`tel:${rep.phone}`">{{ rep.phone }}</FhLink>
            <FhLink v-if="rep.email" :href="`mailto:${rep.email}`" class="break-all">{{ rep.email }}</FhLink>
          </div>
        </div>
      </div>
    </div>
  </FhSection>
</template>

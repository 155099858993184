<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useTimeAgo } from "@vueuse/core";
import FhIcon from "@/components/FhIcon.vue";
import FhImage from "@/components/FhImage.vue";
import FhProductTags from "@/components/FhProductTags.vue";
const props = defineProps({
  id: {
    type: String || Number,
    default: null
  },
  guid: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  pricingVisibility: {
    type: String,
    default: null
  },
  totalItems: {
    type: Number,
    required: true
  },
  updatedAt: {
    type: Date,
    required: true
  },
  products: {
    type: Object,
    required: true
  },
  userName: {
    type: String,
    required: true
  },
  isAdminShoppingList: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(["update", "delete"]);
const shopplingListProducts = ref(props.products);
const availabilityUpdatedTimeAgo = useTimeAgo(() => props.updatedAt);

const showPopup = ref(false);
const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const handleClickTriggers = (click_type) => {
  switch (click_type) {
    case "update": {
      emits("update", props.id);
      break;
    }
    case "delete": {
      emits("delete", props.id);
      break;
    }
  }
  showPopup.value = false;
};

const closePopupOnClick = (event) => {
  if (!(event.target.closest(".toggle-button") !== null)) {
    showPopup.value = false;
  }
};

onMounted(() => {
  window.addEventListener("click", closePopupOnClick);
});

onBeforeUnmount(() => {
  window.removeEventListener("click", closePopupOnClick);
});
</script>
<template>
  <div class="relative">
    <div class="headerText mb-0 py-4">
      <h1 class="text-1xl truncate font-serif md:pb-1 md:text-4xl">{{ props.title }}</h1>
    </div>
    <a
      class="grid grid-cols-[70%_30%] grid-rows-2 border border-solid border-neutral-30 relative flex-1"
      :class="{
        'min-h-52 lg:min-h-80': !shopplingListProducts[0]?.thumbUrl
      }"
      :href="`/shopping-lists/${props.guid}`"
    >
      <div
        class="row-span-2 border-r border-solid border-neutral-30"
        :class="{ 'flex justify-center items-center cursor-pointer': !shopplingListProducts[0]?.thumbUrl }"
      >
        <FhProductTags v-if="props.isAdmin && props.userName && !props.isAdminShoppingList" :tags="[props.userName]" class="absolute top-4 left-4 z-[1]" />
        <FhImage
          v-if="shopplingListProducts[0]?.thumbUrl"
          :src="shopplingListProducts[0]?.thumbUrl"
          :alt="shopplingListProducts[0]?.ProductName"
          class="w-full h-full object-contain"
        />
        <a v-else class="flex items-center justify-center rounded-full border border-solid border-neutral-30 p-3 h-14 w-14">
          <FhIcon name="PlusSign" />
        </a>
      </div>
      <div>
        <FhImage
          v-if="shopplingListProducts[1]?.thumbUrl"
          :src="shopplingListProducts[1]?.thumbUrl"
          :alt="shopplingListProducts[1]?.ProductName"
          class="w-full h-full object-contain"
        />
      </div>
      <div class="border-t border-solid border-neutral-30">
        <FhImage
          v-if="shopplingListProducts[2]?.thumbUrl"
          :src="shopplingListProducts[2]?.thumbUrl"
          :alt="shopplingListProducts[2]?.ProductName"
          class="w-full h-full object-contain"
        />
      </div>
    </a>
    <div class="relative py-3 px-6 text-f-xs-lg">
      <span>{{ props.totalItems }} {{ props.totalItems === 1 ? "item" : "items" }}</span>
      <span class="mx-f1.5 inline-block text-neutral-50">&bull;</span>
      <span class="text-neutral-50">Updated: {{ availabilityUpdatedTimeAgo }}</span>
      <span class="toggle-button" @click="togglePopup"><FhIcon name="HorizontalEllipse" class="relative top-1 float-right hover:cursor-pointer" /></span>
    </div>
    <!-- modal popup for edit remove action -->
    <div v-if="showPopup" class="absolute right-0 bottom-12 mr-2 bg-white shadow-lg">
      <div class="p-4">
        <button
          class="bg-blue-500 hover:bg-blue-600 hover:text-red-500 block rounded hover:underline"
          variant="link"
          :aria-label="AriaClickText"
          @click="handleClickTriggers('update')"
        >
          Edit
        </button>
        <button
          class="bg-red-500 hover:text-red-500 mt-2 block rounded hover:underline"
          variant="link"
          :aria-label="AriaClickText"
          @click="handleClickTriggers('delete')"
        >
          Remove
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="pcss"></style>

<script setup>
import { computed } from "vue";
import { useCartQuery } from "../api/cartApi";
import FhNavCartPresenter from "./FhNavCartPresenter.vue";

const props = defineProps({
  iconWrapperClass: {
    type: String,
    default: null
  }
});

const { data: cart } = useCartQuery();

const totalItemsCount = computed(() => cart.value?.totalItemsCount || 0);
</script>

<template>
  <FhNavCartPresenter :total-items-count="totalItemsCount" :icon-wrapper-class="props.iconWrapperClass" />
</template>

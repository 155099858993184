<script setup>
const props = defineProps({
  header: {
    type: String,
    required: true
  },
  subheader: {
    type: String,
    required: true
  },
  description: {
    type: String,
    default: undefined
  },
  headerVariant: {
    type: String,
    default: "base",
    validator: (value) => ["base", "large", "large-italic"].includes(value)
  }
});

const headerClasses = [props.headerVariant == "large" ? "display1" : null, props.headerVariant == "large-italic" ? "display1 italic" : null];
const bodyClasses = [props.headerVariant.includes("large") ? "text-f-lg-2xl" : null];
</script>

<template>
  <section class="py-f5">
    <div class="lg:gap-30 grid items-center lg:grid-cols-3">
      <div class="lg:col-span-2">
        <h2 v-if="props.subheader" class="text-base text-neutral-50">{{ props.subheader }}</h2>
        <h1 :class="headerClasses" class="text-f-3xl-5xl font-medium text-neutral-70">{{ props.header }}</h1>
      </div>
      <div class="pt-f3 lg:col-span-1 lg:flex lg:flex-1 lg:justify-end lg:pt-0">
        <p v-if="props.description" :class="bodyClasses" class="box-border max-w-md font-normal text-neutral-70">
          {{ description }}
        </p>
      </div>
    </div>
  </section>
</template>

export const CHECKOUT_STEPS = Object.freeze({
  SHIPPING: 1,
  SHIPPING_DETAILS: 2,
  PAYMENT: 3,
  REVIEW: 4,
  SUMMARY: 5
});

export const SHIPPING_DETAILS_FH_CARRIERS_SUB_STEPS = Object.freeze({
  SERVICE_LEVEL_SELECTION: 1,
  OTHER_DETAILS: 2
});

export const SHIPPING_OPTIONS = Object.freeze({
  FH_CARRIERS: "PREFERRED_CARRIER",
  THIRD_PARTY: "THIRD_PARTY_CARRIER",
  PICK_UP: "PICK_UP"
});

export const SHIPPING_OPTIONS_DEFINITION = Object.freeze({
  [SHIPPING_OPTIONS.FH_CARRIERS]: {
    displayName: "Use a Four Hands carrier",
    longDescription: "Four Hands carriers do not deliver to residential addresses and require a commercial address that can accommodate a 53' freight truck."
  },
  [SHIPPING_OPTIONS.THIRD_PARTY]: {
    displayName: "Arrange my own freight carrier",
    longDescription: "Arrange shipment with the carrier of your choice. Additional handling fee may apply."
  },
  [SHIPPING_OPTIONS.PICK_UP]: {
    displayName: "Pick up in Austin, TX",
    longDescription: "Pick up your order at our warehouse."
  }
});

export const FREIGHT_SERVICE_LEVEL = Object.freeze({
  STANDARD: "standard",
  EXPEDITED: "expedited",
  RUSH: "rush"
});

export const FREIGHT_SERVICE_LEVEL_DEFINITION = Object.freeze({
  [FREIGHT_SERVICE_LEVEL.STANDARD]: {
    displayName: "Standard",
    description: "15 to 20 business days",
    longDescription: `
      <p class="mb-f2 text-body-lg">After leaving our warehouse, most products will arrive within 15 to 20 business days.</p>
      <p><em>Canadian delivery times may vary.</em><p>
      <p>Important information:</p>
      <ul>
        <li>Timing depends on stock levels, order processing times and delivery area.</li>
        <li>The delivery address must be able to accommodate a 53’ tractor trailer, unless it is a wallpaper-only order.</li>
        <li>Special delivery requests may require an additional charge. Liftgate option not available with the standard service.</li>
        <li>If you don&rsquo;t select the &ldquo;Don't Want Your Order Shipped Yet?&rdquo; option (in the next step), we will ship all products in your order as they&rsquo;re in stock and you will be charged for each individual shipment.</li>
      </ul>   
    `,
    descriptionWallpaperOnly: "5 to 7 business days",
    longDescriptionWallpaperOnly: `
      <p class="mb-f2 text-body-lg">After leaving our warehouse, most product will arrive within 5-7 business days.</p>
      <p><em>Canadian delivery times may vary.</em><p>
      <p>Important information:</p>
      <ul>
        <li>Product is made to order, processing times is 2-3 weeks. </li>
        <li>Shipping to a residential address is only available for orders containing solely wallpaper products.</li>
        <li>If you don't select the "don't want your order shipped yet?" options (in the next steps), we will ship all products in your order as they're in stock and you will be charged for each individual shipment.</li>
      </ul>      
    `
  },
  [FREIGHT_SERVICE_LEVEL.EXPEDITED]: {
    displayName: "Expedited",
    description: "5 to 6 business days",
    longDescription: `
      <p class="mb-f2 text-body-lg">After leaving our warehouse, most products will arrive within 5 to 6 business days.</p>
      <p><em>Canadian delivery times may vary.</em><p>
      <p>Important information:</p>
      <ul>
        <li>Timing depends on stock levels, order processing times and delivery area.</li>
        <li>The delivery address must be able to accommodate a 53’ tractor trailer, unless it is a wallpaper-only order.</li>
        <li>Special delivery requests may require an additional charge. Liftgate option not available with the standard service.</li>
        <li>If you don&rsquo;t select the &ldquo;Don't Want Your Order Shipped Yet?&rdquo; option (in the next step), we will ship all products in your order as they&rsquo;re in stock and you will be charged for each individual shipment.</li>
      </ul>
    `
  },
  [FREIGHT_SERVICE_LEVEL.RUSH]: {
    displayName: "Rush",
    description: "3 to 4 business days",
    longDescription: `
      <p class="mb-f2 text-body-lg">After leaving our warehouse, most products will arrive within 3 to 4 business days.</p>
      <p><em>Canadian delivery times may vary.</em><p>
      <p>Important information:</p>
      <ul>
        <li>Timing depends on stock levels, order processing times and delivery area.</li>
        <li>The delivery address must be able to accommodate a 53’ tractor trailer, unless it is a wallpaper-only order.</li>
        <li>Special delivery requests may require an additional charge. Liftgate option not available with the standard service.</li>        
        <li>If you don&rsquo;t select the &ldquo;Don't Want Your Order Shipped Yet?&rdquo; option (in the next step), we will ship all products in your order as they&rsquo;re in stock and you will be charged for each individual shipment.</li>
      </ul>
    `
  }
});

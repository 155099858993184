<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.24967 8C6.24967 6.89543 7.18242 6 8.33301 6C9.4836 6 10.4163 6.89543 10.4163 8C10.4163 9.10457 9.4836 10 8.33301 10C7.18242 10 6.24967 9.10457 6.24967 8Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.08301 5C2.08301 3.34315 3.48212 2 5.20801 2H19.7913C21.5172 2 22.9163 3.34315 22.9163 5V19C22.9163 20.6569 21.5172 22 19.7913 22H5.20801C3.48212 22 2.08301 20.6569 2.08301 19V5ZM5.20801 4C4.63271 4 4.16634 4.44772 4.16634 5V19C4.16634 19.4288 4.44749 19.7946 4.84247 19.9367L16.6663 8.58578L20.833 12.5858V5C20.833 4.44772 20.3666 4 19.7913 4H5.20801ZM20.833 15.4142L16.6663 11.4142L7.72281 20H19.7913C20.3666 20 20.833 19.5523 20.833 19V15.4142Z"
      fill="currentColor"
    />
  </svg>
</template>

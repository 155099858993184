<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5002 7.58594L19.4868 14.293L18.0137 15.7073L12.5002 10.4144L6.98681 15.7073L5.51367 14.293L12.5002 7.58594Z"
      fill="currentColor"
    />
  </svg>
</template>

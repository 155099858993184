<script setup>
import { ref, computed, reactive, watch } from "vue";
import FhButton from "@/components/FhButton.vue";
import FhModal from "@/components/FhModal.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhLoader from "@/components/FhLoader.vue";
import formatPrice from "../util/formatPrice.js";
import FhInput from "@/components/FhInput.vue";
import FhImage from "@/components/FhImage.vue";
import FhTabs from "@/components/FhTabs.vue";
import FhFilterSelect from "@/components/FhFilterSelect.vue";
import { useShoppingListQuery } from "@/api/shoppingListApi";
import { useGuestAccountQuery } from "@/api/accountApi";

const emits = defineEmits(["addtolist", "heart-icon-clicked", "heart-icon-filled"]);

const props = defineProps({
  shoppinglist: {
    type: Object,
    required: true
  },
  shoppingDetails: {
    type: Object,
    default: () => {}
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  }
});

const formData = reactive({ ...props.shoppingDetails });
let { isLoading, data: shoppingListData } = useShoppingListQuery();

let { data: guestAccountsData } = useGuestAccountQuery();

const shoppingLists = computed(() => (isLoading.value ? [] : shoppingListData.value));
const myList = computed(() => shoppingLists.value.filter((item) => item.isAdminShoppingList === true));
const guestAccountList = computed(() => shoppingLists.value.filter((item) => item.isAdminShoppingList === false));
const searchQuery = ref("");
const searchQueryGuest = ref("");
const detailTabsSelectedIndex = ref(0);
const closeContainer = ref(false);
const selectedGuestAccount = ref(null);

const guestAccountOptions = computed(() => {
  return guestAccountsData.value.map((account) => ({
    name: account.name,
    value: account.name
  }));
});
const isGuestAccount = computed(() => guestAccountOptions.value.length > 0);
const filteredItems = computed(() => {
  return shoppingLists.value.filter((item) => {
    return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
  });
});

const filteredItemsMylist = computed(() => {
  return myList.value.filter((item) => {
    return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
  });
});

const filteredItemsGuest = computed(() => {
  const searchQueryGuestValue = searchQueryGuest.value.toLowerCase();
  const selectedUserName = selectedGuestAccount.value ? selectedGuestAccount.value.toLowerCase() : null;
  return guestAccountList.value.filter((item) => {
    const titleMatch = item.title.toLowerCase().includes(searchQueryGuestValue);
    const userNameMatch = selectedUserName ? item.userName.toLowerCase().includes(selectedUserName) : true;

    if (selectedUserName === null) {
      return titleMatch;
    } else {
      return titleMatch && userNameMatch;
    }
  });
});

const heartIconStates = reactive(new Map());

watch(
  shoppingLists,
  (newShoppingLists) => {
    Object.values(newShoppingLists).forEach((item) => {
      item.products.forEach((product) => {
        if (product.skuNumber === formData.skuNumber) {
          heartIconStates.set(item, true);
        }
      });
    });
  },
  { immediate: true }
);

const toggleHeartIcon = (item) => {
  const currentState = heartIconStates.get(item);
  const shopping = shoppingLists.value;
  if (currentState) {
    emits("heart-icon-filled", { item, formData, shopping });
  } else {
    emits("heart-icon-clicked", { item, formData, shopping });
  }
  heartIconStates.set(item, !currentState);
};

const updateCloseContainer = () => {
  closeContainer.value = !closeContainer.value;
};
</script>

<template>
  <FhModal
    ref="modal"
    :data="{
      heading: 'Add to Shopping Lists',
      subheading
    }"
    @close="reset"
  >
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <form id="addshoppinglistForm" class="flex flex-col gap-f3" @submit.prevent="save"></form>
      <div class="mb-6 flex items-center gap-3 md:min-w-0 md:gap-f5">
        <!-- Added 'items-center' class -->
        <a class="block w-32 flex-none border border-neutral-30 md:w-f25">
          <FhImage :src="formData.thumbUrl" :alt="formData.name" square :border="true" />
        </a>
        <div class="flex min-w-0 flex-col gap-1">
          <a class="whitespace-pre-line text-f-base-2xl md:mb-f0.5 md:whitespace-normal">{{ formData.name }}</a>
          <div class="flex gap-f1.5 text-f-xs-base">
            <span v-if="formData.price"
              ><span v-if="(props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin)">CAD</span
              >{{ formatPrice(formData.price) }}</span
            >
          </div>
          <div class="text-f-xs-base text-neutral-50">
            <span v-if="formData.skuName" class="w-15">{{ formData.skuName }}&nbsp;&bull;&nbsp;</span>
            <span v-if="formData.skuNumber">{{ formData.skuNumber }}</span>
          </div>
        </div>
      </div>

      <FhTabs
        v-if="props.isAdmin"
        :selected-index="detailTabsSelectedIndex"
        align="left"
        compact
        class="w-full"
        @change="(index) => (detailTabsSelectedIndex = index)"
      >
        <template #trigger-MyLists>My Lists</template>
        <template #content-MyLists>
          <div v-if="myList && myList.length > 0" class="mb-6 w-full">
            <FhInput v-model="searchQuery" placeholder="Search" />
          </div>
          <div class="filtered-items-container">
            <template v-if="myList && myList.length > 0">
              <div v-if="filteredItemsMylist.length > 0">
                <div v-for="item in filteredItemsMylist" :key="item.shoppinglist" class="mb-6 flex gap-3 border-b border-neutral-30 pb-4 md:min-w-0 md:gap-5">
                  <a class="block flex-none">
                    <button aria-label="Add to List" class="rounded-full bg-white text-3xl" @click="toggleHeartIcon(item)">
                      <FhIcon :name="heartIconStates.get(item) ? 'SolidHeart' : 'OutlineHeart'" class="text-gray-500" />
                    </button>
                  </a>
                  <div class="flex min-w-0 flex-col gap-1">
                    <a class="whitespace-pre-line text-base md:mb-0.5 md:whitespace-normal">{{ item.title }}</a>
                    <div class="flex gap-1.5 text-xs">
                      <span>{{ item.totalItems }} items</span>
                    </div>
                  </div>
                </div>
              </div>
              <p v-else class="text-body-lg mb-f2 text-neutral-50">There are no Shopping Lists with that name.</p>
            </template>
            <template v-else>
              <p class="text-body-lg mb-f2 text-neutral-50">You have no Shopping Lists.</p>
            </template>
            <FhLoader v-if="isLoading" class="col-span-2 my-f10" />
          </div>
        </template>
        <template #trigger-GuestLists>Guest Account Lists</template>
        <template #content-GuestLists>
          <div v-if="guestAccountList && guestAccountList.length > 0" class="mb-6 w-full">
            <FhInput v-model="searchQueryGuest" placeholder="Search by Shopping List name" @click="updateCloseContainer()" />
          </div>
          <div v-if="guestAccountList && guestAccountList.length > 0" class="relative mb-6 w-full">
            <FhFilterSelect v-model="selectedGuestAccount" :options="guestAccountOptions" :close-container="closeContainer"></FhFilterSelect>
          </div>
          <template v-if="guestAccountList && guestAccountList.length > 0">
            <div class="filtered-items-container">
              <div v-if="filteredItemsGuest.length > 0">
                <div v-for="item in filteredItemsGuest" :key="item.shoppinglist" class="mb-6 flex gap-3 border-b border-neutral-30 pb-4 md:min-w-0 md:gap-5">
                  <a class="block flex-none">
                    <button aria-label="Add to List" class="rounded-full bg-white text-3xl" @click="toggleHeartIcon(item)">
                      <FhIcon :name="heartIconStates.get(item) ? 'SolidHeart' : 'OutlineHeart'" class="text-gray-500" />
                    </button>
                  </a>
                  <div class="flex min-w-0 flex-col gap-1">
                    <a class="whitespace-pre-line text-base md:mb-0.5 md:whitespace-normal">{{ item.title }}</a>
                    <div class="flex gap-1.5 text-xs">
                      <span>{{ item.totalItems }} items by {{ item.userName }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <p v-else-if="searchQueryGuest.length == 0" class="text-body-lg mb-f2 text-neutral-50">
                The selected Guest Account does not have any Shopping Lists.
              </p>
              <p v-else class="text-body-lg mb-f2 text-neutral-50">There are no Shopping Lists with that name.</p>
            </div>
          </template>
          <template v-else>
            <p v-if="isGuestAccount" class="text-body-lg mb-f2 text-neutral-50">Your Guest Accounts do not have any Shopping Lists.</p>
            <p v-else class="text-body-lg mb-f2 text-neutral-50">You have no Guest Accounts or your Guest Accounts do not have any Shopping Lists.</p>
          </template>
          <FhLoader v-if="isLoading" class="col-span-2 my-f10" />
        </template>
      </FhTabs>
      <template v-if="!props.isAdmin">
        <div v-if="shoppingLists && shoppingLists.length > 0" class="mb-6 w-full">
          <FhInput v-model="searchQuery" placeholder="Search" class="z-10" />
        </div>
        <FhLoader v-if="isLoading" class="col-span-2 my-f10" />
        <div class="filtered-items-container">
          <template v-if="shoppingLists && shoppingLists.length > 0">
            <div v-if="filteredItems.length > 0">
              <div v-for="item in filteredItems" :key="item.shoppinglist" class="mb-6 flex gap-3 border-b border-neutral-30 pb-4 md:min-w-0 md:gap-5">
                <a class="block flex-none">
                  <button aria-label="Add to List" class="rounded-full bg-white text-3xl" @click="toggleHeartIcon(item)">
                    <FhIcon :name="heartIconStates.get(item) ? 'SolidHeart' : 'OutlineHeart'" class="text-gray-500" />
                  </button>
                </a>
                <div class="flex min-w-0 flex-col gap-1">
                  <a class="whitespace-pre-line text-base md:mb-0.5 md:whitespace-normal">{{ item.title }}</a>
                  <div class="flex gap-1.5 text-xs">
                    <span>{{ item.totalItems }} items</span>
                  </div>
                </div>
              </div>
            </div>
            <p v-else class="text-body-lg mb-f2 text-neutral-50">There are no Shopping Lists with that name.</p>
          </template>
          <template v-else>
            <p class="text-body-lg mb-f2 text-neutral-50">You have no Shopping Lists.</p>
          </template>
          <FhLoader v-if="isLoading" class="col-span-2 my-f10" />
        </div>
      </template>
    </template>
    <template #footer="{ close }">
      <div class="mt-20 grid gap-4" :class="detailTabsSelectedIndex === 1 || myList.length === 0 ? 'grid-cols-1' : 'grid-cols-2'">
        <FhButton
          v-if="detailTabsSelectedIndex !== 1"
          type="submit"
          size="medium"
          class="w-full md:w-auto"
          :color="myList.length === 0 && detailTabsSelectedIndex === 0 ? 'primary' : 'secondary'"
          form="addshoppinglistForm"
          @click="emits('addtolist', true)"
        >
          <FhIcon v-if="!(myList.length === 0 && detailTabsSelectedIndex === 0)" name="PlusSign" />{{
            myList.length == 0 && detailTabsSelectedIndex === 0 ? "Create New List" : "Create List"
          }}
        </FhButton>
        <FhButton v-if="!(myList.length === 0 && detailTabsSelectedIndex === 0)" color="primary" class="w-full" @click="close">Continue</FhButton>
      </div>
    </template>
  </FhModal>
</template>

<style scoped>
@media (min-width: 768px) {
  .flex-row-reverse {
    flex-direction: column !important; /* Override with flex-direction: column */
  }
}
.filtered-items-container {
  max-height: 20rem; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrollbar when content overflows */
}
</style>

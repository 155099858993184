<script setup>
import { computed } from "vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const { name, basedIn, medium, descriptionBio } = getDataRefs(props.data);

const missingBasedIn = computed(() => {
  if (basedIn.value === undefined || basedIn.value === null) return "mt-[32px]";
  return "";
});
const missingBasedInAndMedium = computed(() => {
  if ((medium.value === undefined || medium.value === null) && (basedIn.value === undefined || basedIn.value === null)) return "mt-[32px]";
  return "mt-[16px]";
});
</script>

<template>
  <div class="bg-limestone font-sans py-f6 px-f5">
    <h1 class="font-serif text-f-4xl-8xl max-w-[752px]">{{ name }}</h1>
    <div class="grid text-f-sm-base">
      <div v-if="basedIn" class="flex mt-[32px]">
        Based:
        <div class="ml-1 font-semibold">{{ basedIn }}</div>
      </div>
      <div v-if="medium" class="flex" :class="missingBasedIn">
        Medium:
        <div class="ml-1 font-semibold">{{ medium }}</div>
      </div>
    </div>
    <div v-if="descriptionBio" class="max-w-[752px] w-full text-f-lg-xl" :class="missingBasedInAndMedium">
      {{ descriptionBio }}
    </div>
  </div>
</template>

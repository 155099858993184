<script setup>
import { computed } from "vue";
import FhProductDetailSpec from "./FhProductDetailSpec.vue";
import { MEASUREMENT_SYSTEM } from "../constants/measurements";
import { useViewportSizes } from "../composables";

const props = defineProps({
  /**
   * An array of spec objects or, for grouped display mode, an array or arrays of spec objects
   */
  specs: {
    type: Array,
    default: () => []
  },
  /**
   * The measurment system in which numeric specs with a defined unit should be displayed, converting the input value if necessary.
   * @values IMPERIAL, METRIC
   */
  displaySystem: {
    type: String,
    default: () => MEASUREMENT_SYSTEM.IMPERIAL,
    validator: (val) => Object.keys(MEASUREMENT_SYSTEM).includes(val)
  }
});

const _specs = computed(() => {
  let grouped = Array.isArray(props.specs[0]);

  if (!grouped) {
    return props.specs.map((spec) => ({ ...spec, label: spec.label ?? "" }));
  }

  let specsFlat = [];
  for (let i = 0; i < props.specs.length; i++) {
    let specGroup = props.specs[i];
    if (!Array.isArray(specGroup)) {
      throw new Error("specs expected to be an array of arrays in grouped mode");
    }
    for (let j = 0; j < specGroup.length; j++) {
      specsFlat.push({
        ...specGroup[j],
        label: specGroup[j].label ?? "",
        groupBoundary: i < props.specs.length - 1 && j === specGroup.length - 1
      });
    }
  }
  return specsFlat;
});

const { isSm } = useViewportSizes();
</script>

<template>
  <div class="c-specList flex flex-col md:grid md:grid-cols-[minmax(33%,_auto)_1fr] overflow-hidden">
    <template v-for="(spec, i) in _specs" :key="i">
      <FhProductDetailSpec v-bind="spec" :display-system="props.displaySystem" />
      <template v-if="!isSm && spec.groupBoundary">
        <!-- two placeholder elements needed here so that the nth-child selectors still correctly target the labels and values -->
        <div class="group-divider md:h-f4"></div>
        <div class="group-divider md:h-f4"></div>
      </template>
    </template>
  </div>
</template>

<style scoped>
.c-specList {
  /* Label */
  &:deep(> *:nth-child(odd)) {
    @apply md:pr-f3;
  }

  /* Value */
  &:deep(> *:nth-child(even)) {
    @apply mb-f3 md:mb-0;
  }

  &:deep(> *:not(.group-divider)) {
    @apply relative py-f0.5;

    /* Row hover highlight */
    &:before {
      @apply absolute top-0 bottom-0 hidden md:block;
      content: "";
      right: -1000%;
      left: -1000%;
      z-index: -1;
    }
    &:hover:before {
      @apply bg-neutral-10;
    }
  }
}
</style>

import axios from "axios";

// const BASE_URL = "https://localhost:5001/api";
const BASE_URL = "/api";

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

axiosClient.interceptors.response.use((response) => response.data);

export default axiosClient;

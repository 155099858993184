<script setup>
import { ref, reactive } from "vue";
import FhModal from "./FhModal.vue";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";

const props = defineProps({
  /**
   * An array of product care card objects
   */
  data: {
    type: Array,
    required: true
  }
});

// Build up data for the product care modal
const productCareModalData = reactive({
  version: "product",
  heading: "",
  copy: "",
  ctaText: "Download Care Guide",
  ctaURL: "",
  ctaAriaLabel: "Download Care Guide",
  ctaDownload: true
});

const productCareModalIsOpen = ref(false);
const toggleProductCareModal = (item) => {
  productCareModalData.heading = item.careTitle;
  productCareModalData.copy = item.careDescription;
  productCareModalData.ctaURL = item.careGuide;
  productCareModalData.ctaAriaLabel = "Download " + item.materialName + " Care Guide";

  productCareModalIsOpen.value = !productCareModalIsOpen.value;
};
</script>

<template>
  <template v-for="(item, i) in props.data" :key="`${i}`">
    <div>
      <FhButton variant="text" @click="toggleProductCareModal(item)"><FhIcon name="OverlappingSquare" /> Product Care: {{ item.materialName }}</FhButton>
    </div>
  </template>
  <FhModal v-model:is-open="productCareModalIsOpen" :data="productCareModalData" />
</template>

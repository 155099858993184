<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import TransitionExpand from "../transitions/TransitionExpand.vue";
import { getDataRefs } from "../util/vue";
import { useViewportSizes } from "../composables";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { isAuthenticated, heading, customerNumber, customerStatus, customerDiscount, isFromSubPage } = getDataRefs(props.data);
const scrollPosition = ref(window.scrollY);

const { isSm, isMd } = useViewportSizes();
const isMobile = computed(() => isSm.value || isMd.value);

const hideBanner = computed(() => {
  let hideBanner = false;

  if (isMobile.value) {
    if (isFromSubPage.value) hideBanner = true;
    if (scrollPosition.value > 0) {
      hideBanner = true;
    }
  }

  return hideBanner;
});

onMounted(() => {
  window.addEventListener("scroll", () => (scrollPosition.value = window.scrollY));
});

onUnmounted(() => {
  window.removeEventListener("scroll", () => (scrollPosition.value = window.scrollY));
});
</script>

<template>
  <div class="widget--bg-slate">
    <TransitionExpand>
      <div v-if="!hideBanner">
        <div class="relative flex flex-col gap-f2 overflow-hidden bg-slate pb-f4 pt-f12.5 text-white">
          <h1 class="h3">{{ heading }}</h1>
          <div v-if="isAuthenticated" class="text-body-lg flex gap-3">
            <div>Customer # {{ customerNumber }}</div>
            <div v-if="customerStatus">&bull;</div>
            <div>
              {{ customerStatus }}
              <span v-if="customerDiscount" class="opacity-50">({{ customerDiscount }})</span>
            </div>
          </div>
        </div>
      </div>
    </TransitionExpand>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useAddCartItemsMutation } from "../api/cartApi.js";
import { useNotificationStore } from "../stores/NotificationStore.js";
import { CART } from "../constants/routes";
import formatPrice from "../util/formatPrice.js";
import FhButton from "./FhButton.vue";
import FhQuantityInput from "./FhQuantityInput.vue";

const props = defineProps({
  skuNumber: {
    type: String,
    required: true
  },
  price: {
    type: Number,
    required: true
  },
  category: {
    type: Array,
    default: () => {
      [];
    }
  },
  secondary: {
    type: Boolean,
    default: () => false
  },
  quantity: {
    type: Number,
    default: 1
  }
});

const emit = defineEmits(["update:addToCart", "update:quantity"]);

const quantity = computed({
  get() {
    return props.quantity ?? 1;
  },
  set(newQuantity) {
    emit("update:quantity", newQuantity);
  }
});
const addCartItemsMutation = useAddCartItemsMutation();

const isAdding = computed(() => addCartItemsMutation.isLoading.value);
const productCategory = computed(() => props.category.map((cat) => cat.name).join("/"));

const notificationStore = useNotificationStore();

function notifySuccess(cart) {
  let productName = cart.items.find((item) => item.sku.skuNumber === props.skuNumber).sku.productName;
  notificationStore.notifySuccess(`${productName} added.`, { linkText: "View Cart", linkUrl: CART });
  emit("update:addToCart", { sku: props.skuNumber, qty: quantity.value });
}

function notifyError() {
  notificationStore.notifyError("Sorry, something went wrong and we could not add this item to your cart. Please try again.");
}

function addToCart() {
  const items = [{ skuNumber: props.skuNumber, quantity: quantity.value, category: productCategory.value }];
  addCartItemsMutation.mutate(items, { onSuccess: notifySuccess, onError: notifyError });
}
</script>

<template>
  <div class="flex gap-f2">
    <FhButton
      :color="props.secondary ? 'secondary' : 'primary'"
      class="flex-1 whitespace-nowrap"
      :class="{ 'font-bold': !props.secondary }"
      :disabled="isAdding"
      @click="addToCart"
    >
      <span v-if="!props.secondary">{{ formatPrice(props.price * (quantity || 1)) }}&nbsp;&nbsp;<span class="opacity-30">|</span>&nbsp;&nbsp;</span>
      <span>Add to Cart</span>
    </FhButton>
    <FhQuantityInput v-model="quantity" />
  </div>
</template>

<script setup>
import FhHeading from "@/components/FhHeading.vue";
import FhSection from "../includes/FhSection.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const {
  generalInquiriesHeading,
  tollFreePhoneLabel,
  tollFreePhone,
  headquartersPhoneLabel,
  headquartersPhone,
  headquartersPhoneHours,
  addressLabel,
  mailingAddress1,
  mailingAddress2,
  cityStateZip,
  country
} = getDataRefs(props.data);
</script>

<template>
  <FhSection
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <!-- GENERAL INQUIRIES -->
    <div class="mb-f10 flex flex-col text-neutral-70 md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10 md:pb-f10">
      <FhHeading tag="h2">{{ generalInquiriesHeading }}</FhHeading>
      <div class="mb-4 flex flex-col content-start items-start md:flex-row">
        <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12">
          {{ tollFreePhoneLabel }}
        </span>
        <a class="underline underline-offset-4" role="link" :href="`tel:${tollFreePhone}`">
          {{ tollFreePhone }}
        </a>
      </div>
      <div class="mb-6 flex flex-col md:mb-10 md:flex-row">
        <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12">
          {{ headquartersPhoneLabel }}
        </span>
        <span class="flex flex-col content-start items-start">
          <a class="mb-2 underline underline-offset-4" role="link" :href="`tel:${headquartersPhone}`">
            {{ headquartersPhone }}
          </a>
          <small class="text-f-xs-sm text-neutral-50">
            {{ headquartersPhoneHours }}
          </small>
        </span>
      </div>
      <div class="flex flex-col md:flex-row">
        <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12">
          {{ addressLabel }}
        </span>
        <div class="flex flex-col text-neutral-50">
          <span class="md:mb-2">
            {{ mailingAddress1 }}
          </span>
          <span v-if="mailingAddress2" class="md:mb-2">
            {{ mailingAddress2 }}
          </span>
          <span class="md:mb-2">
            {{ cityStateZip }}
          </span>
          <span>
            {{ country }}
          </span>
        </div>
      </div>
    </div>
  </FhSection>
</template>
<style lang="pcss" scoped>
.text-white * {
  color: white !important;
}
</style>

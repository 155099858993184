import axiosClient from "./axiosClient";
import { useMutation } from "@tanstack/vue-query";

export function useLoginMutation(options) {
  return useMutation({
    ...(options || {}),
    mutationFn: (data) => axiosClient.post("/auth/login", data)
  });
}

export function useInitiatePasswordResetMutation(options) {
  return useMutation({
    ...(options || {}),
    mutationFn: (data) => axiosClient.post("/auth/initiate_password_reset", data)
  });
}

export function useResetPasswordMutation(options) {
  return useMutation({
    ...(options || {}),
    mutationFn: (data) => axiosClient.post("/auth/reset_password", data)
  });
}

export function useUpdatePasswordMutation(options) {
  return useMutation({
    ...(options || {}),
    mutationFn: (data) => axiosClient.post("/auth/update_password", data)
  });
}

<script setup>
import FhCard from "./FhCard.vue";
import FhPaymentCard from "./Ecomm/FhPaymentCard/FhPaymentCard.vue";

const props = defineProps({
  hasTerms: {
    type: Boolean,
    default: false
  },
  paymentCard: {
    type: Object,
    default: null
  }
});
</script>

<template>
  <FhCard class="flex-1">
    <template v-if="props.hasTerms">
      <div class="mb-f2 text-f-base-xl">Payment Method</div>
      <div class="text-neutral-50">Payment Terms <span class="italic">(Payment Method on File)</span></div>
    </template>
    <FhPaymentCard v-else v-bind="props.paymentCard" :show-remove-link="false" />
  </FhCard>
</template>

<style scoped>
:deep(.paymentCard) {
  @apply flex-row items-center gap-f5;
}
</style>

<script>
/**
 * A generic form control wrapper for sharing functionality common to all form fields (i.e., labels, error messages, etc.)
 * Probably don't use this component directly. Prefer to use FhInput, FhSelect, etc., which all extend this component.
 */
export default {
  inheritAttrs: false
};
</script>
<script setup>
import { computed, useSlots, useAttrs } from "vue";
import getId from "../util/getId";

const props = defineProps({
  label: {
    type: String,
    default: undefined
  },
  note: {
    type: String,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: undefined
  },
  /**
   * The Vuelidate validation object for this field. Use this to automatically set the error state and errorMessage.
   * The `errorMessage` prop will override any error messages from this prop if both are set.
   */
  vuelidate: {
    type: Object,
    default: () => undefined
  },
  /**
   * Fallthrough attributes are applied to the inner form control. Use this object if you need to bind anything custom to the form field wrapper node intead.
   */
  wrapperAttrs: {
    type: Object,
    default: () => {}
  }
});

const slots = useSlots();

if (!slots.default) {
  throw new Error("FhFormField requires a form control in the default slot");
}

const formControlId = getId();

const noteId = getId();
const hasNote = computed(() => props.note || slots.note);

const errorMessageId = getId();
const hasVuelidateError = computed(() => !!props.vuelidate?.$error);
const hasError = computed(() => props.error || hasVuelidateError.value);
const hasErrorMessage = computed(() => (props.error && (props.errorMessage || slots.errorMessage)) || hasVuelidateError.value);

const attrs = useAttrs();

const formControlBindings = computed(() => ({
  ...attrs,
  id: formControlId,
  disabled: props.disabled,
  "aria-required": props.required,
  "aria-describedby": hasNote.value ? noteId : false,
  "aria-invalid": hasError.value,
  "aria-errormessage": hasErrorMessage.value ? errorMessageId : false,
  onBlur: () => {
    if (props.vuelidate) props.vuelidate.$touch();
    if (attrs.onBlur) attrs.onBlur();
  }
}));
</script>

<template>
  <div :class="props.label ? 'gap-f1.5' : 'gap-f0'" class="relative flex flex-col" v-bind="wrapperAttrs">
    <div class="flex items-end">
      <label v-if="props.label" :for="formControlId" class="flex flex-row flex-wrap items-baseline gap-f1">
        <span>{{ label }}</span>
        <span v-if="props.required" class="text-body-xs text-neutral-50">(Required)</span>
      </label>
      <div v-if="slots.topRight" class="ml-auto">
        <slot name="topRight"></slot>
      </div>
    </div>
    <slot name="default" v-bind="formControlBindings"></slot>
    <div v-if="slots.revealPassword" class="absolute top-0 right-0">
      <slot name="revealPassword"></slot>
    </div>
    <div v-if="hasNote" :id="noteId" class="text-body-sm max-w-prose text-neutral-50">
      <slot name="note">{{ props.note }}</slot>
    </div>
    <div v-if="hasErrorMessage" :id="errorMessageId" class="text-body-sm max-w-prose text-brand-red">
      <slot name="errorMessage">
        <template v-if="props.errorMessage">{{ props.errorMessage }}</template>
        <template v-else-if="hasVuelidateError">
          <div v-for="errObj of props.vuelidate.$errors" :key="errObj.$uid">{{ errObj.$message }}</div>
        </template>
      </slot>
    </div>
  </div>
</template>

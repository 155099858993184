<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 7V13H18V7C18 3.68629 15.3137 1 12 1C8.68629 1 6 3.68629 6 7V13H8V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
      fill="currentColor"
    />
    <path d="M3 8H21V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V8Z" stroke="currentColor" stroke-width="2" />
  </svg>
</template>

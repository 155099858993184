<script setup>
import { ref, computed, nextTick } from "vue";
import { orderBy } from "lodash";
import { usePaginatedList } from "../composables";
import scrollToTopOf from "@/util/scrollToTopOf";
import FhButton from "@/components/FhButton.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhLoader from "@/components/FhLoader.vue";
import FhModal from "@/components/FhModal.vue";
import FhOptionCard from "@/components/FhOptionCard.vue";
import FhPaginationNav from "@/components/FhPaginationNav.vue";
import FhShippingAddress from "@/components/FhShippingAddress.vue";
import FhShippingAddressFormModal from "@/components/FhShippingAddressFormModal.vue";
import FhTooltip from "@/components/FhTooltip.vue";

const props = defineProps({
  mode: {
    type: String,
    required: true,
    validator: (val) => ["manage", "select"].includes(val)
  },
  shippingAddresses: {
    type: Array,
    default: () => []
  },
  /**
   * Relevant only if `mode` is `select`. The id of the selected address.
   */
  selectedId: {
    type: Number,
    default: null
  },
  /**
   * Relevant only if `mode` is `select`. Indicates that only addresses that have been verified for freight should be allowed to be selected.
   */
  requireFreight: {
    type: Boolean,
    default: false
  },

  /**
   * Indicates that is Residential Shipping Eligible.
   */
  isResidentialShippingEligible: {
    type: Boolean,
    default: false
  },
  /**
   * Indicates the shipping addresses are being loaded.
   */
  isLoading: {
    type: Boolean,
    default: false
  },
  /**
   * Indicates whether an error was encountered while loading the shipping addresses.
   */
  isError: {
    type: Boolean,
    default: false
  },
  /**
   * List of address IDs that are actively being deleted. Realistaically, there will only ever be
   * one at a time, but this structure allows for the possibility of multiple concurent deletes.
   */
  isDeletingAddressIds: {
    type: Array,
    default: () => []
  },
  /**
   * Controls the vertical alignment of the header content and "Add Address" button, which appear in a single row in the tablet and desktop layouts.
   */
  headingRowAlignment: {
    type: String,
    default: "start",
    validator: (val) => ["start", "center", "end"].includes(val)
  }
});

const emit = defineEmits(["create", "delete", "update:selectedId"]);

const shippingAddressesOrdered = computed(() => orderBy(props.shippingAddresses, (e) => new Number(e.id), ["desc"]));

const PER_PAGE = 12;
let { page, totalPages, sublist: shippingAddressesPage } = usePaginatedList(shippingAddressesOrdered, PER_PAGE);

const rootElem = ref(null);

const isManageMode = computed(() => props.mode === "manage");
const isSelectMode = computed(() => props.mode === "select");

const _selectedId = computed({
  get: () => {
    try {
      const shippingAddresses = shippingAddressesOrdered.value;

      const findFirstValidAddress = (condition) => shippingAddresses?.find(condition)?.id || null;

      if (!props.selectedId) {
        const firstAvailableId = findFirstValidAddress((address) => address.isValidatedForFreight || props.isResidentialShippingEligible);
        if (firstAvailableId) {
          emit("update:selectedId", firstAvailableId);
          return firstAvailableId;
        }
      }

      const selectedAddress = shippingAddresses?.find(({ id }) => id === props.selectedId);

      if (selectedAddress?.isValidatedForFreight === false) {
        const fallbackId = findFirstValidAddress((address) => props.requireFreight && address.isValidatedForFreight && !props.isResidentialShippingEligible);
        if (fallbackId) {
          emit("update:selectedId", fallbackId);
          return fallbackId;
        }
      }

      return props.selectedId;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  set: (val) => emit("update:selectedId", val)
});

const isDataLoaded = computed(() => !props.isLoading && !props.isError);
const isEmpty = computed(() => isDataLoaded.value && props.shippingAddresses.length === 0);

function handleAddressCreate(newAddress) {
  if (isSelectMode.value) {
    _selectedId.value = newAddress.id;
  }
  /*
   TODO: For now we put the user on the first page since the new address is surfaced to the top
   probably in the future we can query the id and find its position and go to that page 
  */
  nextTick(() => {
    if (page.value !== 1) {
      page.value = 1;
    }
    scrollToTopOf(rootElem.value);
  });

  emit("create", newAddress);
}
</script>

<template>
  <div
    ref="rootElem"
    class="flex flex-col gap-f4 md:flex-row md:flex-wrap md:items-start md:justify-between"
    :class="{
      'md:items-start': props.headingRowAlignment === 'start',
      'md:items-center': props.headingRowAlignment === 'center',
      'md:items-end': props.headingRowAlignment === 'end'
    }"
  >
    <div class="md:-order-2">
      <slot name="heading">
        <FhHeading class="mb-0">Saved Addresses</FhHeading>
      </slot>
    </div>
    <div v-if="props.isLoading" class="w-full"><FhLoader class="h-10 w-10" /></div>
    <!-- SHIPPING ADDRESS LIST -->
    <div v-if="!props.isLoading" class="md:w-full">
      <div class="grid gap-f3 md:grid-cols-2">
        <!-- MANAGE MODE ADDRESS CARD-->
        <template v-if="isManageMode">
          <div v-for="address in shippingAddressesPage" :key="address.id" class="flex flex-col items-start gap-f4 border border-neutral-30 py-f4 px-f5">
            <FhShippingAddress v-bind="address" class="flex-1" />
            <FhModal :data="{ heading: 'Confirm Address Removal' }">
              <template #activator="{ open }">
                <FhButton variant="link" @click="open">Remove</FhButton>
              </template>
              <template #body>
                <div class="border border-neutral-30 py-f4 px-f5">
                  <FhShippingAddress v-bind="address"></FhShippingAddress>
                </div>
              </template>
              <template #footer="{ close }">
                <FhButton color="primary" :disabled="props.isDeletingAddressIds.includes(address.id)" @click="emit('delete', address.id)">Remove</FhButton>
                <FhButton variant="text" @click="close">Cancel</FhButton>
              </template>
            </FhModal>
          </div>
        </template>
        <!-- SELECT MODE ADDRESS CARD -->
        <template v-if="isSelectMode">
          <div v-for="address in shippingAddressesPage" :key="address.id" class="relative">
            <FhOptionCard
              ref="card"
              v-model="_selectedId"
              :value="address.id"
              :disabled="props.requireFreight && address.isValidatedForFreight === false && isResidentialShippingEligible === false"
              type="radio"
              alignment="start"
              class="addressOptionCard h-full w-full"
            >
              <FhShippingAddress v-bind="address" />
            </FhOptionCard>
            <FhTooltip
              v-if="props.requireFreight && address.isValidatedForFreight === false && isResidentialShippingEligible === false"
              class="absolute top-3 right-3 text-neutral-60"
            >
              Four Hands carriers only deliver to commercial addresses that can accommodate a 53' freight truck. This address has been flagged as ineligible.
            </FhTooltip>
          </div>
        </template>
      </div>
      <!-- EMPTY MESSAGE -->
      <Transition enter-active-class="ease-out duration-300 transition" enter-from-class="opacity-0" enter-to-class="opacity-100">
        <div v-if="isEmpty" class="text-body-lg mb-f2 text-neutral-50">You have no saved addresses.</div>
      </Transition>
      <!-- ERROR MESSAGE -->
      <div v-if="props.isError" class="text-body-lg mb-f2 flex items-center gap-f1">
        <FhIcon name="AlertCircle" class="flex-none" />
        Sorry, we are not able to load your saved addresses at this time.
      </div>
    </div>
    <!-- ADD ADDRESS BUTTON/MODAL -->
    <FhShippingAddressFormModal :allow-single-use="isSelectMode" @create="handleAddressCreate">
      <template #activator="{ open }">
        <FhButton class="md:-order-1" @click="open">Add Address</FhButton>
      </template>
    </FhShippingAddressFormModal>
    <!-- PAGINATION -->
    <div v-if="totalPages > 1" class="mt-f4 md:w-full">
      <FhPaginationNav
        v-model:page="page"
        :total-items="shippingAddressesOrdered.length"
        :per-page="PER_PAGE"
        @page-change="nextTick(() => scrollToTopOf(rootElem))"
      />
    </div>
  </div>
</template>

<style scoped>
:deep(.addressOptionCard .optionCard__checkbox) {
  @apply top-2;
}
</style>

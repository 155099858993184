<script setup>
import { computed, useAttrs } from "vue";
import FhLoader from "@/components/FhLoader.vue";

const props = defineProps({
  variant: {
    type: String,
    default: "standard",
    validator: (val) => ["standard", "pill", "round", "text", "link"].includes(val)
  },
  color: {
    type: String,
    default: "secondary",
    validator: (val) => ["primary", "secondary", "selected"].includes(val)
  },
  inverse: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: "medium",
    validator: (val) => ["small", "medium", "large"].includes(val)
  },
  wide: {
    type: Boolean,
    default: false
  },
  href: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const attrs = useAttrs();

const tag = computed(() => (props.href && !props.disabled ? "a" : "button")); // <a> tags can't be properly disabled, so we still render a <button> in that case
const type = computed(() => (attrs.type ?? tag.value === "button" ? "button" : undefined));

const isTextStyle = computed(() => props.variant === "text" || props.variant === "link");
const isButtonStyle = computed(() => !isTextStyle.value);

const classObj = computed(() => [
  "btn inline-flex items-center justify-center gap-[0.35em] cursor-pointer disabled:cursor-default text-center",
  `btn--${props.variant}`,
  `btn--${props.size}`,
  {
    "btn--wide": props.wide && props.variant === "standard",
    "btn--primary": isButtonStyle.value && props.color === "primary" && !props.inverse,
    "btn--primary-inverse": isButtonStyle.value && props.color === "primary" && props.inverse,
    "btn--secondary": isButtonStyle.value && props.color === "secondary" && !props.inverse,
    "btn--secondary-inverse": isButtonStyle.value && props.color === "secondary" && props.inverse,
    "btn--selected": isButtonStyle.value && props.color === "selected",
    "btn--text-inverse": isTextStyle.value && props.inverse,
    "hover-underline-with-transition": props.variant === "text" && !props.disabled,
    "hover-underline-with-transition--inverse": props.variant === "text" && !props.disabled && props.inverse
  }
]);
</script>

<template>
  <component :is="tag" :type="type" :href="props.href" :class="classObj" :disabled="disabled">
    <slot v-if="!isLoading"></slot>
    <fh-loader v-if="isLoading" />
  </component>
</template>

<style scoped lang="pcss">
.btn {
  &:focus-visible {
    @apply outline outline-2 outline-offset-4 outline-neutral-70;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }

  /* Increase default icon size within buttons, with an extra large bump for size:large (non-text) buttons */
  &:deep(.icon) {
    font-size: 125%;
  }
  &.btn--large:not(.btn--text, .btn--link):deep(.icon) {
    font-size: 150%;
  }
}

.btn:not(.btn--text, .btn--link) {
  @apply font-bold;
}

.btn--small:not(.btn--text, .btn--link) {
  @apply px-4 py-2.5 text-sm;
}

.btn--medium:not(.btn--text, .btn--link) {
  @apply px-6 py-3.5 text-base;
}

.btn--large:not(.btn--text, .btn--link) {
  @apply px-8 py-5 text-base;
}

.btn--wide {
  &.btn--small {
    @apply px-8;
  }

  &.btn--medium {
    @apply px-12;
  }

  &.btn--large {
    @apply px-16;
  }
}

.btn--text,
.btn--link {
  @apply disabled:text-neutral-50;

  &.btn--small {
    @apply text-f-xs-sm;
  }

  &.btn--medium {
    @apply text-f-sm-base;
  }

  &.btn--large {
    @apply text-f-base-lg;
  }

  &.btn--text-inverse {
    @apply text-white disabled:text-white disabled:opacity-50;
  }
}

.btn--text {
  @apply py-0.5;
}

.btn--link {
  @apply underline;
}

.btn--pill {
  @apply rounded-full;

  &.btn--small {
    @apply px-5;
  }

  &.btn--medium {
    @apply py-3;
  }
}

.btn--round {
  @apply overflow-hidden whitespace-nowrap rounded-full !p-0;

  &.btn--small {
    width: 2.25rem;
    height: 2.25rem;
  }

  &.btn--medium {
    width: 3.25rem;
    height: 3.25rem;
  }

  &.btn--large {
    width: 3.75rem;
    height: 3.75rem;
  }
}

.btn--primary {
  @apply bg-neutral-70 text-white hover:bg-neutral-80 disabled:bg-neutral-30 disabled:text-neutral-50;
}

.btn--primary-inverse {
  @apply bg-white text-neutral-70 disabled:opacity-50;
}

.btn--secondary,
.btn--selected {
  @apply border border-neutral-40  hover:border-neutral-50 active:border-neutral-70 disabled:border-neutral-40 disabled:text-neutral-50;
}

.btn--secondary {
  @apply bg-transparent;
}

.btn--selected {
  @apply bg-neutral-30;
}

.btn--secondary-inverse {
  @apply border border-white bg-transparent text-white disabled:opacity-50;
}
</style>

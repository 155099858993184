<script setup>
import FhButton from "@/components/FhButton.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhLink from "@/components/FhLink.vue";
import { CONTACT_US } from "@/constants/routes";

const emit = defineEmits(["navigate"]);
</script>

<template>
  <FhHeading heading="Recovery Email Sent">
    <template #subheading>
      Follow the instructions in this email to reset your password. If you don&rsquo;t receive the email, please check your junk mail folder or
      <FhLink :href="CONTACT_US">contact us</FhLink> for assistance.
    </template>
  </FhHeading>
  <div class="flex flex-col gap-f3 md:flex-row md:items-center">
    <FhButton color="primary" @click="emit('navigate', 'login')">Back to Log In</FhButton>
  </div>
</template>

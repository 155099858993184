<script setup>
import { computed } from "vue";
import FhTooltip from "./FhTooltip.vue";

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  value: {
    type: String,
    required: true
  },
  text: {
    type: String,
    default: null,
    required: false
  }
});

const showTooltip = computed(() => !!props.text);
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="text-f-sm-lg" :class="{ 'flex items-center gap-3': showTooltip }">
      {{ props.label }}<FhTooltip v-if="showTooltip">{{ props.text }}</FhTooltip>
    </div>
    <span>{{ props.value }}</span>
  </div>
</template>

import axiosClient from "./axiosClient";
import { useQuery, useQueryClient, useMutation } from "@tanstack/vue-query";

const QUERY_KEY = ["saveforlater"];
const QUERY_KEY_CART = ["cart"];

export const useSaveForLaterQuery = (options) => {
  return useQuery({
    ...(options || {}),
    queryKey: QUERY_KEY,
    queryFn: () => axiosClient.get("/saveforlater"),
    select: (saveForLaterResponse) => saveForLaterResponse.saveForLaterItems,
    refetchOnWindowFocus: false
  });
};
export function useAddSaveForLaterItemsMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (cartItem) => {
      const saveForLaterItem = [{ skuNumber: cartItem.sku.skuNumber, quantity: cartItem.quantity, id: cartItem.id, category: cartItem.category }];
      return axiosClient.post("/saveforlater", saveForLaterItem);
    },
    onSuccess: (saveForLaterItems) => {
      queryClient.setQueriesData(QUERY_KEY_CART, saveForLaterItems.cart);
      queryClient.setQueryData(QUERY_KEY, saveForLaterItems.savedForLaterItems);
    }
  });
}
export function useDeleteSaveForItemMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.delete(`/saveforlater/${encodeURIComponent(item.id)}`),
    onSuccess: (_data, variables) => {
      const { id: saveForLaterId } = variables;
      queryClient.setQueriesData(QUERY_KEY, (saveForLaterItemsCache) => {
        let deleteSFLItem = saveForLaterItemsCache.saveForLaterItems.filter((sflItem) => sflItem.id !== saveForLaterId);
        return {
          saveForLaterItems: deleteSFLItem
        };
      });
    }
  });
}

export function useAddToCartMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.post("/saveforlater/addtocart", item),
    onSuccess: (sfl_cartItem, queryPayload) => {
      queryClient.setQueryData(QUERY_KEY_CART, sfl_cartItem);
      queryClient.setQueriesData(QUERY_KEY, (sfl_items) => {
        const updated_sflItems = sfl_items.saveForLaterItems.filter((item) => {
          const cartItemToRemove = queryPayload.find((payload) => payload.id === item.id);
          if (cartItemToRemove) return false;
          return true;
        });
        return {
          saveForLaterItems: updated_sflItems
        };
      });
    }
  });
}

<script setup>
import { ref, computed, inject } from "vue";
import FhButton from "@/components/FhButton.vue";
import FhProductFilterOptions from "@/components/FhProductFilterOptions.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhMultiRangeSlider from "@/components/Form/FhMultiRangeSlider/FhMultiRangeSlider.vue";

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Array,
    required: true
  },
  facetCounts: {
    type: Object,
    default: () => ({})
  },
  isMenuItem: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const isPanelOpenViaHover = ref(false);
const isPanelOpenViaClick = ref(props.isMenuItem);
const isPanelOpen = computed(() => isPanelOpenViaHover.value || isPanelOpenViaClick.value);
const is_fetching_products = inject("fetching_products");

const filterFacetCounts = computed(() => {
  return props.facetCounts;
});

const filterOptions = computed(() => {
  switch (filterFacetCounts.value.type) {
    case "option":
      if (props.options.length === 0 && filterFacetCounts.value.values.length !== 0) {
        return filterFacetCounts.value.values.map((facetValue) => ({ label: facetValue.key, value: facetValue.key }));
      }
      return props.options;
    case "range": {
      const rangeValues = filterFacetCounts.value.values;
      const min = rangeValues.find((rangeValue) => rangeValue.key === "min")?.value || 0;
      const max = rangeValues.find((rangeValue) => rangeValue.key === "max")?.value || 0;
      return [min, max];
    }
    default:
      return props.options || [];
  }
});

const rangeValues = computed(() => {
  if (filterFacetCounts.value.type === "range" && props.modelValue.length > 0) {
    return props.modelValue;
  }

  return filterOptions.value;
});

const rangeType = computed(() => {
  return props.name == "Width" || props.name == "Height" ? "Inches" : "";
});

const HOVER_SHOW_DELAY = 0;
const HOVER_HIDE_DELAY = 0;
let mouseEnterTimer, mouseLeaveTimer;

function handleButtonMouseEnter() {
  if (props.isMenuItem) return;
  clearTimeout(mouseLeaveTimer);
  mouseEnterTimer = setTimeout(() => (isPanelOpenViaHover.value = true), HOVER_SHOW_DELAY);
}

function handleContainerMouseEnter() {
  if (props.isMenuItem) return;
  clearTimeout(mouseLeaveTimer);
}

function handleContainerMouseLeave() {
  if (props.isMenuItem) return;
  clearTimeout(mouseEnterTimer);
  mouseLeaveTimer = setTimeout(() => (isPanelOpenViaHover.value = false), HOVER_HIDE_DELAY);
}

function handleButtonClick() {
  if (!props.isMenuItem) return;
  if (!isPanelOpenViaHover.value) {
    isPanelOpenViaClick.value = !isPanelOpenViaClick.value;
  }
}

const buttonElement = ref(null);
const panelElement = ref(null);

const selectedOptions = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const hasSelectedOptions = computed(() => !!selectedOptions.value.length);

function clearSelectedOptions() {
  selectedOptions.value = [];
  isPanelOpenViaHover.value = false;
}

const handleSliderMoveEnd = (values) => {
  selectedOptions.value = values;
};
</script>

<template>
  <div
    :class="{ 'border-b border-b-neutral-30 py-f2': props.isMenuItem }"
    class="relative inline-block"
    @mouseenter="handleContainerMouseEnter"
    @mouseleave="handleContainerMouseLeave"
  >
    <component
      :is="props.isMenuItem ? 'button' : FhButton"
      ref="buttonElement"
      variant="pill"
      size="medium"
      :color="hasSelectedOptions ? 'selected' : 'secondary'"
      :class="{ accordion__btn: props.isMenuItem }"
      :disabled="filterOptions.length === 0"
      @click="handleButtonClick"
      @mouseenter="handleButtonMouseEnter"
      @hover="handleButtonMouseEnter"
    >
      <span>{{ name }}</span>
      <FhIcon name="DownChevron" :class="{ 'rotate-180': isPanelOpen }" aria-hidden="true" />
    </component>

    <!-- placeholder element to fill the gap between the button and the panel so that mouseleave is not inadvertently triggered in this space -->
    <div v-if="isPanelOpen && !props.isMenuItem" class="absolute top-full left-0 h-f3 w-full bg-transparent"></div>

    <!-- the filter options dropdown panel-->
    <div v-if="isPanelOpen" ref="panelElement" :class="{ hoverMenuPanel: !props.isMenuItem }">
      <FhProductFilterOptions
        v-if="filterFacetCounts.type === 'option'"
        v-model="selectedOptions"
        :options="filterOptions"
        :facet-counts="filterFacetCounts"
        class="mb-f1"
      />
      <FhMultiRangeSlider
        v-if="filterFacetCounts.type === 'range'"
        v-model="rangeValues"
        :selected-options="selectedOptions"
        :min="filterOptions[0]"
        :max="filterOptions[1]"
        :range-type="rangeType"
        :disabled="is_fetching_products"
        step="1"
        @update:slider-move-end="handleSliderMoveEnd"
      />
      <FhButton v-if="hasSelectedOptions && !props.isMenuItem" variant="text" class="mt-f1" @click="clearSelectedOptions">Clear</FhButton>
    </div>
  </div>
</template>

<style scoped lang="pcss">
.hoverMenuPanel {
  @apply absolute top-full -left-f2 z-10 min-w-[328px] translate-y-f3 bg-white px-f2.5 pt-f1 pb-f2 drop-shadow;
}
.accordion__btn {
  @apply flex w-full items-center justify-between py-f2 text-f-base-lg font-bold;
  &:deep(.icon) {
    font-size: 150%;
  }
}
</style>

<script setup>
import { getDataRefs } from "../util/vue";
import FhSection from "../includes/FhSection.vue";
import FhLocation from "./FhLocations/FhLocation.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { locations } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    aria-label="Four Hands Locations"
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div>
      <FhLocation v-for="item in locations" :key="item.id" :item="item"></FhLocation>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.text-white * {
  color: white;
}

.text-white :deep(.bg-limestone *) {
  color: black !important;
}
</style>

import { ref } from "vue";
import { useUrlState } from "@/composables/useURLState";
import { defineStore } from "pinia";

// TODO: Eventually move how filters are set to here for now
// this will only track url state and filter name/value maps
export const useProductFiltersStore = defineStore("productFilters", () => {
  const urlState = useUrlState();
  const filterNameMap = ref(new Map());
  const dyanmicFilters = ref([]);

  const createFilterValueMap = (filterOptions, stopRecurr) => {
    filterOptions &&
      filterOptions.forEach((filterOption) => {
        if (filterOption?.options && !stopRecurr) createFilterValueMap(filterOption?.options, true);
        else filterNameMap.value.set(filterOption.value, filterOption.label);
      });
  };

  const init = (filters) => {
    // Set Filter Name Map
    filters.forEach((filter) => {
      if (!filter?.options || filter?.options?.length == 0) dyanmicFilters.value.push(filter.key);
      createFilterValueMap(filter?.options, false);
    });
  };

  const updateFilterNameMap = (updatedFilters) => {
    Object.values(updatedFilters)
      .filter((filterFacet) => {
        return dyanmicFilters.value.includes(filterFacet.key);
      })
      .forEach((filterdFacet) => {
        const filterMap = filterdFacet.values.map((facetValue) => ({ value: facetValue.key, label: facetValue.key }));
        createFilterValueMap(filterMap);
      });
  };

  return {
    urlState,
    init,
    updateFilterNameMap,
    filterNameMap
  };
});

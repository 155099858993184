<script setup>
import { ref } from "vue";
import Popper from "vue3-popper";
import FhIcon from "./FhIcon.vue";
const isMobile = ref(false);
if (/Mobi|Android/i.test(navigator.userAgent)) {
  isMobile.value = true;
}
</script>

<template>
  <Popper v-bind="isMobile ? click : hover" open-delay="100" close-delay="100" z-index="50" class="popperWrapper">
    <FhIcon name="InformationCircle" class="tooltip cursor-pointer" />
    <template #content>
      <div class="pointer-events-none mx-4 box-border w-max max-w-xs bg-white px-f3 py-f2 shadow-lg md:max-w-sm">
        <slot></slot>
      </div>
    </template>
  </Popper>
</template>

<style scoped>
/* Centers the icon inside the popper wrappers */
.popperWrapper,
.popperWrapper > :deep(div) {
  @apply flex items-center justify-center;
}
</style>

<script setup>
import { computed } from "vue";

const props = defineProps({
  shippedTotal: {
    type: Number,
    default: 0
  }
});

const tableData = computed(() => [
  {
    id: 1,
    indicatorColor: "insider",
    pricingLevel: "Insider",
    ttm: "$0-9,999",
    discount: "Wholesale",
    checkIcon: props.shippedTotal >= 0
  },
  {
    id: 2,
    indicatorColor: "silver",
    pricingLevel: "Silver",
    ttm: "$10-19,999",
    discount: "10% off",
    checkIcon: props.shippedTotal >= 10000
  },
  {
    id: 3,
    indicatorColor: "gold",
    pricingLevel: "Gold",
    ttm: "$20-29,999",
    discount: "15% off",
    checkIcon: props.shippedTotal >= 20000
  },
  {
    id: 4,
    indicatorColor: "platinum",
    pricingLevel: "Platinum",
    ttm: "$30-39,999",
    discount: "20% off",
    checkIcon: props.shippedTotal >= 30000
  },
  {
    id: 5,
    indicatorColor: "diamond",
    pricingLevel: "Diamond",
    ttm: "$40-59,999",
    discount: "25% off",
    checkIcon: props.shippedTotal >= 40000
  },
  {
    id: 6,
    indicatorColor: "enterprise",
    pricingLevel: "Enterprise",
    ttm: "$60,000 +",
    discount: "31% off",
    checkIcon: props.shippedTotal >= 60000
  }
]);
</script>

<template>
  <div class="flex max-w-full flex-col md:border md:border-solid md:border-neutral-30 bg-white">
    <div class="grid grid-cols-[7fr_4fr_4fr] border-b border-solid border-neutral-30 py-5 font-bold md:grid-cols-3">
      <div class="pl-5">Pricing Level</div>
      <div class="md:pl-8">Discount</div>
      <div class="md:pl-8">TTM</div>
    </div>

    <div v-for="item in tableData" :key="item.id" class="grid grid-cols-[7fr_4fr_4fr] border-b border-solid border-neutral-30 last:border-0 md:grid-cols-3">
      <div class="mr-2 flex flex-row items-center">
        <!-- <div class="contents-center ml-0.5 mr-2 flex h-5 w-5 items-center text-f-xs-base">
          <IconCheck v-show="item.checkIcon" />
        </div> -->
        <div class="relative flex flex-row items-center" :class="!item.checkIcon ? 'text-neutral-50' : 'text-neutral-70'">
          <span class="mr-2 block h-10 w-3" :class="`bg-${item.indicatorColor}`"></span>
          {{ item.pricingLevel }}
        </div>
      </div>
      <div class="md:pl-8 flex flex-row items-center" :class="!item.checkIcon ? 'text-neutral-50' : 'text-neutral-70'">
        {{ item.discount }}
      </div>
      <div class="md:pl-8 flex flex-row items-center" :class="!item.checkIcon ? 'text-neutral-50' : 'text-neutral-70'">
        {{ item.ttm }}
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-insider {
  background-color: #e0d1b8;
}
.bg-silver {
  background-color: #b2b2b2;
}
.bg-gold {
  background-color: #d7b683;
}
.bg-platinum {
  background-color: #e5e4e2;
}
.bg-diamond {
  background-color: #c7d2e9;
}
.bg-enterprise {
  background-color: #ba623f;
}
</style>

<script setup>
import { ref, computed, useSlots } from "vue";
import { useViewportSizes } from "../composables";
import FhSection from "../includes/FhSection.vue";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  /**
   * Controls the alignment of the tabs and the content; default is center
   * @values left, center
   */
  align: {
    type: [String, Array],
    required: false,
    default: "center",
    validator: (val) => ["center", "left"].includes(val) || (val.length === 2 && val.every((v) => ["center", "left"].includes(v)))
  },
  /**
   * By default, the tabs will have a full-width underline on mobile only; set this to `true` to show the same full-width underline on larger screens as well
   */
  underline: {
    type: Boolean,
    required: false,
    default: false
  },
  /**
   * Setting this to `true` will reduce the vertical spacing with and around the tabs
   */
  compact: {
    type: Boolean,
    required: false,
    default: false
  },
  /**
   * Allows for programatic control of the selected tab
   */
  selectedIndex: {
    type: Number,
    required: false,
    default: 0
  }
});

const emit = defineEmits(["change"]);

const slots = useSlots();
const triggerSlots = computed(() => Object.keys(slots).filter((key) => key.startsWith("trigger-")));
const contentSlots = computed(() => Object.keys(slots).filter((key) => key.startsWith("content-")));

const tabList = ref(null);

function handleTabChange(index) {
  let tab = tabList.value.$el.children[index];
  let left = tab.offsetLeft - (index === 0 ? 0 : 50);
  tabList.value.$el.scrollTo({
    left,
    behavior: "smooth"
  });

  emit("change", index);
}

// Headless UI programatically focuses the tab button when clicked which causes all major browsers (as of October 2022)
// to show :focus-visible styles as if the button was focused via keyboard input. This prevents us from having
// separate focus styles for mouse and keyboard users. The code below is a workaround found in this thread:
// https://github.com/tailwindlabs/headlessui/issues/1694, which progamatiaclly blurs the focused element immediately
// after being clicked. Since this only runs on click, it won't affect keyboard users.
function handleTabClick() {
  requestAnimationFrame(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  });
}

const { isSm } = useViewportSizes();

const isUnderlineVisible = computed(() => isSm.value || props.underline);

const alignment = computed(() => {
  if (Array.isArray(props.align)) return { tabs: props.align[0], content: props.align[1] };
  return { tabs: props.align, content: props.align };
});
</script>

<template>
  <FhSection aria-label="Tabs" :base="style.base">
    <TabGroup as="div" class="flex flex-col" :selected-index="props.selectedIndex" @change="handleTabChange">
      <div class="relative" :class="[props.compact ? 'mb-6' : 'mb-f6']">
        <TabList ref="tabList" class="hide-scrollbars flex gap-f6 overflow-x-auto">
          <Tab v-for="(name, index) in triggerSlots" :key="index" v-slot="{ selected }" as="template">
            <button
              class="tab group relative min-w-max focus:outline-none focus-visible:outline-none"
              :class="[
                selected ? 'is-active text-neutral-70' : 'text-neutral-50',
                props.compact ? 'py-5' : 'py-f5',
                { 'first:ml-auto last:mr-auto': alignment.tabs === 'center' }
              ]"
              @click="handleTabClick"
            >
              <span
                class="group-focus-visible:outline-nuetral-70 block group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-2"
              >
                <slot :name="name">Tab {{ i + 1 }}</slot>
              </span>
            </button>
          </Tab>
        </TabList>
        <div v-if="isUnderlineVisible" class="absolute bottom-0 h-px w-full bg-neutral-30"></div>
      </div>
      <TabPanels :class="{ 'self-center': alignment.content === 'center' }">
        <TabPanel v-for="(name, index) in contentSlots" :key="index">
          <slot :name="name">
            <div>content {{ index }}</div>
          </slot>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </FhSection>
</template>

<style lang="pcss" scoped>
.tab:after {
  @apply absolute bottom-0 left-0 block h-px w-0 bg-neutral-70 duration-200 content-[''] transition-[width] z-10;
}
.tab.is-active:after {
  @apply w-full;
}

.hide-scrollbars::-webkit-scrollbar {
  display: none;
}
</style>

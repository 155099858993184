<script setup>
import FhSection from "@/includes/FhSection.vue";
import { getDataRefs } from "@/util/vue";
// import vue swiper
import { Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
// import swiper css
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import FhVideo from "@/components/FhVideo.vue";
import { onMounted, onUnmounted, ref } from "vue";
const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  },
  breakpoints: {
    type: Object,
    required: false,
    default: () => ({
      0: {
        slidesPerView: 1.25,
        spaceBetween: 8
      },
      375: {
        slidesPerView: 2.25,
        spaceBetween: 8
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 8
      },
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 16
      },
      1200: {
        slidesPerView: 4.5,
        spaceBetween: 20
      }
    })
  },
  imagesRatio: {
    type: String,
    default: "1/1"
  },
  isVideo: {
    type: Boolean,
    default: false
  }
});

const { slides, gaTagging } = getDataRefs(props.data);

const windowSize = ref();
const randId = Date.now() + Math.floor(Math.random() * (100 - 1) + 1); // The randId is meant to prevent querySelectorAll from selecting the wrong swiper.
const numberOfSlides = slides.value.length;
const numberOfMargins = numberOfSlides - 1;
const wrapperSize = ref();
const slidesWidth = ref();
const calcSlide = ref();
const displayArrows = ref(true);
const arrows = ref([]);

const calculateSlideDimensions = () => {
  const singleSlideItemSize = slidesWidth.value[0].swiperSlideSize;
  let marginSize = 8;

  if (windowSize.value > 0 && windowSize.value <= 1023) {
    marginSize = props.breakpoints[768].spaceBetween;
  }
  if (windowSize.value > 1023 && windowSize.value <= 1199) {
    marginSize = props.breakpoints[1024].spaceBetween;
  }
  if (windowSize.value >= 1200) {
    marginSize = props.breakpoints[1200].spaceBetween;
  }
  calcSlide.value = singleSlideItemSize * numberOfSlides + marginSize * numberOfMargins;
  toggleArrows();
};

const toggleArrows = () => {
  const [previous, next] = arrows.value;
  displayArrows.value = calcSlide.value > wrapperSize.value[0].offsetWidth;

  const action = displayArrows.value ? "remove" : "add";
  previous.classList?.[action]("swiper-button-hidden");
  next.classList?.[action]("swiper-button-hidden");
};

const initDOM = () => {
  wrapperSize.value = document.querySelectorAll(`#swipper-${randId} .swiper-wrapper`);
  slidesWidth.value = document.querySelectorAll(`#swipper-${randId} .swiper-slide`);
  arrows.value = [document.querySelector(`#swipper-${randId} .swiper-button-prev`), document.querySelector(`#swipper-${randId} .swiper-button-next`)];
  windowSize.value = window.innerWidth;
  setTimeout(calculateSlideDimensions, 1);
};

onMounted(() => {
  initDOM();
  window.addEventListener("resize", () => {
    windowSize.value = window.innerWidth;
    let timeout = setTimeout(() => {
      calculateSlideDimensions();
      clearTimeout(timeout);
    }, 100);
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", calculateSlideDimensions);
});
</script>

<template>
  <FhSection
    v-if="slides.length > 0"
    aria-label="Image Carousel"
    :base="style.base"
    class="section-carousel"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="fhImageCarousel">
      <swiper
        :id="'swipper-' + randId"
        :modules="[Navigation, Scrollbar]"
        :breakpoints="breakpoints"
        :threshold="20"
        :scrollbar="{ draggable: true }"
        navigation
      >
        <swiper-slide v-for="slide in slides" :key="slide.id" class="flex slide">
          <a class="h-full w-full" :href="slide.ctaLink" :aria-label="slide.ctaAltText" :target="slide.ctaTarget" :data-ga="gaTagging || 'image_carousel'">
            <figure>
              <div class="fhImageCarousel__image relative block">
                <FhVideo v-if="slide.isVideo" :src="slide.imageSrc" :autoplay="true" :loop="true" :controls="false" class="video_block"></FhVideo>
                <img
                  v-else
                  class="absolute left-0 right-0 top-0 bottom-0 h-full w-full object-cover object-center"
                  :data-src="slide.imageSrc"
                  :src="slide.imageSrc"
                  :alt="slide.imageAltText"
                />
              </div>
              <figcaption v-if="slide.imageCaption" class="mt-f3">
                {{ slide.imageCaption }}
              </figcaption>
            </figure>
          </a>
        </swiper-slide>
      </swiper>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.section-carousel {
  &:deep(h2) {
    @apply pb-f5 mb-0;
  }
}
.fhImageCarousel {
  &:deep(.swiper) {
    @apply pb-0;
  }
  &:deep(.swiper-slide) {
    @apply h-auto;
  }
  &:deep(.swiper-button-prev),
  &:deep(.swiper-button-next) {
    @apply hidden bottom-[42px] top-auto h-[24px] w-[24px] md:flex;
  }
  &:deep(.swiper-button-prev) {
    @apply left-auto right-[40px] bg-center bg-no-repeat;
    background-image: url("../assets/icons/icon-left-arrow.svg");
  }
  &:deep(.swiper-button-next) {
    @apply right-0 bg-center bg-no-repeat;
    background-image: url("../assets/icons/icon-right-arrow.svg");
  }
  &:deep(.swiper-button-prev::after),
  &:deep(.swiper-button-next::after) {
    @apply hidden;
  }
  &:deep(.swiper-scrollbar) {
    /* @apply !left-4 lg:!left-8 !bottom-0 !w-[calc(100%-2rem)] lg:!w-[calc(100%-4rem)]; */
    @apply left-0 py-[40px] w-full md:w-[calc(100%-104px)];
    height: auto;
    border-radius: 0;
    background: transparent;
    &::before {
      @apply bg-neutral-opacity-35;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      margin: auto;
    }
  }
  &:deep(.swiper-scrollbar.swiper-scrollbar-horizontal) {
    @apply relative;
  }
  &:deep(.swiper-scrollbar-drag) {
    @apply bg-neutral-70;
    cursor: pointer;
    height: 4px;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-radius: 0;
    background-clip: content-box;
    box-sizing: content-box;
  }
}

.fhImageCarousel__image {
  aspect-ratio: v-bind(props.imagesRatio);
}

:deep(.fh_video) {
  @apply absolute left-0 right-0 top-0 bottom-0 h-full w-full object-cover object-center;
}

.video_block {
  @apply static;
}

/* Apply filter invert(100%) conditionally */
.text-white :deep(.swiper-button-prev),
.text-white :deep(.swiper-button-next) {
  filter: invert(100%) brightness(10000%) contrast(1000%);
}
.text-white :deep(.swiper-scrollbar-drag) {
  @apply bg-white-opacity-70;
}
.text-white :deep(.swiper-scrollbar::before) {
  @apply bg-neutral-opacity-80;
}
</style>

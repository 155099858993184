<script setup>
import { computed, ref } from "vue";
import { useViewportSizes } from "../../composables";
import TransitionExpand from "../../transitions/TransitionExpand.vue";
import FhIcon from "../FhIcon.vue";
import FhButton from "../FhButton.vue";
import FhLocationsModal from "./FhLocationsModal.vue";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
});
let { isSm, isMd, isLg } = useViewportSizes();

const expanderOpen = ref(false);
const idBase = ref(Math.floor(Math.random() * parseInt(Date.now())));
const triggerId = ref(`trigger-${idBase.value}`);
const regionId = ref(`region-${idBase.value}`);
const triggerAriaAttributes = computed(() => {
  if (isSm) {
    return {
      id: triggerId.value,
      role: "button",
      "aria-controls": regionId.value,
      "aria-expanded": expanderOpen.value ? "true" : "false"
    };
  }
  return {};
});
const regionAriaAttributes = computed(() => {
  if (isSm) {
    return {
      id: regionId.value,
      role: "region",
      "aria-labelledby": triggerId.value
    };
  }
  return {};
});

let modalData;
if (props.item.promo) {
  modalData = {
    title: props.item.promo.title,
    date: props.item.promo.date,
    copy: props.item.promo.copy,
    followUsLink: {
      label: props.item.promo.followUsLink.label,
      url: props.item.promo.followUsLink.url,
      ariaLabel: props.item.promo.followUsLink.ariaLabel,
      target: props.item.promo.followUsLink.target
    },
    slides: [...props.item.promo.slides]
  };
}
</script>

<template>
  <!-- Start: LOCATION WRAPPER -->
  <div
    class="mb-f10 flex grid-cols-[2fr_4fr] flex-col gap-[clamp(1rem,5vw,12rem)] border-b border-solid border-neutral-30 md:grid md:items-start md:pb-f10 lg:grid-cols-[5fr_7fr]"
  >
    <!-- Start: Location image -->
    <div class="grid-row-[span_1] relative">
      <!-- Start: Locations image banner -->
      <div v-if="item.promo || item.promoCopy" class="absolute top-0 left-0 right-0 z-10 bg-limestone py-f2.5 px-2 text-center text-f-sm-base md:px-4">
        <FhWysiwyg>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="item.promoCopy"></span>
        </FhWysiwyg>
        <span>&nbsp;</span>
        <FhLocationsModal :modal-data="modalData">
          <template #default="{ toggleModal }">
            <FhButton
              v-if="item.promo"
              variant="text"
              :size="isSm ? 'small' : 'medium'"
              :class="['underline', isSm ? 'underline-offset-4' : 'underline-offset-[6px]']"
              aria-label="Location Promo Announcement"
              @click="toggleModal"
            >
              {{ item.promoLinkText ? item.promoLinkText : "Read More" }}
            </FhButton>
          </template>
        </FhLocationsModal>
      </div>
      <span class="fhLocation__image relative block">
        <img :src="item.image" :alt="item.imageAltText" class="h-full w-full object-cover" />
      </span>
      <!-- End: Locations image banner -->
    </div>
    <!-- End: Location image -->

    <!-- Start: Location Information -->
    <div class="grid-row-[span_1] flex flex-col md:flex-row md:flex-wrap md:self-center customColor" :class="{ 'pb-f10': expanderOpen }">
      <h2
        v-bind="triggerAriaAttributes"
        class="mb-f5 flex w-full items-center justify-between font-serif md:flex-[1_0_100%] md:cursor-default"
        @click.stop.prevent="isSm ? (expanderOpen = !expanderOpen) : false"
      >
        <span class="text-left text-f-2xl-5xl leading-[0.825]">{{ item.name }}</span>
        <FhIcon
          v-if="isSm"
          name="DownChevron"
          style="width: 1.5rem; height: 1.5rem"
          :class="['text-neutral-50', { 'rotate-180 transition-transform duration-200': expanderOpen }]"
        ></FhIcon>
      </h2>
      <TransitionExpand v-bind="regionAriaAttributes">
        <div v-if="expanderOpen || !isSm" class="md:flex md:w-full md:flex-wrap md:gap-[1.5rem] customColor">
          <!-- ADDRESS -->
          <div v-if="item.address" class="flex-[0_0_calc((100%/3)-1rem)]">
            <div class="mb-1 text-f-sm-base text-neutral-70">Address</div>
            <div class="mb-1 flex flex-col text-f-xs-sm text-neutral-50 md:mb-5">
              <span>{{ item.address.address1 }}</span>
              <span>{{ item.address.address2 }}</span>
              <span>{{ item.address.address3 }}</span>
              <div>
                <span>{{ item.address.city }}, {{ item.address.state }} {{ item.address.zipCode }}</span>
              </div>
            </div>
            <div v-if="item.directionsLink" class="mb-5 md:mb-0">
              <FhButton
                :variant="isSm || isMd ? 'text' : 'standard'"
                :size="isLg ? 'small' : null"
                :href="item.directionsLink"
                arial-label="Directions to Showroom"
                target="_blank"
                :class="isSm || isMd ? 'underline underline-offset-4' : ''"
                class="text-f-xs-sm"
              >
                Directions
              </FhButton>
            </div>
          </div>

          <!-- HOURS -->
          <div v-if="item.days1 && item.hours1" class="mb-5 flex flex-[0_0_calc((100%/3)-1rem)] flex-wrap justify-start md:mb-0 md:flex-col md:flex-nowrap">
            <div class="mb-1 flex-[1_1_100%] text-f-sm-base text-neutral-70 md:flex-[unset]">Hours (CST)</div>
            <div class="flex flex-col text-f-xs-sm text-neutral-50 md:mb-5">
              <span>{{ item.days1 }}</span>
              <span>{{ item.hours1 }}</span>
            </div>
            <div v-if="item.days2 && item.hours2" class="ml-4 flex flex-col text-f-xs-sm text-neutral-50 md:ml-0">
              <span>{{ item.days2 }}</span>
              <span>{{ item.hours2 }}</span>
            </div>
          </div>

          <div class="md:flex-[0_0_calc((100%/3)-1rem)]">
            <!-- PHONE -->
            <div v-if="item.phone" class="md:mb-f5" :class="!item.pickup ? 'mb-7' : 'mb-5'">
              <div class="mb-1 text-f-sm-base text-neutral-70">Phone</div>
              <FhButton
                variant="text"
                size="small"
                :href="`tel:${item.phone}`"
                arial-label="Phone Number"
                class="decoration-current text-f-xs-sm text-neutral-50 underline underline-offset-4"
                >{{ item.phone }}</FhButton
              >
            </div>

            <!-- PICKUP -->
            <div v-if="item.pickup" class="mb-7 md:mb-0">
              <div class="text-f-sm-base">Furniture Pick Up</div>
              <div class="flex flex-col text-f-xs-sm text-neutral-50">
                <span>{{ item.pickup.days }}</span>
                <span>{{ item.pickup.hours }}</span>
              </div>
            </div>
          </div>

          <!-- FOOTER  -->
          <footer v-if="item.announcementCopy" class="mt-f3 text-f-xs-sm md:flex-[1_1_100%]">
            <!-- OPTIONAL RICH TEXT LINKS -->
            <FhWysiwyg>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="item.announcementCopy"></div>
            </FhWysiwyg>
          </footer>
        </div>
      </TransitionExpand>
    </div>
  </div>
  <!-- End: LOCATION WRAPPER -->
</template>

<style lang="pcss" scoped>
.fhLocation__image::after {
  content: "";
  display: block;
  padding-top: calc((2 / 3) * 100%);
}

@supports (aspect-ratio: 3/2) {
  .fhLocation__image {
    aspect-ratio: 3/2;
  }
  .fhLocation__image::after {
    display: none;
  }
}
</style>

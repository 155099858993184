<script setup>
import { getDataRefs } from "../util/vue";
import FhHeading from "@/components/FhHeading.vue";
import FhSection from "../includes/FhSection.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  },
  userType: {
    type: String,
    required: false,
    default: ""
  }
});

const { heading, fields } = getDataRefs(props.data);
</script>

<template>
  <FhSection :base="style.base">
    <!-- MY ACCOUNT COMPANY -->
    <div class="mb-f10 flex flex-col md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10 md:pb-f10">
      <FhHeading tag="h2">{{ heading }}</FhHeading>
      <template v-if="userType !== 'customerGuest'">
        <div v-for="(item, i) in fields" :key="i" class="mb-4 flex flex-col md:mb-2 md:flex-row">
          <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12">
            {{ item[0] }}
          </span>
          <span class="whitespace-pre-line text-neutral-50">{{ item[1] }}</span>
        </div>
      </template>
      <template v-else>
        <div class="mb-4 flex flex-col md:mb-2 md:flex-row">
          <p class="mb-0.5 text-f-sm-base md:mb-0 md:basis-8/12 lg:basis-7/12">
            A guest account has been created for you by a Four Hands customer. With this account, you can browse the entire Four Hands catalog anytime you'd
            like.
            <br /><br />
            If you have any questions, please reach out to the Four Hands customer who created this account.
          </p>
        </div>
      </template>
    </div>
  </FhSection>
</template>

import { createPinia } from "pinia";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { createVuetify } from "vuetify";

const plugins = {
  pinia: (app) => {
    const pinia = createPinia();
    app.use(pinia);
  },
  vueQuery: (app) => {
    app.use(VueQueryPlugin, {
      queryClientConfig: {
        defaultOptions: {
          queries: {
            retryDelay: (failureCount, error) => {
              const responseRegex = /^4\d{2}$/g;
              if (responseRegex.test(error.response.status)) return false;
              return failureCount < 2;
            }
          }
        }
      }
    });
  },
  vuetify: (app) => {
    const vuetify = createVuetify();
    app.use(vuetify);
  }
};

export default function registerVuePlugins(app) {
  Object.keys(plugins).forEach((plugin) => plugins[plugin](app));
}

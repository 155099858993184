import { useBreakpoints } from "@vueuse/core";

// Note: Breakpoints config is duplicated from tailwind.config.js (theme.screens).
//
// Importing the config here was problematic because Tailwind config is CommonJS and
// Vite is ESM and they don't play together nicely without extraneous configuration.
//
// ** Update in both places if these need to change! **
const screens = {
  md: "768px",
  lg: "1200px",
  xl: "1920px",
  "2xl": "2540px"
};

export default function () {
  const breakpoints = useBreakpoints(screens);
  return {
    isSm: breakpoints.smaller("md"),
    isMd: breakpoints.between("md", "lg"),
    isLg: breakpoints.greaterOrEqual("lg")
  };
}

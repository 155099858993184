import axiosClient from "./axiosClient";
import { useQuery } from "@tanstack/vue-query";

const QUERY_KEY_BASE = "freight";

export function useFreightQuery(params, options) {
  return useQuery({
    ...(options || {}),
    queryKey: [QUERY_KEY_BASE, params],
    queryFn: () => axiosClient.get("/freight", { params }),
    refetchOnWindowFocus: false
  });
}

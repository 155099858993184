<script setup>
import { computed, useSlots } from "vue";
import FhIcon from "./FhIcon.vue";

const props = defineProps({
  /**
   * Controls whether this option card behaves functionally as a checkbox or a radio button. There is no visual difference between these two.
   */
  type: {
    type: String,
    default: "checkbox",
    validator: (val) => ["checkbox", "radio"].includes(val)
  },
  modelValue: {
    type: undefined,
    required: true
  },
  value: {
    type: undefined,
    default: null
  },
  label: {
    type: String,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false
  },
  layout: {
    type: String,
    default: "row",
    validator: (val) => ["row", "column"].includes(val)
  },
  alignment: {
    type: String,
    default: "center",
    validator: (val) => ["center", "start"].includes(val)
  }
});

const emit = defineEmits(["update:modelValue"]);

const _value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const isChecked = computed(() => {
  if (props.type === "radio") return _value.value === props.value;
  if (Array.isArray(_value.value)) return _value.value.includes(props.value);
  return !!_value.value;
});

const slots = useSlots();
</script>

<template>
  <label
    class="flex cursor-pointer border border-neutral-30 text-f-sm-lg transition"
    :class="{
      'selected border-limestone bg-limestone': isChecked,
      'cursor-default bg-neutral-20': props.disabled,
      'flex-row gap-f5 p-f5': props.layout === 'row',
      'flex-col gap-f3 px-f3 py-f6': props.layout === 'column',
      'items-center': props.alignment === 'center',
      'items-start': props.alignment === 'start'
    }"
  >
    <div class="optionCard__checkbox relative flex-none">
      <input
        v-model="_value"
        :value="props.value"
        :type="props.type"
        class="absolute h-7 w-7 cursor-pointer opacity-0 md:h-8 md:w-8"
        :disabled="props.disabled"
      />
      <div
        class="flex h-7 w-7 items-center justify-center rounded-full border border-neutral-40 transition md:h-8 md:w-8"
        :class="{ 'border-neutral-70 bg-neutral-70': isChecked, 'opacity-50': props.disabled }"
        aria-hidden="true"
        focusable="false"
      >
        <FhIcon name="Check" class="h-2/3 w-2/3" :class="{ 'text-transparent': !isChecked, 'text-white': isChecked }" />
      </div>
    </div>
    <div
      class="flex-1"
      :class="{
        'opacity-50': props.disabled,
        'text-center': props.layout === 'column' && props.alignment === 'center',
        'text-f-sm-lg': !slots.default,
        'text-neutral-60': !slots.default && !isChecked
      }"
    >
      <!-- @slot The default slot can be used for a label or custom content; overwrites the `label` prop -->
      <slot>{{ props.label }}</slot>
    </div>
  </label>
</template>

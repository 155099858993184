export const MEASUREMENT_SYSTEM = Object.freeze({
  IMPERIAL: "IMPERIAL",
  METRIC: "METRIC"
});

export const MEASUREMENT_UNITS = Object.freeze({
  INCH: "INCH",
  CENTIMETER: "CENTIMETER",
  POUND: "POUND",
  KILOGRAM: "KILOGRAM",
  CUBIC_FOOT: "CUBIC_FOOT",
  CUBIC_METER: "CUBIC_METER"
});

export const MEASUREMENT_UNITS_DEFINITION = Object.freeze({
  INCH: Object.freeze({
    system: MEASUREMENT_SYSTEM.IMPERIAL,
    formatAs: "inch",
    convertsTo: MEASUREMENT_UNITS.CENTIMETER,
    conversionRatio: 2.54
  }),
  CENTIMETER: Object.freeze({
    system: MEASUREMENT_SYSTEM.METRIC,
    formatAs: "centimeter",
    convertsTo: MEASUREMENT_UNITS.INCH,
    conversionRatio: 1 / 2.54
  }),
  POUND: Object.freeze({
    system: MEASUREMENT_SYSTEM.IMPERIAL,
    formatAs: "pound",
    convertsTo: MEASUREMENT_UNITS.KILOGRAM,
    conversionRatio: 0.453592
  }),
  KILOGRAM: Object.freeze({
    system: MEASUREMENT_SYSTEM.METRIC,
    formatAs: "kilogram",
    convertsTo: MEASUREMENT_UNITS.POUND,
    conversionRatio: 1 / 0.453592
  }),
  CUBIC_FOOT: Object.freeze({
    system: MEASUREMENT_SYSTEM.IMPERIAL,
    formatAs: "cubic-foot",
    convertsTo: MEASUREMENT_UNITS.CUBIC_METER,
    conversionRatio: 0.0283168
  }),
  CUBIC_METER: Object.freeze({
    system: MEASUREMENT_SYSTEM.METRIC,
    formatAs: "cubic-meter",
    convertsTo: MEASUREMENT_UNITS.CUBIC_FOOT,
    conversionRatio: 1 / 0.0283168
  })
});

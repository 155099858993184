export default function () {
  const style = document.createElement("style");
  document.head.insertAdjacentElement("beforeend", style);

  const updateVPStyles = () =>
    (style.textContent = `
    :root,
    :root *,
    :root *::before,
    :root *::after {
      --1vw: ${document.body.clientWidth / 100}px;
      --100vw: calc(var(--1vw) * 100);
      --1vh: ${document.documentElement.clientHeight / 100}px;
      --100vh: calc(var(--1vh) * 100);
    }
  `);

  const createResizeWatcher = () => {
    let debounce;
    const onResize = () => {
      clearTimeout(debounce);
      debounce = window.setTimeout(updateVPStyles, 250);
    };
    window.addEventListener("resize", onResize);
  };

  const initialize = () => {
    let limiter = 0;
    let updateInterval = window.setInterval(() => {
      updateVPStyles();
      if (++limiter >= 10) {
        window.clearInterval(updateInterval);
      }
    }, 100);

    updateVPStyles();
    createResizeWatcher();
  };

  initialize();
}

<script setup>
import { reactive } from "vue";
import FhModal from "@/components/FhModal.vue";
import FhButton from "@/components/FhButton.vue";
import { useNotificationStore } from "@/stores/NotificationStore";
const notificationStore = useNotificationStore();

const props = defineProps({
  pathUrl: {
    type: String,
    default: ""
  },
  isAdmin: {
    type: Boolean,
    default: true
  }
});
const initialState = {
  pathUrl: ""
};

const formData = reactive({ ...initialState, pathUrl: props.pathUrl });
const copyLink = () => {
  navigator.clipboard.writeText(formData.pathUrl);
  notificationStore.notifySuccess("Link copied");
};
</script>

<template>
  <FhModal
    ref="modal"
    :data="{ heading: 'Share List', subheading: props.isAdmin ? 'Shared lists display client pricing. Wholesale pricing is not shared.' : '' }"
    @close="reset"
  >
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <FhButton class="copy-link mb-2.5 flex w-full flex-col border-neutral-30 md:w-full" @click="copyLink">
        Copy Link
        <span class="text-sm font-normal">Anyone with the link can view the list</span>
      </FhButton>
    </template>
  </FhModal>
</template>

<style scoped>
.copy-link {
  align-items: start !important;
}
</style>

<script setup>
import { reactive, computed } from "vue";
import { useInitiatePasswordResetMutation } from "@/api/authApi";
import { useNotificationStore } from "@/stores/NotificationStore";
import FhButton from "@/components/FhButton.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhInput from "@/components/FhInput.vue";
import FhLink from "@/components/FhLink.vue";
import { CONTACT_US } from "@/constants/routes";

const emit = defineEmits(["navigate"]);
const props = defineProps({
  username: {
    type: String,
    default: ""
  }
});

const model = reactive({
  username: props.username
});

const modelTrimmed = computed(() => ({
  username: model.username.trim()
}));

const isValid = computed(() => model.username?.length > 0);

const { mutate: loginMutate, isLoading } = useInitiatePasswordResetMutation();
const notificationStore = useNotificationStore();

function submit() {
  if (!isValid.value) return;
  loginMutate(modelTrimmed.value, {
    onSuccess: () => {
      emit("navigate", "sent");
    },
    onError: () => {
      notificationStore.notifyError("Sorry, something went wrong and we could not send the password reset instructions. Please try again.");
    }
  });
}
</script>

<template>
  <form class="flex flex-col" @submit.prevent="submit">
    <FhHeading
      heading="Having Trouble Signing In?"
      subheading="Enter your customer number or email address and we'll send you instructions for resetting your password."
    />
    <div class="mb-f8">
      <FhInput v-model="model.username" label="Email or Customer Number" placeholder="Enter email or customer number">
        <template #note> If you don't know the email address associated with your account, please <FhLink :href="CONTACT_US">contact us</FhLink>. </template>
      </FhInput>
    </div>
    <div class="flex flex-col gap-3 md:items-start md:self-end">
      <FhButton type="submit" color="primary" :disabled="!isValid || isLoading" :is-loading="isLoading">Continue</FhButton>
      <FhButton variant="link" size="small" @click="emit('navigate', 'login')">Back to Log In</FhButton>
    </div>
  </form>
</template>

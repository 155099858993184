<script setup>
import { computed } from "vue";
import { SHIPPING_OPTIONS } from "../constants";
import FhCheckoutStepShippingDetails_FhCarriers from "./FhCheckoutStepShippingDetails_FhCarriers.vue";
import FhCheckoutStepShippingDetails_ThirdParty from "./FhCheckoutStepShippingDetails_ThirdParty.vue";
import FhCheckoutStepShippingDetails_PickUp from "./FhCheckoutStepShippingDetails_PickUp.vue";

const props = defineProps({
  shippingOption: {
    type: String,
    required: true,
    validator: (val) => Object.values(SHIPPING_OPTIONS).includes(val)
  }
});

const stepComponent = computed(() => {
  switch (props.shippingOption) {
    case SHIPPING_OPTIONS.FH_CARRIERS:
      return FhCheckoutStepShippingDetails_FhCarriers;
    case SHIPPING_OPTIONS.THIRD_PARTY:
      return FhCheckoutStepShippingDetails_ThirdParty;
    case SHIPPING_OPTIONS.PICK_UP:
      return FhCheckoutStepShippingDetails_PickUp;
    default:
      return null;
  }
});
</script>

<template>
  <component :is="stepComponent" />
</template>

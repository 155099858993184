<script setup>
import { getDataRefs } from "@/util/vue";
import { computed } from "vue";
import FhHeading from "@/components/FhHeading.vue";
import FhSection from "@/includes/FhSection.vue";
import FhIcon from "@/components/FhIcon.vue";

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const { heading, subHeading, isTaxExempt } = getDataRefs(props.data);

const uploadTaxLink = computed(() => {
  const url = "https://secure.fourhands.com/SecondaryInfo/Default.aspx?id";
  return `${url}=${props.data.customerId}`;
});
</script>

<template>
  <FhSection>
    <div class="mb-f10 flex flex-col text-neutral-70 md:flex-row md:flex-wrap md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10 md:pb-f10">
      <FhHeading tag="h2" class="md:order-1 md:mb-0 md:flex-1 md:justify-center">{{ heading }}</FhHeading>
      <div class="my-4 flex flex-col md:order-3 md:mb-2 md:w-full md:flex-row md:gap-24">
        <span class="mb-3 text-f-sm-base font-bold md:mb-0 md:basis-4/12">
          {{ subHeading }}
        </span>
        <span v-if="!isTaxExempt" class="flex flex-col">
          <a :href="uploadTaxLink" target="_blank" class="hover-underline-with-transition text-neutral-50 cursor-pointer"
            >Click here to upload your tax form <FhIcon name="UpRightArrow" class="relative -top-px"
          /></a>
        </span>
      </div>
    </div>
  </FhSection>
</template>

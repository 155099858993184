import axiosClient from "@/api/axiosClient";
import { useQuery, useQueryClient, useMutation } from "@tanstack/vue-query";

const QUERY_KEY_BASE = "guest_accounts";

export const useGuestAccountQuery = (params, options) => {
  return useQuery({
    ...(options || {}),
    queryKey: [QUERY_KEY_BASE],
    queryFn: () => axiosClient.get("/account/guest-accounts", { params }),
    refetchOnWindowFocus: false
  });
};

export function useDeleteGuestAccountMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (id) => axiosClient.delete(`/account/guest-accounts/${id}`),
    onSuccess: (_data, deletedId) => {
      // Remove the deleted card from the query cache(s)
      queryClient.setQueriesData([QUERY_KEY_BASE], (existingGuestAccounts) => {
        let deleteGuestAccountIndex = existingGuestAccounts.findIndex((guestAccount) => guestAccount.id === deletedId);
        if (deleteGuestAccountIndex < 0) {
          return existingGuestAccounts;
        } else {
          return [...existingGuestAccounts.slice(0, deleteGuestAccountIndex), ...existingGuestAccounts.slice(deleteGuestAccountIndex + 1)];
        }
      });
    }
  });
}

export function useCreateGuestAccountMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: ({ name, email, pricingVisibility }) =>
      axiosClient.post("/account/guest-accounts", {
        name,
        email,
        pricingVisibility
      }),
    onSuccess: (newGuestAccount) => {
      const queryKeyFilter = [QUERY_KEY_BASE];
      queryClient.setQueriesData({ queryKey: queryKeyFilter }, (guestAccounts) => {
        return [newGuestAccount, ...(guestAccounts || [])];
      });
    }
  });
}

export function useUpdateGuestAccountMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: ({ id, name, email, pricingVisibility }) =>
      axiosClient.put(`/account/guest-accounts/${id}`, {
        name,
        email,
        pricingVisibility
      }),
    onSuccess: (updatedGuestAccount) => {
      const queryKeyFilter = [QUERY_KEY_BASE];
      queryClient.setQueriesData({ queryKey: queryKeyFilter }, (guestAccounts) => {
        const updateGuestAccounts = guestAccounts.map((guestAccount) => {
          if (guestAccount.id === updatedGuestAccount.id) {
            return {
              ...guestAccount,
              name: updatedGuestAccount.name,
              pricingVisibility: updatedGuestAccount.pricingVisibility
            };
          } else {
            return guestAccount;
          }
        });

        return updateGuestAccounts;
      });
    }
  });
}

export function useAccountPriceMarkup(options) {
  return useMutation({
    ...(options || {}),
    mutationFn: () => axiosClient.get(`/account/markup`),
    onSuccess: (accountMarkupResponse) => accountMarkupResponse
  });
}

export function useUpdateAccountPriceMarkup(options) {
  return useMutation({
    ...(options || {}),
    mutationFn: (markupObject) => axiosClient.patch(`/account/markup`, markupObject),
    onSuccess: (accountMarkupResponse) => accountMarkupResponse
  });
}

<script setup>
import { ref, computed, defineExpose } from "vue";
import { useViewportSizes } from "@/composables";
import Logo from "@/assets/four-hands-wordmark.svg"; // TODO: how to inline SVGs?
import FhIcon from "@/components/FhIcon.vue";

const props = defineProps({
  backgroundImage: {
    type: String,
    required: true
  },
  hideHeaderOnMobile: {
    type: Boolean,
    default: false
  },
  alignContent: {
    type: String,
    default: "center",
    validator: (val) => ["center", "top"].includes(val)
  },
  /**
   * Since this is the full-screen component, the main nav is not visible; this may cause the user to feel trapped in certain contexts.
   * Setting this prop to `true` adds a small "back" arrow next to the Four Hands logo to help guide to user to use that link to escape the page if needed.
   */
  escapeHatch: {
    type: Boolean,
    default: false
  }
});

// Expose the active scroll container, which is different below and above the md breakpoint
const main = ref(null);
const inner = ref(null);
const { isSm } = useViewportSizes();
const scrollContainer = computed(() => (isSm.value ? main.value : inner.value));
defineExpose({ scrollContainer });
</script>

<template>
  <main ref="main" class="fixed inset-0 z-body-modal bg-white md:grid md:grid-cols-[2fr_3fr]" :class="{ 'overflow-y-auto': isSm }">
    <header
      class="relative z-10 h-48 bg-cover bg-center p-f4 md:h-auto"
      :class="{ 'hidden md:block': props.hideHeaderOnMobile }"
      :style="{ backgroundImage: `url(${props.backgroundImage})` }"
    >
      <a href="/" aria-label="Four Hands Home Page" class="group flex w-max gap-3 p-f1">
        <FhIcon v-if="props.escapeHatch" name="LeftChevron" class="hidden text-xl text-white transition-all group-hover:-translate-x-0.5 md:inline-block" />
        <img :src="Logo" alt="Four Hands" class="w-[150px] brightness-0 invert md:w-[180px]" />
      </a>
    </header>
    <section ref="inner" class="px-f4 pt-f8 pb-f10 md:px-f6" :class="{ 'overflow-y-auto': !isSm }">
      <div class="mx-auto flex min-h-full w-full max-w-3xl flex-col">
        <div class="md:flex md:flex-1" :class="{ 'md:items-center': props.alignContent === 'center' }">
          <div class="w-full">
            <slot name="default"></slot>
          </div>
        </div>
        <footer v-if="$slots.footer" class="mt-f8">
          <slot name="footer"></slot>
        </footer>
      </div>
    </section>
  </main>
</template>

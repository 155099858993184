/* Based off vuetify range slider https://next.vuetifyjs.com/en/components/range-sliders/ */
<script setup>
import { watch, ref, computed } from "vue";
import { VRangeSlider } from "vuetify/components";

const props = defineProps({
  min: {
    type: [String, Number],
    required: true,
    default: "0"
  },
  max: {
    type: [String, Number],
    required: true,
    default: "10"
  },
  step: {
    type: [String, Number],
    required: true,
    default: "1"
  },
  modelValue: {
    type: Array,
    required: true
  },
  selectedOptions: {
    type: Array,
    default: () => []
  },
  rangeType: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue", "update:sliderMoveEnd"]);
const range = ref(props.modelValue);

const selectedRangeValues = computed(() => props.selectedOptions);
const defaultRange = computed(() => props.modelValue);

const rangeSliderTextClass = computed(() => {
  return {
    "range-slider__text": true,
    "range-slider__quotes": props.rangeType == "Inches" ? true : false
  };
});

const updateValue = (val) => {
  emit("update:modelValue", val);
};
const onSliderMoveEnd = (value) => {
  emit("update:sliderMoveEnd", value);
};
watch(selectedRangeValues, (newVal) => {
  if (newVal.length === 0) {
    range.value = props.modelValue;
  }
});
// This is not ideal but working with in the constraints of the component
watch(defaultRange, (newVal) => {
  range.value = newVal;
});
</script>

<template>
  <div class="fh-multi-range-slider">
    <v-range-slider
      v-model="range"
      color="#000000"
      :min="props.min"
      :max="props.max"
      :step="props.step"
      :disabled="props.disabled"
      strict
      thumb-color="#fff"
      track-color="#000"
      track-fill-color="#000"
      elevation="0"
      @update:model-value="updateValue"
      @end="onSliderMoveEnd"
    />

    <div class="flex w-full justify-between">
      <span :class="rangeSliderTextClass" class="border-b border-neutral-40 p-2">{{ range[0] }}</span>
      <span :class="rangeSliderTextClass" class="border-b border-neutral-40 p-2">{{ range[1] }}</span>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.fh-multi-range-slider {
  &:deep(.v-slider-thumb__surface) {
    border: 1px solid #000;
  }
  &:deep(.v-slider-thumb__surface::before),
  &:deep(.v-slider-thumb__surface::after),
  &:deep(.v-slider-thumb__ripple) {
    display: none;
  }
}
.range-slider__text {
  display: flex;
}
.range-slider__quotes {
  &:after {
    content: '"';
  }
}
</style>

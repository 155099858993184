<script setup>
import { computed } from "vue";
import { SHIPPING_OPTIONS, FREIGHT_SERVICE_LEVEL } from "../constants/checkout";
import FhCard from "./FhCard.vue";
import FhShippingAddress from "./FhShippingAddress.vue";
import FhCheckoutShippingDetailsSummary from "./FhCheckoutShippingDetailsSummary.vue";

const props = defineProps({
  shippingOption: {
    type: String,
    default: null,
    validator: (val) => Object.values(SHIPPING_OPTIONS).includes(val)
  },
  shippingAddress: {
    type: Object,
    default: null
  },
  freightServiceLevel: {
    type: String,
    default: null,
    validator: (val) => Object.values(FREIGHT_SERVICE_LEVEL).includes(val)
  },
  carrierNotes: {
    type: String,
    default: null
  },
  carrierName: {
    type: String,
    default: null
  },
  carrierAccountNumber: {
    type: String,
    default: null
  },
  pickUpContactName: {
    type: String,
    default: null
  },
  pickUpContactPhone: {
    type: String,
    default: null
  },
  holdDate: {
    type: String,
    default: null
  }
});

const showShippingAddress = computed(() => props.shippingOption !== SHIPPING_OPTIONS.PICK_UP && props.shippingAddress);
</script>

<template>
  <div class="flex flex-col gap-f3 md:flex-row">
    <FhCard v-if="showShippingAddress" class="flex-1 overflow-hidden">
      <div class="mb-f2 text-f-base-xl">Delivery Address</div>
      <FhShippingAddress v-bind="props.shippingAddress" simple-display class="text-neutral-50" />
    </FhCard>
    <FhCard class="flex-1 overflow-hidden">
      <div class="mb-f2 text-f-base-xl">Delivery Information</div>
      <FhCheckoutShippingDetailsSummary v-bind="$props" class="text-neutral-50" />
    </FhCard>
  </div>
</template>

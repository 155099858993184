<script setup>
import { ref } from "vue";
import FhModal from "../FhModal.vue";
import FhButton from "../FhButton.vue";

const props = defineProps({
  modalData: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

const isOpen = ref(false);
const toggleModal = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <slot :toggle-modal="toggleModal">
    <!-- Button To Open/Close Injected Here -->
  </slot>
  <!-- PERSON ITEM MODAL -->
  <FhModal v-model:is-open="isOpen" :data="{ heading: `${props.modalData.firstName} ${props.modalData.lastName}`, subheading: props.modalData.role }">
    <template #body>
      <fh-wysiwyg v-if="modalData.bioCopy" :content="props.modalData.bioCopy" />
    </template>
    <template v-if="modalData.linkedInUrl" #footer>
      <FhButton
        variant="link"
        :href="modalData.linkedInUrl"
        :aria-label="`Link to ${modalData.firstName} ${modalData.lastName}'s' LinkedIn Profile`"
        target="_blank"
      >
        LinkedIn
      </FhButton>
    </template>
  </FhModal>
</template>

<script setup>
import { useViewportSizes } from "@/composables";

const props = defineProps({
  /**
   * Controls whether or not the summary slot is sticky to the bottom of the screen on mobile.
   */
  showInlineSummaryOnMobile: {
    type: Boolean,
    default: false
  }
});

const { isLg } = useViewportSizes();
</script>

<template>
  <div class="relative my-f15 lg:grid lg:grid-cols-[3fr_1fr] lg:items-start lg:gap-f15">
    <div>
      <slot></slot>
    </div>
    <div :class="{ 'sticky bottom-0 z-10 -mb-f3': !isLg && !props.showInlineSummaryOnMobile, 'sticky top-f4': isLg }">
      <div :class="{ 'relative left-1/2 w-screen -translate-x-1/2 border-t border-neutral-30 bg-white p-f3': !isLg && !props.showInlineSummaryOnMobile }">
        <slot name="summary"></slot>
      </div>
    </div>
  </div>
</template>

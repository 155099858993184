<script setup>
import { reactive, ref } from "vue";
import FhInput from "@/components/FhInput.vue";
import FhModal from "@/components/FhModal.vue";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";
import { useUpdateAccountPriceMarkup } from "@/api/accountApi";
import { useNotificationStore } from "@/stores/NotificationStore";

const props = defineProps({
  markupValue: {
    type: Number,
    default: 0
  }
});

const emits = defineEmits(["update"]);
const isSaveButtonDisabled = ref(false);
const formData = reactive({ markup: props.markupValue });
const modal = ref(null);
const notificationStore = useNotificationStore();
const { isLoading: updatingPriceModifer, mutate: updatePriceModiferMutation } = useUpdateAccountPriceMarkup();
function updateSentence() {
  const value = parseFloat(formData.markup);
  if (value !== null && value < 0) {
    isSaveButtonDisabled.value = true;
    return "Please enter a value of 0 or greater.";
  } else if (!isNaN(value) && value > 0 && value <= 999.99) {
    const roundedValue = parseFloat(value.toFixed(2));
    formData.markup = roundedValue;
    isSaveButtonDisabled.value = false;
    return `After the ${value}% markup is applied, a $1000 product will become $${(1000 + (value / 100) * 1000).toFixed(
      2
    )}. To remove the markup, enter 0 or leave the field blank.`;
  } else if (value > 999.99) {
    isSaveButtonDisabled.value = true;
    return "Maximum value is 999.99%";
  } else {
    isSaveButtonDisabled.value = false;
    return "Enter a value greater than 0% to apply a markup.";
  }
}

const triggerUpdatePriceModifer = () => {
  formData.markup = formData.markup == "" ? 0 : formData.markup;
  updatePriceModiferMutation(formData, {
    onSuccess: (updatePriceModiferResponse) => {
      notificationStore.notifySuccess("Your price markup has been updated.");
      emits("update", updatePriceModiferResponse.markup);
      modal.value.close();
    },
    onError: () => {
      notificationStore.notifySuccess("Sorry, something went wrong and we could not update your price markup.");
    }
  });
};

const reset = () => {
  formData.markup = props.markupValue;
};
</script>

<template>
  <FhModal ref="modal" :data="{ heading: 'Update Markup' }" @close="reset">
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <form>
        <fieldset class="mb-f8">
          <div class="flex flex-col gap-f3">
            <div class="flex w-1/2">
              <FhInput v-model="formData.markup" type="number" step="0.01" mi="0" max="999.99" @change="updateSentence" />
              <span><FhIcon name="IconPercentage" /></span>
            </div>

            <div v-html="updateSentence()"></div>
            <div>
              Do you typically calculate markup based on a multiplier? Below are some examples of how some of the most common multipliers translate to
              percentages:<br /><br />
              1.8x = 80%<br />
              2x = 100%<br />
              2.1x = 110%<br />
              2.5x = 150%<br />
              3x = 200%<br />
              3.2x = 220%<br /><br />
              Please note: Markup updates will be reflected across all Guest Accounts and Shopping Lists created with the “Price Markup” option.
            </div>
          </div>
        </fieldset>
      </form>
    </template>
    <template #footer="{ close }">
      <FhButton
        type="submit"
        color="primary"
        :is-loading="updatingPriceModifer"
        :disabled="updatingPriceModifer || isSaveButtonDisabled"
        @click="triggerUpdatePriceModifer"
        >{{ props.editMode ? "Update" : "Save" }}</FhButton
      >
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
</template>

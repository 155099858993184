<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 5H5V7H2V5Z" fill="currentColor" />
    <path d="M22 5H8V7H22V5Z" fill="currentColor" />
    <path d="M22 11H8V13H22V11Z" fill="currentColor" />
    <path d="M5 11H2V13H5V11Z" fill="currentColor" />
    <path d="M2 17H5V19H2V17Z" fill="currentColor" />
    <path d="M22 17H8V19H22V17Z" fill="currentColor" />
  </svg>
</template>

<script setup>
import { ref, reactive, computed, inject } from "vue";
import { SUPPORT_PHONE_NUMBER } from "@/constants/strings";
import { useCreateShippingAddressMutation } from "@/api/shippingAddressApi";
import { useNotificationStore } from "@/stores/NotificationStore.js";
import COUNTRIES from "@/constants/countries";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, maxLength } from "@vuelidate/validators";
import { omit } from "lodash";
import FhButton from "@/components/FhButton.vue";
import FhModal from "@/components/FhModal.vue";
import FhInput from "@/components/FhInput.vue";
import FhInputPhone from "@/components/FhInputPhone.vue";
import FhSelect from "@/components/FhSelect.vue";
import FhCheckbox from "@/components/FhCheckbox.vue";

const props = defineProps({
  allowSingleUse: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["create"]);

const initialState = {
  country: "US",
  addressee: null,
  line1: null,
  line2: null,
  city: null,
  state: null,
  postalCode: null,
  phone: null,
  saveForLater: false
};

const formData = reactive({ ...initialState });

const subheading = inject("addressFormSubhead");

const ALLOWED_SHIP_TO_COUNTRIES = ["US", "CA"];
const countryOptions = Object.keys(COUNTRIES)
  .filter((key) => ALLOWED_SHIP_TO_COUNTRIES.includes(key))
  .map((key) => ({ value: key, text: COUNTRIES[key].name }));

const country = computed(() => COUNTRIES[formData.country]);
const stateLabel = computed(() => country.value.stateName);
const stateOptions = computed(() => Object.keys(country.value.states).map((key) => ({ value: key, text: country.value.states[key] })));
const postalCodeLabel = computed(() => country.value.postalCodeName);
const postalCodeRegex = computed(() => country.value.postalCodeRegex);

const vuelidate = useVuelidate(
  {
    addressee: {
      required: helpers.withMessage("Please enter a business name.", required),
      maxLength: helpers.withMessage("Please enter no more than 30 characters.", maxLength(30))
    },
    line1: {
      required: helpers.withMessage("Please enter an address.", required),
      maxLength: helpers.withMessage("Please enter no more than 40 characters.", maxLength(40))
    },
    line2: { maxLength: helpers.withMessage("Please enter no more than 40 characters.", maxLength(40)) },

    city: {
      required: helpers.withMessage("Please enter a city.", required),
      maxLength: helpers.withMessage("Please enter no more than 20 characters.", maxLength(20))
    },
    state: { required: helpers.withMessage(() => `Please select a ${stateLabel.value.toLowerCase()}.`, required) },
    postalCode: {
      required: helpers.withMessage(() => `Please enter a ${postalCodeLabel.value.toLowerCase()}.`, required),
      maxLength: helpers.withMessage("Please enter no more than 10 characters.", maxLength(10)),
      validPostalCode: helpers.withMessage(
        () => `Please enter a valid ${postalCodeLabel.value.toLowerCase()}.`,
        (value) => !helpers.req(value) || postalCodeRegex.value.test(value)
      )
    },
    phone: {
      required: helpers.withMessage("Please enter a phone number.", required),
      validPhone: helpers.withMessage("Please enter a valid phone number.", helpers.regex(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/))
    }
  },
  formData
);

const modal = ref(null);

const notificationStore = useNotificationStore();

const createShippingAddressMutation = useCreateShippingAddressMutation();

const isAdding = computed(() => createShippingAddressMutation.isLoading.value);

async function save() {
  if (await vuelidate.value.$validate()) {
    let data = { ...omit(formData, "saveForLater"), isSingleUse: props.allowSingleUse && !formData.saveForLater };
    createShippingAddressMutation.mutate(data, { onSuccess: handleSaveSuccess, onError: handleSaveError });
  }
}

function handleSaveSuccess(newAddress) {
  modal.value.close();
  notificationStore.notifySuccess("Address added.");
  emit("create", newAddress);
}

function handleSaveError() {
  notificationStore.notifyError("Sorry, something went wrong and we could not add this address. Please try again.");
}

function reset() {
  Object.assign(formData, initialState);
  vuelidate.value.$reset();
}
</script>

<template>
  <FhModal
    ref="modal"
    :data="{
      heading: 'Add Address',
      subheading
    }"
    @close="reset"
  >
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <form id="addressForm" class="flex flex-col gap-f3" @submit.prevent="save">
        <FhSelect v-model="formData.country" label="Country" :options="countryOptions">
          <template #note>
            <span>
              For addresses outside US and Canada, please call us at
              <span class="whitespace-nowrap">{{ SUPPORT_PHONE_NUMBER }}.</span>
            </span>
          </template>
        </FhSelect>
        <FhInput v-model="formData.addressee" label="Your Company Name" required :vuelidate="vuelidate.addressee" />
        <FhInput
          v-model="formData.line1"
          label="Address Line 1"
          required
          :vuelidate="vuelidate.line1"
          note="If using a third-party receiving warehouse, please include its name here."
        />
        <FhInput v-model="formData.line2" label="Address Line 2" :vuelidate="vuelidate.line2" />
        <FhInput v-model="formData.city" label="City" required :vuelidate="vuelidate.city" />
        <FhSelect v-model="formData.state" :options="stateOptions" :label="stateLabel" required :vuelidate="vuelidate.state" />
        <FhInput v-model="formData.postalCode" :label="postalCodeLabel" required :vuelidate="vuelidate.postalCode" />
        <FhInputPhone v-model="formData.phone" label="Phone Number" required :vuelidate="vuelidate.phone" />
        <FhCheckbox
          v-if="props.allowSingleUse"
          v-model="formData.saveForLater"
          label="Save this address to my account for future use."
          size="lg"
          class="my-f4"
        />
      </form>
    </template>
    <template #footer="{ close }">
      <FhButton type="submit" form="addressForm" color="primary" :disabled="isAdding">Save</FhButton>
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
</template>

<style scoped></style>

<script setup>
import { computed } from "vue";
import FhIcon from "@/components/FhIcon.vue";
import { useProductFiltersStore } from "@/stores/ProductFiltersStore";

const props = defineProps({
  filters: {
    type: Array,
    required: true
  },
  filterModel: {
    type: Object,
    required: true,
    default: () => ({})
  },
  facetCounts: {
    type: Object,
    default: () => ({})
  }
});

const emits = defineEmits(["update:modelValue"]);

const model = computed({
  get: () => props.filterModel,
  set: (val) => emits("update:modelValue", val)
});

const { filterNameMap } = useProductFiltersStore();

const selectedOptionsList = computed(() => {
  const rangeFiltersKey = props.filters.filter((filter) => filter.type == "range").map((filter) => filter.key);

  return Object.entries(props.filterModel)
    .map(([key, value]) => {
      if (value.length === 0) return value;
      // Lets discuss this one, I'm treating range as one value becuase it got kind of funky if we treaded them as two seperate values
      // Checking if range key = Width or Height in order to show the inches indicator
      if (rangeFiltersKey.includes(key) && (key == "width" || key == "height")) {
        return [`${key}: ${value[0]}" - ${value[1]}"`];
      } else if (rangeFiltersKey.includes(key)) {
        return [`${key}: ${value[0]} - ${value[1]}`];
      } else return value;
    })
    .flat();
});

const removeFilter = (event, filterValue) => {
  event?.preventDefault();

  // Check if range value
  // Please refactor me this is not really scalable
  // TODO this will be better as a map with type assoicated with it
  if (/[\w]+:\s/g.test(filterValue)) {
    const possibleRangeKey = filterValue.split(":")[0];
    const rangeKey = props.filters.find((filter) => filter.key == possibleRangeKey.toLowerCase().trim());
    if (rangeKey && rangeKey.type == "range") {
      model.value = { ...model.value, [rangeKey.key]: [] };
      return;
    }
  }
  const newModel = {};
  Object.keys(props.filterModel).forEach((filter) => {
    newModel[filter] = props.filterModel[filter].filter((modelValue) => modelValue != filterValue);
  });
  model.value = { ...model.value, ...newModel };
};
</script>

<template>
  <section v-if="selectedOptionsList.length > 0" data-product-chips class="filter-list overflow-x-auto border-b border-b-neutral-30">
    <ul class="flex flex-nowrap justify-start gap-4 md:flex-wrap">
      <template v-for="selectedOption in selectedOptionsList" :key="selectedOption">
        <li v-if="filterNameMap.get(selectedOption) || selectedOption.includes(':')">
          <button
            class="flex cursor-pointer items-center gap-1 text-f-xs-sm font-bold text-neutral-50 md:font-bold"
            @click="removeFilter($event, selectedOption)"
          >
            <span class="whitespace-nowrap capitalize">{{ filterNameMap.get(selectedOption) || selectedOption }}</span>
            <span><FhIcon name="XIcon" class="!text-lg" /></span>
          </button>
        </li>
      </template>
      <slot></slot>
    </ul>
  </section>
</template>

<style scoped></style>

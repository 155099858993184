import axiosClient from "./axiosClient";
import { useQueryClient, useQuery, useMutation } from "@tanstack/vue-query";

const QUERY_KEY = ["cart"];

function selectCartData(cart) {
  return {
    ...cart,
    totalItemsCount: cart.items.reduce((count, item) => count + item.quantity, 0)
  };
}

export function useCartQuery(options) {
  return useQuery({
    ...(options || {}),
    queryKey: QUERY_KEY,
    queryFn: () => axiosClient.get("/cart"),
    select: selectCartData,
    refetchOnWindowFocus: false
  });
}

export function useAddCartItemsMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (items) => axiosClient.post("/cart/items", items),
    select: selectCartData,
    onSuccess: (cart) => queryClient.setQueryData(QUERY_KEY, cart)
  });
}

export function useUpdateCartItemMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.put(`/cart/items/${encodeURIComponent(item.id)}`, item),
    select: selectCartData,
    onSuccess: (cart) => queryClient.setQueryData(QUERY_KEY, cart)
  });
}

export function useRemoveCartItemMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.delete(`/cart/items/${encodeURIComponent(item.id)}`),
    select: selectCartData,
    onSuccess: (cart) => queryClient.setQueryData(QUERY_KEY, cart)
  });
}

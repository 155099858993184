<script setup>
import FhSection from "../includes/FhSection.vue";
import { getDataRefs } from "../util/vue";

import FhAccordionItem from "./FHAccordion/FhAccordionItem.vue";
import FhAccordionItems from "./FHAccordion/FhAccordionItems.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  },
  isNavItem: {
    type: Boolean,
    default: false
  },
  viewAllLink: {
    default: () => ({ url: "", title: "" }),
    validator: (value) => {
      return value && typeof value.url === "string" && typeof value.title === "string";
    }
  }
});

const { version, title, items } = getDataRefs(props.data);
</script>

<template>
  <a id="accordion-top" />
  <FhSection aria-label="Accordion Control Group" :base="style.base">
    <template v-if="version === 'single'">
      <fh-accordion-item v-for="item in items" :key="item.id" :item="item" :is-nav-item="props.isNavItem" :view-all-link="props.viewAllLink" />
    </template>
    <template v-else-if="version === 'grouped'">
      <fh-accordion-items
        :items="items"
        :title="title"
        :class="[
          props.style.base?.background !== 'limestone' && props.style.base?.background !== null && props.style.base?.background !== '' ? 'text-white' : ''
        ]"
      >
      </fh-accordion-items>
    </template>
  </FhSection>
</template>

<style lang="pcss" scoped>
::v-deep .text-white * {
  color: white !important;
}
/* Apply filter invert(100%) conditionally */
.text-white :deep(.ChevronDown) {
  filter: invert(100%) brightness(10000%) contrast(1000%);
}
</style>

<script setup>
import { ref, nextTick } from "vue";
import { getDataRefs } from "../util/vue";
import { usePaginatedList } from "../composables";
import scrollToTopOf from "../util/scrollToTopOf";
import FhSection from "../includes/FhSection.vue";
import FhPaginationNav from "./FhPaginationNav.vue";
import FhPressItem from "./FhPressListing/FhPressItem.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { items } = getDataRefs(props.data);

const PER_PAGE = 8;
const { page, totalPages, sublist: currentPage } = usePaginatedList(items, PER_PAGE);

const rootElem = ref(null);
</script>

<template>
  <div ref="rootElem">
    <FhSection aria-label="Press Listing" :base="style.base">
      <div class="mx-auto mb-f6 max-w-[1530px] border-b border-solid border-neutral-30">
        <FhPressItem v-for="item in currentPage" :key="item.id" :item="item"></FhPressItem>
      </div>
      <div v-if="totalPages > 1" class="mb-f12.5 flex items-center justify-center">
        <FhPaginationNav v-model:page="page" :total-items="items.length" :per-page="8" @page-change="nextTick(() => scrollToTopOf(rootElem))" />
      </div>
    </FhSection>
  </div>
</template>

<style lang="pcss" scoped></style>

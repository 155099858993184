const _map_category_ga = (product, cateogryList) => {
  const productObj = product;
  cateogryList.forEach((category, index) => {
    const key = index === 0 ? "item_category" : `item_category${index + 1}`;
    productObj[key] = category?.name || category;
  });
  return productObj;
};

const _mapProductDataToGA = (products = [], cateogryList) => {
  return products.map((product, index) => {
    const productObject = {
      item_id: product?.sku,
      item_name: product?.name,
      item_list_name: product?.list,
      index: product?.index ?? index,
      item_variant: product?.skuName,
      affiliation: product?.collaboration || null,
      dimension5: product?.inStock ? "In Stock" : "Out of Stock",
      status: product?.status
    };

    if (cateogryList.length > 0) {
      return _map_category_ga(productObject, cateogryList);
    }

    return productObject;
  });
};

const _mapProductDetailsToGA = (product = null, cateogryList = []) => {
  const productObject = {
    item_id: product?.skuNumber,
    item_name: product?.name || product?.productName,
    item_list_name: product?.list,
    item_variant: product?.skuName || product?.skuVariantName,
    price: product?.price,
    quantity: product?.quantity || 1,
    affiliation: product?.collaboration || null,
    dimension5: product?.inStock ? "In Stock" : "Out of Stock",
    status: product?.status,
    has_video: product?.has_video || product?.hasVideo || false
  };

  if (cateogryList.length > 0) {
    return _map_category_ga(productObject, cateogryList);
  }

  return productObject;
};

const send_ecommerce_event = (eventName, ecommObj = null) => {
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: eventName,
    ecommerce: ecommObj
  });
};

export const event_viewListItem = (productData = [], categoryList = []) => {
  send_ecommerce_event("view_item_list", {
    items: _mapProductDataToGA(productData, categoryList)
  });
};

export const event_selectItem = (productData = [], categoryList = []) => {
  send_ecommerce_event("select_item", {
    items: _mapProductDataToGA(productData, categoryList)
  });
};

export const event_viewItem = (productData = null, categoryList = []) => {
  if (!productData) return false;
  send_ecommerce_event("view_item", {
    currency: "USD",
    value: productData?.price,
    items: [_mapProductDetailsToGA(productData, categoryList)]
  });
};

export const event_addItemToCart = (productData = null, categoryList = []) => {
  send_ecommerce_event("add_to_cart", {
    currency: "USD",
    value: productData?.price * (productData?.quantity || 1),
    items: [_mapProductDetailsToGA(productData, categoryList)]
  });
};

export const event_beginCheckout = (productData) => {
  send_ecommerce_event("begin_checkout", {
    currency: "USD",
    value: productData?.totalPrice,
    items: productData.products.map((product) =>
      _mapProductDetailsToGA(
        { ...product.sku, quantity: product.quantity, price: product.unitPrice, collaboration: product.sku?.collaboration },
        product?.category?.split("/")
      )
    )
  });
};

export const event_Purchase = (purchaseData) => {
  send_ecommerce_event("purchase", {
    transaction_id: purchaseData?.orderNumber,
    currency: "USD",
    value: purchaseData?.totalPrice,
    tax: purchaseData?.tax,
    shipping: purchaseData?.shippingPrice,
    items: purchaseData.products.map((product) =>
      _mapProductDetailsToGA(
        { ...product.sku, quantity: product.quantity, price: product.unitPrice, collaboration: product.sku?.collaboration },
        product?.category?.split("/")
      )
    )
  });
};

export const event_Search = (searchTerm) => {
  send_ecommerce_event("search", {
    search_term: searchTerm
  });
};

export const event_ShippingInfo = (checkoutTotal, productData = [], shipping_method) => {
  send_ecommerce_event("add_shipping_info", {
    currency: "USD",
    value: checkoutTotal,
    shipping_tier: shipping_method,
    items: productData.map((product) =>
      _mapProductDetailsToGA({ ...product.sku, quantity: product.quantity, price: product.unitPrice }, product?.category?.split("/"))
    )
  });
};

export const event_PaymentInfo = (checkoutTotal, productData = [], payment_method) => {
  send_ecommerce_event("add_payment_info", {
    currency: "USD",
    value: checkoutTotal,
    payment_type: payment_method,
    items: productData.map((product) =>
      _mapProductDetailsToGA({ ...product.sku, quantity: product.quantity, price: product.unitPrice }, product?.category?.split("/"))
    )
  });
};

export const event_AddToWishList = (product) => {
  const productObj = {
    item_id: product?.skuNumber,
    item_name: product?.name || product?.productName,
    item_list_id: product?.listId,
    item_list_name: product?.title,
    item_variant: product?.skuName || product?.skuVariantName,
    price: product?.price,
    quantity: product?.quantity || 1,
    dimension5: product?.inStock ? "In Stock" : "Out of Stock"
  };
  send_ecommerce_event("add_to_wishlist", {
    currency: "USD",
    value: product?.price,
    items: [_map_category_ga(productObj, product.categoryList)]
  });
};

export const send_user_dimension = (userData) => {
  window?.dataLayer?.push(userData);
};

export const sent_custom_event = (eventName, data = {}) => {
  window?.dataLayer?.push({
    event: eventName,
    ...data
  });
};

<script setup>
import { getDataRefs } from "../util/vue";
import FhSection from "../includes/FhSection.vue";
import FhButton from "./FhButton.vue";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { layout, title, description, ctaLink, ctaTarget, ctaText, ctaAltText } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    aria-label="Content Block"
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div
      class="content-block__container"
      :class="[
        ctaLink ? 'pb-28 pt-f15' : 'py-f15',
        layout === 'titleRight' ? 'md:flex-row-reverse' : '',
        'relative flex flex-col md:mx-auto md:flex-row md:justify-between md:gap-10 lg:px-f10'
      ]"
    >
      <div class="md:max-w-[600px] md:basis-1/2">
        <h2 class="mb-8 font-serif text-f-4xl-8xl md:mb-10">{{ title }}</h2>
        <FhButton
          v-if="ctaLink"
          :href="ctaLink"
          :target="ctaTarget"
          :aria-label="ctaAltText"
          class="absolute bottom-0 left-0 right-0 mb-16 md:relative md:top-0 md:left-auto md:right-0 md:bottom-auto md:mb-0"
        >
          {{ ctaText }}
        </FhButton>
      </div>
      <FhWysiwyg>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div :class="[ctaLink ? 'mb-10' : '', 'text-f-base-2xl font-normal md:max-w-[600px] md:basis-1/2']" v-html="description"></div>
      </FhWysiwyg>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.widget--width-full {
  .content-block__container {
    @media (max-width: 1199px) {
      @apply px-f6;
    }
  }
}
</style>

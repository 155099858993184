<script setup>
import FhSection from "../includes/FhSection.vue";
import FhWysiwyg from "../includes/FhWysiwyg.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { content, gaTagging } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    :base="style.base"
    :data-ga="gaTagging"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <FhWysiwyg :content="content" :spacing="true"></FhWysiwyg>
  </FhSection>
</template>

<style scoped lang="pcss"></style>

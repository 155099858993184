<script setup>
import { useViewportSizes } from "../composables";
import FhSection from "../includes/FhSection.vue";
import imageSm from "../assets/storybook/company-timeline/fh-company-timeline--mobile.png";
import imageMd from "../assets/storybook/company-timeline/fh-company-timeline--tablet.png";
import imageLg from "../assets/storybook/company-timeline/fh-company-timeline--desktop.png";

defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

let { isSm, isMd } = useViewportSizes();
</script>

<template>
  <FhSection aria-label="About Us Hero" :base="style.base">
    <div class="flex flex-col-reverse lg:flex-row">
      <div class="bg-limestone pt-f8 pb-f10 lg:basis-7/12 lg:py-f25">
        <div class="mx-4 flex flex-col md:mx-auto md:max-w-xl">
          <h2 class="mb-f8 font-serif text-f-2xl-7xl">Our Most Recent Milestones</h2>
          <div
            class="before: relative flex items-start justify-start before:absolute before:top-3 before:left-px before:z-0 before:h-full before:w-px before:bg-neutral-opacity-10 before:content-['']"
          >
            <div
              class="relative pl-f4 text-f-base-xl before:absolute before:top-2/4 before:left-0 before:z-10 before:h-[3px] before:w-[3px] before:-translate-y-2/4 before:rounded-full before:bg-neutral-70 before:content-['']"
            >
              2024
            </div>
            <div class="ml-f6 flex-initial pb-f6 text-f-base-xl">New and expanded Four Hands Las Vegas Showroom opened January 2024.</div>
          </div>

          <div
            class="before: relative flex items-start justify-start before:absolute before:top-3 before:left-px before:z-0 before:h-full before:w-px before:bg-neutral-opacity-10 before:content-['']"
          >
            <div
              class="relative pl-f4 text-f-base-xl before:absolute before:top-2/4 before:left-0 before:z-10 before:h-[3px] before:w-[3px] before:-translate-y-2/4 before:rounded-full before:bg-neutral-70 before:content-['']"
            >
              2023
            </div>
            <div class="ml-f6 flex-initial pb-f6 text-f-base-xl">Launched new and evolved design partnerships including Thomas Bina and Van Thiel.</div>
          </div>

          <div
            class="before: relative flex items-start justify-start before:absolute before:top-3 before:left-px before:z-0 before:h-full before:w-px before:bg-neutral-opacity-10 before:content-['']"
          >
            <div
              class="relative pl-f4 text-f-base-xl before:absolute before:top-2/4 before:left-0 before:z-10 before:h-[3px] before:w-[3px] before:-translate-y-2/4 before:rounded-full before:bg-neutral-70 before:content-['']"
            >
              2023
            </div>
            <div class="ml-f6 flex-initial pb-f6 text-f-base-xl">Won Inc. 5000 fastest-growing companies for the 14th time.</div>
          </div>

          <div
            class="before: relative flex items-start justify-start before:absolute before:top-3 before:left-px before:z-0 before:h-full before:w-px before:bg-neutral-opacity-10 before:content-['']"
          >
            <div
              class="relative pl-f4 text-f-base-xl before:absolute before:top-2/4 before:left-0 before:z-10 before:h-[3px] before:w-[3px] before:-translate-y-2/4 before:rounded-full before:bg-neutral-70 before:content-['']"
            >
              2023
            </div>
            <div class="ml-f6 flex-initial pb-f6 text-f-base-xl">Expanded Four Hands Art Studio to 54,000 sq. ft. location.</div>
          </div>

          <div
            class="before: relative flex items-start justify-start before:absolute before:top-3 before:left-px before:z-0 before:h-full before:w-px before:bg-neutral-opacity-10 before:content-['']"
          >
            <div
              class="relative pl-f4 text-f-base-xl before:absolute before:top-2/4 before:left-0 before:z-10 before:h-[3px] before:w-[3px] before:-translate-y-2/4 before:rounded-full before:bg-neutral-70 before:content-['']"
            >
              2023
            </div>
            <div class="ml-f6 flex-initial pb-f6 text-f-base-xl">
              Expanded photo department to include a dedicated CGI team and launched a 12,000 sq. ft. photography studio &mdash; all in-house.
            </div>
          </div>

          <div
            class="before: relative flex items-start justify-start before:absolute before:top-3 before:left-px before:z-0 before:h-full before:w-px before:bg-neutral-opacity-10 before:content-['']"
          >
            <div
              class="relative pl-f4 text-f-base-xl before:absolute before:top-2/4 before:left-0 before:z-10 before:h-[3px] before:w-[3px] before:-translate-y-2/4 before:rounded-full before:bg-neutral-70 before:content-['']"
            >
              2022
            </div>
            <div class="ml-f6 flex-initial pb-f6 text-f-base-xl">
              Introduced new product offerings including Games, Crafted in the USA upholstery, and revolutionary fabrics such as artisan-made Belgian
              Linen&trade; and FIQA.
            </div>
          </div>

          <div class="relative flex items-start justify-start">
            <div
              class="relative pl-f4 text-f-base-xl before:absolute before:top-2/4 before:left-0 before:z-10 before:h-[3px] before:w-[3px] before:-translate-y-2/4 before:rounded-full before:bg-neutral-70 before:content-['']"
            >
              2021
            </div>
            <div class="ml-f6 flex-initial pb-f6 text-f-base-xl">
              Global headquarters and flagship showroom in Austin undergo massive design renovation and continue ongoing expansion.
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative z-10 w-full after:absolute after:bottom-0 after:z-[-1] after:h-2/6 after:w-full after:bg-limestone after:content-[''] lg:basis-5/12 lg:after:hidden"
      >
        <img v-if="isSm" :src="imageSm" class="w-full" alt="Company Timeline Image" />
        <img v-else-if="isMd" :src="imageMd" class="mx-auto max-w-xl" alt="Company Timeline Image" />
        <img v-else :src="imageLg" class="h-full w-full object-cover" alt="Company Timeline Image" />
      </div>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped></style>

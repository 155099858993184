<script setup></script>

<template>
  <div class="transition-fade">
    <Transition name="fade" mode="out-in">
      <slot></slot>
    </Transition>
  </div>
</template>

<style lang="pcss" scoped>
.transition-fade {
  display: contents;
  &:deep(.fade-enter-active),
  &:deep(.fade-leave-active) {
    transition: opacity 0.35s;
  }
  &:deep(.fade-enter-from),
  &:deep(.fade-leave-to) {
    opacity: 0
  }
}
</style>

<script setup>
import { ref, computed } from "vue";
import FhModal from "../FhModal.vue";

const props = defineProps({
  modalData: {
    type: Object,
    required: true
  }
});

const modal = ref(null);
const isOpen = ref(false);
const toggleModal = () => {
  isOpen.value = !isOpen.value;
};

const videoURL = computed(() => {
  if (props.modalData.embedLink.includes("?")) return `${props.modalData.embedLink}&autoplay=1`;
  return `${props.modalData.embedLink}?autoplay=1`;
});
</script>

<template>
  <slot :toggle-modal="toggleModal">
    <!-- Button To Open/Close Injected Here -->
  </slot>
  <FhModal ref="modal" v-model:is-open="isOpen" wide>
    <template #body>
      <div class="relative aspect-video w-full after:block after:pb-[56.25%] after:content-['']">
        <iframe
          :src="videoURL"
          class="absolute inset-0 h-full w-full"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </template>
    <template #footer>
      <p>{{ modalData.caption }}</p>
    </template>
  </FhModal>
</template>

<style lang="pcss" scoped></style>

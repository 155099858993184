<script setup>
import { computed, ref, useSlots, watch } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import FhIcon from "@/components/FhIcon.vue";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(["update:isOpen", "open", "close"]);

const _isOpen = ref(props.isOpen);

watch(props.isOpen, (val) => {
  _isOpen.value = val;
  emit("update:isOpen", val);
});

watch(_isOpen, (val) => {
  emit("update:isOpen", val);
  emit(val ? "open" : "close");
});

const slots = useSlots();
const bodySlotName = computed(() => (slots.body ? "body" : "default"));
const hasFooter = computed(() => props.data.ctaText || slots.footer);

function open() {
  _isOpen.value = true;
}

function close() {
  _isOpen.value = false;
}

defineExpose({ open, close });
</script>

<template>
  <slot name="activator" :open="open"></slot>
  <TransitionRoot :show="_isOpen" as="template">
    <Dialog class="relative z-10" @close="close">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-lg">
                <div class="shadow-panel flex h-full flex-col overflow-y-scroll bg-white">
                  <div class="px-4 sm:px-6 py-3 header-panel">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-base leading-6 text-gray-900 w-[90%] text-center font-bold">
                        <slot name="title">
                          <h1>{{ data.title }}</h1>
                        </slot>
                      </DialogTitle>
                      <div class="ml-3 flex items-center">
                        <button
                          type="button"
                          class="border border-neutral-40 relative rounded-full p-4 leading-none bg-white text-gray-400 hover:border-neutral-50 focus:outline-none"
                          @click="close"
                        >
                          <FhIcon name="XIcon" class="text-xl" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="panel-content-container overflow-auto">
                    <div class="body-panel relative px-4 sm:px-6">
                      <slot :name="bodySlotName">
                        <FhWysiwyg v-if="data.body" :content="data.body" spacing />
                      </slot>
                    </div>
                    <div v-if="hasFooter" class="footer-panel relative mt-4 px-4 sm:px-6">
                      <slot name="footer" :close="close"> </slot>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style lang="pcss">
.shadow-panel {
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
}
</style>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.51367 9.70718L6.98681 8.29297L12.5002 13.5859L18.0137 8.29297L19.4868 9.70718L12.5002 16.4143L5.51367 9.70718Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup>
import FhSection from "../includes/FhSection.vue";
import FhButton from "./FhButton.vue";
import FhContactSubscriptionFormModal from "./FhContactSubscriptionFormModal.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  }
});
</script>

<template>
  <FhSection aria-label="Newsletter Signup" :base="props.style.base" class="border-b border-solid border-neutral-30">
    <div class="flex flex-col items-center justify-center">
      <div class="mb-f6 text-center">
        <h2 class="mb-f4 font-serif text-f-4xl-8xl">Stay in Touch</h2>
        <p class="max-w-[700px] text-f-sm-xl">Subscribe to our email list and be the first to hear about new collections, special promotions and more.</p>
      </div>

      <div class="flex flex-col md:flex-row md:justify-center">
        <FhContactSubscriptionFormModal class="col-span-full md:col-span-5">
          <template #activator="{ open }"> <FhButton color="secondary" size="small" class="md:justify-center" @click="open">Sign Up</FhButton></template>
        </FhContactSubscriptionFormModal>
      </div>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped></style>

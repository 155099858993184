<script setup>
const props = defineProps({
  heading: {
    type: String,
    default: null
  },
  subheading: {
    type: String,
    default: null
  },
  tag: {
    type: String,
    default: "h1",
    validator: (val) => ["h1", "h2", "h3", "h4", "h5", "h6", "div"].includes(val)
  }
});
</script>

<template>
  <div class="flex flex-col gap-f1" :class="{ 'mb-f6': !$attrs.class?.match(/mb\-/) }">
    <component :is="tag" class="font-serif text-f-2xl-5xl">
      <slot name="default">{{ props.heading }}</slot>
    </component>
    <div v-if="props.subheading || $slots.subheading" class="max-w-prose">
      <slot name="subheading">{{ props.subheading }}</slot>
    </div>
  </div>
</template>

<template>
  <component :is="iconComponent" role="img" class="icon fill-current inline-block h-[1em] w-[1em] flex-none" />
</template>

<script>
///Arrows
import LeftArrow from "@/components/icons/IconLeftArrow.vue";
import RightArrow from "@/components/icons/IconRightArrow.vue";
import UpArrow from "@/components/icons/IconUpArrow.vue";
import DownArrow from "@/components/icons/IconDownArrow.vue";
import DownLeftArrow from "@/components/icons/IconDownLeftArrow.vue";
import UpLeftArrow from "@/components/icons/IconUpLeftArrow.vue";
import UpRightArrow from "@/components/icons/IconUpRightArrow.vue";
import DownRightArrow from "@/components/icons/IconDownRightArrow.vue";
import DownChevron from "@/components/icons/IconDownChevron.vue";
import UpChevron from "@/components/icons/IconUpChevron.vue";
import LeftChevron from "@/components/icons/IconLeftChevron.vue";
import RightChevron from "@/components/icons/IconRightChevron.vue";
import CornerDownLeft from "@/components/icons/IconCornerDownLeft.vue";
import CornerDownRight from "@/components/icons/IconCornerDownRight.vue";
import CornerLeftDown from "@/components/icons/IconCornerLeftDown.vue";
import CornerLeftUp from "@/components/icons/IconCornerLeftUp.vue";
import CornerRightDown from "@/components/icons/IconCornerRightDown.vue";
import CornerRightUp from "@/components/icons/IconCornerRightUp.vue";
import CornerUpLeft from "@/components/icons/IconCornerUpLeft.vue";
import CornerUpRight from "@/components/icons/IconCornerUpRight.vue";
import Play from "@/components/icons/IconPlay.vue";

//Shopping
import ShoppingBag from "@/components/icons/IconShoppingBag.vue";
import ShoppingCart from "@/components/icons/IconShoppingCart.vue";
import Package from "@/components/icons/IconPackage.vue";
import Image from "@/components/icons/IconImage.vue";
import SolidHeart from "@/components/icons/IconSolidHeart.vue";
import OutlineHeart from "@/components/icons/IconOutlineHeart.vue";
import HeartPlus from "@/components/icons/IconHeartPlus.vue";
import Shipping from "@/components/icons/IconShipping.vue";
import Lock from "@/components/icons/IconLock.vue";

//Utility
import XIcon from "@/components/icons/XIcon.vue";
import Check from "@/components/icons/IconCheck.vue";
import Search from "@/components/icons/IconSearch.vue";
import Browse from "@/components/icons/IconBrowse.vue";
import LightningBolt from "@/components/icons/IconLightningBolt.vue";
import AlertCircle from "@/components/icons/IconAlertCircle.vue";
import InformationCircle from "@/components/icons/IconInformationCircle.vue";
import AlertTriangle from "@/components/icons/IconAlertTriangle.vue";
import User from "@/components/icons/IconUser.vue";
import Menu from "@/components/icons/IconMenu.vue";
import List from "@/components/icons/IconList.vue";
import Gallery from "@/components/icons/IconGallery.vue";
import Grid from "@/components/icons/IconGrid.vue";
import PlusSign from "@/components/icons/IconPlusSign.vue";
import MinusSign from "@/components/icons/IconMinusSign.vue";
import Slash from "@/components/icons/IconSlash.vue";
import PasswordReveal from "@/components/icons/IconPasswordReveal.vue";
import HorizontalEllipse from "@/components/icons/IconHorizontalEllipse.vue";
import VerticalEllipse from "@/components/icons/IconVerticalEllipse.vue";
import Share from "@/components/icons/IconShare.vue";
import Download from "@/components/icons/IconDownload.vue";
import Filter from "@/components/icons/IconFilter.vue";
import TextDocument from "@/components/icons/IconTextDocument.vue";
import OverlappingSquare from "@/components/icons/IconOverlappingSquare.vue";
import IconPercentage from "@/components/icons/IconPercentage.vue";
import VideoPlay from "@/components/icons/IconVideoPlay.vue";
import VideoPause from "@/components/icons/IconVideoPause.vue";
import Calender from "@/components/icons/IconCalender.vue";

//Social
import Instagram from "@/components/icons/IconInstagram.vue";
import Pintrest from "@/components/icons/IconPintrest.vue";
import LinkedIn from "@/components/icons/IconLinkedIn.vue";

const icons = {
  //Arrows
  LeftArrow,
  RightArrow,
  UpArrow,
  DownArrow,
  DownLeftArrow,
  UpLeftArrow,
  UpRightArrow,
  DownRightArrow,
  DownChevron,
  UpChevron,
  LeftChevron,
  RightChevron,
  CornerDownLeft,
  CornerDownRight,
  CornerLeftDown,
  CornerLeftUp,
  CornerRightDown,
  CornerRightUp,
  CornerUpLeft,
  CornerUpRight,
  Play,
  //Shopping
  ShoppingBag,
  ShoppingCart,
  Package,
  Image,
  SolidHeart,
  OutlineHeart,
  HeartPlus,
  Shipping,
  Lock,
  //Utility
  XIcon,
  Check,
  Search,
  Browse,
  LightningBolt,
  AlertCircle,
  InformationCircle,
  AlertTriangle,
  User,
  Menu,
  List,
  Gallery,
  Grid,
  PlusSign,
  MinusSign,
  Slash,
  PasswordReveal,
  HorizontalEllipse,
  VerticalEllipse,
  Share,
  Download,
  Filter,
  TextDocument,
  OverlappingSquare,
  IconPercentage,
  VideoPlay,
  VideoPause,
  Calender,
  //Social
  Instagram,
  Pintrest,
  LinkedIn
};

export default {
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value);
      }
    }
  },
  computed: {
    iconComponent() {
      return icons[this.name];
    }
  }
};
</script>

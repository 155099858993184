<script setup>
import FhIcon from "@/components/FhIcon.vue";
import FhButton from "@/components/FhButton.vue";

const props = defineProps({
  title: {
    type: String,
    default: null
  },
  alignment: {
    type: String,
    default: "center",
    validator: (val) => ["center", "top"].includes(val)
  }
});

const emit = defineEmits(["change"]);
</script>

<template>
  <div
    class="flex flex-row flex-wrap justify-between gap-f1 border border-neutral-30 p-f5 md:gap-f5"
    :class="{ 'items-center': props.alignment === 'center', 'items-start': props.alignment === 'top' }"
  >
    <div class="order-1 flex h-7 w-7 items-center justify-center rounded-full border bg-neutral-70 md:h-8 md:w-8">
      <FhIcon name="Check" class="h-2/3 w-2/3 text-white" />
    </div>
    <div class="order-3 w-full grow md:order-2 md:w-auto">
      <slot name="content">
        <div v-if="props.title" class="text-body-lg">{{ props.title }}</div>
        <div class="text-neutral-50"><slot name="default"></slot></div>
      </slot>
    </div>
    <div class="order-2 flex gap-f2 md:order-3">
      <slot name="actions">
        <FhButton size="small" @click="emit('change')">Change</FhButton>
      </slot>
    </div>
  </div>
</template>

<style scoped></style>

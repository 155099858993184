export default Object.freeze({
  US: {
    name: "United States",
    stateName: "State",
    states: Object.freeze({
      AL: "Alabama",
      AK: "Alaska",
      AS: "American Samoa",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District Of Columbia",
      FM: "Federated States Of Micronesia",
      FL: "Florida",
      GA: "Georgia",
      GU: "Guam",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MH: "Marshall Islands",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      MP: "Northern Mariana Islands",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PW: "Palau",
      PA: "Pennsylvania",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VI: "Virgin Islands",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming"
    }),
    postalCodeName: "ZIP Code",
    postalCodeRegex: /^([0-9]{5})(?:-([0-9]{4}))?$/
  },
  CA: {
    name: "Canada",
    stateName: "Province/Territory",
    states: Object.freeze({
      AB: "Alberta",
      BC: "British Columbia",
      MB: "Manitoba",
      NB: "New Brunswick",
      NL: "Newfoundland and Labrador",
      NS: "Nova Scotia",
      NU: "Nunavut",
      NW: "Northwest Territories",
      ON: "Ontario",
      PE: "Prince Edward Island",
      QC: "Quebec",
      SK: "Saskatchewan",
      YU: "Yukon"
    }),
    postalCodeName: "Postal Code",
    postalCodeRegex: /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\s*([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i
  },
  AR: {
    name: "Argentina"
  },
  BZ: {
    name: "Belize"
  },
  BO: {
    name: "Bolivia"
  },
  BR: {
    name: "Brazil"
  },
  KY: {
    name: "Cayman Islands"
  },
  CL: {
    name: "Chile"
  },
  CO: {
    name: "Columbia"
  },
  CR: {
    name: "Costa Rica"
  },
  DO: {
    name: "Dominican Republic"
  },
  EC: {
    name: "Ecuador"
  },
  SV: {
    name: "El Salvador"
  },
  GT: {
    name: "Guatemala"
  },
  GY: {
    name: "Guyana"
  },
  HN: {
    name: "Honduras"
  },
  JM: {
    name: "Jamaica"
  },
  MX: {
    name: "Mexico"
  },
  NI: {
    name: "Nicaragua"
  },
  PA: {
    name: "Panama"
  },
  PY: {
    name: "Paraguay"
  },
  PE: {
    name: "Peru"
  },
  SR: {
    name: "Suriname"
  },
  GB: {
    name: "United Kingdom"
  },
  UY: {
    name: "Uruguay"
  },
  VE: {
    name: "Venezuela"
  }
});

<script setup>
import FhSection from "../includes/FhSection.vue";
import { getDataRefs } from "../util/vue";
import { ref } from "vue";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const {
  imageSrc,
  imageAltText,
  productName,
  productDescription = ref(false),
  dimensions = ref(false),
  material = ref(false),
  retailPrice = ref(false)
} = getDataRefs(props.data);
</script>

<template>
  <FhSection
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="flex flex-col gap-f4 md:grid md:grid-cols-[minmax(200px,300px)_minmax(450px,1fr)] md:items-center md:gap-f10">
      <div class="col-span-1 row-span-1 flex">
        <img :src="imageSrc" :alt="imageAltText" class="max-w-[300px] md:max-w-full" />
      </div>
      <div class="col-span-1 row-span-1 flex flex-col gap-f2 text-f-xs-sm">
        <h5 class="text-f-base-xl">{{ productName }}</h5>
        <FhWysiwyg>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-if="productDescription" class="text-neutral-50" v-html="productDescription"></div>
        </FhWysiwyg>
        <ul v-if="dimensions || material || retailPrice" class="font-medium text-neutral-80">
          <li v-if="dimensions" class="">Dimensions: {{ dimensions }}</li>
          <li v-if="material" class="">Material: {{ material }}</li>
          <li v-if="retailPrice" class="">Retail: {{ retailPrice }}</li>
        </ul>
      </div>
    </div>
  </FhSection>
</template>

<style scoped lang="pcss">
.text-white * {
  color: white;
}
</style>

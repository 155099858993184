<script setup>
import { getDataRefs } from "../util/vue";
import FhSection from "../includes/FhSection.vue";
import FhButton from "./FhButton.vue";
import FhVideo from "@/components/FhVideo.vue";

const props = defineProps({
  style: {
    type: Object,
    default: null
  },
  data: {
    type: Object,
    required: true
  },
  isVideo: {
    type: Boolean,
    default: false
  }
});

const { layout, image, imageAltText, heading, eyebrowText, description, linkText, linkUrl, linkAltText, linkTarget, gaTagging } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <!-- CTA BLOCK FLEX CONTAINER -->
    <div
      class="widget__container w-full"
      :class="['flex flex-col-reverse md:flex-row', layout === 'imageLeft' || layout === 'imageLeft6040' ? 'md:flex-row-reverse' : '']"
    >
      <!-- CTA BLOCK CONTENT -->
      <div
        class="widget__containerItem widget__containerItem--text"
        :class="[
          'flex flex-col md:items-start',
          layout === 'default' || layout === 'imageLeft' ? 'justify-center md:w-6/12 p-f6 lg:p-f15' : '',
          layout === 'imageLeft6040' || layout === 'imageRight6040' ? 'justify-center md:w-5/12 lg:w-4/12 p-f6 lg:p-f10' : ''
        ]"
      >
        <!-- CTA BLOCK CONTENT Eyebrow Text -->
        <h2 v-if="eyebrowText" class="text-base pb-3" aria-level="2">{{ eyebrowText }}</h2>
        <!-- CTA BLOCK CONTENT TITLE -->
        <h5 v-if="heading" class="mb-8 font-serif text-f-4xl-8xl md:mb-10" role="heading" aria-level="5">
          {{ heading }}
        </h5>
        <p v-if="description" class="mb-f5 text-f-base-2xl" v-html="description"></p>
        <!-- CTA BLOCK LINK -->
        <FhButton
          v-if="linkText != ''"
          class="text-center"
          v-bind="{ href: linkUrl, variant: 'default', inverse: false }"
          :target="linkTarget"
          :aria-label="linkAltText"
          :data-ga="gaTagging"
        >
          {{ linkText }}
        </FhButton>
      </div>
      <!-- CTA BLOCK IMAGE -->
      <div
        v-if="image"
        class="widget__containerItem widget__containerItem--image w-full"
        :class="[
          layout === 'default' || layout === 'imageLeft' ? 'md:w-6/12' : '',
          layout === 'imageLeft6040' ? 'md:w-7/12 lg:w-8/12' : '',
          layout === 'imageRight6040' ? 'md:w-7/12 lg:w-8/12' : ''
        ]"
      >
        <FhVideo v-if="props.isVideo" :src="image" :autoplay="true" :loop="true" :controls="false"></FhVideo>
        <img v-else class="w-full h-full object-cover" :src="image" :alt="imageAltText" />
      </div>
    </div>
  </FhSection>
</template>

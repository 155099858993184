import { STRINGS } from "@/constants";
const CREDIT_CARDS = {
  amex: {
    mapName: "AMEX",
    name: "American Express",
    icon: "/icons/payment-methods/icon-amex.svg"
  },
  discover: {
    mapName: "DISC",
    name: "Discover",
    icon: "/icons/payment-methods/icon-discover.svg"
  },
  mastercard: {
    mapName: "MAST",
    name: "MasterCard",
    icon: "/icons/payment-methods/icon-mastercard.svg"
  },
  visa: {
    mapName: "VISA",
    name: "Visa",
    icon: "/icons/payment-methods/icon-visa.svg"
  }
};

const ERROR_RESPONSE_MESSAGES = {
  40015: {
    error_msg: "Duplicate Card"
  },
  CardAssignedToNotProcessedOrder: {
    error_msg: `<div>This card cannot be deleted.</div><p>This credit card is tied to an open order. In order to delete this card, please call your Sales Support Specialist at ${STRINGS.SUPPORT_PHONE_NUMBER} to change the credit card associated with your open order.</p>`
  }
};

export { CREDIT_CARDS, ERROR_RESPONSE_MESSAGES };

<script setup>
import { computed } from "vue";
import { MEASUREMENT_SYSTEM, MEASUREMENT_UNITS, MEASUREMENT_UNITS_DEFINITION } from "../constants/measurements";
import FhTooltip from "./FhTooltip.vue";

const props = defineProps({
  value: {
    type: [String, Number],
    required: true
  },
  label: {
    type: String,
    default: () => null
  },
  /**
   * An optional unit of measure for numeric values.
   * Options are limited to the standard units in Four Hands' specs for length, weight, and volume measures.
   * @values INCH, CENTIMETER, POUND, KILOGRAM, CUBIC_FOOT, CUBIC_METER
   */
  unit: {
    type: String,
    default: () => null,
    validator: (val) => Object.keys(MEASUREMENT_UNITS).includes(val)
  },
  /**
   * The unit formatting style to use in unit formatting. The default is "short".
   * Corresponds to the unitDisplay property of the Intl.NumberFormat() options object:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
   * @values long, short, narrow
   */
  unitDisplay: {
    type: String,
    default: "short",
    validator: (val) => ["long", "short", "narrow"].includes(val)
  },
  /**
   * The measurment system in which the value should be displayed, converting the input value if necessary.
   * @values IMPERIAL, METRIC
   */
  displaySystem: {
    type: String,
    default: () => MEASUREMENT_SYSTEM.IMPERIAL,
    validator: (val) => Object.keys(MEASUREMENT_SYSTEM).includes(val)
  }
});

const showLabel = computed(() => props.label !== null && typeof props.label !== "undefined");

const isValueNumeric = computed(() => typeof props.value === "number" || !isNaN(Number.parseFloat(props.value)));
const valueAsNumber = computed(() => (isValueNumeric.value ? Number.parseFloat(props.value) : NaN));
const shouldFormat = computed(() => props.unit && isValueNumeric.value);
const unitSystem = computed(() => (props.unit ? MEASUREMENT_UNITS_DEFINITION[props.unit].system : null));
const shouldConvert = computed(() => unitSystem.value && unitSystem.value !== props.displaySystem);
const displayUnit = computed(() => (shouldConvert.value ? MEASUREMENT_UNITS_DEFINITION[props.unit].convertsTo : props.unit));
const valueAsNumberConverted = computed(() =>
  shouldConvert.value ? valueAsNumber.value * MEASUREMENT_UNITS_DEFINITION[props.unit].conversionRatio : valueAsNumber.value
);

const displayValue = computed(() => {
  if (!shouldFormat.value) return props.value.toString();

  // Custom formatting for cubic feet and cubic meters since the built-in Intl.NumberFormat doesn't support these units
  if (
    displayUnit.value === MEASUREMENT_UNITS.CUBIC_FOOT ||
    displayUnit.value === MEASUREMENT_UNITS.CUBIC_METER ||
    displayUnit.value === MEASUREMENT_UNITS.INCH
  ) {
    let nf = new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(valueAsNumberConverted.value);
    if (displayUnit.value === MEASUREMENT_UNITS.CUBIC_FOOT) {
      return props.unitDisplay === "long" ? `${nf} cubic feet` : props.unitDisplay === "narrow" ? `${nf}ft³` : `${nf} cu ft`;
    } else if (displayUnit.value === MEASUREMENT_UNITS.CUBIC_METER) {
      return props.unitDisplay === "long" ? `${nf} cubic meters` : props.unitDisplay === "narrow" ? `${nf}m³` : `${nf} cu m`;
    } else if (displayUnit.value === MEASUREMENT_UNITS.INCH) {
      return `${nf}"`;
    }
  }

  return new Intl.NumberFormat("en-US", {
    style: "unit",
    unit: MEASUREMENT_UNITS_DEFINITION[displayUnit.value].formatAs,
    unitDisplay: props.unitDisplay,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(valueAsNumberConverted.value);
});

const cleaningCodes = {
  s: "S - Solvent: Spot clean only with a water-free dry-cleaning solvent. Do not use water.",
  sw: "SW - Solvent / Water Based: Use any cleaning product, whether water or solvent based.",
  ws: "WS - Water Based / Solvent: Use any cleaning product, whether water or solvent based.",
  w: "W - Water Based: Spot clean only with water-based shampoo or foam upholstery cleaner. Do not use solvents.",
  x: "X - No Cleaning Product: Clean only by vacuuming or light brushing. Do not use any water-or-solvent-based cleaner.",
  dc: "DC - Dry clean only"
};

const isCleaningCode = computed(() => {
  return props.label.split(" ").join("").toLowerCase() === "cleaningcode";
});
const cleaningCodeValue = computed(() => {
  if (typeof props.value === "string") {
    const codeValue = props.value.split(" ")[0].toLowerCase();
    const allowed = ["s", "sw", "ws", "w", "x", "dc"];
    if (allowed.includes(codeValue)) {
      return codeValue;
    }
  }
  return null;
});
</script>

<template>
  <span v-if="showLabel" class="mr-f1 inline-flex">
    {{ label }}
    <template v-if="cleaningCodeValue !== null">
      <FhTooltip v-if="isCleaningCode & (cleaningCodes[cleaningCodeValue] !== null)" class="pl-f1 product-detail-tooltip" placement="top">
        <div class="inline-block">
          {{ cleaningCodes[cleaningCodeValue] }}
        </div>
      </FhTooltip>
    </template>
  </span>
  <span class="inline-block text-neutral-50">{{ displayValue }}</span>
</template>

<style lang="pcss" scoped>
:deep(.popperWrapper.product-detail-tooltip) {
  @apply inline pt-[2px];
}
</style>

<script setup>
import { computed } from "vue";
import { SHIPPING_OPTIONS, FREIGHT_SERVICE_LEVEL } from "@/constants";
import { useFreightQuery } from "@/api/freightApi";
import FhCheckoutFreightOptionPresenter from "@/components/FhCheckoutFreightOptionPresenter.vue";

const props = defineProps({
  modelValue: {
    type: undefined,
    required: true
  },
  freightServiceLevel: {
    type: String,
    required: true,
    validator: (val) => Object.values(FREIGHT_SERVICE_LEVEL).includes(val)
  },
  shippingCountry: {
    type: String,
    required: true
  },
  shippingPostalCode: {
    type: String,
    required: true
  },
  totalItemsPrice: {
    type: Number,
    required: true
  },
  isResidentialShippingEligible: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val)
});

const { isLoading, isError, data } = useFreightQuery({
  shippingOption: SHIPPING_OPTIONS.FH_CARRIERS,
  serviceLevel: props.freightServiceLevel,
  country: props.shippingCountry,
  postalCode: props.shippingPostalCode,
  totalItemsPrice: props.totalItemsPrice,
  isResidentialShippingEligible: props.isResidentialShippingEligible
});

const price = computed(() => data.value?.price);
const priceToBeQuoted = computed(() => data.value?.toBeQuoted);
</script>

<template>
  <FhCheckoutFreightOptionPresenter
    v-model="value"
    :freight-service-level="props.freightServiceLevel"
    :is-residential-shipping-eligible="isResidentialShippingEligible"
    :price="price"
    :price-to-be-quoted="priceToBeQuoted"
    :is-loading="isLoading"
    :is-error="isError"
  />
</template>

<script setup>
import { reactive, computed, toRef, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, maxLength } from "@vuelidate/validators";
import FhButton from "@/components/FhButton.vue";
import FhCheckoutSelectionSummary from "@/components/FhCheckoutSelectionSummary.vue";
import FhInput from "@/components/FhInput.vue";
import FhPaymentMethods from "@/components/Partials/FhPaymentMethods/FhPaymentMethods.vue";
import { SHIPPING_OPTIONS } from "@/constants/checkout";

const props = defineProps({
  hasTerms: {
    type: Boolean,
    default: false
  },
  paymentCard: {
    type: Object,
    default: null
  },
  poNumber: {
    type: String,
    default: null
  },
  isSaving: {
    type: Boolean,
    default: false
  },
  shippingAddress: {
    type: Object,
    required: true
  },
  shippingOption: {
    type: String,
    default: ""
  }
});

const emit = defineEmits(["complete"]);

const data = reactive({
  paymentCardId: props.paymentCard?.id,
  poNumber: props.poNumber,
  isSingleUse: props.paymentCard?.isSingleUse
});

const updateCardSelected = (card) => {
  data.isSingleUse = card.isSingleUse;
};

// Update `data` values in response to corresponding props changing
Object.keys(data).forEach((key) => watch(toRef(props, key), (val) => (data[key] = val)));

const isValid = computed(() => (props.hasTerms || data.paymentCardId) && !vuelidate.value.$invalid);

const specialCharsValidation = helpers.regex(/^[^\t]*$/);

const vuelidate = useVuelidate(
  {
    poNumber: {
      maxLength: helpers.withMessage("Please enter no more than 25 characters.", maxLength(25)),
      validCharacters: helpers.withMessage("Custom PO numbers cannot contain tabs.", specialCharsValidation)
    }
  },
  data,
  { $scope: false } // don't collect validation errors for child components (the payment card form, in this case) which is the Vuelidate default
);

function complete() {
  if (props.hasTerms) {
    emit("complete", { poNumber: data.poNumber });
  } else {
    emit("complete", { ...data });
  }
}
</script>

<template>
  <div class="flex flex-col gap-f8">
    <div v-if="props.hasTerms">
      <FhCheckoutSelectionSummary title="Payment Method">
        <template #default>Payment Terms <span class="italic">(Payment Method on File)</span></template>
        <template #actions>&nbsp;<!-- Intentionally left blank to remove the default action --></template>
      </FhCheckoutSelectionSummary>
    </div>
    <FhPaymentMethods
      v-else
      v-model:selected-id="data.paymentCardId"
      :shipping-address="props.shippingAddress"
      :allow-same-as-shipping="props.shippingOption !== SHIPPING_OPTIONS.PICK_UP"
      mode="select"
      heading-row-alignment="center"
      @update:selected-card="updateCardSelected"
    >
      <template #header>
        <div class="mb-f0.5 text-f-base-xl">Select your payment method</div>
        <div class="text-neutral-50">You won&rsquo;t be charged until your items ship.</div>
      </template>
    </FhPaymentMethods>
    <div>
      <div class="mb-f4 text-f-base-xl">Custom PO <span class="text-neutral-50">(Optional)</span></div>
      <FhInput v-model="data.poNumber" label="PO Number" label-note="Optional" :vuelidate="vuelidate.poNumber" :wrapper-attrs="{ class: 'md:w-96' }" />
    </div>
    <FhButton color="primary" :disabled="!isValid || props.isSaving" class="w-full" @click="complete">Review Order</FhButton>
  </div>
</template>

<style scoped></style>

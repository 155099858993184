<script setup>
import { computed, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, requiredIf, maxLength } from "@vuelidate/validators";
import FhHeading from "@/components/FhHeading.vue";
import FhOptionCard from "@/components/FhOptionCard.vue";
import FhButton from "@/components/FhButton.vue";
import FhInput from "@/components/FhInput.vue";
import TransitionExpand from "@/transitions/TransitionExpand.vue";
import { useViewportSizes } from "@/composables";

const emit = defineEmits(["update:modelValue", "navigate"]);
const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  model: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
});

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

// Compute the page to return to when the "Back" link is clicked, based on where they came from
const prevPage = computed(() => (props.model.primaryBusiness == "commercial-design" ? 2 : 1));

const projectTypeOtherOtherRequired = computed(() => {
  return value.value.projectTypeOther.includes("commercial-specialty");
});

watch(projectTypeOtherOtherRequired, (val) => {
  if (val) return;
  value.value.projectTypeOtherOther = "";
});

const vuelidate = useVuelidate(
  {
    projectTypeOtherOther: {
      requiredIf: helpers.withMessage("Please describe what types of projects you work on.", requiredIf(projectTypeOtherOtherRequired)),
      maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(255))
    }
  },
  value
);

const { isSm } = useViewportSizes();
</script>

<template>
  <div class="flex flex-col">
    <FhHeading
      heading="Select any other types of design projects you work on."
      subheading="Select all that apply. If none are applicable, do not select any."
    />
    <div class="grid grid-cols-2 gap-f3 lg:grid-cols-3">
      <FhOptionCard
        v-for="option in options"
        :key="option.value"
        v-model="value.projectTypeOther"
        :value="option.value"
        :label="option.label"
        type="checkbox"
        layout="column"
        class="cb-grid__item"
      />
    </div>
    <TransitionExpand>
      <FhInput
        v-if="projectTypeOtherOtherRequired"
        v-model="value.projectTypeOtherOther"
        label="If other, please describe"
        :wrapper-attrs="{ class: 'mt-4 lg:col-span-3' }"
        required
        :vuelidate="vuelidate.projectTypeOtherOther"
      />
    </TransitionExpand>
    <div class="mt-f8 flex items-center gap-f3 md:self-end">
      <FhButton :variant="isSm ? false : 'text'" :class="{ 'flex-1': isSm }" @click="emit('navigate', prevPage)">Back</FhButton>
      <FhButton :disabled="vuelidate.$invalid" color="primary" :class="{ 'flex-1': isSm }" @click="emit('navigate', 4)">Continue</FhButton>
    </div>
  </div>
</template>

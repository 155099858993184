<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.293 5.29297L15.7073 6.70718L10.4144 12.0001L15.7073 17.293L14.293 18.7072L7.58594 12.0001L14.293 5.29297Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup>
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { heading, quote, author, imageUrl } = getDataRefs(props.data);
</script>

<template>
  <div
    aria-label="Quote Block"
    :base="style.base"
    :class="[
      'relative p-12 md:p-20 overflow-hidden',
      style.base?.background ? `bg-${style.base.background}` : '',
      (style.base?.background !== 'limestone' && props.style.base?.background !== null && props.style.base?.background !== '') || imageUrl ? 'text-white' : ''
    ]"
    :style="{ backgroundImage: `url('${imageUrl}')` }"
  >
    <div class="relative z-10 flex flex-col text-center">
      <h2 v-if="heading" class="text-base" aria-level="2">{{ heading }}</h2>
      <h3 v-if="quote" class="my-8 font-serif text-6xl md:my-10" role="heading" aria-level="5">&ldquo;{{ quote }}&rdquo;</h3>
      <h2 v-if="author" class="text-base" aria-level="2">{{ author }}</h2>
    </div>
  </div>
</template>

<style lang="pcss" scoped></style>

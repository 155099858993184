<script setup>
import { computed } from "vue";
import FhCard from "@/components/FhCard.vue";
import FhButton from "@/components/FhButton.vue";
import { PRICING_LEVELS_MARK_UP, CAD_PRICING } from "@/constants/account";
const props = defineProps({
  id: {
    type: String || Number,
    default: null
  },
  name: {
    type: String,
    default: null
  },
  email: {
    type: String,
    default: null
  },
  pricingVisibility: {
    type: String,
    default: null
  },
  markup: {
    type: Number,
    default: 0
  }
});
const emits = defineEmits(["update", "delete"]);
const markup = computed(() => (props.markup != null ? props.markup : 0));

//const pricing_visibilities = computed(() => PRICING_LEVELS_MARK_UP(markup.value));   --this code block is required after removeing feature flag
const pricing_visibilities = computed(() => {
  const markupValues = PRICING_LEVELS_MARK_UP(markup.value);
  return [...markupValues, ...CAD_PRICING];
});

const pricing = computed(() => {
  const selectedLevel = pricing_visibilities.value.find((level) => level.value === props.pricingVisibility);
  return selectedLevel ? selectedLevel.text : "";
});
const handleClickTriggers = (click_type) => {
  switch (click_type) {
    case "update": {
      emits("update", props.id);
      break;
    }
    case "delete": {
      emits("delete", props.id);
      break;
    }
  }
};
</script>

<template>
  <FhCard>
    <div class="flex flex-col gap-f4">
      <ul class="flex flex-col gap-1">
        <li class="text-body-lg mb-f0.5">{{ props.name }}</li>
        <li class="text-neutral-50">{{ props.email }}</li>
        <li class="text-neutral-50">Pricing Visibility: {{ pricing }}</li>
      </ul>
      <section class="flex gap-4">
        <FhButton variant="link" :aria-label="AriaClickText" @click="handleClickTriggers('update')">Update</FhButton>
        <FhButton variant="link" :aria-label="AriaClickText" @click="handleClickTriggers('delete')">Remove</FhButton>
      </section>
    </div>
  </FhCard>
</template>

<script setup>
import { ref, computed, watch, nextTick } from "vue";
import { useCheckoutQuery, useCreateCheckoutMutation, useUpdateCheckoutMutation, UPDATE_CHECKOUT_PATHS, useSubmitOrderMutation } from "@/api/checkoutApi";
import { useCartQuery } from "@/api/cartApi";
import { useNotificationStore } from "@/stores/NotificationStore.js";
import { CART } from "@/constants/routes";
import { SUPPORT_PHONE_NUMBER } from "@/constants/strings";
import { CHECKOUT_STEPS, SHIPPING_OPTIONS_DEFINITION } from "@/constants/checkout";
import { event_beginCheckout, event_ShippingInfo, event_PaymentInfo } from "@/util/googleAnalytics";
import FhAlert from "@/components/FhAlert.vue";
import FhButton from "@/components/FhButton.vue";
import FhCartCheckoutLayout from "@/components/FhCartCheckoutLayout.vue";
import FhCartSummary from "@/components/FhCartSummary.vue";
import FhCheckoutPaymentSectionSummary from "@/components/FhCheckoutPaymentSectionSummary.vue";
import FhCheckoutPoNumber from "@/components/FhCheckoutPoNumber.vue";
import FhCheckoutSection from "@/components/FhCheckoutSection.vue";
import FhCheckoutSelectionSummary from "@/components/FhCheckoutSelectionSummary.vue";
import FhCheckoutShippingSectionSummary from "@/components/FhCheckoutShippingSectionSummary.vue";
import FhCheckoutStepPayment from "@/components/FhCheckoutStepPayment.vue";
import FhCheckoutStepReview from "@/components/FhCheckoutStepReview.vue";
import FhCheckoutStepShipping from "@/components/FhCheckoutStepShipping.vue";
import FhCheckoutStepShippingDetails from "@/components/FhCheckoutStepShippingDetails.vue";
import FhLink from "@/components/FhLink.vue";
import FhShippingAddress from "@/components/FhShippingAddress.vue";
import FhModal from "@/components/FhModal.vue";

const props = defineProps({
  hasTerms: {
    type: Boolean,
    default: false
  },
  /**
   * Set this prop to `true` if there are any unrecoverable issues are detected at page-load time that would prevent the customer from completing checkout.
   * The primary scenario this is designed for is when PaymentBroker is down, but leaving this prop generic as there may be other scenarios.
   * When `true`, no queries will be executed and the body of this component will be replaced with an alert message.
   */
  isUnavailable: {
    type: Boolean,
    default: false
  },
  shippingTooltip: {
    type: String,
    default: null
  },
  taxTooltip: {
    type: String,
    default: null
  },
  carriersTooltip: {
    type: String,
    default: null
  },
  thirdPartyTooltip: {
    type: String,
    default: null
  },
  pickUpTooltip: {
    type: String,
    default: null
  },
  checkoutMessaging: {
    type: String,
    default: null
  }
});
const isFirstLoad = ref(true);
const notificationStore = useNotificationStore();

/**
 * Checkout Query
 */

const {
  isLoading,
  isError: isCheckoutQueryError,
  data: checkout,
  refetch: refetchCheckout
} = useCheckoutQuery({
  onError: handleCheckoutQueryError,
  enabled: !props.isUnavailable // don't run the checkout query if checkout is unavailable
});

function handleCheckoutQueryError(error) {
  if (error.response.status === 404) {
    handleCheckoutUnprovisioned();
  }
}

/**
 * Unprovisioned Checkout Handling
 */

const isCheckoutUnprovisioned = ref(false);
const isProvisionCheckoutError = ref(false);

const { mutate: createCheckout } = useCreateCheckoutMutation();

async function handleCheckoutUnprovisioned() {
  isCheckoutUnprovisioned.value = true;

  await ensureCartIsEligibleForCheckoutOrRedirect();

  createCheckout(null, {
    onSuccess: () => (isCheckoutUnprovisioned.value = false),
    onError: () => (isProvisionCheckoutError.value = true)
  });
}

const { data: cart, refetch: refetchCart } = useCartQuery({ enabled: false });

async function ensureCartIsEligibleForCheckoutOrRedirect() {
  await refetchCart();
  if (!cart.value?.items || cart.value.items.every((item) => item.sku.isAvailable === false)) {
    window.location = CART;
  }
}

/**
 * Loading & Error State
 */

const isUnrecoverableError = computed(() => (isCheckoutUnprovisioned.value ? isProvisionCheckoutError.value : isCheckoutQueryError.value));

const isDataLoaded = computed(() => !isLoading.value && !isCheckoutUnprovisioned.value && !isUnrecoverableError.value);

/**
 * Cart Summary Data
 */

const getPriceForSummary = (prop) => (isDataLoaded.value ? prop : null);
const cartSummaryProps = computed(() => ({
  totalItemsPrice: getPriceForSummary(checkout.value?.totalItemsPrice),
  totalShippingPrice: getPriceForSummary(checkout.value?.totalShippingPrice),
  shippingPriceToBeQuoted: checkout.value?.shippingPriceToBeQuoted,
  totalTaxPrice: getPriceForSummary(checkout.value?.totalTaxPrice),
  totalPrice: getPriceForSummary(checkout.value?.totalPrice),
  shippingTooltip: props.shippingTooltip,
  taxTooltip: props.taxTooltip
}));

const tooltipProps = computed(() => ({
  carriersTooltip: props.carriersTooltip,
  thirdPartyTooltip: props.thirdPartyTooltip,
  pickUpTooltip: props.pickUpTooltip
}));
/**
 * Checkout Step Management
 */

const step = ref(1);

const { mutate: updateCheckout, isLoading: isSaving } = useUpdateCheckoutMutation();

function getUpdateCheckoutPath() {
  switch (step.value) {
    case CHECKOUT_STEPS.SHIPPING:
      return UPDATE_CHECKOUT_PATHS.SHIPPING;
    case CHECKOUT_STEPS.SHIPPING_DETAILS:
      return UPDATE_CHECKOUT_PATHS.SHIPPING_DETAILS;
    case CHECKOUT_STEPS.PAYMENT:
      return UPDATE_CHECKOUT_PATHS.PAYMENT;
    default:
      throw new Error("Checkout update path cannot be determined");
  }
}

function saveStep(data, goToNextStep = false) {
  if (!data) {
    if (goToNextStep) step.value++;
    return;
  }

  updateCheckout(
    { path: getUpdateCheckoutPath(), data },
    {
      onSuccess: () => {
        if (goToNextStep) step.value++;
      },
      onError: () => {
        notificationStore.notifyError(
          `Sorry, something went wrong and we could not save your information. Please try again or contact sales support at ${SUPPORT_PHONE_NUMBER}.`
        );
      }
    }
  );
}

function completeStep(data) {
  saveStep(data, true);
}

/**
 * Order Submission
 */

const unavailableItems = computed(() => checkout.value?.items.filter((item) => item.sku.isAvailable === false));
const allowSubmitOrder = computed(() => step.value === CHECKOUT_STEPS.REVIEW && unavailableItems.value?.length === 0);
const isSubmitting = ref(false);

const { mutate: submitOrderMutation } = useSubmitOrderMutation();

function submitOrder() {
  isSubmitting.value = true;
  submitOrderMutation(null, {
    onSuccess: (data) => (window.location = data.url),
    onError: handleOrderSubmissionError
  });
}

function handleOrderSubmissionError() {
  // Manually refetch checkout data. In the case order submission failed due to EOL items,
  // this will update the UI, instructing the customer how to proceed.
  refetchCheckout();
  isSubmitting.value = false;
  notificationStore.notifyError(
    `Sorry, something went wrong and we could not place your order. Please try again or contact sales support at ${SUPPORT_PHONE_NUMBER}.`
  );
}

const sectionRefs = {
  shipping: ref(null),
  shippingDetails: ref(null),
  payment: ref(null),
  review: ref(null)
};

watch(step, async (nextStepValue) => {
  await nextTick();
  switch (nextStepValue) {
    case CHECKOUT_STEPS.SHIPPING:
      sectionRefs.shipping.value?.checkoutSectionRef?.scrollIntoView({ behavior: "smooth" });
      break;
    case CHECKOUT_STEPS.SHIPPING_DETAILS:
      sectionRefs.shippingDetails?.value?.scrollIntoView({ behavior: "smooth" });
      break;
    case CHECKOUT_STEPS.PAYMENT:
      event_ShippingInfo(checkout.value?.totalPrice, checkout.value?.items, checkout.value?.shippingOption);
      sectionRefs.payment.value?.checkoutSectionRef?.scrollIntoView({ behavior: "smooth" });
      break;
    case CHECKOUT_STEPS.REVIEW:
      event_PaymentInfo(checkout.value?.totalPrice, checkout.value?.items, props.hasTerms ? "Terms" : "Credit Card");
      sectionRefs.review.value?.checkoutSectionRef?.scrollIntoView({ behavior: "smooth" });
      break;
  }
});

watch(checkout, (checkoutdata) => {
  if (isFirstLoad.value) {
    event_beginCheckout({
      totalPrice: checkoutdata.totalPrice,
      products: checkoutdata.items
    });
    isFirstLoad.value = false;
  }
});
const residentialShippingModal = {
  data: {
    heading: "Your order qualifies for residential shipping",
    copy: "Residential shipping through a Four Hands carrier is now available for <strong>wallpaper-only orders</strong>."
  }
};
const isResidentialShippingEligible = computed(() => checkout.value?.isResidentialShippingEligible);
</script>

<template>
  <FhModal v-if="isResidentialShippingEligible" ref="modal" :is-open="true" v-bind="residentialShippingModal">
    <template #footer="{ close }">
      <FhButton color="primary" @click="close">Continue</FhButton>
    </template>
  </FhModal>
  <FhCartCheckoutLayout :show-inline-summary-on-mobile="step === CHECKOUT_STEPS.REVIEW">
    <template #default>
      <div class="pb-8 md:pb-f6" :class="{ 'border-b border-neutral-30': isDataLoaded }">
        <h1 class="font-serif text-f-2xl-7xl">Checkout</h1>
      </div>
      <template v-if="isDataLoaded">
        <FhCheckoutSection :ref="sectionRefs.shipping" title="1. Shipping" is-active>
          <div class="flex flex-col gap-f3">
            <FhCheckoutStepShipping
              v-if="step === CHECKOUT_STEPS.SHIPPING"
              v-bind="tooltipProps"
              :shipping-option="checkout?.shippingOption"
              :shipping-address-id="checkout?.shippingAddress?.id"
              :is-saving="isSaving"
              :is-residential-shipping-eligible="isResidentialShippingEligible"
              @complete="completeStep"
            />
            <template v-else-if="step === CHECKOUT_STEPS.SHIPPING_DETAILS">
              <div class="flex flex-col gap-f3">
                <FhCheckoutSelectionSummary title="Delivery Method" @change="step = CHECKOUT_STEPS.SHIPPING">
                  {{ SHIPPING_OPTIONS_DEFINITION[checkout.shippingOption].displayName }}
                </FhCheckoutSelectionSummary>
                <FhCheckoutSelectionSummary v-if="checkout.shippingAddress" title="Delivery Address" alignment="top" @change="step = CHECKOUT_STEPS.SHIPPING">
                  <FhShippingAddress v-bind="checkout.shippingAddress" simple-display />
                </FhCheckoutSelectionSummary>
              </div>
              <div :ref="sectionRefs.shippingDetails">
                <FhCheckoutStepShippingDetails
                  v-bind="checkout"
                  :is-residential-shipping-eligible="isResidentialShippingEligible"
                  :is-saving="isSaving"
                  @save="saveStep"
                  @complete="completeStep"
                />
              </div>
            </template>
            <FhCheckoutShippingSectionSummary v-else v-bind="checkout" />
          </div>
          <template #actions>
            <FhButton v-if="step > CHECKOUT_STEPS.SHIPPING_DETAILS" size="small" @click="step = CHECKOUT_STEPS.SHIPPING">Edit</FhButton>
          </template>
        </FhCheckoutSection>
        <FhCheckoutSection :ref="sectionRefs.payment" title="2. Payment" :is-active="step >= CHECKOUT_STEPS.PAYMENT">
          <FhCheckoutStepPayment
            v-if="step === CHECKOUT_STEPS.PAYMENT"
            :has-terms="props.hasTerms"
            :payment-card="checkout?.paymentCard"
            :po-number="checkout?.poNumber"
            :is-saving="isSaving"
            :shipping-address="checkout.shippingAddress"
            :shipping-option="checkout.shippingOption"
            @complete="completeStep"
          />
          <FhCheckoutPaymentSectionSummary v-else :has-terms="props.hasTerms" :payment-card="checkout?.paymentCard" />
          <template #actions>
            <FhButton v-if="step > CHECKOUT_STEPS.PAYMENT && !props.hasTerms" size="small" @click="step = CHECKOUT_STEPS.PAYMENT">Edit</FhButton>
          </template>
        </FhCheckoutSection>
        <FhCheckoutSection :ref="sectionRefs.review" title="3. Review" :is-active="step >= CHECKOUT_STEPS.REVIEW">
          <FhCheckoutStepReview :items="checkout.items" :checkout-messaging="props.checkoutMessaging" />
          <FhButton
            color="primary"
            class="mt-f8 hidden w-full lg:block"
            :disabled="!allowSubmitOrder || isSubmitting"
            :is-loading="isSubmitting"
            @click="submitOrder"
          >
            Place Order
          </FhButton>
        </FhCheckoutSection>
      </template>
      <!-- ERROR MESSAGE -->
      <FhAlert v-if="isUnrecoverableError || props.isUnavailable" icon="AlertCircle" class="mb-f15">
        <div class="flex max-w-prose flex-col gap-f2">
          <p>
            Sorry, something went wrong and checkout is unavailable at this time. If the problem persists, please contact sales support at
            <span class="whitespace-nowrap">{{ SUPPORT_PHONE_NUMBER }}</span
            >.
          </p>
          <FhLink :href="CART">Return to Shopping Cart</FhLink>
        </div>
      </FhAlert>
    </template>
    <template #summary>
      <FhCartSummary v-bind="cartSummaryProps" :show-inline-on-mobile="step === CHECKOUT_STEPS.REVIEW">
        <template #extras>
          <FhCheckoutPoNumber :po-number="checkout?.poNumber" />
        </template>
        <template #actions>
          <FhButton color="primary" :disabled="!allowSubmitOrder || isSubmitting" :is-loading="isSubmitting" @click="submitOrder">Place Order</FhButton>
          <FhButton :href="CART">Edit Cart</FhButton>
          <div class="text-body-sm text-center text-neutral-50">You won&rsquo;t be charged until your items ship.</div>
        </template>
      </FhCartSummary>
    </template>
  </FhCartCheckoutLayout>
</template>

<script setup>
import { computed } from "vue";
import { SHIPPING_OPTIONS, SHIPPING_OPTIONS_DEFINITION, FREIGHT_SERVICE_LEVEL, FREIGHT_SERVICE_LEVEL_DEFINITION } from "@/constants/checkout";

const props = defineProps({
  shippingOption: {
    type: String,
    default: null,
    validator: (val) => Object.values(SHIPPING_OPTIONS).includes(val)
  },
  freightServiceLevel: {
    type: String,
    default: null,
    validator: (val) => Object.values(FREIGHT_SERVICE_LEVEL).includes(val)
  },
  carrierNotes: {
    type: String,
    default: null
  },
  carrierName: {
    type: String,
    default: null
  },
  carrierAccountNumber: {
    type: String,
    default: null
  },
  pickUpContactName: {
    type: String,
    default: null
  },
  pickUpContactPhone: {
    type: String,
    default: null
  },
  holdDate: {
    type: String,
    default: null
  }
});

const holdDateString = computed(() => {
  if (!props.holdDate || isNaN(new Date(props.holdDate))) return null;
  return "Hold processing until " + new Date(props.holdDate).toLocaleDateString(navigator.language || "en-US", { timeZone: "UTC", dateStyle: "long" });
});
</script>

<template>
  <div>
    <template v-if="props.shippingOption === SHIPPING_OPTIONS.FH_CARRIERS">
      <div>Four Hands Carrier</div>
      <div v-if="props.shippingOption === SHIPPING_OPTIONS.FH_CARRIERS && props.freightServiceLevel">
        {{ FREIGHT_SERVICE_LEVEL_DEFINITION[props.freightServiceLevel].displayName }} Service &ndash;
        {{ FREIGHT_SERVICE_LEVEL_DEFINITION[props.freightServiceLevel].description }}
      </div>
      <div v-if="holdDateString">{{ holdDateString }}</div>
      <div v-if="props.carrierNotes" class="text-body-sm mt-f2"><span class="italic">Notes:</span> {{ carrierNotes }}</div>
    </template>
    <template v-if="props.shippingOption === SHIPPING_OPTIONS.THIRD_PARTY">
      <div class="mb-2"><strong>Carrier Name:</strong> {{ props.carrierName }}</div>
      <div v-if="props.carrierAccountNumber && props.carrierAccountNumber.trim().toLowerCase() !== 'n/a'">
        <strong>Account #:</strong> {{ props.carrierAccountNumber }}
      </div>
      <div v-if="holdDateString" class="mt-2">{{ holdDateString }}</div>
      <div v-if="props.carrierNotes" class="text-body-sm mt-2"><span class="italic">Notes:</span> {{ carrierNotes }}</div>
    </template>
    <template v-if="props.shippingOption === SHIPPING_OPTIONS.PICK_UP">
      <div>{{ SHIPPING_OPTIONS_DEFINITION[SHIPPING_OPTIONS.PICK_UP].displayName }}</div>
      <div>{{ props.pickUpContactName }} &ndash; {{ props.pickUpContactPhone }}</div>
      <div v-if="holdDateString">{{ holdDateString }}</div>
    </template>
  </div>
</template>

<style scoped></style>

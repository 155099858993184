<script setup>
import { computed, ref, watch } from "vue";
import FhInput from "@/components/FhInput.vue";
import FhRadio from "@/components/FhRadio.vue";
import FhModal from "@/components/FhModal.vue";
import FhIcon from "@/components/FhIcon.vue";
import { useViewportSizes } from "../composables";

const props = defineProps({
  modelValue: {
    type: undefined,
    required: true
  },
  options: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: "All Guest Accounts"
  },
  closeContainer: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["update:modelValue"]);
const showContainer = ref(false);
const all = "All Guest Accounts";
const searchQuery = ref("");

const { isSm, isMd } = useViewportSizes();
const isMobile = computed(() => isSm.value || isMd.value);

const value = computed({
  get: () => {
    if (!props.modelValue) {
      return all;
    }
    return props.modelValue;
  },
  set: (value) => {
    emit("update:modelValue", value);
    showContainer.value = false;
  }
});
const openFilterContainer = () => {
  searchQuery.value = "";
  showContainer.value = !showContainer.value;
};
const closeGuestAccountFilterModal = () => {
  showContainer.value = false;
};

const filteredItems = computed(() => {
  const filtered = props.options.filter((option) => {
    return option.name.toLowerCase().includes(searchQuery.value.toLowerCase());
  });
  // Find the index of the currently selected option
  const selectedIndex = filtered.findIndex((option) => option.name === value.value);

  if (selectedIndex !== -1) {
    // If the selected option exists in the filtered list, move it to the top
    const selectedOption = filtered.splice(selectedIndex, 1)[0];
    filtered.unshift(selectedOption);
  }
  return filtered;
});

const toggleRadio = computed(() => {
  return () => {
    value.value = null;
  };
});

watch(
  () => props.closeContainer,
  () => {
    showContainer.value = false;
  }
);
</script>

<template>
  <div class="relative cursor-pointer flex justify-center items-center fh-input" @click="openFilterContainer">
    <input class="cursor-pointer w-full focus-visible:outline-0" :placeholder="props.placeholder" :value="value" readonly />
    <FhIcon name="DownChevron" class="text-xl" />
  </div>
  <div v-if="showContainer && !isMobile" class="absolute w-full z-50 bg-white shadow-md p-4">
    <FhInput v-model="searchQuery" placeholder="Search Guest Account" />
    <div class="filtered-items-container mt-6 overflow-x-auto">
      <div v-if="filteredItems.length > 0">
        <a class="flex-none mb-6 flex gap-3 md:min-w-0 md:gap-">
          <FhRadio v-model="value" :value="all" size="lg" @click="toggleRadio()">All Guest Accounts</FhRadio>
        </a>
        <div v-for="option in filteredItems" :key="option.name" class="mb-6 flex gap-3 md:min-w-0 md:gap-5">
          <a class="flex-none">
            <FhRadio v-model="value" :value="option.name" size="lg">{{ option.name }}</FhRadio>
          </a>
        </div>
      </div>
      <p v-else class="text-body-lg mb-f2 text-neutral-50">No Guest Accounts match your search</p>
    </div>
  </div>
  <FhModal v-else :is-open="showContainer" @close="closeGuestAccountFilterModal">
    <FhInput v-model="searchQuery" placeholder="Search Guest Account" />
    <div class="filtered-items-container mt-6 overflow-x-auto">
      <div v-if="filteredItems.length > 0">
        <a class="flex-none mb-6 flex gap-3 md:min-w-0 md:gap-">
          <FhRadio v-model="value" :value="all" size="lg" @click="toggleRadio()">All Guest Accounts</FhRadio>
        </a>
        <div v-for="option in filteredItems" :key="option.name" class="mb-6 flex gap-3 md:min-w-0 md:gap-5">
          <a class="flex-none">
            <FhRadio v-model="value" :value="option.name" size="lg">{{ option.name }}</FhRadio>
          </a>
        </div>
      </div>
      <p v-else class="text-body-lg mb-f2 text-neutral-50">No Guest Accounts match your search</p>
    </div>
  </FhModal>
</template>

<style scoped>
.filtered-items-container {
  max-height: 20rem; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrollbar when content overflows */
}
</style>

<script setup>
import { getDataRefs } from "../util/vue";
import FhSection from "../includes/FhSection.vue";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { benefits } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    aria-label="Benefits"
    :base="style.base"
    :class="{ 'text-white': props.style.base?.background !== 'limestone' && props.style.base?.background !== null && props.style.base?.background !== '' }"
  >
    <div class="grid grid-cols-1 pt-f8 md:grid-cols-2 lg:grid-cols-3">
      <!-- BENEFIT ITEM -->
      <div v-for="item in benefits" :key="item.id" class="-mt-px flex flex-col items-center border border-neutral-30 py-f15 px-f10 text-center md:-ml-px">
        <img src="../assets/four-hands-logo.svg" class="mb-f4 logo" alt="Four Hands Logo" />
        <h3 class="mb-f4 font-serif text-f-2xl-5xl">{{ item.title }}</h3>
        <FhWysiwyg>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div
            class="text-f-sm-lg text-neutral-50"
            :class="[
              props.style.base?.background !== 'limestone' && props.style.base?.background !== null && props.style.base?.background !== '' ? 'text-white' : ''
            ]"
            v-html="item.copy"
          ></div>
        </FhWysiwyg>
      </div>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
/* Apply filter invert(100%) conditionally */
.text-white :deep(.logo) {
  filter: invert(100%) brightness(10000%) contrast(1000%);
}
</style>

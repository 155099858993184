<script setup>
import { ref, watch, computed } from "vue";
import { getDataRefs } from "../util/vue";
import { useViewportSizes } from "../composables";
import FhPricingProgress from "./FhPricingProgram/FhPricingProgress.vue";
import FhPricingTable from "./FhPricingProgram/FhPricingTable.vue";
import FhSection from "../includes/FhSection.vue";
import FhTooltip from "./FhTooltip.vue";
import FhIcon from "../components/FhIcon.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});
const tableData = computed(() => [
  {
    id: 1,
    indicatorColor: "insider",
    pricingLevel: "Insider"
  },
  {
    id: 2,
    indicatorColor: "silver",
    pricingLevel: "Silver"
  },
  {
    id: 3,
    indicatorColor: "gold",
    pricingLevel: "Gold"
  },
  {
    id: 4,
    indicatorColor: "platinum",
    pricingLevel: "Platinum"
  },
  {
    id: 5,
    indicatorColor: "diamond",
    pricingLevel: "Diamond"
  },
  {
    id: 6,
    indicatorColor: "enterprise",
    pricingLevel: "Enterprise"
  }
]);

const { shippedTotal, shippedUpdated, reviewDate, pricingLevel, pricingLevelDiscount, nextPricingLevel, nextPricingLevelSpend, nextPricingLevelDiscount } =
  getDataRefs(props.data);

const isOpen = ref(false);
const { isSm } = useViewportSizes();

watch(isSm, () => (isOpen.value = false));
</script>

<template>
  <FhSection :base="style.base" class="mb-f10">
    <!-- New Pricing Program -->
    <div class="grid md:grid-cols-2 gap-2 bg-limestone mb-6">
      <div class="p-6 md:p-12 flex flex-col justify-between">
        <p class="mb-4">Pricing Program</p>
        <h1 class="font-serif text-7xl mt-6 mb-6 leading-[3.25rem]">Take Your Business To The Next Level</h1>
        <p class="mb-0">Earn better pricing the more you spend, with tiered discounts based on your Trailing Twelve Months (TTM) shipped total</p>
      </div>
      <!-- Pricing table -->
      <div class="p-6 md:p-12">
        <FhPricingTable :shipped-total="shippedTotal"></FhPricingTable>
      </div>
    </div>

    <div class="grid md:grid-cols-3 gap-1">
      <div class="md:col-span-2 p-6 md:p-12 bg-limestone flex flex-col justify-between">
        <!-- START: PRICING LEVEL -->
        <div class="grid grid-cols-2 md:grid-cols-3 gap-8 md:gap-4">
          <div>
            <span class="mb-f0.5 text-sm text-neutral-50">Your level</span>
            <span class="text-f-xl-6xl flex">
              <span v-for="item in tableData" :key="item.id">
                <template v-if="item.pricingLevel === pricingLevel">
                  <span class="mr-2 inline-block h-6 w-6 rounded-[50%]" :class="`bg-${item.indicatorColor}`"></span>
                </template>
              </span>
              {{ pricingLevel }}</span
            >
          </div>
          <div v-if="pricingLevel !== 'Insider'" class="flex flex-col md:m-auto">
            <span class="mb-f0.5 text-sm text-neutral-50">Your discount</span>
            <span class="text-f-xl-4xl capitalize">{{ pricingLevelDiscount }}</span>
          </div>
        </div>
        <div>
          <!-- END: PRICING LEVEL -->
          <FhPricingProgress
            class=""
            :shipped-total="shippedTotal"
            :pricing-level="pricingLevel"
            :pricing-level-discount="pricingLevelDiscount"
            :next-level="nextPricingLevel"
            :amount-to-next-level="nextPricingLevelSpend"
            :next-discount="nextPricingLevelDiscount"
          ></FhPricingProgress>
        </div>
      </div>
      <div class="p-6 md:ps-10 md:py-12 bg-limestone">
        <div class="grid grid-cols-6 gap-1 bottom-inherit">
          <div class="col-start-1 text-center"><FhIcon name="Package" class="text-2xl text-neutral-80" /></div>
          <div class="col-start-2 col-end-7"><span class="mb-f0.5 text-sm gap-3 flex text-neutral-50">TTM shipped total</span></div>
          <div class="col-start-2 col-end-7">
            <span class="mb-f0.5 text-f-sm-2xl font-bold">${{ shippedTotal.toLocaleString() }}</span>
          </div>
          <div class="col-start-2 col-end-7">
            <span class="text-f-xs-sm text-neutral-50">Updated as of {{ shippedUpdated }}</span>
          </div>
        </div>
        <div class="grid grid-cols-6 gap-1">
          <div class="col-start-1 text-center"><FhIcon name="Calender" class="text-2xl text-neutral-80" /></div>
          <div class="col-start-2 col-end-7"><span class="mb-f0.5 text-sm gap-3 flex text-neutral-50">Price level review date</span></div>
          <div class="col-start-2 col-end-7">
            <span class="mb-f0.5 text-f-sm-2xl font-bold">{{ reviewDate }}</span>
          </div>
          <div class="col-start-2 col-end-7">
            <a class="text-sm underline flex"
              >What is a price level review date?
              <FhTooltip>
                WHAT IS A PRICE LEVEL REVIEW DATE?<br />Your price level is assigned for 12 months. At the end of that 12-month period, we will evaluate your
                TTM shipped total to determine *which* price level you will be assigned for the next 12 months. During that 12-month window, your price level
                will remain the same unless your TTM shipped total reaches a higher level and thus qualifies you for a better price, at which point we will
                automatically upgrade you to the next price level.
              </FhTooltip>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- old pricing program -->
    <!-- PRICING PROGRAM -->
    <!-- <div class="mb-8 flex flex-col border-b border-solid border-neutral-70 border-opacity-10 pb-8 text-neutral-70 md:mb-10 md:pb-12"> -->
    <!-- <FhHeading
        tag="h2"
        heading="Pricing Program"
        subheading="Earn better pricing the more you spend, with tiered discounts based on your Trailing Twelve Months shipped total."
      /> -->
    <!-- START: PRICING LEVEL -->
    <!-- <div class="flex flex-col md:flex-row">
        <div class="mb-6 flex flex-col pr-2 md:basis-4/12 lg:basis-3/12">
          <span class="mb-f0.5 text-sm text-neutral-50">Your level</span>
          <span class="text-f-xl-6xl">{{ pricingLevel }}</span>
        </div>
        <div v-if="pricingLevel !== 'Insider'" class="flex flex-col">
          <span class="mb-f0.5 text-sm text-neutral-50">Your discount</span>
          <span class="text-f-xl-6xl">{{ pricingLevelDiscount }}</span>
        </div>
      </div> -->
    <!-- END: PRICING LEVEL -->
    <!-- </div> -->
    <!-- <div class="mb-f8 flex flex-col border-0 pb-f8 text-neutral-70 md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10">
      <div class="mb-8 flex flex-col border-b border-solid border-neutral-70 border-opacity-10 pb-f8 md:mb-0 md:flex-row md:border-0">
        <div v-if="shippedTotal >= 0" class="mb-6 flex flex-col pr-2 md:basis-4/12 lg:basis-3/12">
          <span class="mb-f0.5 text-sm text-neutral-50">Trailing Twelve Months shipped total</span>
          <span class="mb-f0.5 text-f-sm-2xl font-bold">${{ shippedTotal.toLocaleString() }}</span>
          <span class="text-f-xs-sm text-neutral-50">* Updated as of {{ shippedUpdated }}</span>
        </div>
        <div class="flex flex-col">
          <span class="mb-f0.5 flex items-center gap-3 text-sm text-neutral-50">
            Price level review date
            <FhTooltip>
              WHAT IS A PRICE LEVEL REVIEW DATE?<br />Your price level is assigned for 12 months. At the end of that 12-month period, we will evaluate your TTM
              shipped total to determine *which* price level you will be assigned for the next 12 months. During that 12-month window, your price level will
              remain the same unless your TTM shipped total reaches a higher level and thus qualifies you for a better price, at which point we will
              automatically upgrade you to the next price level.
            </FhTooltip>
          </span>
          <span class="mb-f0.5 text-f-sm-2xl font-bold">{{ reviewDate }}</span>
        </div>
      </div>

      <FhPricingProgress
        v-if="shippedTotal >= 0"
        class="mb-f6"
        :shipped-total="shippedTotal"
        :pricing-level="pricingLevel"
        :pricing-level-discount="pricingLevelDiscount"
        :next-level="nextPricingLevel"
        :amount-to-next-level="nextPricingLevelSpend"
        :next-discount="nextPricingLevelDiscount"
      ></FhPricingProgress>

      <FhButton v-if="isSm" class="w-full" @click="isOpen = true">View levels</FhButton>
      <component :is="isSm ? FhModal : 'div'" v-model:is-open="isOpen">
        <FhPricingTable :shipped-total="shippedTotal"></FhPricingTable>
      </component>
    </div> -->
  </FhSection>
</template>

<style lang="pcss" scoped>
.bg-insider {
  background-color: #e0d1b8;
}
.bg-silver {
  background-color: #b2b2b2;
}
.bg-gold {
  background-color: #d7b683;
}
.bg-platinum {
  background-color: #e5e4e2;
}
.bg-diamond {
  background-color: #c7d2e9;
}
.bg-enterprise {
  background-color: #ba623f;
}
.bottom-inherit {
  padding-bottom: inherit !important;
}
</style>

<script setup>
const props = defineProps({
  href: {
    type: String,
    required: true
  }
});
</script>

<template>
  <a :href="props.href" class="cursor-pointer underline">
    <slot></slot>
  </a>
</template>

import axiosClient from "./axiosClient";
import { useQuery, useQueryClient, useInfiniteQuery } from "@tanstack/vue-query";

const QUERY_KEY_BASE = "products";

// TODO: Maybe look into creating a simpler object for filters instead of passing the whole filters object
export const useProductQuery = (searchBody, pageIndex, activeFilters, sortOrder) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [QUERY_KEY_BASE, pageIndex, activeFilters, sortOrder],
    queryFn: ({ queryKey }) => {
      // TODO: possibly figure out why its not pulling from cache on its own
      // but for now this is manually looking in cache
      const hasQueryCache = queryClient.getQueryData(queryKey);
      if (hasQueryCache) return hasQueryCache;
      return axiosClient.post(`/products/v2/search`, { ...searchBody, pageIndex: queryKey[1] });
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });
};

export const useInfiniteProductQuery = (searchBody, activeFilters, sortOrder) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY_BASE, activeFilters, sortOrder],
    queryFn: ({ pageParam = 0 }) => {
      return axiosClient.post(`/products/v2/search`, { ...searchBody, pageIndex: pageParam });
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.total <= lastPage.pageSize) return null;
      if (allPages.length <= 1) return 0;
      if (lastPage.total / lastPage.pageSize > lastPage.pageIndex + 1) {
        return lastPage.pageIndex + 1;
      }
      return null;
    },
    refetchOnWindowFocus: false,
    gcTime: 0 // Lets look into another way to handle this turning off cache feels dirty :(
  });
};

<script setup>
import { computed } from "vue";
const props = defineProps({
  addressee: {
    type: String,
    default: null
  },
  line1: {
    type: String,
    required: true
  },
  line2: {
    type: String,
    default: null
  },
  city: {
    type: String,
    required: true
  },
  state: {
    type: String,
    required: true
  },
  postalCode: {
    type: String,
    required: true
  },
  country: {
    type: String,
    required: true
  },
  phone: {
    type: String,
    default: null
  },
  simpleDisplay: {
    type: Boolean,
    default: false
  }
});

const cityStatePostalCode = computed(() => {
  let s = props.city;
  if (props.state) s += ", " + props.state;
  s += " " + props.postalCode;
  return s;
});

const countryDisplayName = computed(() => new Intl.DisplayNames(["en"], { type: "region" }).of(props.country));
</script>

<template>
  <div>
    <div class="mb-f0.5" :class="{ 'text-body-lg': !props.simpleDisplay }">{{ props.addressee }}</div>
    <div class="flex flex-col gap-f0.5" :class="{ 'text-neutral-50': !props.simpleDisplay }">
      <div>{{ props.line1 }}</div>
      <div v-if="props.line2">{{ props.line2 }}</div>
      <div>{{ cityStatePostalCode }}</div>
      <div>{{ countryDisplayName }}</div>
      <div v-if="props.phone">{{ props.phone }}</div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useResetPasswordMutation } from "@/api/authApi";
import { useNotificationStore } from "@/stores/NotificationStore";
import FhButton from "@/components/FhButton.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhNewPassword from "@/components/FhNewPassword.vue";

const props = defineProps({
  userId: {
    type: [Number, String],
    default: null
  },
  token: {
    type: String,
    default: null
  },
  isInvalid: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["navigate"]);

const password = ref("");

const allowSubmit = computed(() => !!password.value);

const { mutate: passwordResetMutate, isLoading } = useResetPasswordMutation();
const isResetError = ref(false);
const notificationStore = useNotificationStore();

function submit() {
  if (!allowSubmit.value) return;

  passwordResetMutate(
    {
      userId: props.userId,
      token: props.token,
      password: password.value
    },
    {
      onSuccess: (data) => {
        notificationStore.notifySuccess("Your password has been updated. You can log in using your new password.");
        emit("navigate", "login", data.userName);
      },
      onError: (error) => {
        if (error.response.status === 400) {
          isResetError.value = true;
        } else {
          notificationStore.notifyError("Sorry, something went wrong and we could not update your password. Please try again.");
        }
      }
    }
  );
}
</script>

<template>
  <FhHeading heading="Update Your Password">
    <template #subheading>
      <p v-if="isResetError" class="text-brand-red">
        Sorry, we could not update your password.<br />
        Please <FhButton variant="link" @click="emit('navigate', 'forgot')">request another</FhButton> password reset link and try again.
      </p>
    </template>
  </FhHeading>
  <div v-if="props.isInvalid">
    <div class="gap-f mb-f6 flex flex-row items-center gap-f1">
      <FhIcon name="AlertCircle" class="text-[125%]" />
      <span>Sorry, this link is no longer valid.</span>
    </div>
    <FhButton color="primary" @click="emit('navigate', 'forgot')">Request Another Link</FhButton>
  </div>
  <form v-if="!props.isInvalid" class="flex flex-col" @submit.prevent="submit">
    <FhNewPassword v-model="password" class="mb-f8" />
    <div class="flex flex-col gap-3 md:items-start md:self-end">
      <FhButton type="submit" color="primary" :disabled="!allowSubmit || isLoading" :is-loading="isLoading">Update Password</FhButton>
    </div>
  </form>
</template>

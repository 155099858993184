<script setup>
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, minLength } from "@vuelidate/validators";
import { reactive, watch } from "vue";
import FhInput from "@/components/FhInput.vue";

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  labelPassword: {
    type: String,
    default() {
      return "New Password";
    }
  },
  labelConfirm: {
    type: String,
    default() {
      return "Confirm New Password";
    }
  },
  placeholderPassword: {
    type: String,
    default() {
      return "Enter new password";
    }
  },
  placeholderConfirm: {
    type: String,
    default() {
      return "Confirm new password";
    }
  }
});

const emit = defineEmits(["update:modelValue"]);

const model = reactive({
  password: props.modelValue,
  passwordConfirmation: props.modelValue
});

const vuelidate = useVuelidate(
  {
    password: {
      required: helpers.withMessage("Please enter a new password.", required),
      minLength: helpers.withMessage("Please choose a password that is at least 8 characters long.", minLength(8)),
      requiredCharacters: helpers.withMessage(
        "Please choose a password with at least one uppercase letter, one lowercase letter, one number, and one special character.",
        helpers.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).+$/)
      )
    },
    passwordConfirmation: {
      passwordsMatch: helpers.withMessage("Passwords do not match.", () => model.password === model.passwordConfirmation)
    }
  },
  model
);

watch(model, (updatedModel) => {
  if (!vuelidate.value.$invalid) {
    emit("update:modelValue", updatedModel.password);
  }
});
</script>

<template>
  <div class="flex flex-col gap-f4">
    <FhInput
      v-model="model.password"
      type="password"
      :label="labelPassword"
      :placeholder="placeholderPassword"
      note="Minimum 8 characters with at least one uppercase letter, one lowercase letter, one number, and one special character."
      :vuelidate="vuelidate.password"
    />
    <FhInput
      v-model="model.passwordConfirmation"
      type="password"
      :label="labelConfirm"
      :placeholder="placeholderConfirm"
      :vuelidate="vuelidate.passwordConfirmation"
    />
  </div>
</template>

<script setup>
import FhSection from "../includes/FhSection.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { items } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <!-- VALUE PROPS WRAPPING GRID -->
    <div class="flex flex-col gap-f8 lg:flex-row">
      <!-- VALUE PROP ITEM -->
      <div v-for="item in items" :key="item.id" class="flex flex-[1_1_0] flex-col">
        <!--  VALUE PROP ITEM TITLE -->
        <h3 v-if="item.title" class="mb-4 font-serif text-f-2xl-5xl lg:mb-6" role="heading" aria-level="3">
          {{ item.title }}
        </h3>
        <!--  VALUE PROP ITEM DESCRIPTION -->
        <p class="mb-3 grow text-f-sm-xl lg:mb-6">
          {{ item.copy }}
        </p>
        <!--  VALUE PROP ITEM LINK -->
        <a :href="item.linkHref" :aria-label="item.ariaLabel" :target="item.linkTarget" class="text-f-sm-lg underline">
          {{ item.linkText }}
        </a>
      </div>
    </div>
  </FhSection>
</template>

<script setup>
import { computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, email, sameAs, maxLength } from "@vuelidate/validators";
import { useViewportSizes } from "@/composables";
import FhButton from "@/components/FhButton.vue";
import FhCheckbox from "@/components/FhCheckbox.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhInput from "@/components/FhInput.vue";
import FhInputPhone from "@/components/FhInputPhone.vue";
import FhLink from "@/components/FhLink.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  model: {
    type: Object,
    required: true
  },
  isSubmitting: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue", "navigate", "submit"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const isCountryUSorCA = computed(() => {
  return props.model.businessContact.registeredLocation === "US" || props.model.businessContact.registeredLocation === "CA";
});

const phoneNumberValidation = (value) => {
  if (isCountryUSorCA.value) {
    return helpers.regex(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/)(value);
  }
  return true;
};

const phoneNumberLengthValidation = (value) => {
  if (!isCountryUSorCA.value) {
    const phoneLength = value.length;
    return phoneLength <= 15;
  }
  return true;
};

const emailAddress = computed(() => {
  return value.value.email;
});

const vuelidate = useVuelidate(
  {
    firstName: {
      required: helpers.withMessage("Please enter a first name.", required),
      maxLength: helpers.withMessage("Please keep your entry to no more than 15 characters.", maxLength(15))
    },
    lastName: {
      required: helpers.withMessage("Please enter a last name", required),
      maxLength: helpers.withMessage("Please keep your entry to no more than 15 characters.", maxLength(15))
    },
    title: { maxLength: helpers.withMessage("Please keep your entry to no more than 20 characters.", maxLength(20)) },
    email: {
      required: helpers.withMessage("Please enter an email address.", required),
      email: helpers.withMessage("Please enter a valid email address.", email)
    },
    confirmEmail: {
      required: helpers.withMessage("Please confirm your email address.", required),
      sameAs: helpers.withMessage("Email addresses do not match.", sameAs(emailAddress))
    },
    terms: { sameAs: sameAs(true) },
    phone: {
      required: helpers.withMessage("Please enter a phone number.", required),
      validPhone: helpers.withMessage("Please enter a valid phone number.", phoneNumberValidation),
      validPhoneLength: helpers.withMessage("Please keep your entry to no more than 15 characters.", phoneNumberLengthValidation)
    }
  },
  value
);

const { isSm } = useViewportSizes();
</script>

<template>
  <div class="flex flex-col">
    <FhHeading
      heading="Contact details"
      subheading="Enter the contact details for the person who will be managing the Four Hands account and placing orders."
    />
    <div class="grid grid-cols-1 items-start gap-x-f3 gap-y-f4 lg:grid-cols-2">
      <FhInput v-model="value.firstName" label="First name" required :vuelidate="vuelidate.firstName" />
      <FhInput v-model="value.lastName" label="Last name" required :vuelidate="vuelidate.lastName" />
      <FhInput v-model="value.title" label="Title or role" :vuelidate="vuelidate.title" :wrapper-attrs="{ class: 'lg:col-span-2' }" />
      <FhInput v-model="value.email" label="Email address" class="flex-[1_1_0]" required :vuelidate="vuelidate.email" />
      <FhInput v-model="value.confirmEmail" label="Confirm email address" class="flex-[1_1_0]" required :vuelidate="vuelidate.confirmEmail" />
      <component :is="isCountryUSorCA ? FhInputPhone : FhInput" v-model="value.phone" label="Phone number" required :vuelidate="vuelidate.phone" />
      <FhCheckbox v-model="value.terms" required :vuelidate="vuelidate.terms" class="lg:col-span-2">
        <div>
          To submit your application, please acknowledge you have read and agree to comply with our minimum advertised pricing (MAP) policy. Review the Four
          Hands MAP policy
          <FhLink target="_blank" href="https://secure.fourhands.com/SecondaryInfo/documents/IMAP%20Policy.pdf">here</FhLink>.
        </div>
      </FhCheckbox>
    </div>
    <div class="mt-f8 flex items-center gap-f3 md:self-end">
      <FhButton :variant="isSm ? false : 'text'" :class="{ 'flex-1': isSm }" @click="emit('navigate', -1)">Back</FhButton>
      <FhButton :disabled="vuelidate.$invalid || isSubmitting" :is-loading="isSubmitting" color="primary" :class="{ 'flex-1': isSm }" @click="emit('submit')">
        Submit&nbsp;Application
      </FhButton>
    </div>
  </div>
</template>

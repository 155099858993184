<script setup>
import { ref, computed, watch } from "vue";
import FhIcon from "./FhIcon.vue";
import TransitionExpand from "../transitions/TransitionExpand.vue";

const props = defineProps({
  selectedValueId: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true,
    validator: (val) => ["default", "swatch"].includes(val)
  },
  name: {
    type: String,
    required: true
  },
  values: {
    type: Array,
    required: true
  },
  isExpanded: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits(["update:selectedValueId", "change", "update:isExpanded"]);

const _selectedValueId = computed({
  get: () => props.selectedValueId,
  set: (val) => {
    emit("update:selectedValueId", val);
    emit("change");
  }
});

const selectedValue = computed(() => props.values.find((val) => val.id === props.selectedValueId));

const id = "id" + Math.random().toString(16).slice(2);

const isText = computed(() => props.type === "default");
const isSwatch = computed(() => props.type === "swatch");

const isExpanded = ref(props.isExpanded);
watch(isExpanded, (val) => emit("update:isExpanded", val));
watch(
  () => props.isExpanded,
  (val) => (isExpanded.value = val)
);
</script>
<template>
  <div class="border border-solid border-neutral-30" role="group" :aria-labelledby="id">
    <button class="flex w-full items-center py-f3 px-4" @click="isExpanded = !isExpanded">
      <div :id="id" class="text-body-lg flex-none pr-3">{{ props.name }}</div>
      <div class="flex min-w-0 flex-1 items-center justify-end gap-2 pr-2">
        <img v-if="isSwatch" :src="selectedValue.swatchImage" class="h-[1.125em] w-[1.125em] flex-none rounded-full" />
        <span class="truncate">{{ selectedValue.name }}</span>
      </div>
      <FhIcon
        name="DownChevron"
        class="flex-none text-2xl transition-transform duration-200 ease-linear"
        :class="{ 'rotate-180': isExpanded }"
        aria-hidden="true"
      />
    </button>
    <TransitionExpand>
      <div v-show="isExpanded">
        <div class="mb-f1 flex overflow-x-auto px-4 pb-f2 md:flex-wrap" :class="{ '-ml-2': isText, '-ml-1.5': isSwatch }">
          <label
            v-for="(value, i) in props.values"
            :key="i"
            :title="value.name"
            class="group relative block cursor-pointer"
            :class="{ 'ml-2 mb-2': isText, 'ml-1.5 mb-1.5': isSwatch }"
          >
            <input
              v-model="_selectedValueId"
              type="radio"
              :value="value.id"
              class="input pointer-events-none absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 opacity-0"
            />
            <span
              v-if="isText"
              class="label text-body-base block border border-solid border-neutral-40 bg-white px-4 py-3.5"
              :class="{ 'group-hover:border-neutral-50': !value.disabled, 'text-neutral-40': value.disabled }"
            >
              {{ value.name }}
            </span>
            <span
              v-if="isSwatch"
              class="label relative block h-16 w-16 rounded-full border border-solid border-transparent bg-white p-1"
              :class="{ 'group-hover:border-neutral-40': !value.disabled }"
            >
              <img :src="value.swatchImage" :alt="value.name" class="w-full rounded-full object-cover" :class="{ 'opacity-40': value.disabled }" />
              <div v-if="value.disabled" class="absolute top-0 left-0 flex h-full w-full p-1">
                <div class="flex-1 rounded-full border border-solid border-neutral-70">
                  <div class="relative top-1/2 h-px w-full rotate-[120deg] bg-neutral-70"></div>
                </div>
              </div>
            </span>
          </label>
        </div>
      </div>
    </TransitionExpand>
  </div>
</template>

<style scoped>
.input:checked + .label {
  @apply border-neutral-70;
}

.input:focus-visible + .label {
  @apply outline outline-2 outline-offset-2 outline-neutral-70;
}
</style>

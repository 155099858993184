import axiosClient from "./axiosClient";
import { useQueryClient, useQuery, useMutation } from "@tanstack/vue-query";

const QUERY_KEY = ["checkout"];

export function useCheckoutQuery(options) {
  return useQuery({
    ...(options || {}),
    queryKey: QUERY_KEY,
    queryFn: () => axiosClient.get("/checkout"),
    // checkout data should be as fresh as possible because items can EOL during a checkout session
    refetchOnWindowFocus: true
  });
}

export const UPDATE_CHECKOUT_PATHS = Object.freeze({
  SHIPPING: "shipping",
  SHIPPING_DETAILS: "shipping_details",
  PAYMENT: "payment",
  PO: "po"
});

export function useCreateCheckoutMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: () => axiosClient.post("/checkout"),
    onSuccess: (data) => queryClient.setQueryData(QUERY_KEY, data)
  });
}

export function useUpdateCheckoutMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (data) => {
      if (!Object.values(UPDATE_CHECKOUT_PATHS).includes(data.path)) {
        throw new Error(`Unsupported path: ${data.path}`);
      }
      return axiosClient.put(`/checkout/${data.path}`, data.data);
    },
    onSuccess: (data) => queryClient.setQueryData(QUERY_KEY, data)
  });
}

export function useSubmitOrderMutation(options) {
  return useMutation({
    ...(options || {}),
    mutationFn: () => axiosClient.post("/checkout/submit_order")
  });
}

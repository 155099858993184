<script setup>
import { ref, reactive, nextTick, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import FhButton from "@/components/FhButton.vue";
import FhLink from "@/components/FhLink.vue";
import FhFullscreenFocusedLayout from "@/components/FhFullscreenFocusedLayout.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhInput from "@/components/FhInput.vue";
import FhNewPassword from "@/components/FhNewPassword.vue";
import SidebarImg from "@/assets/img/trade_program_sidebar.jpg";
import { SUPPORT_PHONE_NUMBER } from "@/constants/strings";
import { LOGIN } from "@/constants/routes";

const props = defineProps({
  companyName: {
    type: String,
    required: true
  },
  username: {
    type: String,
    required: true
  },
  email: {
    type: String,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  isSignUpComplete: {
    type: Boolean,
    default: false
  },
  isLoginComplete: {
    type: Boolean,
    default: false
  },
  isInvalid: {
    type: Boolean,
    default: false
  },
  guestAccount: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["submit"]);

const model = reactive({
  password: ""
});
const isGuestAccount = ref(props.guestAccount);
const vuelidate = useVuelidate({}, model);

const form = ref(null);
const focusFirstEmptyInput = () => {
  nextTick(() => form.value.querySelector("input:placeholder-shown")?.focus());
};
onMounted(focusFirstEmptyInput);

function submit() {
  emit("submit", model);
}
</script>

<template>
  <FhFullscreenFocusedLayout ref="layout" :background-image="SidebarImg">
    <template #default>
      <!-- INVALID STATE -->
      <FhHeading v-if="props.isInvalid" heading="Sorry, there is a problem">
        <template #subheading>
          This link is invalid. If you already have a FourHands.com account and know your login details, you may <FhLink :href="LOGIN">log in here</FhLink>. If
          you are a new customer trying to set up your account for the first time, please contact support at {{ SUPPORT_PHONE_NUMBER }}.
        </template>
      </FhHeading>
      <!-- SUCCESS STATE -->
      <div v-else-if="!props.isLoading && props.isSignUpComplete" class="flex flex-col items-start gap-f6">
        <div v-if="!isGuestAccount" class="font-serif text-f-2xl-9xl">Your account has officially been created!</div>
        <div v-else class="font-serif text-f-2xl-9xl">Your Guest Account is Ready</div>
        <div class="flex w-full flex-col gap-f3 border-y border-neutral-30 py-f3 md:flex-row md:flex-wrap">
          <div v-if="!isGuestAccount" class="mr-f5 flex flex-col gap-f0.5">
            <div class="text-neutral-50">Customer Number</div>
            <div class="text-f-lg-3xl">{{ props.username }}</div>
          </div>
          <div class="flex flex-col gap-f0.5">
            <div class="text-neutral-50">Email Address</div>
            <div class="text-f-lg-3xl">{{ props.email }}</div>
          </div>
        </div>
        <p v-if="!isGuestAccount" class="text-body-lg max-w-xl">
          Our goal is to offer you and your space something transformative. There is no place we won&rsquo;t go to discover creative perspectives, innovative
          materials and exclusive products to set you apart.
        </p>
        <p v-else class="text-body-lg max-w-xl">
          Now you&rsquo;ll be able to browse over 6,000 styles across living, dining, art and more with new pieces arriving each week.
        </p>
        <FhButton color="primary" size="large" :href="props.isLoginComplete ? '/' : `${LOGIN}?returnUrl=/`">
          {{
            props.isLoginComplete
              ? !isGuestAccount
                ? "Start Shopping"
                : "Start Browsing"
              : !isGuestAccount
                ? "Log In to Start Shopping"
                : "Log in to Start Browsing"
          }}
        </FhButton>
      </div>
      <!-- INITIAL STATE -->
      <div v-else ref="form">
        <FhHeading
          :heading="!isGuestAccount ? `Welcome, ${props.companyName}` : 'Welcome'"
          :subheading="
            !isGuestAccount
              ? 'You have been approved for a trade account where you can easily buy Four Hands products, manage orders, and most importantly, discover style. Create your account to get started.'
              : 'A guest account has been created for you by a Four Hands customer. Please set your password below so that you can start browsing our full catalog.'
          "
        />
        <div class="grid grid-cols-1 gap-x-f3 gap-y-f4 lg:grid-cols-2">
          <FhInput v-if="!isGuestAccount" :model-value="props.username" label="Customer Number" disabled :wrapper-attrs="{ class: 'lg:col-span-2' }" />
          <FhInput :model-value="props.email" label="Email" disabled :wrapper-attrs="{ class: 'lg:col-span-2' }" />
        </div>
        <form class="flex flex-col" @submit.prevent="submit">
          <div class="mb-14 mt-f4 flex flex-col">
            <FhNewPassword
              v-model="model.password"
              :label-password="'Password'"
              :label-confirm="'Confirm Password'"
              :placeholder-password="'Enter password'"
              :placeholder-confirm="'Confirm password'"
            >
            </FhNewPassword>
          </div>
          <div class="flex flex-col gap-3 md:items-start md:self-end">
            <FhButton type="submit" color="primary" :disabled="vuelidate.$invalid || props.isLoading" :is-loading="props.isLoading">Create Account</FhButton>
          </div>
        </form>
      </div>
    </template>
  </FhFullscreenFocusedLayout>
</template>

<script setup>
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";

// TODO revisit combining with the FhDownloads component or renaming this component if we want to use outside of the PDP

const props = defineProps({
  /**
   * An array of download objects
   */
  downloads: {
    type: Array,
    default: () => []
  }
});
</script>

<template>
  <template v-for="(download, i) in props.downloads" :key="i">
    <div>
      <FhButton :href="download.url" variant="text" size="medium" target="_blank"><FhIcon name="Download" />{{ download.label }}</FhButton>
    </div>
  </template>
</template>

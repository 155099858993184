<script setup>
import { computed, onMounted, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, requiredIf, required, maxLength } from "@vuelidate/validators";
import { useViewportSizes } from "@/composables";
import FhButton from "@/components/FhButton.vue";
import FhCheckbox from "@/components/FhCheckbox.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhInput from "@/components/FhInput.vue";
import FhOptionGroup from "@/components/FhOptionGroup.vue";
import FhSelect from "@/components/FhSelect.vue";
import FhTextarea from "@/components/FhTextarea.vue";
import FhRadio from "@/components/FhRadio.vue";
import TransitionExpand from "@/transitions/TransitionExpand.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  model: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(["update:modelValue", "navigate"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const isRetail = computed(() => {
  return props.model.primaryBusiness === "retailer";
});

const isNotCommercialorResidential = computed(() => {
  return props.model.primaryBusiness === "retailer" || props.model.primaryBusiness.includes("e-commerce");
});

const isEcommerce = computed(() => {
  return props.model.primaryBusiness === "e-commerce";
});

const servicesOtherRequired = computed(() => {
  return value.value.services.includes("OTHER (please describe)");
});

watch(servicesOtherRequired, (val) => {
  if (val) return;
  value.value.servicesOther = "";
});

onMounted(() => {
  if (!isNotCommercialorResidential.value) {
    value.value.services = [];
    value.value.servicesOther = "";
    value.value.brands = "";
  }

  if (!isRetail.value) {
    value.value.locations = "";
  }

  if (!servicesOtherRequired.value) {
    value.value.servicesOther = "";
  }

  if (!value.value.social.website.selected) {
    value.value.websiteEcommCapable = null;
  }

  if (isEcommerce.value) {
    value.value.websiteEcommCapable = true;
  }
});

const validatedState = computed(() => {
  return {
    ...value.value,
    isAtLeastOneSocialSelected: computed(() => Object.values(value.value.social).some((item) => item.selected))
  };
});

const vuelidate = useVuelidate(
  {
    locations: { requiredIf: helpers.withMessage("Please select a number of locations.", requiredIf(isRetail)) },
    revenue: { required: helpers.withMessage("Please select your annual revenue.", required) },
    staff: { required: helpers.withMessage("Please select the number of designers and/or sales people you have on staff.", required) },
    servicesOther: {
      requiredIf: helpers.withMessage("Please enter other services offered.", requiredIf(servicesOtherRequired)),
      maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(32768))
    },
    description: {
      required: helpers.withMessage("Please enter information about your business.", required),
      maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(32768))
    },
    brands: {
      requiredIf: helpers.withMessage("Please enter key brands you carry.", requiredIf(isNotCommercialorResidential)),
      maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(32768))
    },
    social: {
      website: {
        user: {
          requiredIf: helpers.withMessage(
            "Please enter a website URL.",
            requiredIf(() => value.value.social.website.selected)
          ),
          maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(255)),
          validWebsite: helpers.withMessage(
            "Please enter a valid website URL.",
            helpers.regex(
              /^((ftp|http|https):\/\/)?(www.)?(?![a-zA-Z0-9_.-]*(ftp|http|https|www\.))[a-zA-Z0-9_.-]+(\.[a-zA-Z]{2,62})+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/
            )
          )
        }
      },
      instagram: {
        user: {
          requiredIf: helpers.withMessage(
            "Please enter Instagram handle.",
            requiredIf(() => value.value.social.instagram.selected)
          ),
          maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(255))
        }
      },
      facebook: {
        user: {
          requiredIf: helpers.withMessage(
            "Please enter Facebook page.",
            requiredIf(() => value.value.social.facebook.selected)
          ),
          maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(255))
        }
      },
      pinterest: {
        user: {
          requiredIf: helpers.withMessage(
            "Please enter Pinterest handle.",
            requiredIf(() => value.value.social.pinterest.selected)
          ),
          maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(255))
        }
      },
      other: {
        network: {
          requiredIf: helpers.withMessage(
            "Please enter other social platform.",
            requiredIf(() => value.value.social.other.selected)
          ),
          maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(50))
        },
        user: {
          requiredIf: helpers.withMessage(
            "Please enter other social platform handle.",
            requiredIf(() => value.value.social.other.selected)
          ),
          maxLength: helpers.withMessage("Character limit exceeded, please shorten your entry.", maxLength(200))
        }
      }
    },
    isAtLeastOneSocialSelected: {
      required: helpers.withMessage("Please select at least one social network.", (val) => val === true)
    },
    websiteEcommCapable: {
      requiredIf: helpers.withMessage(
        "Please select Yes or No.",
        requiredIf(() => value.value.social.website.selected && !isEcommerce.value)
      )
    }
  },
  validatedState
);

const locations = [
  ["1-2", "1-2"],
  ["3-5", "3-5"],
  ["6+", "6+"]
];

const revenue = [
  ["< $500K", "<$500K"],
  ["$500K-$1M", "$500K-$1M"],
  ["$1M-$5M", "$1M-$5M"],
  ["$5M-$10M", "$5M-$10M"],
  ["$10M+", "$10M+"]
];

const numberStaff = [
  ["1-2", "1-2"],
  ["3-5", "3-5"],
  ["6-10", "6-10"],
  ["10+", "10+"]
];

const services = [
  {
    value: "IN HOME DESIGN SERVICES",
    label: "Design Services"
  },
  {
    value: "TRADE PROGRAM",
    label: "Trade program"
  },
  {
    value: "OTHER (please describe)",
    label: "Other"
  }
];

const { isSm } = useViewportSizes();
</script>

<template>
  <div class="flex flex-col">
    <FhHeading heading="Business details" subheading="Please tell us more about your business." />
    <div class="flex flex-col gap-f4">
      <div class="flex flex-col gap-f4 lg:flex-row lg:gap-f3">
        <FhSelect
          v-if="isRetail"
          v-model="value.locations"
          label="How many locations do you have?"
          :options="locations"
          required
          :vuelidate="vuelidate.locations"
          :wrapper-attrs="{ class: 'flex-1' }"
        />
        <FhSelect
          v-model="value.revenue"
          label="What is your annual revenue?"
          :options="revenue"
          required
          :vuelidate="vuelidate.revenue"
          :wrapper-attrs="{ class: 'flex-1' }"
        />
      </div>
      <FhSelect
        v-model="value.staff"
        label="How many designers and/or salespeople do you have on staff?"
        :options="numberStaff"
        required
        :vuelidate="vuelidate.staff"
      />
      <div>
        <FhOptionGroup
          v-if="isNotCommercialorResidential"
          v-model="value.services"
          type="checkbox"
          label="Do you offer any additional services?"
          :options="services"
        />
        <TransitionExpand>
          <FhTextarea
            v-if="isNotCommercialorResidential && value.services.includes('OTHER (please describe)')"
            v-model="value.servicesOther"
            label="If other, please describe"
            required
            :vuelidate="vuelidate.servicesOther"
            :wrapper-attrs="{ class: 'pt-f3' }"
          />
        </TransitionExpand>
      </div>
      <FhTextarea v-model="value.description" label="Briefly describe your business" required :vuelidate="vuelidate.description" />
      <FhTextarea
        v-if="isNotCommercialorResidential"
        v-model="value.brands"
        label="What are the key brands you carry?"
        required
        :vuelidate="vuelidate.brands"
      />
      <fieldset
        :aria-required="true"
        :aria-invalid="vuelidate.isAtLeastOneSocialSelected.$error"
        :aria-errormessage="vuelidate.isAtLeastOneSocialSelected.$error ? 'socialErrorMessage' : null"
      >
        <legend class="mb-f2 flex flex-row flex-wrap items-baseline gap-f1">
          <span>Please provide your current online presence. Select all that apply.</span>
          <span class="text-body-xs text-neutral-50">(Required)</span>
        </legend>
        <div class="flex flex-col gap-f2">
          <FhCheckbox
            v-model="value.social.website.selected"
            label="Website URL"
            :disabled="isEcommerce"
            @change="vuelidate.isAtLeastOneSocialSelected.$touch"
          />
          <FhInput
            v-if="value.social.website.selected"
            v-model="value.social.website.user"
            label="Enter website URL"
            required
            :vuelidate="vuelidate.social.website.user"
          />
          <FhCheckbox v-model="value.social.instagram.selected" label="Instagram" @change="vuelidate.isAtLeastOneSocialSelected.$touch" />
          <FhInput
            v-if="value.social.instagram.selected"
            v-model="value.social.instagram.user"
            label="Enter your Instagram handle"
            required
            :vuelidate="vuelidate.social.instagram.user"
          />
          <FhCheckbox v-model="value.social.facebook.selected" label="Facebook" @change="vuelidate.isAtLeastOneSocialSelected.$touch" />
          <FhInput
            v-if="value.social.facebook.selected"
            v-model="value.social.facebook.user"
            label="Enter your Facebook page"
            required
            :vuelidate="vuelidate.social.facebook.user"
          />
          <FhCheckbox v-model="value.social.pinterest.selected" label="Pinterest" @change="vuelidate.isAtLeastOneSocialSelected.$touch" />
          <FhInput
            v-if="value.social.pinterest.selected"
            v-model="value.social.pinterest.user"
            label="Enter your Pinterest handle"
            required
            :vuelidate="vuelidate.social.pinterest.user"
          />
          <FhCheckbox v-model="value.social.other.selected" label="Other" @change="vuelidate.isAtLeastOneSocialSelected.$touch" />
          <FhInput
            v-if="value.social.other.selected"
            v-model="value.social.other.network"
            label="Enter name of social platform"
            required
            :vuelidate="vuelidate.social.other.network"
          />
          <FhInput
            v-if="value.social.other.selected"
            v-model="value.social.other.user"
            label="Enter your username"
            required
            :vuelidate="vuelidate.social.other.user"
          />
        </div>
        <div v-if="vuelidate.isAtLeastOneSocialSelected.$error" id="socialErrorMessage" class="text-body-sm mt-f2 text-brand-red">
          <div v-for="err of vuelidate.isAtLeastOneSocialSelected.$errors" :key="err.$uid">{{ err.$message }}</div>
        </div>
      </fieldset>
      <fieldset
        v-if="value.social.website.selected && !isEcommerce"
        :aria-required="value.social.website.selected && !isEcommerce"
        :aria-invalid="vuelidate.websiteEcommCapable.$error"
        :aria-errormessage="vuelidate.websiteEcommCapable.$error ? 'websiteEcommCapableErrorMessage' : null"
      >
        <legend class="mb-f2 flex flex-row flex-wrap items-baseline gap-f1">
          <span>Do you allow customers to purchase from your website?</span>
          <span class="text-body-xs text-neutral-50">(Required)</span>
        </legend>
        <div class="flex flex-col gap-f2">
          <FhRadio v-model="value.websiteEcommCapable" :value="true">Yes</FhRadio>
          <FhRadio v-model="value.websiteEcommCapable" :value="false">No</FhRadio>
        </div>
        <div v-if="vuelidate.websiteEcommCapable.$error" id="websiteEcommCapableErrorMessage" class="text-body-sm mt-f2 text-brand-red">
          <div v-for="err of vuelidate.websiteEcommCapable.$errors" :key="err.$uid">{{ err.$message }}</div>
        </div>
      </fieldset>
    </div>
    <div class="mt-f8 flex items-center gap-f3 md:self-end">
      <FhButton :variant="isSm ? false : 'text'" :class="{ 'flex-1': isSm }" @click="emit('navigate', -1)">Back</FhButton>
      <FhButton :disabled="vuelidate.$invalid" color="primary" :class="{ 'flex-1': isSm }" @click="emit('navigate', 6)">Continue</FhButton>
    </div>
  </div>
</template>

<style lang="pcss" scoped></style>

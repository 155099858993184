import axiosClient from "@/api/axiosClient";
import { useQuery, useQueryClient, useMutation } from "@tanstack/vue-query";
import PaytraceEncryption from "@/util/paytraceEncryption";

const QUERY_KEY_BASE = "payment_cards";
const encryptService = new PaytraceEncryption();

export const useCreditCardQuery = (params, options) => {
  return useQuery({
    ...(options || {}),
    queryKey: [QUERY_KEY_BASE, { includeSingleUse: !!params?.includeSingleUse }],
    queryFn: () => axiosClient.get("/payment_cards", { params }),
    refetchOnWindowFocus: false
  });
};

export const useCreateCreditCardMutation = (options) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (creditCard) => {
      //TODO: how should we handle errors
      const encryptedCardNumber = encryptService.encryptValue(creditCard.cardNumber);
      const lastFour = creditCard.cardNumber.slice(-4);
      const expiration = creditCard.expiration.replace(/\s+/g, "");
      const updatedCCData = { ...creditCard, cardNumber: encryptedCardNumber, lastFour, expiration };
      return axiosClient.post("/payment_cards", updatedCCData);
    },
    onSuccess: (newCreditCard, { isSingleUse }) => {
      // Add the new card to the query cache(s)
      const queryKeyFilter = [QUERY_KEY_BASE];
      if (isSingleUse) {
        queryKeyFilter.push({ includeSingleUse: true });
      }
      queryClient.setQueriesData({ queryKey: queryKeyFilter }, (existingCreditCards) => {
        return [...(existingCreditCards || []), newCreditCard];
      });
    }
  });
};

export function useDeleteCreditCardMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (id) => axiosClient.delete(`/payment_cards/${encodeURIComponent(id)}`),
    onSuccess: (_data, deletedId) => {
      // Remove the deleted card from the query cache(s)
      queryClient.setQueriesData([QUERY_KEY_BASE], (existingCreditCards) => {
        let deletedCreditCardIndex = existingCreditCards.findIndex((creditCard) => creditCard.id === deletedId);
        if (deletedCreditCardIndex < 0) {
          return existingCreditCards;
        } else {
          return [...existingCreditCards.slice(0, deletedCreditCardIndex), ...existingCreditCards.slice(deletedCreditCardIndex + 1)];
        }
      });
    }
  });
}

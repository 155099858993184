<script setup>
import { getDataRefs } from "../util/vue";
import FhHeading from "@/components/FhHeading.vue";
import FhSection from "../includes/FhSection.vue";
import { onMounted, ref } from "vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { heading, orderHistoryUrl } = getDataRefs(props.data);

const iframe = ref(null);

onMounted(() => {
  window.addEventListener(
    "message",
    function (e) {
      let message = e.data;
      iframe.value.style.height = message + "px";
      //console.log("set iframe to " + message);
    },
    false
  );
});
</script>

<template>
  <FhSection :base="style.base">
    <!-- ORDER STATUS TERMS -->
    <div class="mb-f10 flex flex-col text-neutral-70 md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10 md:pb-f10">
      <FhHeading tag="h2">{{ heading }}</FhHeading>
      <div class="mb-5 hidden md:flex md:flex-col">
        <h2 class="mb-4 text-xl lg:mb-10">Order Status Terms</h2>
        <div class="mb-2 flex flex-col md:flex-row">
          <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12"> Open </span>
          <span class="text-neutral-50 md:basis-8/12 lg:basis-9/12"> Order in progress </span>
        </div>
        <div class="mb-2 flex flex-col md:flex-row">
          <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12"> Payment Processing </span>
          <span class="text-neutral-50 md:basis-8/12 lg:basis-9/12"> Payment pending approval </span>
        </div>
        <div class="mb-2 flex flex-col md:flex-row">
          <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12"> Processing </span>
          <span class="text-neutral-50 md:basis-8/12 lg:basis-9/12"> Packaging for shipment </span>
        </div>
        <div class="mb-2 flex flex-col md:flex-row">
          <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12"> Boarded </span>
          <span class="text-neutral-50 md:basis-8/12 lg:basis-9/12"> Ready for transit </span>
        </div>
        <div class="mb-2 flex flex-col md:flex-row">
          <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12"> Shipped </span>
          <span class="text-neutral-50 md:basis-8/12 lg:basis-9/12"> Order en route </span>
        </div>
      </div>
      <div class="block">
        <iframe ref="iframe" :src="orderHistoryUrl" frameborder="0" style="width: 100%; border: none" scrolling="no"></iframe>
      </div>
    </div>
  </FhSection>
</template>

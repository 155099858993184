<script setup>
import { ref, computed, watch, nextTick } from "vue";
import FhIcon from "./FhIcon.vue";

const props = defineProps({
  /**
   * Model of the component; Either use this property (along with a listener for 'update:model-value' event) OR use v-model directive
   */
  modelValue: {
    type: Number,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const MIN = 1;
const MAX = 999;

const input = ref(null);
const value = ref(props.modelValue);

function tryUpdateValue(incomingValue) {
  let oldValue = value.value;
  let n = parseInt(incomingValue);
  if (incomingValue === "" || (/^\d+$/.test(incomingValue) && n >= MIN && n <= MAX)) {
    value.value = n;
  } else {
    nextTick(() => {
      input.value.value = isNaN(oldValue) ? "" : oldValue;
    });
  }
}

watch(
  () => props.modelValue,
  (value) => {
    tryUpdateValue(value);
  }
);

function handleInput(event) {
  tryUpdateValue(event.target.value);
}

function handleBlur() {
  if (isNaN(value.value)) {
    value.value = MIN;
  }
  emit("blur");
}

const isDecreasable = computed(() => !props.disabled && !isNaN(value.value) && value.value > MIN);
const isIncreasable = computed(() => !props.disabled && !isNaN(value.value) && value.value < MAX);

function decrement() {
  if (isDecreasable.value) {
    value.value = value.value - 1;
    emit("step", value.value);
  }
}

function increment() {
  if (isIncreasable.value) {
    value.value = value.value + 1;
    emit("step", value.value);
  }
}

const emit = defineEmits(["update:modelValue", "blur", "step"]);
watch(value, (value) => emit("update:modelValue", value));
</script>

<template>
  <label class="relative flex w-32">
    <input
      ref="input"
      :value="isNaN(value) ? '' : value"
      inputmode="numeric"
      pattern="[0-9]*"
      :min="MIN"
      :max="MAX"
      :disabled="props.disabled"
      aria-label="Quantity"
      class="text-body-lg w-full border border-neutral-30 bg-white py-3.5 text-center focus:border-neutral-70 focus:outline-none disabled:border-neutral-30 disabled:bg-neutral-20 disabled:text-neutral-50"
      @focus="input.select()"
      @input="handleInput"
      @blur="handleBlur"
      @keydown.up.prevent="increment"
      @keydown.down.prevent="decrement"
      @keyup.enter.prevent="input.blur()"
    />
    <button class="spin-button left-f0.5" aria-hidden="true" tabindex="-1" title="Decrement" :disabled="!isDecreasable" @click="decrement">
      <FhIcon name="MinusSign" class="text-body-xl" />
    </button>
    <button class="spin-button right-f0.5" aria-hidden="true" tabindex="-1" title="Increment" :disabled="!isIncreasable" @click="increment">
      <FhIcon name="PlusSign" class="text-body-xl" />
    </button>
  </label>
</template>

<style scoped>
.spin-button {
  @apply absolute h-full w-11 text-center;

  &:disabled {
    @apply text-neutral-40;
  }
}
</style>

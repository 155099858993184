import axiosClient from "@/api/axiosClient";
import { useQuery, useMutation, useQueryClient } from "@tanstack/vue-query";

const QUERY_KEY = ["shopping_list"];
const QUERY_KEY_DETAILS = ["shopping_list_details"];
const QUERY_KEY_CART = ["cart"];

//Get to shopping List Management page
export const useShoppingListQuery = (options = null) => {
  return useQuery({
    ...(options || {}),
    queryKey: QUERY_KEY,
    queryFn: () => axiosClient.get("/shoppinglist"),
    select: (shoppingListResponse) => {
      return shoppingListResponse.shoppingList;
    },
    refetchOnWindowFocus: false
  });
};

//Get to shopping List Detail page
export const useShoppingListDetailQuery = (guid, isPreview, options) => {
  return useQuery({
    ...(options || {}),
    queryKey: QUERY_KEY_DETAILS,
    queryFn: () => axiosClient.get(`/shoppinglist/${guid}/${isPreview}`),
    select: (shoppingListDetailResponse) => shoppingListDetailResponse.shoppingList[0],
    refetchOnWindowFocus: false
  });
};

// Create shopping List
export function useCreateShoppingListMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: ({ id, title, description, pricingVisibility, ShoppingListItems }) =>
      axiosClient.post("/shoppinglist", { id, title, description, pricingVisibility, ShoppingListItems }),
    onSuccess: (updatedShoppingList) => {
      const queryKeyFilter = QUERY_KEY;
      queryClient.setQueryData({ queryKey: queryKeyFilter }, updatedShoppingList);
    }
  });
}

//Update Shopping List
export function useUpdateShoppingListMutation(options, isDetailsPage = false) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: ({ id, title, description, pricingVisibility }) =>
      axiosClient.post("/shoppinglist", {
        id,
        title,
        description,
        pricingVisibility
      }),
    onSuccess: (updatedShoppingList, payLoadData) => {
      if (isDetailsPage) {
        const { id: listIdToUpdate } = payLoadData;
        const shoppingLists = updatedShoppingList.shoppingList;
        const updatedDetails = shoppingLists.find((shopping_list) => shopping_list.id == listIdToUpdate);
        queryClient.setQueryData({ queryKey: QUERY_KEY_DETAILS }, { shoppingList: [updatedDetails] });
      } else {
        const queryKeyFilter = QUERY_KEY;
        queryClient.setQueryData({ queryKey: queryKeyFilter }, updatedShoppingList);
      }
    }
  });
}

//Delete shopping List Mutation
export function useDeleteShoppingListMutation(options, isDetailPage = false) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (id) => axiosClient.delete(`/shoppinglist/${id}`),
    onSuccess: (_data, deletedId) => {
      if (isDetailPage) return;
      const queryKeyFilter = QUERY_KEY;
      queryClient.setQueryData(queryKeyFilter, ({ shoppingList }) => {
        const updatedShoppingList = shoppingList.filter((item) => item.id !== deletedId);
        return { shoppingList: updatedShoppingList };
      });
    }
  });
}

// Update the Shopping List Item Quantity
export function useUpdateItemMutation(options, isDetailPage = false) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.post("/shoppinglist/item", item),
    onSuccess: (updatedShoppingListResponse) => {
      const { shoppingList: updatedShoppingList } = updatedShoppingListResponse;
      if (isDetailPage) {
        // Quick and dirty, will need to update to target specific item as to not cause a full item refresh
        queryClient.setQueryData(QUERY_KEY_DETAILS, updatedShoppingListResponse);
      } else {
        queryClient.setQueryData(QUERY_KEY, (shoppingListCache) => {
          const updatedList = shoppingListCache.shoppingList.map((list) => (list.id == updatedShoppingList[0].id ? updatedShoppingList[0] : list));
          return { shoppingList: updatedList };
        });
      }
    }
  });
}

// Delete Item From the shopping List
export function useDeleteShoppingItemMutation(options, isDetailPage = false) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.delete(`/shoppinglist/${item.ShoppingListID}/item/${item.id}`),
    onSuccess: (_data, itemToRemoveObject) => {
      if (isDetailPage) {
        queryClient.setQueryData(QUERY_KEY_DETAILS, (shoppingDetailCache) => {
          const shoppingDetail = shoppingDetailCache.shoppingList[0];
          const updatedProducts = shoppingDetail.products.filter((item) => item.id !== itemToRemoveObject.id);
          let totalQuantity = 0;
          let totalPrice = 0;
          updatedProducts.forEach((item) => {
            totalQuantity += item.quantity;
            totalPrice += item.totalPrice;
          });
          return {
            shoppingList: [
              {
                ...shoppingDetail,
                products: updatedProducts,
                totalItems: totalQuantity,
                totalPrice: totalPrice
              }
            ]
          };
        });
      } else {
        queryClient.setQueryData(QUERY_KEY, (shoppingListsCache) => {
          const updatedList = shoppingListsCache.shoppingList.reduce((accum, currentList) => {
            if (currentList.id == itemToRemoveObject.ShoppingListID) {
              const listRemovedProductArray = currentList.products.filter((product) => product.id != itemToRemoveObject.id);
              const totalItems = Math.max(0, currentList.totalItems - itemToRemoveObject.quantity);
              accum.push({ ...currentList, products: listRemovedProductArray, totalItems });
            } else {
              accum.push(currentList);
            }
            return accum;
          }, []);

          return { shoppingList: updatedList };
        });
      }
    }
  });
}

//Add All to cart
export function useAddAllToCartMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (guid) => axiosClient.post(`/shoppinglist/addalltocart/${guid}`),
    onSuccess: (response) => {
      queryClient.setQueryData(QUERY_KEY_CART, response);
    }
  });
}

// Add to cart mutation
export function useAddToCartMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.post("/shoppinglist/addtocart", item),
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEY_CART, data);
    }
  });
}

<script setup>
import FhSection from "../includes/FhSection.vue";
import { getDataRefs } from "../util/vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { title, items } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="flex flex-col items-start">
      <h2 class="mb-4 font-serif text-f-2xl-5xl lg:mb-6" role="heading" aria-level="3">{{ title }}</h2>
      <ul :class="['block list-none columns-1 gap-8', items.length > 5 ? 'md:columns-2' : 'md-columns-1']">
        <li v-for="item in items" :key="item.id" class="break-inside-avoid">
          <a :href="item.ctaURL" :aria-label="item.ariaLabel" :target="item.ctaTarget" class="mb-2 flex" download>
            <span
              :class="`pr-3 after:-mr-3 after:inline-block after:h-[12px] after:w-[12px] after:bg-[url('/src/assets/icons/icon-download.svg')] after:bg-contain after:bg-center after:content-['']`"
              ><span class="underline">{{ item.ctaText }}</span
              >&nbsp;&nbsp;</span
            >
          </a>
        </li>
      </ul>
      <hr class="my-f8 self-stretch text-neutral-30" />
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped></style>

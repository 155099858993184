<script setup>
import { ref } from "vue";
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  isActive: {
    type: Boolean,
    default: false
  }
});

const checkoutSectionRef = ref(null);
defineExpose({
  checkoutSectionRef
});
</script>

<template>
  <div ref="checkoutSectionRef" class="flex flex-col md:flex-row md:flex-wrap md:items-center" :class="{ inactive: !props.isActive, 'pb-f10': props.isActive }">
    <div class="py-f5">
      <h2 class="text-f-xl-4xl" :class="{ 'text-neutral-50': !props.isActive }">{{ props.title }}</h2>
    </div>
    <div v-if="props.isActive" class="w-full md:order-1">
      <slot></slot>
    </div>
    <div v-if="$slots.actions" class="mt-f4 flex flex-col gap-f2 md:mt-0 md:ml-auto md:flex-row-reverse">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<style scoped>
.inactive + .inactive {
  @apply border-t border-neutral-30;
}
</style>

<script setup>
import { ref, computed, watch } from "vue";
import FhPaymentCard from "@/components/Ecomm/FhPaymentCard/FhPaymentCard.vue";
import FhPaginationNav from "@/components/FhPaginationNav.vue";
import FhModal from "@/components/FhModal.vue";
import FhButton from "@/components/FhButton.vue";
import FhCard from "@/components/FhCard.vue";
import FhIcon from "@/components/FhIcon.vue";
import { usePaginatedList } from "@/composables";
import { ERROR_RESPONSE_MESSAGES } from "@/constants/payment";
import { useDeleteCreditCardMutation } from "@/api/creditCardApi";
import { useNotificationStore } from "@/stores/NotificationStore";

const props = defineProps({
  cards: {
    type: Array,
    default: () => []
  },
  cardsPerPage: {
    type: Number,
    default: 12
  },
  isError: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const showDeleteModal = ref(false);
const cardToRemove = ref(null);
const cardsToShow = computed(() => props.cards);
const cardRef = ref(null);
const deleteCardError = ref(null);

const { isLoading, mutate: deleteCreditCardMutation } = useDeleteCreditCardMutation();
const notificationStore = useNotificationStore();

const isEmpty = computed(() => props.cards.length === 0);

const handleRemoveCC = (val) => {
  const card = props.cards.find((card) => card.id === val);
  if (card) {
    showDeleteModal.value = true;
    cardToRemove.value = card;
  }
};

const removeCreditCard = () => {
  deleteCreditCardMutation(cardToRemove.value.id, {
    onSuccess: () => notificationStore.notifySuccess("Card removed."),
    onError: handleDeleteError,
    onSettled: (data, error) => {
      if (!error) {
        showDeleteModal.value = false;
        deleteCardError.value = null;
      }
    }
  });
};

const handleDeleteError = (error) => {
  const { data, status } = error.response;
  if (status === 400 && Object.keys(ERROR_RESPONSE_MESSAGES).includes(data?.error_code?.toString())) {
    deleteCardError.value = ERROR_RESPONSE_MESSAGES[data?.error_code]?.error_msg;
  } else {
    notificationStore.notifyError("Sorry, something went wrong and we could not remove that card.");
  }
};

const reset = () => {
  showDeleteModal.value = false;
  deleteCardError.value = null;
};

let { page, totalPages, sublist: currentPage } = usePaginatedList(cardsToShow, props.cardsPerPage);

watch(cardsToShow, (newCards, oldCards) => {
  if (newCards.length > oldCards.length) {
    page.value = totalPages.value;
    cardRef.value[cardRef.value.length - 1]?.paymentCardRef?.scrollIntoView({ behavior: "smooth" });
  }
});
</script>

<template>
  <div class="grid gap-f3 md:grid-cols-2">
    <FhCard v-for="card in currentPage" :key="card.id">
      <FhPaymentCard
        :id="card.id"
        ref="cardRef"
        :card-type="card.cardType"
        :nick-name="card.nickName"
        :last-four="card.lastFour"
        :expiration="card.expiration"
        :is-expired="card.isExpired"
        @delete="handleRemoveCC"
      />
    </FhCard>
  </div>
  <div v-if="totalPages > 1" class="mt-f8 flex items-center justify-center">
    <FhPaginationNav v-model:page="page" :total-items="cards.length" :per-page="props.cardsPerPage" />
  </div>
  <FhModal :data="{ heading: 'Confirm Card Removal' }" :is-open="showDeleteModal" @close="reset">
    <template #body>
      <FhCard>
        <FhPaymentCard
          :id="cardToRemove.id"
          :card-type="cardToRemove.cardType"
          :nick-name="cardToRemove.nickName"
          :last-four="cardToRemove.lastFour"
          :expiration="cardToRemove.expiration"
          :show-remove-link="false"
        />
      </FhCard>
      <div v-if="deleteCardError" class="mt-3 flex items-center gap-2 py-2 text-brand-red">
        <div>
          <fh-icon name="AlertCircle" />
        </div>
        <div v-html="deleteCardError"></div>
      </div>
    </template>
    <template #footer>
      <FhButton color="primary" :disabled="isLoading" :is-loading="isLoading" @click="removeCreditCard">Remove</FhButton>
      <FhButton variant="text" @click="reset">Cancel</FhButton>
    </template>
  </FhModal>
  <div v-if="isEmpty && !props.isError" class="text-body-lg mb-f2 text-neutral-50">You have no saved cards.</div>
  <div v-if="props.isError" class="text-body-lg mb-f2 flex items-center gap-f1">
    <fh-icon name="AlertCircle" class="flex-none" />
    Sorry, we are not able to load your saved cards at this time.
  </div>
</template>

<style scoped></style>

<script setup>
import FhButton from "../FhButton.vue";
import FhPersonModal from "./FhPersonModal.vue";

const props = defineProps({
  person: {
    type: Object,
    required: true
  }
});

let modalData = {
  firstName: props.person.firstName,
  lastName: props.person.lastName,
  role: props.person.role,
  bioCopy: props.person.bioCopy,
  linkedInUrl: props.person.linkedInUrl
};
</script>

<template>
  <!-- PERSON ITEM -->
  <div>
    <FhPersonModal :modal-data="modalData">
      <template #default="{ toggleModal }">
        <component
          :is="person.bioCopy ? FhButton : 'div'"
          v-bind="{ ...(!!person.bioCopy && { variant: 'text', 'aria-label': `Open bio for ${person.firstName} ${person.lastName}` }) }"
          class="flex w-full flex-col justify-start after:hidden"
          @click="person.bioCopy ? toggleModal() : false"
        >
          <div class="fh-person__image relative w-full">
            <img :src="person.image" :alt="person.imageAltText" class="absolute inset-0 h-full w-full object-cover" />
          </div>
          <div class="w-full p-f3 text-left">
            <h3 class="mb-f2 font-serif text-f-2xl-5xl">{{ person.firstName }} {{ person.lastName }}</h3>
            <div class="text-f-base-xl text-neutral-50">
              {{ person.role }}
            </div>
          </div>
        </component>
      </template>
    </FhPersonModal>
  </div>
</template>

<style lang="pcss" scoped>

.fh-person__image {
  &::before {
    display: block;
    width: 100%;
    content: '';
    pointer-events: none;
    z-index: -1;
    padding-top: 100%;
  }
}
</style>

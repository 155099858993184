<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.8988 3.49351C11.0964 1.50216 14.0904 1.50216 15.288 3.49351L23.7233 17.5195C24.9209 19.5108 23.4239 22 21.0287 22H4.15811C1.76289 22 0.265886 19.5108 1.46349 17.5195L9.8988 3.49351ZM13.6478 4.4026C13.1792 3.62338 12.0076 3.62338 11.539 4.4026L3.10369 18.4286C2.63506 19.2078 3.22085 20.1818 4.15811 20.1818H21.0287C21.966 20.1818 22.5518 19.2078 22.0831 18.4286L13.6478 4.4026Z"
      fill="currentColor"
    />
    <path d="M13.5413 8V14H11.458V8H13.5413Z" fill="currentColor" />
    <path d="M13.5518 16H11.458V18H13.5518V16Z" fill="currentColor" />
  </svg>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import FhInput from "@/components/FhInput.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhButton from "@/components/FhButton.vue";
import FhSlideOverPanel from "@/components/FhSlideOverPanel.vue";
import FhCheckbox from "@/components/FhCheckbox.vue";
import FhLink from "@/components/FhLink.vue";
import { MEASUREMENT_SYSTEM } from "@/constants/measurements";

const props = defineProps({
  /**
   * We will receive the Method from the parent slot to open or close the panel
   */
  openPanel: {
    type: Boolean,
    default: false
  },
  /**
   * This will contain the width and height of the wallpaper Imperial System
   */
  dimensionsImperial: {
    type: Object,
    default: () => ({})
  },
  /**
   * This will contain the width and height of the wallpaper Metric System
   */
  dimensionsMetric: {
    type: Object,
    default: () => ({})
  },
  /**
   * Amount of rolls
   */
  quantity: { type: Number, default: 1 },
  /**
   * The reserve is the amount of waste percentage, so the user has an extra amount of roll to use.
   */
  reserve: { type: Number, default: 15 },
  /**
   * Reserve Checkbox ON/OFF
   */
  reserveCheckboxOnOff: { type: Boolean, default: true },
  /**
   * Toggle between the Imperial and Metric systems
   */
  displaySystem: {
    type: String,
    default: () => MEASUREMENT_SYSTEM.IMPERIAL,
    validator: (val) => Object.keys(MEASUREMENT_SYSTEM).includes(val)
  }
});
onMounted(() => calculate());
const emit = defineEmits(["updateWallpaperPdp"]);

const label = computed(() => (props.displaySystem === "IMPERIAL" ? "ft" : "m"));
const label2 = computed(() => (props.displaySystem === "IMPERIAL" ? "in" : "cm"));
const panelMeasurementSysemSign = computed(() => (props.displaySystem === "IMPERIAL" ? '"' : "cm"));
const dimensions = computed(() => (props.displaySystem === MEASUREMENT_SYSTEM.IMPERIAL ? props.dimensionsImperial : props.dimensionsMetric));
const wp_width = computed(() => dimensions.value.width ?? 0);
const wp_length = computed(() => dimensions.value.height ?? 0);

watch(
  () => [wp_width.value, wp_length.value],
  () => calculate()
);
const decimals = ref(2);
const reserveCheckbox = ref(props.reserveCheckboxOnOff);

const rollDimensions = computed(() => {
  const dimensions = props.displaySystem === MEASUREMENT_SYSTEM.IMPERIAL ? props.dimensionsImperial : props.dimensionsMetric;
  const x = convert(dimensions.width);
  const y = convert(dimensions.height);
  const baseValue = calculateArea(x, y);
  return roundToNearest(baseValue, decimals.value);
});

const bannerNumberRolls = ref(props.quantity ?? 1);
const panelNumberRolls = ref(0);
const exededMaxAmount = ref(false);

const singularPluralRoll = (quantity = 1) => {
  return quantity > 1 ? "rolls" : "roll";
};

watch(
  () => props.quantity,
  () => {
    bannerNumberRolls.value = props.quantity;
    calculateTotalRollDimensions();
  }
);
const totalDimensionsNeededBanner = computed(() => calculateTotalRollDimensions(bannerNumberRolls.value));
const totalDimensionsNeededPanel = ref(1);
const updatePdpRoll = () => {
  bannerNumberRolls.value = panelNumberRolls.value;
  emit("updateWallpaperPdp", panelNumberRolls.value);
};

const rows = ref([
  {
    width: {
      first_x_value: 0,
      second_x_value: 0,
      total_length: 0
    },
    height: {
      first_y_value: 0,
      second_y_value: 0,
      total_length: 0
    }
  }
]);

watch(
  () => rows.value.length,
  () => calculate()
);
const focusInput = (event) => {
  event.target.select();
};

watch(
  () => reserveCheckbox.value,
  () => calculate()
);

const addRow = () => {
  rows.value.push({
    width: { first_x_value: 0, second_x_value: 0, total_length: 0 },
    height: { first_y_value: 0, second_y_value: 0, total_length: 0 }
  });
};

const removeRow = (index) => {
  if (index > 0) {
    rows.value.splice(index, 1);
  }
};
const checkIfExededAmount = (amountOfRolls) => {
  exededMaxAmount.value = false;
  if (amountOfRolls > 999) {
    exededMaxAmount.value = true;
  }
  return amountOfRolls;
};

const calculate = () => {
  totalDimensionsNeededPanel.value = 0;

  rows.value.forEach((rowElement) => {
    try {
      rowElement.width.total_length = rowElement.width.first_x_value + convert(rowElement.width.second_x_value);
      rowElement.height.total_length = rowElement.height.first_y_value + convert(rowElement.height.second_y_value);
    } catch (error) {
      console.error("Error calculating rows:", error);
    }
  });

  rows.value.forEach((rowElement) => {
    try {
      if (rowElement.width.total_length >= 0 && rowElement.height.total_length >= 0) {
        totalDimensionsNeededPanel.value = totalDimensionsNeededPanel.value + calculateArea(rowElement.width.total_length, rowElement.height.total_length);
      }
    } catch (error) {
      console.error("Error calculating area for each row:", error);
    }
  });

  if (reserveCheckbox.value === true) {
    totalDimensionsNeededPanel.value *= 1 + props.reserve / 100;
  }
  const amountOfRolls = Math.ceil(totalDimensionsNeededPanel.value / rollDimensions.value);

  panelNumberRolls.value = checkIfExededAmount(amountOfRolls);
};

const measurementSysemSign = computed(() => (props.displaySystem === MEASUREMENT_SYSTEM.IMPERIAL ? "sq. ft." : "m²"));

const toggleCheckboxValue = (checkboxValue = reserveCheckbox.value) => {
  reserveCheckbox.value = checkboxValue;
};

const calculateTotalRollDimensions = (rollQty = 1) => {
  let baseValue = rollQty * rollDimensions.value;
  return roundToNearest(baseValue, decimals.value);
};

const dimensionsWithUSFormatSystem = (value) => {
  const formattedValue = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals.value
  }).format(value);
  return `${formattedValue}`;
};

const convert = (value = 0) => {
  if (isNaN(value)) return 0;

  if (props.displaySystem == MEASUREMENT_SYSTEM.IMPERIAL) {
    const totalFeet = value / 12;
    return totalFeet;
  }

  if (props.displaySystem == MEASUREMENT_SYSTEM.METRIC) {
    const totalMeter = value / 100;
    return totalMeter;
  }
};

const calculateArea = (width, length) => {
  return roundToNearest(width * length, decimals.value);
};

const roundToNearest = (num, decimalPlaces) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};
</script>

<template>
  <FhSlideOverPanel :is-open="openPanel" @update:is-open="openPanel">
    <template #activator="{ open }">
      <div class="py-f2 border-y border-neutral-30">
        <div>
          {{ bannerNumberRolls }} {{ singularPluralRoll(bannerNumberRolls) }} of ({{ dimensionsWithUSFormatSystem(rollDimensions) }} {{ measurementSysemSign }})
          = {{ dimensionsWithUSFormatSystem(totalDimensionsNeededBanner) }} {{ measurementSysemSign }}
        </div>
        <FhLink @click="open">Wallpaper Calculator</FhLink>
      </div>
    </template>
    <template #body>
      <h1 class="text-2xl mb-f2 font-semibold">How to Calculate</h1>
      <ol class="w-full px-11 list-decimal pb-3">
        <li>Measure the length and width of your wall.</li>
        <li>Enter your measurements in the calculator, adding sections as needed.</li>
      </ol>

      <h1 class="text-2xl my-f3 font-semibold">Enter Width and Length</h1>
      <TransitionGroup name="row">
        <div class="grid-calculator-container">
          <div v-for="(item, index) in rows" :key="index" class="grid-calculator">
            <FhInput
              v-model="item.width.first_x_value"
              :label="label"
              class="input-L"
              type="number"
              :min="0"
              @:focus="focusInput"
              @:change="calculate"
              @:keyup="calculate"
            />
            <FhInput
              v-model="item.width.second_x_value"
              :label="label2"
              class="input-L"
              type="number"
              :min="0"
              @:focus="focusInput"
              @:change="calculate"
              @:keyup="calculate"
            />
            <div class="icon-multiply">
              <FhIcon name="XIcon" />
            </div>
            <FhInput
              v-model="item.height.first_y_value"
              :label="label"
              class="input-R"
              type="number"
              :min="0"
              @:focus="focusInput"
              @:change="calculate"
              @:keyup="calculate"
            />
            <FhInput
              v-model="item.height.second_y_value"
              :label="label2"
              class="input-R"
              type="number"
              :min="0"
              @:focus="focusInput"
              @:change="calculate"
              @:keyup="calculate"
            />

            <div class="remove-btn w-f8">
              <FhButton
                v-if="index !== 0"
                class="border border-neutral-50 relative rounded-full p-4 leading-none bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
              >
                <FhIcon name="XIcon" @click="removeRow(index)" />
              </FhButton>
            </div>
          </div>
        </div>
      </TransitionGroup>
      <FhButton class="w-full my-f3" color="secondary" @click="addRow"> <FhIcon name="PlusSign" /> Add Section </FhButton>
    </template>

    <template #footer="{ close }">
      <div class="w-full">
        <div class="custom-grid">
          <div class="pr-f4 capitalize">Total needed:</div>
          <div class="text-neutral-50">{{ dimensionsWithUSFormatSystem(totalDimensionsNeededPanel) }} {{ measurementSysemSign }}</div>
        </div>

        <div class="custom-grid">
          <div class="pr-f4 capitalize">Rolls required:</div>
          <div class="text-neutral-50">
            {{ panelNumberRolls }} {{ singularPluralRoll(panelNumberRolls) }} @ {{ wp_width }}{{ panelMeasurementSysemSign }} x {{ wp_length
            }}{{ panelMeasurementSysemSign }}
          </div>
        </div>
        <div class="flex">
          <fh-checkbox :model-value="reserveCheckbox" class="pr-f1 my-f1" @update:model-value="toggleCheckboxValue">
            Add a recommended {{ reserve }}% for waste and reserve
          </fh-checkbox>
        </div>
      </div>
      <small v-if="exededMaxAmount" class="text-body-sm max-w-prose text-brand-red">
        You have exceeded the maximum amount of 999 rolls. Please modify your inputs.
      </small>
      <FhButton
        class="w-full mt-f4"
        color="primary"
        :disabled="exededMaxAmount"
        @click="
          close();
          updatePdpRoll();
        "
      >
        Update Quantity on Product Page
      </FhButton>
    </template>
  </FhSlideOverPanel>
</template>

<style lang="pcss" scoped>
.grid-calculator {
  transition: all 1s;
}

.grid-calculator > div {
  @apply gap-0;
}
.remove-btn,
.icon-multiply {
  align-items: center;
  display: flex;
  align-self: end;
  height: 63px;
}

:deep(.remove-btn button.btn) {
  @apply rounded-full p-4;
}
.grid-calculator {
  @apply mb-f2;
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: auto auto 20px auto auto auto;
  grid-template-areas: "input-L input-L icon-multiply input-R input-R remove-btn";
}
.icon-multiply svg {
  width: 100%;
}

/* ROW ANIMATION */
.row-enter-active,
.row-leave-active {
  transition: all 0.3s ease;
}
.row-enter-from,
.row-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
/* ROW ANIMATION END */

.custom-grid {
  display: grid;
  grid-template-columns: 165px auto;
}
</style>

<script setup>
import { computed } from "vue";
import FhProductCard from "./FhProductCard.vue";
import FhLoader from "@/components/FhLoader.vue";

const props = defineProps({
  products: {
    type: Array,
    default: () => []
  },
  isAdmin: {
    type: Boolean,
    required: true
  },
  hasError: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  isOwner: {
    type: Boolean,
    default: true
  },
  isPreview: {
    type: Boolean,
    default: true
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  isAdminShoppingList: {
    type: Boolean,
    default: false
  }
});
const products = computed(() => props.products);
const isEmpty = computed(() => products.value.length === 0);
const hasError = computed(() => props.hasError);
const emit = defineEmits(["itemQuantityUpdate", "itemDelete", "additemtoCart"]);
</script>

<template>
  <div v-if="props.isLoading" class="w-full"><FhLoader class="mt-10 h-10 w-10" /></div>
  <div v-else class="grid-action sm:grid-cols-1 my-f5 grid grid-cols-1 gap-x-f2.5 gap-y-f10 md:grid-cols-4 lg:grid-cols-4 portrait:grid-cols-2">
    <FhProductCard
      v-for="product in products"
      :key="product.id"
      :product="product"
      :is-shopping-list="true"
      :is-admin="props.isAdmin"
      :is-preview="props.isPreview"
      :is-owner="props.isOwner"
      :is-c-a-d-price="props.isCADPrice"
      :is-customer-guest="props.isCustomerGuest"
      :is-admin-shopping-list="props.isAdminShoppingList"
      @update:quantity="(quantity) => emit('itemQuantityUpdate', { SkuNumber: product.skuNumber, Quantity: quantity, actualQuantity: product.quantity })"
      @delete="() => emit('itemDelete', { id: product.id })"
      @addtocart="() => emit('additemtoCart', { skuNumber: product.skuNumber, Quantity: product.quantity })"
    />
  </div>
  <div v-if="hasError && !props.isLoading">
    <p class="text-body-lg mb-f2 text-neutral-50">Sorry, we are not able to load your shopping list items.</p>
  </div>
  <div v-else-if="isEmpty && !props.isLoading">
    <p class="text-body-lg mb-f2 text-neutral-50">Your shopping list is empty.</p>
  </div>
</template>
<style scoped>
@media (max-width: 767px) {
  .grid-action {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
</style>

<script setup>
import { ref, reactive, computed } from "vue";
import { useLoginMutation } from "@/api/authApi";
import { useNotificationStore } from "@/stores/NotificationStore";
import FhButton from "@/components/FhButton.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhInput from "@/components/FhInput.vue";
import FhLink from "@/components/FhLink.vue";
import { CONTACT_US } from "@/constants/routes";

const props = defineProps({
  successRedirect: {
    type: String,
    default: "/"
  },
  userName: {
    type: String,
    default: ""
  }
});

const emit = defineEmits(["navigate"]);

const model = reactive({
  username: props.userName,
  password: ""
});

const isValid = computed(() => model.username?.length > 0 && model.password?.length > 0);

const { mutate: loginMutate, isLoading } = useLoginMutation();
const isRedirecting = ref(false);
const isAuthError = ref(false);
const notificationStore = useNotificationStore();

function submit() {
  if (!isValid.value) return;
  loginMutate(
    {
      username: model.username.trim(),
      password: model.password,
      destinationPath: props.successRedirect
    },
    {
      onSuccess: () => {
        isRedirecting.value = true;
        window.location = props.successRedirect;
      },
      onError: (error) => {
        const defaultErrorMsg = "Sorry, something went wrong and we could not log you in. Please try again.";
        if (error.response.status === 401) {
          isAuthError.value = true;
        } else if (error.response.status === 503) {
          const retryAfter = error.response.headers["retry-after"] || 1;
          if (retryAfter) {
            const retryAfterMs = parseInt(retryAfter) * 1000;
            setTimeout(() => {
              submit();
            }, retryAfterMs);
          } else {
            notificationStore.notifyError(defaultErrorMsg);
          }
        } else {
          notificationStore.notifyError(defaultErrorMsg);
        }
      }
    }
  );
}
</script>

<template>
  <form class="flex flex-col" @submit.prevent="submit">
    <FhHeading heading="Access Your Account">
      <template #subheading>
        <p v-if="isAuthError" class="text-brand-red">
          That account could not be found or you have entered an invalid password.<br />
          Please try again or <FhLink :href="CONTACT_US">contact us</FhLink> for assistance.
        </p>
      </template>
    </FhHeading>
    <div class="mb-f8 flex flex-col gap-f4">
      <FhInput v-model="model.username" label="Email or Customer Number" placeholder="Enter email or customer number" />
      <FhInput v-model="model.password" type="password" label="Password" placeholder="Enter password" />
    </div>
    <div class="flex flex-col gap-3 md:items-start md:self-end">
      <FhButton type="submit" color="primary" :disabled="!isValid || isLoading || isRedirecting" :is-loading="isLoading || isRedirecting">Continue</FhButton>
      <FhButton variant="link" size="small" @click="emit('navigate', 'forgot')">Forgot Password?</FhButton>
    </div>
  </form>
</template>

<script setup>
import { ref, reactive, watch, toRef, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { SHIPPING_DETAILS_FH_CARRIERS_SUB_STEPS as SUB_STEPS, FREIGHT_SERVICE_LEVEL, FREIGHT_SERVICE_LEVEL_DEFINITION } from "@/constants";
import { watchOnce } from "@vueuse/shared";
import FhButton from "@/components/FhButton.vue";
import FhCheckoutSelectionSummary from "@/components/FhCheckoutSelectionSummary.vue";
import FhInput from "@/components/FhInput.vue";
import FhCheckoutFreightOption from "@/components/FhCheckoutFreightOption.vue";
import FhCheckoutHoldDateSelect from "@/components/FhCheckoutHoldDateSelect.vue";
import { getHoldDateLimits } from "@/util/dateFunctions";

const props = defineProps({
  subStep: {
    type: Number,
    default: SUB_STEPS.SERVICE_LEVEL_SELECTION,
    validator: (val) => Object.values(SUB_STEPS).includes(val)
  },
  /**
   * The shippingAddress object from the cart, needed for the freight service cost lookup
   */
  shippingAddress: {
    type: Object,
    required: true
  },
  /**
   * The cart totalItemsPrice value from the cart, needed for the freight service cost lookup
   */
  totalItemsPrice: {
    type: Number,
    required: true
  },
  /**
   * The selected freight service level
   */
  freightServiceLevel: {
    type: String,
    default: null,
    validator: (val) => Object.values(FREIGHT_SERVICE_LEVEL).includes(val)
  },
  carrierNotes: {
    type: String,
    default: null
  },
  holdDate: {
    type: String,
    default: null
  },
  isSaving: {
    type: Boolean,
    default: false
  },
  isResidentialShippingEligible: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["save", "complete"]);

const data = reactive({
  freightServiceLevel: props.freightServiceLevel,
  carrierNotes: props.carrierNotes,
  holdDate: props.holdDate
});

const filteredFreightServiceLevels = computed(() => {
  if (props.isResidentialShippingEligible) {
    // If Cart is only Wallpaper swatches, return STANDARD option only
    // This is to prevent Rush/Expediated shipping options from showing on order of only wallpaper swatches
    return [FREIGHT_SERVICE_LEVEL.STANDARD];
  } else {
    // If Cart is other items than just swatches, return all options
    return FREIGHT_SERVICE_LEVEL;
  }
});

const isDateValid = (value) => {
  return !helpers.req(value) || (value <= maxDate && value >= minDate);
};

// Update `data` values in response to corresponding props changing
Object.keys(data).forEach((key) => watch(toRef(props, key), (val) => (data[key] = val)));

const vuelidate = useVuelidate(
  {
    holdDate: {
      isDateValid: helpers.withMessage("Please choose a date within 90 days from today.", isDateValid)
    }
  },
  data
);

const currentSubStep = ref(props.subStep);

const { minDate, maxDate } = getHoldDateLimits();

function goToNextStep() {
  currentSubStep.value = currentSubStep.value + 1;
}

function save() {
  if (currentSubStep.value === SUB_STEPS.SERVICE_LEVEL_SELECTION) {
    if (props.freightServiceLevel === data.freightServiceLevel) {
      goToNextStep();
    } else {
      watchOnce(
        () => props.freightServiceLevel,
        () => goToNextStep()
      );
      emit("save", { ...data });
    }
  }
}

function complete() {
  if (vuelidate.value.$validate()) {
    emit("complete", { ...data });
  }
}
</script>

<template>
  <div>
    <div v-if="currentSubStep === SUB_STEPS.SERVICE_LEVEL_SELECTION" class="mt-f5 flex flex-col gap-f8">
      <fieldset>
        <legend class="mb-f4">
          <div class="mb-1 text-f-base-xl">Select a delivery service level</div>
          <div class="text-neutral-50">Time estimates are based on when shipment leaves the warehouse</div>
        </legend>
        <FhCheckoutFreightOption
          v-for="serviceLevel in filteredFreightServiceLevels"
          :key="serviceLevel"
          v-model="data.freightServiceLevel"
          :freight-service-level="serviceLevel"
          :is-residential-shipping-eligible="isResidentialShippingEligible"
          :shipping-country="props.shippingAddress.country"
          :shipping-postal-code="props.shippingAddress.postalCode"
          :total-items-price="props.totalItemsPrice"
          class="fsl-option-card relative -mb-px"
        />
      </fieldset>
      <FhButton color="primary" :disabled="!data.freightServiceLevel || props.isSaving" class="self-end" @click="save">Continue</FhButton>
    </div>
    <div v-if="currentSubStep === SUB_STEPS.OTHER_DETAILS" class="flex flex-col gap-f8">
      <FhCheckoutSelectionSummary title="Service Level" @change="currentSubStep = SUB_STEPS.SERVICE_LEVEL_SELECTION">
        {{ FREIGHT_SERVICE_LEVEL_DEFINITION[props.freightServiceLevel].displayName }},
        {{ FREIGHT_SERVICE_LEVEL_DEFINITION[props.freightServiceLevel].description }}
      </FhCheckoutSelectionSummary>
      <FhCheckoutHoldDateSelect v-model="data.holdDate" :min-date="minDate" :max-date="maxDate" :vuelidate="vuelidate" />
      <div>
        <div class="mb-f4 text-f-base-xl">Additional Details <span class="text-neutral-50">(Optional)</span></div>
        <FhInput
          v-model="data.carrierNotes"
          label="Carrier and/or Freight Broker Instructions"
          placeholder="Ex. 24 hour call ahead"
          :wrapper-attrs="{ class: 'max-w-prose' }"
          note="Special requests, such as 24 hr call ahead ($50) and Liftgate ($200), require an additional charge and are typically only available for Expedited and Rush service. If you are shipping internationally, please include freight broker name, contact and phone number here."
        />
      </div>
      <FhButton color="primary" class="w-full" :disabled="props.isSaving" @click="complete">Continue to Payment</FhButton>
    </div>
  </div>
</template>

<style scoped>
.fsl-option-card.selected {
  z-index: 1;
}
</style>

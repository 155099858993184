<script setup>
import { ref, computed, onMounted } from "vue";
import formatPrice from "@/util/formatPrice.js";
import { MEASUREMENT_UNITS, MEASUREMENT_SYSTEM } from "@/constants/measurements";
import FhProductDetailSpec from "@/components/FhProductDetailSpec.vue";

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  isAuthenticated: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  displaySystem: {
    type: String,
    default: () => MEASUREMENT_SYSTEM.IMPERIAL,
    validator: (val) => Object.keys(MEASUREMENT_SYSTEM).includes(val)
  }
});

const tearSheetProductSku = ref(props.product?.sku);
const showPrice = computed(() => tearSheetProductSku.value.price && props.isAuthenticated);
const availabilityUpdatedTimeAgo = new Date().toLocaleDateString();
const tearSheetImages = computed(() => tearSheetProductSku.value?.tearsheetMedia || []);

const tearSheetProductSpec = computed(() => {
  let sku = tearSheetProductSku.value;
  let specs = [];
  if (sku?.dimensionsImperial) specs.push({ label: "Overall Dimensions", value: sku.dimensionsImperial });
  if (sku?.colors) specs.push({ label: "Colors", value: sku.colors });
  if (sku?.materials) specs.push({ label: "Materials", value: sku.materials });
  if (sku?.hasPerformanceFabric) specs.push({ label: "Fabric", value: "Performance" });
  if (sku?.weightPounds) specs.push({ label: "Weight", value: sku.weightPounds, unit: MEASUREMENT_UNITS.POUND });
  if (sku?.volumeCubicFeet) specs.push({ label: "Volume", value: sku.volumeCubicFeet, unit: MEASUREMENT_UNITS.CUBIC_FOOT });
  return specs;
});

onMounted(() => {
  print();
});
</script>

<template>
  <table>
    <tr>
      <td class="w-1/3 align-top">
        <section class="grid grid-cols-2 gap-3">
          <template v-for="(productImage, index) in tearSheetImages" :key="index">
            <img
              :class="{ 'col-span-2': index === 0 }"
              class="mb-3 border border-solid border-neutral-30"
              :src="productImage.largeUrl"
              :alt="productImage.altText"
            />
          </template>
        </section>
      </td>
      <td class="w-1/2 align-top">
        <section class="tearsheet pl-7 text-neutral-50">
          <!-- eyebrowText NAME -->
          <div v-if="tearSheetProductSku?.eyebrowText" class="text-body-sm font-medium md:w-full mb-f1 text-neutral-50">
            {{ tearSheetProductSku.eyebrowText }}
          </div>
          <h1 class="text-5xl text-neutral-70">{{ props.product.name }}</h1>
          <section class="text-body mb-f1">
            <span v-if="tearSheetProductSku.name">{{ tearSheetProductSku.name }}&nbsp;&bull;&nbsp;</span>
            <span>{{ tearSheetProductSku.skuNumber }}</span>
          </section>
          <section v-if="showPrice" class="mt-f3 flex items-center gap-f3 text-neutral-70">
            <div class="text-f-3xl-5xl" :class="{ 'text-brand-red': tearSheetProductSku.isDiscounted }">
              <span v-if="props.isCADPrice && !props.isAdmin">CAD</span>{{ formatPrice(tearSheetProductSku.price) }}
            </div>
            <div
              v-if="tearSheetProductSku.isDiscounted && tearSheetProductSku.originalPrice"
              class="text-f-3xl-5xl line-through"
              :class="{ 'hidden md:block': tearSheetProductSku.secondaryPrice }"
            >
              <span v-if="props.isCADPrice && !props.isAdmin">CAD</span>{{ formatPrice(tearSheetProductSku.originalPrice) }}
            </div>
          </section>
          <section class="my-5">
            <h3 class="text-md mb-2 inline-block border-b border-b-slate pb-1 font-bold text-neutral-70">Overview</h3>
            <div>{{ tearSheetProductSku.description }}</div>
          </section>
          <section>
            <h3 class="text-md mb-2 inline-block border-b border-b-slate pb-1 font-bold text-neutral-70">Details</h3>
            <div v-for="spec in tearSheetProductSpec" :key="spec.label" class="text-body spec-list mb-2">
              <FhProductDetailSpec v-bind="spec" :display-system="props.displaySystem" />
            </div>
          </section>
          <section v-if="props.isAuthenticated" class="mt-8 w-full border border-solid border-neutral-30 bg-neutral-10 p-f2">
            <div v-if="tearSheetProductSku.availabilityDescription">
              <div
                v-if="tearSheetProductSku.availabilityDescription"
                class="font-semibold text-neutral-70"
                v-html="tearSheetProductSku.availabilityDescription.replaceAll('strong', 'span')"
              ></div>
              <div v-if="availabilityUpdatedTimeAgo">As of {{ availabilityUpdatedTimeAgo }}</div>
            </div>
          </section>
        </section>
      </td>
    </tr>
  </table>
</template>

<style lang="pcss" scoped>
.spec-list {
  line-height: 14pt;
  &::v-deep(span:first-child) {
    @apply block font-bold text-neutral-70;
  }
  &::v-deep(span:last-child) {
    @apply block;
  }
}

.tearsheet {
  line-height: 14pt;
}
</style>

<script setup>
import { computed } from "vue";
import FhSection from "../includes/FhSection.vue";
import { getDataRefs } from "../util/vue";

// THIS CAUSES WEB BUILD AND CHROMATIC TO BREAK, THOUGH IT WORKS LOCALLY
// import resolveConfig from "tailwindcss/resolveConfig";
// import tailwindConfig from "../../tailwind.config.js";
// const fullConfig = resolveConfig(tailwindConfig);
// const mediaLg = computed(() => `(min-width: ${fullConfig.theme.screens.lg})`);
// const mediaMd = computed(() => `(min-width: ${fullConfig.theme.screens.md})`);

const mediaLg = computed(() => `(min-width: 1200px)`);
const mediaMd = computed(() => `(min-width: 768px)`);

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { imagePathMobile, imagePathTablet, imagePathDesktop, imageAltText } = getDataRefs(props.data);
</script>

<template>
  <FhSection aria-label="About Us Hero" :base="style.base">
    <picture class="w-full">
      <source :srcset="imagePathDesktop" :media="mediaLg" />
      <source :srcset="imagePathTablet" :media="mediaMd" />
      <img class="h-auto w-full" :src="imagePathMobile" :alt="imageAltText" />
    </picture>
  </FhSection>
</template>

<style lang="pcss" scoped></style>

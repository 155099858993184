<script setup>
import { computed } from "vue";
import { useNotificationStore } from "../stores/NotificationStore.js";
import FhNotification from "./FhNotification.vue";

const NOTIFICATION_DURATION = 3000;
const NOTIFICATION_DURATION_LONG = 5000;

const notificationStore = useNotificationStore();
const notifications = computed(() => notificationStore.notifications);

const timers = {};

function startTimer(notification) {
  if (!timers[notification.id]) {
    // show errors for longer to give the user more time to digest them
    const time = notification.type === "error" ? NOTIFICATION_DURATION_LONG : NOTIFICATION_DURATION;
    timers[notification.id] = setTimeout(() => dismiss(notification), time);
  }
}

function cancelTimer(notification) {
  if (timers[notification.id]) {
    clearTimeout(timers[notification.id]);
    delete timers[notification.id];
  }
}

function dismiss(notification) {
  notificationStore.dismiss(notification.id);
}
</script>

<template>
  <Teleport to="body">
    <div class="fixed left-0 top-0 right-0 z-body-notifications" aria-live="assertive">
      <TransitionGroup
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="-translate-y-1/2 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition ease-in duration-200 absolute"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="-translate-y-1/2 opacity-0"
        move-class="transtion duration-500"
      >
        <FhNotification
          v-for="notification in notifications"
          :key="notification.id"
          v-bind="notification"
          @vue:mounted="startTimer(notification)"
          @mouseenter="cancelTimer(notification)"
          @mouseleave="startTimer(notification)"
          @dismiss="dismiss(notification)"
          @link-click="dismiss(notification)"
        />
      </TransitionGroup>
    </div>
  </Teleport>
</template>

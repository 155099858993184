<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 6C5.44721 6 4 7.49397 4 9.28C4 10.4052 4.5106 11.6013 5.86655 13.1938C7.24178 14.809 9.2474 16.5632 11.8632 18.8403L11.8646 18.8416L12 18.9599L12.1368 18.8403C14.7526 16.5632 16.7582 14.809 18.1334 13.1938C19.4894 11.6013 20 10.4052 20 9.28C20 7.49397 18.5528 6 16.5 6C15.3349 6 14.2053 6.53083 13.491 7.3298L12.7455 8.16367H11.2545L10.509 7.3298C9.79469 6.53083 8.66515 6 7.5 6ZM2 9.28C2 6.31629 4.4172 4 7.5 4C9.23996 4 10.91 4.77757 12 5.9968C13.09 4.77757 14.76 4 16.5 4C19.5828 4 22 6.31629 22 9.28C22 11.0894 21.1502 12.7357 19.6562 14.4904C18.1626 16.2447 16.0251 18.1071 13.4506 20.3483C13.4504 20.3485 13.4508 20.3481 13.4506 20.3483L12 21.616L10.55 20.3488C10.5498 20.3486 10.5502 20.349 10.55 20.3488C7.97552 18.1076 5.83742 16.2447 4.34375 14.4904C2.84975 12.7357 2 11.0894 2 9.28Z"
      fill="#363636"
    />
  </svg>
</template>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 19.0002V11.0002H5V19.0002C5 19.2654 5.10536 19.5197 5.29289 19.7073C5.48043 19.8948 5.73478 20.0002 6 20.0002H18C18.2652 20.0002 18.5196 19.8948 18.7071 19.7073C18.8946 19.5197 19 19.2654 19 19.0002V11.0002H21V19.0002C21 19.7958 20.6839 20.5589 20.1213 21.1215C19.5587 21.6841 18.7957 22.0002 18 22.0002H6C5.20435 22.0002 4.44129 21.6841 3.87868 21.1215C3.31607 20.5589 3 19.7958 3 19.0002Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 1.58594L16.7071 6.29304L15.2929 7.70726L12 4.41436L8.70711 7.70726L7.29289 6.29304L12 1.58594Z"
      fill="currentColor"
    />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 16.0002V3.00015H13V16.0002H11Z" fill="currentColor" />
  </svg>
</template>

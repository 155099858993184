<script setup>
import { getDataRefs } from "../util/vue";
import FhHeading from "@/components/FhHeading.vue";
import FhSection from "../includes/FhSection.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { heading, downloadLabel, downloadUrl, downloadTarget, tipsCopy } = getDataRefs(props.data);
</script>

<template>
  <FhSection :base="style.base">
    <!-- PRODUCT SUPPORT -->
    <div class="mb-f4 flex flex-col border-b border-solid border-neutral-70 border-opacity-10 pb-10 text-neutral-70 md:pb-f10">
      <FhHeading tag="h2">{{ heading }}</FhHeading>
      <div v-if="downloadLabel" class="mb-8">
        <a :href="downloadUrl" :aria-label="downloadLabel" :target="downloadTarget" class="mb-2 inline-flex font-bold" download>
          <span
            :class="`pr-3 after:-mr-3 after:inline-block after:h-[12px] after:w-[12px] after:bg-[url('/src/assets/icons/icon-download.svg')] after:bg-contain after:bg-center after:content-['']`"
          >
            <span class="underline">
              {{ downloadLabel }}
            </span>
            &nbsp;&nbsp;
          </span>
        </a>
      </div>
      <div class="md:grid md:grid-cols-7">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="last:mb-0 md:col-span-5 md:mb-10" v-html="tipsCopy"></div>
      </div>
    </div>
  </FhSection>
</template>

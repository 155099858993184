<script setup>
import { computed, ref } from "vue";
import FhModal from "../FhModal.vue";
import FhCheckbox from "../FhCheckbox.vue";
import FhButton from "../FhButton.vue";

const emit = defineEmits(["update:modelValue", "submit", "cancel"]);
const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const values = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  }
});

const modal = ref(null);
const isOpen = computed(() => props.isOpen);
const toggleModal = () => {
  emit("cancel");
};

const allowSubmit = computed(() => {
  return Object.entries(values.value).every(([key, value]) => {
    if (key === "homeNewsletter") return true;
    if (key === "outletNewsletter") return true;
    return String(value).trim().length;
  });
});

const submit = () => {
  emit("submit", values.value);
};
</script>

<template>
  <slot :toggle-modal="toggleModal">
    <!-- Button To Open/Close Injected Here -->
  </slot>
  <FhModal ref="modal" v-model:is-open="isOpen">
    <template #body>
      <h2 class="mb-f3 font-serif text-f-2xl-4xl">Email Preferences</h2>
      <p class="mb-f8 font-bold">Manage your preferences for {{ values.emailAddress }}</p>
      <div class="mb-f5 flex flex-col">
        <FhCheckbox v-model="values.homeNewsletter" label="Four Hands Home Newsletter" class="" />
        <p class="ml-8 text-f-sm-base text-neutral-50">Stay informed of Four Hands Home exclusive events, sales and new product offerings.</p>
      </div>
      <div class="flex flex-col">
        <FhCheckbox v-model="values.outletNewsletter" label="Four Hands Home Outlet Newsletter" class="" />
        <p class="mb-f8 ml-8 text-f-sm-base text-neutral-50">Stay informed of Four Hands Home Outlet exclusive events, sales and new product offerings.</p>
      </div>
    </template>

    <template #footer>
      <FhButton
        color="primary"
        aria-label="Submit Newsletter Signup`"
        :disabled="!allowSubmit || props.isLoading !== false || (!values.homeNewsletter && !values.outletNewsletter)"
        :is-loading="props.isLoading"
        @click.stop.prevent="submit"
      >
        Sign Up
      </FhButton>
      <FhButton variant="text" aria-label="Cancel Newsletter Signup" @click="toggleModal">Cancel</FhButton>
    </template>
  </FhModal>
</template>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.542 7V13H11.4587V7H13.542Z" fill="currentColor" />
    <path d="M13.5524 15H11.4587V17H13.5524V15Z" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.04199 12C1.04199 5.92487 6.17206 1 12.5003 1C18.8286 1 23.9587 5.92487 23.9587 12C23.9587 18.0751 18.8286 23 12.5003 23C6.17206 23 1.04199 18.0751 1.04199 12ZM12.5003 3C7.32266 3 3.12533 7.02944 3.12533 12C3.12533 16.9706 7.32266 21 12.5003 21C17.678 21 21.8753 16.9706 21.8753 12C21.8753 7.02944 17.678 3 12.5003 3Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup>
import { computed, watch, onMounted, onUnmounted, ref } from "vue";
import TransitionExpand from "../../transitions/TransitionExpand.vue";
import FhIcon from "@/components/FhIcon.vue";
import IconChevronDown from "../../assets/icon-chevron-down.svg";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  isNavItem: {
    type: Boolean,
    default: false
  },
  viewAllLink: {
    default: () => ({ url: "", title: "" }),
    validator: (value) => {
      return value && typeof value.url === "string" && typeof value.title === "string";
    }
  }
});

const isActive = ref(false);

const anchorTitle = computed(() => {
  const aText = props.item.title?.toLowerCase().replace(/[^\w\s]/gi, "");
  return aText?.split(" ").join("-").toLowerCase() ?? "";
});

const windowHash = ref(window.location.hash);

watch(windowHash, (newHash) => {
  if (newHash.replace("#", "") === anchorTitle.value) {
    scrollToFaq();
  }
});

const scrollToFaq = () => {
  const element = document.querySelector(windowHash.value);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
    const openAnimation = setTimeout(() => {
      isActive.value = true;
      clearTimeout(openAnimation);
    }, 300);
  }
};

onMounted(() => {
  const isFaq = computed(() => window.location.pathname.includes("faq"));
  if (isFaq.value) {
    document.body.classList.add("smooth-scroll");
  }

  if (window.location.hash.length > 0 && window.location.hash.replace("#", "") === anchorTitle.value) {
    scrollToFaq();
  }
  window.addEventListener("hashchange", () => {
    windowHash.value = window.location.hash;
  });
});

onUnmounted(() => {
  window.removeEventListener("hashchange", () => {
    windowHash.value = window.location.hash;
  });
});
</script>

<template>
  <!-- ACCORDION ITEM -->
  <div :class="[!isNavItem ? 'border-b border-neutral-20' : 'border-b-[1px] border-neutral-30']">
    <!-- ACCORDION title -->
    <button
      :id="anchorTitle"
      :aria-expanded="isActive"
      :class="[
        isNavItem
          ? 'flex w-full items-center text-xl px-5 leading-tight bg-white py-6'
          : 'flex w-full cursor-pointer list-none items-start py-f4 text-neutral-70 focus:outline-none'
      ]"
      @click="isActive = !isActive"
    >
      <!-- title TITLE -->
      <span class="pointer-events-none w-full grow basis-auto text-left" :class="[!isNavItem ? 'text-f-base-2xl' : '']">
        {{ item.title }}
      </span>
      <template v-if="isNavItem">
        <FhIcon v-if="!isActive" name="PlusSign" />
        <FhIcon v-else name="MinusSign" />
      </template>
      <template v-else>
        <span class="pointer-events-none ml-8 shrink pt-1.5 pr-6">
          <img
            :src="IconChevronDown"
            alt="Chevron Down"
            class="ChevronDown h-2 w-3.5 transition-transform duration-200 ease-linear lg:h-3 lg:w-5"
            :class="{ 'rotate-180': isActive }"
          />
        </span>
      </template>
    </button>
    <TransitionExpand>
      <!-- ACCORDION CONTENT -->
      <div v-show="isActive" :aria-hidden="!isActive" :class="[isNavItem ? 'border-t-[1px] border-neutral-30' : '']">
        <template v-if="isNavItem">
          <div class="py-2.5">
            <div v-for="(copyItem, index) in item.copy" :key="index">
              <a :href="copyItem.url" class="flex items-center text-xl px-10 leading-tight bg-white py-5">{{ copyItem.content }}</a>
            </div>
            <a :href="viewAllLink.url" class="flex items-center text-xl px-10 leading-tight bg-white py-5">{{ viewAllLink.title }}</a>
          </div>
        </template>
        <template v-else>
          <div :class="['mb-f5 ml-1 flex flex-col pl-f5 text-f-sm-xl text-neutral-50 md:ml-1.5 md:pr-f25 lg:ml-0']">
            <FhWysiwyg>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <!-- Does this need to be rendered like this? Can we refactor to avoid the eslint warning? -->
              <p class="text-inherit" v-html="item.copy"></p>
            </FhWysiwyg>
          </div>
        </template>
      </div>
    </TransitionExpand>
  </div>
</template>

<style lang="pcss">
html:has(body.smooth-scroll) {
  scroll-behavior: smooth;
}
</style>

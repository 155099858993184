<script setup>
import { getDataRefs } from "../util/vue";
import FhHeading from "@/components/FhHeading.vue";
import FhSection from "../includes/FhSection.vue";
import FhRepresentativeCard from "./FhRepresentativeCards/FhRepresentativeCard.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { heading, cards } = getDataRefs(props.data);
</script>

<template>
  <FhSection :base="style.base">
    <div class="mb-16 flex flex-col text-neutral-70 md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10 md:pb-16">
      <FhHeading tag="h2">{{ heading }}</FhHeading>
      <div class="grid grid-cols-[1fr] gap-y-[1rem] md:grid-cols-[repeat(2,1fr)] md:gap-y-[2.5rem] md:gap-x-[1.5rem]">
        <FhRepresentativeCard v-for="(card, i) in cards" :key="`card-${i}`" :card="card"></FhRepresentativeCard>
      </div>
    </div>
  </FhSection>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import { debounce } from "lodash";
import FhImage from "@/components/FhImage.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhButton from "@/components/FhButton.vue";
import FhProductTags from "@/components/FhProductTags.vue";
import formatPrice from "@/util/formatPrice.js";
import FhQuantityInput from "@/components/FhQuantityInput.vue";
import { event_selectItem } from "@/util/googleAnalytics";
import { isMobile } from "mobile-device-detect";

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isShoppingList: {
    type: Boolean,
    default: false
  },
  isOwner: {
    type: Boolean,
    default: false
  },
  isPreview: {
    type: Boolean,
    default: false
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  isAdminShoppingList: {
    type: Boolean,
    default: false
  }
});

const hoverSwatch = ref(null);
const selectedSwatch = ref(null);
const activeSwatch = computed(() => hoverSwatch.value ?? selectedSwatch.value);
const activeDetailsObj = computed(() => activeSwatch.value ?? props.product);

const isImageHovered = ref(false);
const image = computed(() => activeDetailsObj.value.images?.default);
const hoverImage = computed(() => activeDetailsObj.value.images?.hover);
const imageSrc = computed(() => (isImageHovered.value && hoverImage.value ? hoverImage.value : image.value));

const url = computed(() => activeDetailsObj.value.url);
const tags = computed(() => activeDetailsObj.value.tags);
const inStock = computed(() => activeDetailsObj.value.inStock);

const price = computed(() => activeDetailsObj.value.price);
const originalPrice = computed(() => activeDetailsObj.value.originalPrice);
const isDiscounted = computed(() => activeDetailsObj.value.isDiscounted);

const buildPriceString = (priceData) => {
  if (typeof priceData === "object" && priceData !== null && !Number.isNaN(Number(priceData.min)) && !Number.isNaN(Number(priceData.max))) {
    if ((props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin) || (props.isCADPrice && props.isAdmin && props.isPreview)) {
      return `CAD${formatPrice(Number(priceData.min))} - CAD${formatPrice(Number(priceData.max))}`;
    } else {
      return `${formatPrice(Number(priceData.min))} - ${formatPrice(Number(priceData.max))}`;
    }
  } else if (priceData !== null && priceData !== "" && !Number.isNaN(Number(priceData))) {
    if ((props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin) || (props.isCADPrice && props.isAdmin && props.isPreview)) {
      return `CAD${formatPrice(Number(priceData))}`;
    } else {
      return formatPrice(Number(priceData));
    }
  } else {
    return undefined;
  }
};

const priceString = computed(() => buildPriceString(price.value));
const originalPriceString = computed(() => buildPriceString(originalPrice.value));

// Show the original (strikethrough) price when the isDiscounted prop is true, except when:
//   1. price is a range (i.e., price string contains a dash)
//   2. price and originalPrice are the same
const showOriginalPrice = computed(() => isDiscounted.value && priceString.value.indexOf("-") === -1 && price.value !== originalPrice.value);

const handleSwatchMouseEnter = (swatch) => (hoverSwatch.value = swatch);
const handleSwatchMouseLeave = () => (hoverSwatch.value = null);
const handleSwatchClick = (swatch) => (selectedSwatch.value = swatch);
const isSwatchSelected = (swatch) => selectedSwatch.value && selectedSwatch.value.swatchName === swatch.swatchName;
const categoryList = inject("product_category_list");

const navigateToProduct = (event) => {
  if (props.isShoppingList && event.srcElement.localName === "img") {
    window.location = props.product.url;
  } else if (props.product.url && !props.isShoppingList) {
    event_selectItem([props.product], categoryList);
    window.location = url.value;
  }
};
const emit = defineEmits(["update:quantity", "delete", "addtocart", "addtoshoppinglist"]);

const _quantity = computed({
  get: () => props.product.quantity,
  set: debounce((val) => {
    if (val && val !== props.product.quantity) {
      emit("update:quantity", val);
    }
  }, 500)
});

const getSwatchName = () => {
  if (!activeDetailsObj.value?.swatches) return activeDetailsObj.value?.skuName || activeDetailsObj.value?.swatchName;

  if (activeSwatch.value) return activeSwatch.value.swatchName;

  return activeDetailsObj.value?.swatches[0]?.swatchName;
};
const getSwatchPrice = () => {
  if (activeDetailsObj.value?.swatches) {
    const activesku = activeDetailsObj.value?.sku || activeDetailsObj.value?.skuNumber;
    const swatch = activeDetailsObj.value?.swatches.find((sku) => sku.skuNumber === activesku);
    return swatch.skuPrice.price;
  } else if (activeDetailsObj.value.skuPrice != null) {
    return activeDetailsObj.value.skuPrice.price;
  } else {
    return activeDetailsObj.value.price;
  }
};

const getSelectedProductObject = () => {
  return {
    name: props.product.name,
    skuNumber: activeSwatch.value ? activeSwatch.value.skuNumber : activeDetailsObj.value?.sku,
    thumbUrl: image.value ?? props.product.images.default,
    price: getSwatchPrice(),
    skuName: getSwatchName(),
    categoryList,
    index: props.product?.index,
    inStock: inStock.value
  };
};

const handleHeartClick = () => {
  emit("addtoshoppinglist", getSelectedProductObject());
};
const isImageHoveredFn = (isHovered) => {
  if (!isMobile) {
    isImageHovered.value = isHovered;
  }
};
</script>

<template>
  <component :is="props.product.url ? 'a' : 'div'" :href="props.isShoppingList ? undefined : url" class="block" @click.prevent="navigateToProduct">
    <div class="relative" :class="{ group: !isShoppingList }" @mouseenter="isImageHoveredFn(true)" @mouseleave="isImageHoveredFn(false)">
      <!-- PRODUCT TAGS -->
      <FhProductTags :tags="tags" class="absolute top-4 left-4 z-[1] hidden md:block" />
      <!-- PRODUCT IMAGE -->
      <FhImage
        :class="{ 'cursor-pointer': isShoppingList }"
        :src="isShoppingList ? props.product.thumbUrl : imageSrc"
        :alt="isShoppingList ? props.product.productName : props.product.name"
        square
        border
      />
      <div v-if="!isShoppingList" class="absolute right-1 top-1 md:right-5 md:top-5 lg:hidden lg:group-hover:block">
        <button aria-label="Add to List" class="rounded-full bg-white p-2" @click.stop.prevent="handleHeartClick">
          <FhIcon name="OutlineHeart" class="w-[24px] h-[24px] text-gray-500" />
        </button>
      </div>
    </div>
    <div
      v-if="isShoppingList && !props.isPreview"
      class="mt-6 hidden gap-4 md:grid"
      :class="(props.isOwner && isAdmin) || (isAdmin && !props.isAdminShoppingList) ? 'grid-cols-3' : 'grid-cols-1'"
    >
      <!-- First column -->
      <div
        v-if="(isShoppingList && props.isOwner) || (isShoppingList && props.isAdmin && !props.isAdminShoppingList)"
        :class="{ 'col-span-3': !props.isOwner && !props.isAdmin }"
      >
        <FhQuantityInput v-model="_quantity" class="md:mb-2.5 md:w-full" />
      </div>

      <!-- Second column -->
      <div
        v-if="(isShoppingList && props.isOwner && isAdmin) || (isShoppingList && isAdmin && !props.isAdminShoppingList)"
        class="col-span-2"
        :class="{ 'col-span-3': !props.isOwner && !isAdmin }"
      >
        <FhButton class="mb-2.5 w-full border-neutral-30 md:w-full" @click="emit('addtocart')"> Add to Cart </FhButton>
      </div>
    </div>

    <!-- PRODUCT CARD CONTENT CONTAINER -->
    <div class="relative flex flex-col px-2.5 py-f3">
      <!-- eyebrowText NAME -->
      <div v-if="props.product.eyebrowText" class="text-body-sm font-medium mb-f1 text-neutral-50">Amber Lewis x Four Hands</div>
      <div
        :class="{
          'flex grow flex-col md:flex-row md:items-baseline md:justify-between': !isShoppingList,
          'flex items-center justify-between text-f-sm-lg text-neutral-70': isShoppingList
        }"
      >
        <!-- PRODUCT NAME -->
        <div class="mb-f1 flex flex-row items-baseline justify-between">
          <div class="mr-f3 text-f-sm-lg">{{ isShoppingList ? props.product.productName : props.product.name }}</div>
          <!-- MOBILE IN STOCK ICON -->
          <div class="text-xs md:hidden">
            <FhIcon
              name="LightningBolt"
              class="relative -top-px"
              :class="{
                hidden: !inStock || isShoppingList
              }"
            />
          </div>
        </div>
        <!-- PRODUCT PRICE -->
        <div v-if="price && !isShoppingList" class="mb-f1 whitespace-nowrap text-f-xs-lg">
          <span v-if="showOriginalPrice" class="mr-2 line-through">
            {{ originalPriceString }}
          </span>
          <span :class="{ 'text-brand-red': isDiscounted }">
            {{ priceString }}
          </span>
        </div>
        <div v-if="isShoppingList && props.isOwner && !props.isPreview" class="mb-f1 whitespace-nowrap text-f-xs-lg">
          <span v-if="props.product.isDiscounted && props.isAdmin" class="mr-2 line-through">
            <span
              v-if="
                (props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin) || (props.isCADPrice && props.isAdmin && props.isPreview)
              "
              >CAD</span
            >{{ formatPrice(props.product.originalPrice * props.product.quantity) }}
          </span>
          <span v-if="props.product.totalPrice" :class="{ 'text-brand-red': props.product.isDiscounted && props.isAdmin }">
            <span
              v-if="
                (props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin) || (props.isCADPrice && props.isAdmin && props.isPreview)
              "
              >CAD</span
            >{{ formatPrice(props.product.totalPrice) }}
          </span>
        </div>
        <div
          v-if="
            (isShoppingList && props.isPreview) ||
            (isShoppingList && props.isAdmin && !props.isOwner && props.isAdminShoppingList) ||
            (isShoppingList && props.isAdmin && !props.isOwner && !props.isAdminShoppingList) ||
            (isShoppingList && !props.isAdmin && !props.isOwner)
          "
          class="text-neutral-50 whitespace-nowrap"
        >
          Qty: {{ props.product.quantity }}
        </div>
      </div>
      <div
        v-if="
          (isShoppingList && props.isPreview) ||
          (isShoppingList && props.isAdmin && !props.isOwner && props.isAdminShoppingList) ||
          (isShoppingList && props.isAdmin && !props.isOwner && !props.isAdminShoppingList) ||
          (isShoppingList && !props.isAdmin && !props.isOwner)
        "
        class="mb-f1 whitespace-nowrap text-f-xs-lg"
      >
        <span v-if="props.product.isDiscounted && props.isAdmin" class="mr-2 line-through">
          <span
            v-if="(props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin) || (props.isCADPrice && props.isAdmin && props.isPreview)"
            >CAD</span
          >{{ formatPrice(props.product.originalPrice * props.product.quantity) }}
        </span>
        <span v-if="props.product.totalPrice" :class="{ 'text-brand-red': props.product.isDiscounted && props.isAdmin }">
          <span
            v-if="(props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin) || (props.isCADPrice && props.isAdmin && props.isPreview)"
            >CAD</span
          >{{ formatPrice(props.product.totalPrice) }}
        </span>
      </div>
      <!-- ARTIST NAME -->
      <div v-if="props.product.artistName" class="text-body-sm mb-f1 text-neutral-50">
        {{ props.product.artistName }}
      </div>
      <!-- MORE OPTIONS -->
      <div v-if="props.product.hasMoreOptions" class="text-body-sm mb-f1 text-neutral-50">More Options</div>
      <!-- SWATCHES -->
      <div v-if="props.product.swatches?.length" class="mb-f1 flex flex-row flex-wrap">
        <button
          v-for="(swatch, i) in props.product.swatches"
          :key="i"
          class="group hidden items-center justify-center px-0.5 first:-ml-1 md:flex"
          :title="swatch.name"
          @click.stop.prevent="handleSwatchClick(swatch)"
          @mouseenter="handleSwatchMouseEnter(swatch)"
          @mouseleave="handleSwatchMouseLeave"
        >
          <img
            :src="swatch.swatchImage"
            :alt="swatch.name"
            class="box-content h-5 w-5 rounded-full border border-solid border-transparent object-cover p-0.5 group-hover:border-neutral-50"
            :class="{ '!border-neutral-70': isSwatchSelected(swatch) }"
          />
        </button>
        <div v-if="!props.product.hasMoreOptions" class="text-body-sm text-neutral-50 md:hidden">{{ props.product.swatches.length }} Styles</div>
      </div>
      <div v-if="isShoppingList" class="truncate text-f-xs-base text-neutral-50">
        <span v-if="props.product.skuName">{{ props.product.skuName }}&nbsp;&bull;&nbsp;</span>
        <span>{{ props.product.skuNumber }}</span>
      </div>
      <!-- IN STOCK -->
      <div v-if="inStock" class="text-body-sm flex items-center" :class="{ 'hidden md:block': !isShoppingList }">
        <span class="mr-px" :class="{ 'hidden md:inline-block': !isShoppingList }">In Stock</span>
        <FhIcon name="LightningBolt" class="relative -top-px" />
      </div>
      <!-- mobile view -->
      <div
        v-if="isShoppingList && !props.isPreview"
        class="mt-6 grid gap-4 md:hidden"
        :class="(props.isOwner && props.isAdmin) || (props.isAdmin && !props.isAdminShoppingList) ? 'grid-cols-3' : 'grid-cols-1'"
      >
        <!-- First column -->
        <div
          v-if="(isShoppingList && props.isOwner) || (props.isAdmin && !props.isAdminShoppingList)"
          :class="{ 'col-span-3': !props.isOwner && !props.isAdmin }"
        >
          <FhQuantityInput v-model="_quantity" class="md:mb-2.5 md:w-full" :class="{ 'w-auto': isShoppingList }" />
        </div>

        <!-- Second column -->
        <div
          v-if="(isShoppingList && props.isOwner && props.isAdmin) || (props.isAdmin && !props.isAdminShoppingList)"
          class="col-span-2"
          :class="{ 'col-span-3': !props.isOwner && !isAdmin }"
        >
          <FhButton class="mb-2.5 w-full border-neutral-30 md:w-full" @click="emit('addtocart')"> Add to Cart </FhButton>
        </div>
      </div>

      <div class="mt-3 flex justify-center text-neutral-50 md:mt-1 md:justify-end">
        <div
          v-if="(isShoppingList && !props.isPreview && props.isOwner) || (isAdmin && !props.isAdminShoppingList && isShoppingList)"
          class="mt-auto mb-f1 whitespace-nowrap text-center text-f-xs-lg"
        >
          <FhButton class="items-end justify-end text-f-xs-base" variant="link" @click="emit('delete')">Remove Item</FhButton>
        </div>
      </div>
    </div>
  </component>
</template>
<style scoped>
.text-3xl {
  font-size: 1.75rem;
  line-height: 1rem;
}
.truncate-text {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
